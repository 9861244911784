import { ethers } from 'ethers'
import axios from 'axios'
// import { RPC_URL, NETWORK_SCAN_URL } from '@env'


const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL)

export const getCustomChainTransaction = async (walletAddress: string, contractAddress?: string) => {
	if (contractAddress) {
		return await getERC20TransactionHistory(walletAddress, contractAddress)
	} else {
		return await getNativeTransactionHistory(walletAddress)
	}
}

const fetchTransactionHistory = async (walletAddress: string) => {
	try {
		const response = await axios.get(`${process.env.REACT_APP_NETWORK_SCAN_URL}/api/v2/addresses/${walletAddress}/transactions`)
		const transactions = response.data.items

		const filteredTransactions = transactions.filter((tx: any) => {
			// tx.to 수신자의 주소가 있을 경우
			// !tx.method 스마트 계약 함수 호출과 관련이 없는 순수 전송
			// 전송량이 0보다 커야 실제 전송이 일어난 상태

			// 기존 코드
			// return tx.to && !tx.method && tx.value > 0
			// 0xc6ad3a88: 네이티브 코인 -> ERC20 스왑 컨트랙트 콜 메소드 값
			return tx.to && (tx.value > 0 || tx.method === '0xc6ad3a88') && tx.to !== null
		})

		const internalResponse = await axios.get(
			`${process.env.REACT_APP_NETWORK_SCAN_URL}/api/v2/addresses/${walletAddress}/internal-transactions`
		)
		const internalTransactions = internalResponse.data.items

		const filteredInternalTransactions = internalTransactions.filter((tx: any) => {
			return tx.to && tx.to !== null
		})

		const mergedTransactions = [...filteredTransactions, ...filteredInternalTransactions]

		return { items: mergedTransactions }
	} catch (error) {
		console.log('Error fetching transaction history:', error)
		return { items: [] }
	}
}

const getNativeTransactionHistory = async (walletAddress: string) => {
	return await fetchTransactionHistory(walletAddress)
}

const getERC20TransactionHistory = async (walletAddress: string, contractAddress: string) => {
	const contract = new ethers.Contract(
		contractAddress,
		[
			'event Transfer(address indexed from, address indexed to, uint value)',
			'function decimals() view returns (uint8)'
		],
		provider
	)

	const filterFrom = contract.filters.Transfer(walletAddress, null)
	const filterTo = contract.filters.Transfer(null, walletAddress)

	const [decimals, logsFrom, logsTo] = await Promise.all([
		contract.decimals(),
		provider.getLogs({ ...filterFrom, fromBlock: 0, toBlock: 'latest' }),
		provider.getLogs({ ...filterTo, fromBlock: 0, toBlock: 'latest' })
	])

	const formatValue = (value: any) => ethers.utils.formatUnits(value, decimals)

	const uniqueTransactions = new Set()
	const allTransfers: any = []

	const processLog = async (log: any) => {
		const parsed = contract.interface.parseLog(log)
		const block = await provider.getBlock(log.blockNumber)
		const transaction = {
			hash: log.transactionHash,
			from: parsed.args.from,
			to: parsed.args.to,
			value: formatValue(parsed.args.value),
			// timeStamp: new Date(block.timestamp * 1000).toLocaleString(),
			timeStamp: new Date(block.timestamp * 1000).toISOString(),
			timestamp: block.timestamp
		}

		if (!uniqueTransactions.has(transaction.hash)) {
			uniqueTransactions.add(transaction.hash)
			allTransfers.push(transaction)
		}
	}

	await Promise.all(logsFrom.map(processLog))
	await Promise.all(logsTo.map(processLog))

	allTransfers.sort((a: any, b: any) => b.timestamp - a.timestamp)

	// @ts-ignore
	// const result = allTransfers.map(({ timestamp, ...rest }) => rest)
	const result = allTransfers.map(({ ...rest }) => rest)

	return result.filter((tx: any) => tx.to && (tx.from === walletAddress || tx.to === walletAddress))
}

import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeContext } from '../../context/ThemeContext';
import { Check, X, RefreshCw } from 'lucide-react';
import { ethers } from 'ethers'

import DatePicker from 'react-datepicker'; // react-datepicker 사용
import 'react-datepicker/dist/react-datepicker.css'; // DatePicker 스타일 추가
import { Spin, Modal, Button } from 'antd';

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
import Container from '../Common/Container';
import CourierText from '../../styles/CourierText';
import CustomListCardWithIcon from '../Componentization/Card/CustomListCardWithIcon';
import CustomListCard from '../Componentization/Card/CustomListCard';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkHeaderText, checkPincode, RootState } from '../../store';
import { getCustomChainTransaction } from '../../hooks/useFetchBalance';

import useWallet from '../../hooks/useWallet';
import useEthereum from '../../hooks/useEthereum';
import useEthereumOther from '../../hooks/useEthereumOther';
import useSecureStorage from '../../hooks/useSecureStorage';
import MainPageHeader from '../../nav/MainPageHeader';
import NavigationBar from '../../nav/NavigationBar';
import i18next from 'i18next'
import moment from 'moment'

import { ParamType } from 'ethers/lib/utils'

interface Wallet {
	id: string
	type: string
	amount: string
	currency: string
	date: any
	direction: 'in' | 'out'
}

interface Asset {
	id: number
	name: string
	symbol: string
	balance: string
	icon: any
}

const emptyToken: Asset = {
	id: 0,
	name: '',
	symbol: '',
	balance: '',
	icon: '/img/icon_buff.png'
}

const CACHE_DURATION = 60000

// 이전 들어왔던 토큰의 심볼
let pre_symbol = ""

// 이전페이지 캐싱 데이터
let pre_cached: Wallet[] = []

const WalletScreen: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const { t } = useTranslation();

	const { getBalance } = useEthereum()
	const { getTransactionHistory, getEthTokenInfo } = useEthereumOther()
	const { getWalletAddress, setCacheLog, getCacheLog } = useSecureStorage()

	const [loading, setLoading] = useState(false)
	const [accountAddress, setAccountAddress] = useState('')
	const [isDateModalVisible, setDateModalVisible] = useState(false)
	const [isTransactionModalVisible, setTransactionModalVisible] = useState(false)
	const [selectedTransactionOption, setSelectedTransactionOption] = useState(
		i18next.t('template_wallet_screen_message_16')
	)
	const [selectedStatusOption, setSelectedStatusOption] = useState(i18next.t('template_wallet_screen_message_22'))
	const [isStatusModalVisible, setStatusModalVisible] = useState(false)
	const [transactions, setTransactions] = useState<Wallet[]>([])
	const [filteredTransactions, setFilteredTransactions] = useState<Wallet[]>([])
	const [showStartDatePicker, setShowStartDatePicker] = useState(false)
	const [showEndDatePicker, setShowEndDatePicker] = useState(false)
	const [startDate, setStartDate] = useState<any>()
	const [endDate, setEndDate] = useState<any>()
	const [selectedDateOption, setSelectedDateOption] = useState(null)
	const [selectedStartDate, setSelectedStartDate] = useState(new Date())
	const [selectedEndDate, setSelectedEndDate] = useState(new Date())

	const [refreshing, setRefreshing] = useState(false)

	// Balance -> Wallet 으로 전달한 파라미터 받기
	// navigate 활용 state 넘기기
	// const asdf = () => {
	// 	navigate('WalletScreen', {
	// 		state: {
	// 			token: "token"
	// 		}
	// 	})
	// }
	// navigate 활용 state 받기
	// const location = useLocation();
	// const [id, setId] = useState(location.state === null ? "" : location.state.id);

	const location = useLocation();
	const [token, setToken] = useState(location.state === null ? null : location.state.token);
	useEffect(() => {
		setToken(emptyToken)
		if (location.state) {
			const newToken = location.state.token
			setToken(newToken);
			if (pre_symbol == newToken.symbol) {
				console.log('------------------------------------------')
				console.log(`이전 표시한 토큰과 동일한 토큰 2. ${pre_symbol} | ${newToken.symbol}`)
				console.log('------------------------------------------')
				setTransactions(pre_cached)
				setFilteredTransactions(pre_cached)
			} else {
				console.log('------------------------------------------')
				console.log(`이전 표시한 토큰과 다른 토큰 2. 내역 state 초기화 ${pre_symbol} | ${newToken.symbol}`)
				console.log('------------------------------------------')
				setTransactions([])
				setFilteredTransactions([])
				pre_cached = []
			}
			pre_symbol = newToken.symbol

		}
	}, [location.state]);

	const CACHE_KEY = `${token.symbol}_TRANSACTION_DATA`

	useEffect(() => {
		dispatch(checkHeaderText({ "link": "WalletScreen", "title": t('template_wallet_screen_message_01') }))
	}, [])

	useEffect(() => {
		const fetchAccountDetails = async () => {
			const walletAddress = await getWalletAddress()
			if (walletAddress) {
				setAccountAddress(walletAddress)
			}
		}
		fetchAccountDetails()
	}, [])

	useEffect(() => {
		const refreshBalanceAndTransactions = async () => {
			if (accountAddress) {
				console.log(`refreshBalanceAndTransactions`)
				try {
					if (token.symbol === process.env.REACT_APP_NETWORK_CURRENCY) {
						const balancePromise = await getBalance(accountAddress)

						const [balance] = await Promise.all([balancePromise])
						console.log(`refreshBalanceAndTransactions balance : ${balance}`)

						setToken((prevToken: any) => ({
							...prevToken,
							balance: `${parseFloat(balance || '0').toFixed(4)}`
						}))
					}
				} catch (error) {
					console.log('Error refreshing balance and transactions:', error)
				}
			}
		}

		refreshBalanceAndTransactions()
	}, [accountAddress, getBalance, token.symbol, setToken])

	const mapTransactionToWallet = (tx: any, accountAddress: string, index: number) => {
		const toAddress = tx.to ? tx.to.hash || tx.to : null
		const fromAddress = tx.from ? tx.from.hash || tx.from : null
		let fee = '0';
		const date = new Date(tx.timestamp).toISOString().split('T')[0]
		const detailDate = moment(tx.timestamp).format('YYYY. M. D. HH:mm:ss');
		let txId = ''

		// internal tx인 경우 tx.hash 값이 없음
		if (null != tx.transaction_hash && undefined != tx.transaction_hash && 'undefined' != tx.transaction_hash) {
			// console.log('\ninternal tx')
			// console.log(tx)
			// console.log('-----------------------------')
			txId = tx.transaction_hash
		} else {
			txId = tx.hash
		}

		if (null != tx.fee?.value && undefined != tx.fee?.value && 'undefined' != tx.fee?.value) {
			fee = tx.fee?.value.toString()
		} else {
			fee = '0'
		}


		return {
			id: `${txId}-${index}`,
			type:
				fromAddress.toLowerCase() === accountAddress.toLowerCase()
					? i18next.t('template_wallet_screen_message_09')
					: i18next.t('template_wallet_screen_message_10'),
			amount: ethers.utils.formatEther(tx.value),
			currency: process.env.REACT_APP_NETWORK_CURRENCY,
			date: date,
			detaildate: detailDate,
			direction: fromAddress.toLowerCase() === accountAddress.toLowerCase() ? 'out' : 'in',
			from: fromAddress.toLowerCase(),
			to: toAddress.toLowerCase(),
			fee: fee,
		}
	}

	const mapTransactionToWalletERC20 = (tx: any, accountAddress: string, index: number) => {
		let date
		let detailDate
		let fee = '0';

		try {
			// console.log(`mapTransactionToWalletERC20`)
			// console.log(`tx.timeStamp : ${tx.timeStamp}`)
			// console.log(`tx.timestamp : ${tx.timestamp}`)
			if (typeof tx.timeStamp === 'string') {
				const dateTimePart = tx.timeStamp.split(' ')
				// console.log(`dateTimePart : ${dateTimePart}`)
				date = dateTimePart.toString().split('T')[0];
				detailDate = moment.unix(tx.timestamp).format('YYYY-MM-DD HH:mm:ss');
			} else {
				date = new Date(parseInt(tx.timeStamp) * 1000).toISOString().split('T')[0]
				detailDate = moment(tx.timestamp).format('YYYY. M. D. HH:mm:ss');
			}
		} catch (error) {
			console.error('Error parsing date:', tx.timeStamp, error)
			date = 'Invalid Date'
		}

		return {
			id: `${tx.hash}-${index}`,
			type:
				tx.from.toLowerCase() === accountAddress.toLowerCase()
					? i18next.t('template_wallet_screen_message_09')
					: i18next.t('template_wallet_screen_message_10'),
			amount: tx.value,
			currency: token.symbol,
			date: date,
			detaildate: detailDate,
			direction: tx.from.toLowerCase() === accountAddress.toLowerCase() ? 'out' : 'in',
			from: tx.from.toLowerCase(),
			to: tx.to.toLowerCase(),
			fee: fee,
		}
	}

	const mapTransactionToWalletBSC = (tx: any, accountAddress: string, index: number) => {
		return {
			id: `${tx.hash}-${index}`,
			type:
				tx.from.toLowerCase() === accountAddress.toLowerCase()
					? i18next.t('template_wallet_screen_message_09')
					: i18next.t('template_wallet_screen_message_10'),
			amount: ethers.utils.formatUnits(tx.value, tx.tokenDecimal),
			currency: token.symbol || '',
			date: new Date(parseInt(tx.timeStamp) * 1000).toLocaleDateString(),
			detaildate: moment.unix(tx.timeStamp).format('YYYY. M. D. HH:mm:ss'),
			direction: tx.from.toLowerCase() === accountAddress.toLowerCase() ? 'out' : 'in',
			from: tx.from.toLowerCase(),
			to: tx.to.toLowerCase(),
			fee: (tx.gasPrice * tx.gasUsed).toString()
		}
	}

	// 페이지 진입 갱신
	useEffect(() => {
		const fetchTransactions = async () => {
			setLoading(true)
			let history
			if (token.name === 'BSC' || token.name === 'USDT') {
				history = await getTransactionHistory(accountAddress, token?.address)
			} else {
				history = await getCustomChainTransaction(accountAddress, token?.address || undefined)
			}

			let transactionsWithId
			if (token.name === 'BSC' || token.name === 'USDT') {
				transactionsWithId = history.map((tx: any, index: any) => mapTransactionToWalletBSC(tx, accountAddress, index))
			} else if (history.items) {
				transactionsWithId = history.items.map((tx: any, index: any) =>
					mapTransactionToWallet(tx, accountAddress, index)
				)
			} else {
				// console.log(history)
				transactionsWithId = history.map((tx: any, index: any) =>
					mapTransactionToWalletERC20(tx, accountAddress, index)
				)
			}

			setTransactions(transactionsWithId)
			setFilteredTransactions(transactionsWithId)
			await setCacheLog(CACHE_KEY, JSON.stringify({ data: transactionsWithId, timestamp: Date.now() }))

			setLoading(false)
		}

		const loadCachedTransactions = async () => {
			const cachedData = await getCacheLog(CACHE_KEY)
			if (cachedData) {
				const parsedCache = JSON.parse(cachedData)
				const currentTime = Date.now()

				setTransactions(parsedCache.data)
				setFilteredTransactions(parsedCache.data)
				setLoading(false)

				// 캐싱 데이터 로컬 저장
				pre_cached = parsedCache.data

				// 유효시간이 지나거나 강제 리프레싱을 할 경우 캐시된 전송내역 업데이트
				if (currentTime - parsedCache.timestamp > CACHE_DURATION || true === refreshing) {
					console.log('------------------------------------------')
					console.log(`캐싱 유효시간 지남 / 수동갱신 : ${refreshing}. 캐싱데이터 갱신 요청`)
					console.log('------------------------------------------')
					await fetchTransactions()
				}
				// else {
				// 	await fetchTransactions()
				// }
			} else {
				await fetchTransactions()
			}
		}

		if (accountAddress) {
			loadCachedTransactions()
		}
	}, [accountAddress, token.symbol])

	// 수동 리프레시
	useEffect(() => {
		const fetchTransactions = async () => {
			if (false === refreshing) {
				return
			}

			setLoading(true)

			let history
			if (token.name === 'BSC' || token.name === 'USDT') {
				history = await getTransactionHistory(accountAddress, token?.address)
			} else {
				history = await getCustomChainTransaction(accountAddress, token?.address || undefined)
			}

			let transactionsWithId
			if (token.name === 'BSC' || token.name === 'USDT') {
				transactionsWithId = history.map((tx: any, index: any) => mapTransactionToWalletBSC(tx, accountAddress, index))
			} else if (history.items) {
				transactionsWithId = history.items.map((tx: any, index: any) =>
					mapTransactionToWallet(tx, accountAddress, index)
				)
			} else {
				// console.log(history)
				transactionsWithId = history.map((tx: any, index: any) =>
					mapTransactionToWalletERC20(tx, accountAddress, index)
				)
			}

			setTransactions(transactionsWithId)
			setFilteredTransactions(transactionsWithId)
			await setCacheLog(CACHE_KEY, JSON.stringify({ data: transactionsWithId, timestamp: Date.now() }))

			setLoading(false)
		}

		if (accountAddress) {
			fetchTransactions()
		}
	}, [refreshing])

	const groupedTransactions = filteredTransactions.reduce((groups, transaction) => {
		const date = transaction.date
		if (!groups[date]) {
			groups[date] = []
		}
		groups[date].push(transaction)
		return groups
	}, {} as { [key: string]: Wallet[] })

	const applyFilters = () => {
		const filtered = transactions.filter((tx: any) => {
			const txDate = moment(tx.date, 'YYYY. M. D. A h:mm:ss').toDate()
			const normalizedTxDate: any = new Date(txDate.setHours(0, 0, 0, 0))

			let isWithinDateRange = true

			if (startDate) {
				const normalizedStartDate = new Date(startDate).setHours(0, 0, 0, 0)
				isWithinDateRange = isWithinDateRange && normalizedTxDate >= normalizedStartDate
			}

			if (endDate) {
				const normalizedEndDate = new Date(endDate).setHours(23, 59, 59, 999)
				isWithinDateRange = isWithinDateRange && normalizedTxDate <= normalizedEndDate
			}

			const isTransactionType =
				selectedTransactionOption === i18next.t('template_wallet_screen_message_16') ||
				tx.type === selectedTransactionOption

			return isWithinDateRange && isTransactionType
		})

		setFilteredTransactions(filtered)
	}

	useEffect(() => {
		applyFilters()
	}, [startDate, endDate, selectedTransactionOption])

	const handleDateSubmit = () => {
		setDateModalVisible(false)
		setStartDate(selectedStartDate)
		setEndDate(selectedEndDate)
		applyFilters()
	}

	// const copyToClipboard = () => {
	// 	Clipboard.setString(accountAddress)
	// }

	const handleDateOptionPress = (option: any) => {
		setSelectedDateOption(option)
		const now = new Date()
		let newStartDate = new Date()
		switch (option) {
			case `7${i18next.t('template_wallet_screen_message_12')}`:
				newStartDate.setDate(now.getDate() - 7)
				break
			case `30${i18next.t('template_wallet_screen_message_12')}`:
				newStartDate.setDate(now.getDate() - 30)
				break
			case `90${i18next.t('template_wallet_screen_message_12')}`:
				newStartDate.setDate(now.getDate() - 90)
				break
			case `180${i18next.t('template_wallet_screen_message_12')}`:
				newStartDate.setDate(now.getDate() - 180)
				break
		}
		setSelectedStartDate(newStartDate)
		setSelectedEndDate(now)
	}

	const handleTransactionOptionPress = (option: any) => {
		setSelectedTransactionOption(option)
		setTransactionModalVisible(false)
		applyFilters()
	}

	const onRefresh = useCallback(() => {
		setRefreshing(true)
		setTimeout(() => {
			setRefreshing(false)
		}, 2000)
	}, [])

	const renderTransaction = ({ item }: { item: Wallet }) => (
		<div style={{ ...styles.transactionItem, backgroundColor: theme.transactionHistoryListBackgroundColor }}>
			<div style={{ display: "flex" }}>
				<img
					src={item.direction === 'in' ? '/img/in_icon.png' : '/img/out_icon.png'} // 실제 이미지 경로로 수정
					alt={item.direction === 'in' ? 'in transaction' : 'out transaction'}
					style={styles.transactionIcon}
				/>
				<CustomText style={{ ...styles.transactionName, color: theme.primaryText }}>
					{item.direction === 'in'
						? t('template_wallet_screen_message_10')
						: t('template_wallet_screen_message_09')}
				</CustomText>
			</div>
			<div style={styles.transactionAmountContainer}>
				<CourierText
					weight="bold"
					style={{ ...styles.transactionAmount, color: item.direction === 'in' ? theme.text_07 : theme.text_18 }}
				>
					{item.direction === 'in' ? '+' : '-'}
					{formatNumberWithCommas(Number(item.amount))}
				</CourierText>
				<CustomText style={styles.transactionSymbol}>{item.currency}</CustomText>
			</div>
		</div>
	)

	const renderTransactionGroup = ({ item: date }: { item: string }) => (
		<div style={styles.transactionGroup}>
			<CustomText style={{ ...styles.transactionDate, color: theme.transactionHistoryDateTextColor }}>
				{date}
			</CustomText>
			{groupedTransactions[date].map((transaction) => (
				<div
					key={transaction.id}
					onClick={() => navigate('/WalletScreenDetail', { state: { item: transaction } })}
					style={{ cursor: "pointer" }}
				>
					{renderTransaction({ item: transaction })}
				</div>
			))
			}
		</div >
	);

	const renderSkeleton = () => (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
			<Spin size="large" /> {/* Show the spin loader in the center of the page */}
		</div>
	)

	const renderDateModal = () => (
		<div style={styles.bottomModal}>
			<div style={styles.dateModalContent}>
				<div style={styles.modalHeader}>
					<CustomText weight="medium" style={styles.modalTitle}>
						{t('template_wallet_screen_message_11')}
					</CustomText>
					<Button onClick={() => setDateModalVisible(false)} style={styles.closeButton}>
						<X name='close' size={20} color={theme.text_05} />
					</Button>
				</div>

				<div style={styles.dateOptions}>
					{[
						`7${i18next.t('template_wallet_screen_message_12')}`,
						`30${i18next.t('template_wallet_screen_message_12')}`,
						`90${i18next.t('template_wallet_screen_message_12')}`,
						`180${i18next.t('template_wallet_screen_message_12')}`].map((option) => (
							<Button
								key={option}
								onClick={() => handleDateOptionPress(option)}
								style={{
									...styles.dateOption,
									backgroundColor: selectedDateOption === option ? 'blue' : 'white',
									color: selectedDateOption === option ? 'white' : 'black',
								}}
							>
								{option}
							</Button>
						))}
				</div>

				<div style={styles.dateInputContainer}>
					<DatePicker
						value={selectedStartDate.toISOString().split('T')[0]}
						onChange={(date) => setSelectedStartDate(date!)}
						placeholder="시작 날짜"
					/>
					<span style={styles.dateSeparator}>~</span>
					<DatePicker
						value={selectedEndDate.toISOString().split('T')[0]}
						onChange={(date) => setSelectedEndDate(date!)}
						placeholder="종료 날짜"
					/>
				</div>

				<CustomText style={{ ...styles.dateInfo, color: 'gray' }}>
					{t('template_wallet_screen_message_13')}
				</CustomText>

				<CustomButton
					containerStyle={{ ...styles.dateSubmitButton, backgroundColor: 'blue' }}
					textStyle={styles.dateSubmitButtonText}
					onPress={() => { }}
					text={t('template_wallet_screen_message_14')}
					weight="medium"
					withBackground={true}
					backgroundColor="blue"
				/>
			</div>
		</div>
	)

	const renderTransactionModal = () => (
		<div style={styles.bottomModal}>
			<div style={styles.tradeModalContent}>
				<div style={styles.modalHeader}>
					<CustomText weight='medium' style={styles.modalTitle}>
						{i18next.t('template_wallet_screen_message_15')}
					</CustomText>
					<button onClick={() => setTransactionModalVisible(false)} style={{ background: 'none', border: 'none' }}>
						<X name='close' size={20} color={theme.text_05} />
					</button>
				</div>
				{[
					i18next.t('template_wallet_screen_message_16'),
					i18next.t('template_wallet_screen_message_10'),
					i18next.t('template_wallet_screen_message_09')
				].map(option => (
					<div
						key={option}
						style={styles.modalOption}
						onClick={() => handleTransactionOptionPress(option)}
					>
						<CustomText
							weight={selectedTransactionOption === option ? 'semi' : undefined}
							style={{
								...styles.modalOptionText,
								...(selectedTransactionOption === option ? { color: theme.primary, fontSize: 18 } : {})
							}}
						>
							{option}
						</CustomText>
						{selectedTransactionOption === option && <Check name='checkmark' size={20} color={theme.primary} />}
					</div>
				))}
			</div>
		</div>
	)

	const renderStatusModal = () => (
		<div style={styles.bottomModal}>
			<div style={styles.statusModalContent}>
				<div style={styles.modalHeader}>
					<CustomText weight='medium' style={styles.modalTitle}>
						{i18next.t('template_wallet_screen_message_17')}
					</CustomText>
					<button onClick={() => setStatusModalVisible(false)} style={{ background: 'none', border: 'none' }}>
						<X name='close' size={20} color={theme.text_05} />
					</button>
				</div>
				{[
					i18next.t('template_wallet_screen_message_17'),
					i18next.t('template_wallet_screen_message_18'),
					i18next.t('template_wallet_screen_message_19'),
					i18next.t('template_wallet_screen_message_20'),
					i18next.t('template_wallet_screen_message_21')
				].map(option => (
					<div key={option} style={styles.modalOption} onClick={() => setSelectedStatusOption(option)}>
						<CustomText
							weight={selectedStatusOption === option ? 'semi' : undefined}
							style={{
								...styles.modalOptionText,
								...(selectedStatusOption === option ? { color: theme.primary, fontSize: 18 } : {})
							}}
						>
							{option}
						</CustomText>
						{selectedStatusOption === option && <Check name='checkmark' size={20} color={theme.primary} />}
					</div>
				))}
			</div>
		</div>
	)

	const formatNumberWithCommas = (number: number): string => {
		// null이나 NaN인 경우 "0.000000" 반환
		if (number === null || isNaN(number)) {
			return "0.000000";
		}

		// 소수점 6자리에서 자르기
		const fixedNumber = (Math.floor((number * 1000000)) / 1000000).toFixed(6)
		const [integerPart, decimalPart] = fixedNumber.split('.')
		const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		return `${formattedIntegerPart}.${decimalPart}`
	}

	// const handleIconPress = () => {
	// 	// 더보기 스택으로 이동시 강제로 첫번째 스크린 설정
	// 	navigation.dispatch(
	// 		CommonActions.reset({
	// 			index: 0,
	// 			routes: [{ name: 'More', params: { screen: 'SeeMoreScreen' } }]
	// 		})
	// 	);
	// 	// @ts-ignore
	// 	// navigation.navigate('More', {
	// 	// 	screen: 'SeeMoreScreen'
	// 	// })
	// }

	const handleSwapScreen = (token: any) => {
		// 메인넷 코인/토큰, eth usdt에 따라 스크린 이동 분리

		// @ts-ignore
		// navigation.navigate('SwapScreen', { token: token })
		// console.log(`handleSwapScreen token.chainid : ${token.chainid} token.name : ${token.name} token.useswap : ${token.useswap}`)
		if (token.name === process.env.REACT_APP_NETWORK_CURRENCY) {
			// 메인넷 코인
			navigate('/SwapScreen', { state: { token: token } })
		}
		else if (token.chainid === process.env.REACT_APP_NETWORK_ID && true === token.useswap) {
			// 메인넷 토큰
			navigate('/SwapScreen', { state: { token: token } })
		} else {
			// othernetwork
			console.log(`handleSwapScreen`)
			console.log(`token.name : ${token.name} | token.useswap : ${token.useswap}`)
			console.log(token)
			if (true == token.useswap) {
				// @ts-ignore
				navigate('SmartSwapScreen', { token: token })
			} else {
				// Toast.show({
				// 	type: 'error',
				// 	text1: '스마트스왑이 지원되지 않는 토큰 입니다',
				// 	position: 'bottom',
				// 	visibilityTime: 500
				// })
				alert('Not Support SmartSwap')
			}
		}
	}

	function shortenString(str: string): string {
		let split_str = str.split('-')[0]

		if (split_str) {
			if (split_str.length <= 10) {
				return split_str;
			}

			const start = split_str.slice(0, 6);
			const end = split_str.slice(-4);

			return `${start}...${end}`;
		}
		else return ``
	}

	const styles = {
		container: {
			backgroundColor: theme.background_03,
			padding: "60px 0px 24px",
			// padding: "60px 0px 70px",
		} as React.CSSProperties,
		header: {
			display: "flex",
			justifyContent: 'space-between',
			alignItems: 'center',
			marginBottom: 15
		} as React.CSSProperties,
		headerTitle: {
			fontSize: 16,
			fontWeight: 'bold'
		} as React.CSSProperties,
		tabsContainer: {
			display: "flex",
			alignItems: 'center'
		} as React.CSSProperties,
		tab: {
			cursor: "pointer"
		} as React.CSSProperties,
		tabText: {
			color: theme.text_01,
			fontSize: 14,
			margin: "0 5px",
		} as React.CSSProperties,
		tabDivider: {
			width: 1,
			height: 10,
			backgroundColor: theme.divider_background_03,
			margin: "0 10px"
		} as React.CSSProperties,
		balanceText: {
			fontSize: 28,
			textAlign: 'right',
		} as React.CSSProperties,
		filterContainer: {
			display: "flex",
			justifyContent: 'space-between',
			alignItems: 'center',
			padding: "10px 0",
		} as React.CSSProperties,
		filterOptions: {
			display: "flex",
			alignItems: 'center'
		} as React.CSSProperties,
		filterText: {
			fontSize: 14,
			margin: "0 5px",
		} as React.CSSProperties,
		filterIcon: {
			width: 20,
			height: 16,
		} as React.CSSProperties,
		transactionHistoryContainer: {
			backgroundColor: theme.card_background_04,
			borderTopLeftRadius: 30,
			borderTopRightRadius: 30,
			padding: "24px",
			// height: "500px",
			height: "calc(100vh - 220px)",
			overflow: "auto"
		} as React.CSSProperties,
		transactionList: {
			marginTop: 10,
		} as React.CSSProperties,
		transactionGroup: {
			padding: "20px 0 0",
			borderRadius: 10,
		} as React.CSSProperties,
		transactionItem: {
			display: "flex",
			alignItems: 'center',
			padding: "13px 15px",
			backgroundColor: theme.card_background_04,
			borderRadius: 10,
			marginBottom: 10,
			border: `1px solid ${theme.border_04}`,
			width: "100%",
			boxSizing: "border-box",
			justifyContent: "space-between",
		} as React.CSSProperties,
		transactionIcon: {
			width: 24,
			height: 24,
			marginRight: 10
		} as React.CSSProperties,
		transactionName: {
			fontSize: 15,
			paddingTop: "3px"
		} as React.CSSProperties,
		transactionAmountContainer: {
			display: "flex",
			alignItems: 'center'
		} as React.CSSProperties,
		transactionAmount: {
			fontSize: 18
		} as React.CSSProperties,
		transactionSymbol: {
			color: theme.text_06,
			fontSize: 12,
			marginLeft: 5
		} as React.CSSProperties,
		transactionDate: {
			fontSize: 14,
			color: theme.text_10,
			marginBottom: 15
		} as React.CSSProperties,
		dateModalContent: {
			position: "absolute",
			top: "50%",
			left: "50%",
			transform: "translate(-50%, -50%)",
			backgroundColor: 'white',
			padding: 20,
			borderRadius: 20,
			boxSizing: "border-box",
			width: "90%"
		} as React.CSSProperties,
		modalHeader: {
			display: "flex",
			justifyContent: 'space-between',
			width: '100%',
			alignItems: 'center',
			marginBottom: 20,
			padding: "0 10px",
			boxSizing: "border-box",
		} as React.CSSProperties,
		modalTitle: {
			fontSize: 16,
			color: theme.text_05
		} as React.CSSProperties,
		dateOptions: {
			display: "flex",
			justifyContent: 'space-between',
			width: '100%',
			marginBottom: 20
		} as React.CSSProperties,
		dateOption: {
			padding: "15px 25px",
			borderRadius: 8,
			alignItems: 'center'
		} as React.CSSProperties,
		dateInputContainer: {
			display: "flex",
			justifyContent: 'center',
			alignItems: 'center',
			marginVertical: 10,
			width: '100%'
		} as React.CSSProperties,
		dateSeparator: {
			fontSize: 18,
			margin: "0 10px",
		} as React.CSSProperties,
		dateInfo: {
			fontSize: 14,
			color: theme.text_03,
			marginTop: "5px",
			marginBottom: "20px"
		} as React.CSSProperties,
		dateSubmitButton: {
			backgroundColor: theme.button_02,
			padding: 12,
			borderRadius: 28,
			width: '100%',
			alignItems: 'center',
			justifyContent: 'center'
		} as React.CSSProperties,
		dateSubmitButtonText: {
			color: 'white',
			fontSize: 18
		} as React.CSSProperties,
		bottomModal: {
			display: "flex",
			margin: 0,
			width: "100%",
			height: "100%",
			position: "fixed",
			top: "0px",
			left: "0px",
			background: theme.modal_background_01
		} as React.CSSProperties,
		tradeModalContent: {
			width: "100%",
			position: "absolute",
			bottom: "60px",
			left: "0px",
			backgroundColor: 'white',
			padding: 20,
			borderTopLeftRadius: 30,
			borderTopRightRadius: 30,
			boxSizing: "border-box"
		} as React.CSSProperties,
		modalOption: {
			padding: "20px 10px",
			width: '100%',
			display: "flex",
			justifyContent: 'space-between',
			alignItems: 'center',
			boxSizing: "border-box"
		} as React.CSSProperties,
		modalOptionText: {
			fontSize: 16,
			color: theme.text_05
		} as React.CSSProperties,
		statusModalContent: {
			width: "100%",
			position: "absolute",
			bottom: "60px",
			left: "0px",
			backgroundColor: 'white',
			padding: 20,
			borderTopLeftRadius: 30,
			borderTopRightRadius: 30,
			boxSizing: "border-box"
		} as React.CSSProperties,
		optionPressableContainer: {
			display: "flex",
			alignItems: 'center',
		} as React.CSSProperties,
		modal: {
			maxWidth: '600px',
			margin: '0 auto',
			padding: '20px',
			borderRadius: '10px',
		} as React.CSSProperties,
		closeButton: {
			background: 'none',
			border: 'none',
			cursor: 'pointer',
			fontSize: '24px',
			color: theme.text_05,
			display: "flex",

		} as React.CSSProperties,
	}

	return (
		<>
			<MainPageHeader />
			{/* <NavigationBar /> */}
			<div className='container' style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
				<div style={{ width: "100%" }}>
					<div style={{ padding: '24px' }}>
						<div style={styles.header}>
							<CustomText style={{ ...styles.headerTitle, color: theme.primaryText }}>
								{token.symbol}
							</CustomText>
							<div style={styles.tabsContainer}>
								<button
									onClick={() => navigate('/WithdrawScreen', { state: { token } })}
									style={styles.tab}
								>
									<CustomText style={{ ...styles.tabText, color: theme.primaryText }}>
										{t('template_wallet_screen_message_02')}
									</CustomText>
								</button>
								<div style={styles.tabDivider}></div>
								<button
									onClick={() => navigate('/ReceiveScreen', { state: { token } })}
									style={styles.tab}
								>
									<CustomText style={{ ...styles.tabText, color: theme.primaryText }}>
										{t('template_wallet_screen_message_03')}
									</CustomText>
								</button>
								<div style={styles.tabDivider}></div>
								<button
									onClick={() => { handleSwapScreen(token) }}
									style={styles.tab}
								>
									<CustomText style={{ ...styles.tabText, color: theme.primaryText }}>
										{t('template_wallet_screen_message_04')}
									</CustomText>
								</button>
							</div>
						</div>
						<CourierText weight="bold" style={{ ...styles.balanceText, color: theme.primaryText }}>
							{formatNumberWithCommas(Number(token.balance))}
						</CourierText>
					</div>
					<div className='containerScroll' style={{ ...styles.transactionHistoryContainer, backgroundColor: theme.transactionContainerBackgroundColor }}>
						<div style={styles.filterContainer}>
							<CustomText style={{ ...styles.headerTitle, color: theme.primaryText }}>
								{t('template_wallet_screen_message_05')}
							</CustomText>
							<div style={styles.filterOptions}>
								<button
									style={styles.optionPressableContainer}
									onClick={() => setDateModalVisible(true)}
								>
									<CustomText style={{ ...styles.filterText, color: theme.primaryText }}>
										{t('template_wallet_screen_message_06')}
									</CustomText>
									<img src="/img/arrow_down_icon.png" alt="arrow" style={styles.filterIcon} />
								</button>

								<button
									style={styles.optionPressableContainer}
									onClick={() => setTransactionModalVisible(true)}
								>
									<CustomText style={{ ...styles.filterText, color: theme.primaryText }}>
										{t('template_wallet_screen_message_07')}
									</CustomText>
									<img src="/img/arrow_down_icon.png" alt="arrow" style={styles.filterIcon} />
								</button>

								<div style={{ padding: "0 7px", display: "flex", alignItems: 'center', justifyContent: 'center' }} onClick={() => { onRefresh() }}>
									<RefreshCw
										color={theme.text_01}
										size={18}
										className={refreshing ? 'rotating-icon' : ''}
									/>
								</div>

								{/* <button
									style={styles.optionPressableContainer}
									onClick={() => setStatusModalVisible(true)}
								>
									<CustomText style={{ ...styles.filterText, color: theme.primaryText }}>
										{t('template_wallet_screen_message_08')}
									</CustomText>
									<img src="/img/arrow_down_icon.png" alt="arrow" style={styles.filterIcon} />
								</button> */}
							</div>
						</div>

						{loading ? (
							renderSkeleton()
						) : (
							<div style={styles.transactionList}>
								{Object.keys(groupedTransactions).map((date) => (
									<div key={date}>
										{renderTransactionGroup({ item: date })}
									</div>
								))}
							</div>
						)}

					</div>
				</div>

				{isDateModalVisible && renderDateModal()}
				{isTransactionModalVisible && renderTransactionModal()}
				{/* {isStatusModalVisible && renderStatusModal()} */}
			</div>
		</>
	)
}

export default WalletScreen

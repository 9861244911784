import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../../context/ThemeContext';
import { Check } from 'lucide-react';

import CustomButton from '../../Componentization/CustomButton';
import CustomText from '../../../styles/CustomText';
import Container from '../../Common/Container';
import CourierText from '../../../styles/CourierText';
import CustomListCardWithIcon from '../../Componentization/Card/CustomListCardWithIcon';
import CustomListCard from '../../Componentization/Card/CustomListCard';
import CustomSwtich from '../../Componentization/Switch/CustomSwtich';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkHeaderText, checkPincode, RootState } from '../../../store';

import useWallet from '../../../hooks/useWallet';
import useEthereum from '../../../hooks/useEthereum';
import useEthereumOther from '../../../hooks/useEthereumOther';
import useSecureStorage from '../../../hooks/useSecureStorage';
import useDefaultNetworkAndToken from '../../../hooks/useDefaultNetworkAndToken';

import MainPageHeader from '../../../nav/MainPageHeader';
import NavigationBar from '../../../nav/NavigationBar';

// import i18next from 'i18next'
import { initI18n, changeLanguage, getStoredLanguage } from '../../../locales/i18n';

const languages = [
	{ code: 'en', name: 'English' },
	{ code: 'ko', name: '한국어' }
	// { code: 'id', name: 'Indonesian' }
]

const LanguageSettingsScreen: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const { t } = useTranslation();

	// const languageContext = useContext(LanguageContext)

	// if (!languageContext) return null

	const [language, setLanguage] = useState('')
	// const language = await getStoredLanguage

	useEffect(() => {
		const initLanguage = async () => {
			const lang = await getStoredLanguage()
			setLanguage(lang)
		}
		initLanguage()
	}, [language])

	const selectLanguage = async (langCode: string) => {
		console.log(`선택한 언어 : ${langCode}`)
		changeLanguage(langCode)
		setLanguage(langCode)
	}

	useEffect(() => {
		dispatch(checkHeaderText({ "link": "LanguageSettingsScreen", "title": t('stack_header_message_10') }))
	}, [])

	const styles = {
		container: {
			padding: "60px 24px 24px",
		} as React.CSSProperties,
		languageOption: {
			display: "flex",
			justifyContent: 'space-between',
			alignItems: 'center',
			padding: "16px 0",
			borderBottom: `1px solid ${theme.border_10}`
		} as React.CSSProperties,
		languageText: {
			fontSize: 18,
			color: theme.text_13
		} as React.CSSProperties,
	}

	return (
		<div className='container' style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
			<div style={{ width: "100%" }}>
				{languages.map((languageOption) => (
					<div
						key={languageOption.code}
						style={styles.languageOption}
						onClick={() => selectLanguage(languageOption.code)}
					>
						<CustomText style={{ ...styles.languageText, color: theme.primaryText }}>
							{languageOption.name}
						</CustomText>
						{language === languageOption.code && <Check name="checkmark" size={24} color={theme.primary} />}
					</div>
				))}
			</div>
		</div>
	)
}

export default LanguageSettingsScreen

import { useContext, useMemo } from 'react';
import { ThemeContext } from "../context/ThemeContext";

interface GlobalStyles {
	textRegular: React.CSSProperties;
	textBold: React.CSSProperties;
	textSemiBold: React.CSSProperties;
	textMedium: React.CSSProperties;
	montserratRegular: React.CSSProperties;
	montserratBoldItalic: React.CSSProperties;
	montserratMedium: React.CSSProperties;
	montserratBold: React.CSSProperties;
	montserratSemiBold: React.CSSProperties;
	courierRegular: React.CSSProperties;
	courierBoldItalic: React.CSSProperties;
	courierBold: React.CSSProperties;
}

const useGlobalStyles = (): GlobalStyles => {
	const { theme } = useContext(ThemeContext);

	return useMemo(() => ({
		textRegular: {
		fontFamily: 'Pretendard-Regular',
		color: theme.text_05,
		},
		textBold: {
		fontFamily: 'Pretendard-Bold',
		color: theme.text_05,
		},
		textSemiBold: {
		fontFamily: 'Pretendard-SemiBold',
		color: theme.text_05,
		},
		textMedium: {
		fontFamily: 'Pretendard-Medium',
		color: theme.text_05,
		},
		montserratRegular: {
		fontFamily: 'Pretendard-Regular',
		color: theme.text_05,
		},
		montserratBoldItalic: {
		fontFamily: 'Pretendard-BoldItalic',
		color: theme.text_05,
		},
		montserratMedium: {
		fontFamily: 'Pretendard-Medium',
		color: theme.text_05,
		},
		montserratBold: {
		fontFamily: 'Pretendard-Bold',
		color: theme.text_05,
		},
		montserratSemiBold: {
		fontFamily: 'Pretendard-SemiBold',
		color: theme.text_05,
		},
		courierRegular: {
		fontFamily: 'Courier-Regular',
		color: theme.text_05,
		},
		courierBoldItalic: {
		fontFamily: 'Courier-BoldItalic',
		color: theme.text_05,
		},
		courierBold: {
		fontFamily: 'Courier-Bold',
		color: theme.text_05,
		},
	}), [theme]);
};

export default useGlobalStyles;

import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import { Check } from 'lucide-react';

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
import Container from '../Common/Container';
import CourierText from '../../styles/CourierText';
import CustomListCardWithIcon from '../Componentization/Card/CustomListCardWithIcon';
import CustomListCard from '../Componentization/Card/CustomListCard';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkHeaderText, checkPincode, RootState } from '../../store';

import useWallet from '../../hooks/useWallet';
import useEthereum from '../../hooks/useEthereum';
import useEthereumOther from '../../hooks/useEthereumOther';
import useSecureStorage from '../../hooks/useSecureStorage';
import MainPageHeader from '../../nav/MainPageHeader';
import NavigationBar from '../../nav/NavigationBar';

import i18next from 'i18next'

const PaymentScreen: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const { t } = useTranslation();

	useEffect(()=>{
		dispatch(checkHeaderText({ "link": "PaymentScreen", "title": "" }))
	},[])

	const styles = {
		container: {
			display: "block",
			position: "relative",
			padding: "24px 24px 24px"
			// padding: "24px 24px 70px"
		} as React.CSSProperties,
		mainContainer: {
			marginTop: "110px"
		} as React.CSSProperties,
		emptyContainer: {
		} as React.CSSProperties,
		row: {
			display: "flex",
			alignItems: 'flex-start',
			marginTop: 30
		} as React.CSSProperties,
		textContainer: {
			marginTop: 30
		} as React.CSSProperties,
		icon: {
			width: 46,
			height: 46,
			marginRight: 10
		} as React.CSSProperties,
		text: {
			color: theme.text_05,
			fontSize: 22,
			display: 'flex',
			gap: "6px"
		} as React.CSSProperties,
		linkText: {
			color: theme.text_08
		} as React.CSSProperties,
	}
	
	return (
		<>
			{/* <NavigationBar /> */}
			<div className='container' style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
				<div style={styles.mainContainer}>
					<div>
						<div>
							<img
								src="/img/alarm.png" // 실제 이미지 경로로 수정 필요
								alt="Alarm Icon"
								style={styles.icon}
							/>
						</div>
						<div style={styles.textContainer}>
							<CustomText weight="medium" style={{ ...styles.text, color: theme.primaryText }}>
								<CustomText weight="medium" style={{ color: theme.primaryText }}>
									{t('prepare_payment_screen_message_01')}{' '}
								</CustomText>
								<CustomText weight="medium" style={{ ...styles.linkText, color: theme.primary }}>
									{t('prepare_payment_screen_message_02')}
								</CustomText>
								{t('prepare_payment_screen_message_03')}
							</CustomText>
							<CustomText weight="medium" style={{ ...styles.text, color: theme.primaryText }}>
								{t('prepare_payment_screen_message_04')}
							</CustomText>
						</div>
					</div>

					<div style={styles.row}>
						<CustomText weight="medium" style={{ ...styles.text, color: theme.primaryText }}>
							{t('prepare_payment_screen_message_05')}
						</CustomText>
					</div>

					<div style={styles.emptyContainer}></div>
				</div>
			</div>
		</>
	)
}

export default PaymentScreen

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en.json';
import ko from './ko.json';

export const getStoredLanguage = async (): Promise<string> => {
	try {
		const language = localStorage.getItem('selectedLanguage')
		return language || 'en'
	} catch (error) {
		console.error('Failed to get stored language', error)
		return 'en'
	}
}

export const changeLanguage = async (language: string): Promise<void> => {
	try {
		localStorage.setItem('selectedLanguage', language);
		i18n.changeLanguage(language)
	} catch (error) {
		console.error('Failed to change language', error)
	}
}

export const initI18n = async (): Promise<void> => {	
	let language = await getStoredLanguage()

	i18n.use(initReactI18next).init({
		compatibilityJSON: 'v3',
		fallbackLng: 'en',
		lng: language,
		resources: {
			en: { translation: en },
			ko: { translation: ko }
			// id: { translation: id }
		},
		interpolation: {
			escapeValue: false
		},
		react: {
			useSuspense: true
		}
	})
}

export default i18n;

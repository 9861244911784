import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../context/ThemeContext';

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const SecretPhraseGeneration: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	const { t } = useTranslation();
	const navigate = useNavigate()

	const handleNext = () => {
		navigate('/SecretPhraseWarning')
	}

	const styles = {
		sectionTwo: {
			width: '100%',
			paddingHorizontal: 20
		} as React.CSSProperties,
		sectionThree: {
		} as React.CSSProperties,
		sectionOne: {
			width: '100%',
		} as React.CSSProperties,
		title1: {
			fontSize: 24,
			textAlign: 'left',
			color: theme.text_05
		} as React.CSSProperties,
		title2: {
			fontSize: 24,
			textAlign: 'left',
			color: theme.text_05,
			marginBottom: 10
		} as React.CSSProperties,
		subtitle1: {
			fontSize: 16,
			color: theme.text_03,
			textAlign: 'left'
		} as React.CSSProperties,
		subtitle2: {
			fontSize: 16,
			color: theme.text_03,
			textAlign: 'left',
			marginBottom: 20
		} as React.CSSProperties,
		image: {
			width: 132,
			height: 171
		} as React.CSSProperties,
		button: {
			backgroundColor: theme.button_02,
			padding: 15,
			borderRadius: 10,
			width: '100%',
			alignItems: 'center',
			height: 54,
			justifyContent: 'center'
		} as React.CSSProperties,
		buttonText: {
			color: theme.text_01,
			fontSize: 16
		} as React.CSSProperties
	}

	return (
		<div className='container' style={{ backgroundColor: theme.primaryBackgroundColor }}>
			<div style={styles.sectionTwo}>
				<CustomText weight="medium" style={{ ...styles.title1, color: theme.primaryText }}>
					{t('generate_recovery_phrase_message_01')}
				</CustomText>
				<CustomText weight="medium" style={{ ...styles.title2, color: theme.primaryText }}>
					{t('generate_recovery_phrase_message_02')}
				</CustomText>
				<CustomText style={{ ...styles.subtitle1, color: theme.secondaryText }}>
					{t('generate_recovery_phrase_message_03')}
				</CustomText>
				<CustomText style={{ ...styles.subtitle2, color: theme.secondaryText }}>
					{t('generate_recovery_phrase_message_04')}
				</CustomText>
			</div>

			<div style={styles.sectionThree}>
				<img
					src="/img/shield.png" // 웹에서는 이미지를 파일 경로로 대체해야 합니다.
					alt="Shield"
					style={styles.image}
				/>
			</div>

			<div style={styles.sectionOne}>
				<CustomButton
					containerStyle={{ ...styles.button, backgroundColor: theme.primary }}
					textStyle={styles.buttonText}
					onPress={handleNext}
					text={t('generate_recovery_phrase_message_05')}
					withBackground={true}
					backgroundColor={theme.primaryTouchBackgroundColor}
				/>
			</div>
		</div>
	)
}

export default SecretPhraseGeneration

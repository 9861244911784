import React, { useContext } from 'react';
import CustomText from '../../../styles/CustomText'
import { ThemeContext } from '../../../context/ThemeContext';
// import { theme } from 'antd';

interface CustomCardProps {
	style?: React.CSSProperties;
	onPress?: () => void;
	title?: string;
	subtitle?: string;
	icon?: string; // 웹 환경에서는 string으로 이미지 경로를 사용
	children?: React.ReactNode;
	cardStyle?: React.CSSProperties;
	titleStyle?: React.CSSProperties;
	subtitleStyle?: React.CSSProperties;
}

const CustomCard: React.FC<CustomCardProps> = ({
	style,
	onPress,
	title,
	subtitle,
	icon,
	children,
	cardStyle,
	titleStyle = {},
	subtitleStyle = {},
}) => {
	const { theme } = useContext(ThemeContext)

	const styles = {
		card: {
			borderRadius: '10px',
			padding: '15px',
			backgroundColor: theme.card_background_03,
			display: 'flex',
			flexDirection: 'row' as const,
			alignItems: 'center',
			cursor: 'pointer',
		} as React.CSSProperties,
		icon: {
			width: '40px',
			height: '40px',
			marginRight: '15px',
		} as React.CSSProperties,
		textContainer: {
			flex: 1,
		} as React.CSSProperties,
		title: {
			fontSize: '15px',
			color: theme.text_01,
			fontWeight: 'bold',
		} as React.CSSProperties,
		subtitle: {
			fontSize: '13px',
			color: theme.text_03,
		} as React.CSSProperties,
	}

	return (
		<div onClick={onPress} style={{ ...styles.card, ...cardStyle, ...style }}>
			{/* 이미지 처리 */}
			{icon && <img src={icon} alt="icon" style={styles.icon} />}
			<div style={styles.textContainer}>
				{title && <div style={{ ...styles.title, ...titleStyle }}>{title}</div>}
				{subtitle && <div style={{ ...styles.subtitle, ...subtitleStyle }}>{subtitle}</div>}
				{children}
			</div>
		</div>
	);
};


export default CustomCard

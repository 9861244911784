import React, { useContext } from 'react';
import { ThemeContext } from '../../../context/ThemeContext';

type Props = {
	onToggle: () => void;
	isOn: boolean;
};

const CustomSwitch = ({ onToggle, isOn }: Props) => {
	const { theme } = useContext(ThemeContext)
	
	const styles = {
		toggleContainer: {
			width: '48px',
			height: '24px',
			borderRadius: '12px',
			display: 'flex',
			justifyContent: 'left',
			backgroundColor: theme.button_06,
			cursor: 'pointer',
		},
		toggleWheel: {
			width: '24px',
			height: '24px',
			backgroundColor: 'white',
			borderRadius: '50%',
		},
	};

	return (
		<div
			onClick={onToggle}
			style={{
				...styles.toggleContainer,
				backgroundColor: isOn ? theme.button_08 : theme.button_06,
			}}
		>
			<div style={{ ...styles.toggleWheel, transform: `translateX(${isOn ? 24 : 0}px)` }} />
		</div>
	);
};

export default CustomSwitch;

import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../../context/ThemeContext';
import { Check } from 'lucide-react';

import CustomButton from '../../Componentization/CustomButton';
import CustomText from '../../../styles/CustomText';
import Container from '../../Common/Container';
import CourierText from '../../../styles/CourierText';
import CustomListCardWithIcon from '../../Componentization/Card/CustomListCardWithIcon';
import CustomListCard from '../../Componentization/Card/CustomListCard';
import CustomSwtich from '../../Componentization/Switch/CustomSwtich';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkHeaderText, checkUnlock, checkPincode, RootState } from '../../../store';

import useWallet from '../../../hooks/useWallet';
import useEthereum from '../../../hooks/useEthereum';
import useEthereumOther from '../../../hooks/useEthereumOther';
import useSecureStorage from '../../../hooks/useSecureStorage';
import useDefaultNetworkAndToken from '../../../hooks/useDefaultNetworkAndToken';

import MainPageHeader from '../../../nav/MainPageHeader';
import NavigationBar from '../../../nav/NavigationBar';

import i18next from 'i18next'

import WebApp from '@twa-dev/sdk'

import PinCodeScreen from '../../Authentication/PinCodeScreen';

const SecuritySettings = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const { t } = useTranslation();

	const { getWalletAddress, getAutoLockValue, logOut, setFingerprintEnabled, getFingerprintEnabled } = useSecureStorage()

	const [isEnabled, setIsEnabled] = useState(false)
	const [isDeleteModalVisible, setDeleteModalVisible] = useState(false)
	// const [showFingerprintAuth, setShowFingerprintAuth] = useState(false)
	const [autoLockSetting, setAutoLockSetting] = useState(i18next.t('auto_lock_screen_message_04'))
	// const [verifyEmail, setVerifyEmail] = useState('')
	const [showPinCodeAuth, setShowPinCodeAuth] = useState(false)
	// const [appState, setAppState] = useState(AppState.currentState)
	const [isPinCodeScreenVisible, setPinCodeScreenVisible] = useState(false)
	const [deletePress, setDeletePress] = useState(false)
	const [changePassword, setChangePassword] = useState(false)	
	const [fingerPrintTogglePress, setFingerPrintTogglePress] = useState(false)

	const items = [
		{ 
			text: i18next.t('security_setting_screen_message_02'), 
			screen: '', 
			hasSwitch: true, 
			hasBadge: true,
			extraText: null
		},
		{
			text: i18next.t('security_setting_screen_message_03'),
			screen: 'ChangePassword',
			hasSwitch: false,
			hasBadge: true,
			extraText: null
		},
		// {
		// 	text: i18next.t('security_setting_screen_message_04'),
		// 	screen: 'AutoLockScreen',
		// 	hasSwitch: false,
		// 	extraText: autoLockSetting
		// }
		// {
		// 	text: i18next.t('security_setting_screen_message_16'), // 휴대폰 인증 페이지
		// 	screen: 'VerifyPhoneScreen',
		// 	hasSwitch: false,
		// 	extraText: verifyEmail
		// }
	]

	// const maskEmail = (email: string): string => {
	// 	const [name, domain] = email.split('@')
	// 	const maskedName = name.slice(0, 2) + '***'
	// 	const [domainName, domainExt] = domain.split('.')
	// 	const maskedDomain = domainName.slice(0, 2) + '***.' + domainExt
	// 	return `${maskedName}@${maskedDomain}`
	// }

	useEffect(() => {
		dispatch(checkHeaderText({ "link": "SecuritySettings", "title": t('stack_header_message_06') }))
	}, [])

	// const TestTgWebAppSetCloudStorageCallBack = async (error: any, isSuccess?: boolean) => {
	// 	console.log(`//-------------------------------------------------`)
	// 	console.log(`TestTgWebApp SetCloudStorage CallBack !!!!!`)
	// 	console.log(`텔레그램 클라우드 스토리지 저장요청 결과 : ${isSuccess}`)
	// };

	// const TestTgWebAppGetCloudStorageCallBack = async (error: any, value?:string) => {
	// 	console.log(`//-------------------------------------------------`)
	// 	console.log(`TestTgWebApp GetCloudStorage CallBack !!!!!`)
	// 	console.log(`텔레그램 클라우드 스토리지 데이터 요청 결과 : ${error} | value :`, value)
	// };

	useEffect(() => {
		const getFingerprintSetting = async () => {
			try {
				const value = await localStorage.getItem('fingerprintEnabled')
				if (value !== null) {
					setIsEnabled(value === 'true')
				}

			} catch (e) {
				console.error(e)
			}
		}

		const getAutoLockSetting = async () => {
			try {
				const value = await getAutoLockValue()
				if (value !== null) {
					setAutoLockSetting(i18next.t(value))
				}
			} catch (e) {
				console.error(e)
			}
		}
		// const getEmail = async () => {
		// 	try {
		// 		const email = await AsyncStorage.getItem('email')
		// 		if (email) {
		// 			setVerifyEmail(maskEmail(email))
		// 		}
		// 	} catch (e) {
		// 		console.error(e)
		// 	}
		// }
		getFingerprintSetting()
		getAutoLockSetting()
		// getEmail()
	}, [])

	const toggleSwitch = async () => {
		if (false === WebApp.BiometricManager.isBiometricAvailable ||
			false === WebApp.BiometricManager.isInited || 
			false === WebApp.BiometricManager.isAccessGranted
		) {
			WebApp.showAlert(i18next.t('security_setting_screen_message_17'))
			return;
		}

		try {
			if(false === isEnabled) {
				// 지문인증 사용안함 -> 사용
				setFingerPrintTogglePress(true)
				
				// pincode 입력창 표시
				setShowPinCodeAuth(true)
				setPinCodeScreenVisible(true)
			}
			else {
				// 지문인증 사용 -> 사용안함
				setFingerprintEnabled((!isEnabled).toString())
				setIsEnabled(previousState => !previousState)

				// biotoken 초기화
				WebApp.BiometricManager.updateBiometricToken("")
			}
		} catch (e) {
			console.error(e);
		}
	}

	
	// 지갑 데이터 삭제 메소드
	const deleteAddress = async () => {
		await logOut()

		// 지감 잠금상태로 변경
		dispatch(checkUnlock(false))

		setTimeout(() => {
			setDeleteModalVisible(false)
			navigate('/CreateWallet') // 지갑 생성 페이지로 이동
		}, 500)
	}

	const handleItemPress = async (screen: string) => {
		if (screen) {
			if (screen === 'ChangePassword') {
				setShowPinCodeAuth(true)
				setPinCodeScreenVisible(true)
				setChangePassword(true)
			} else {
				navigate(`/${screen}`)
			}
		}
	}

	// 지갑 삭제 버튼
	const handleDeletePress = async () => {
		setDeletePress(true)
		setShowPinCodeAuth(true)
		setPinCodeScreenVisible(true)
		setDeleteModalVisible(false)
	}

	// 핀코드 확인 성공
	const handlePinCodeAuthSuccess = async (pin: string) => {
		// setShowPinCodeAuth(false)
		// setPinCodeScreenVisible(false)
		if (true === deletePress) {
			// 지갑 초기화
			setShowPinCodeAuth(false)
			setPinCodeScreenVisible(false)
			await deleteAddress()
		} else if (true === changePassword){
			// 비밀번호 변경 페이지로 이동
			setShowPinCodeAuth(false)
			setPinCodeScreenVisible(false)
			setChangePassword(false)

			navigate('/ChangePassword', {
				state: { pincode: pin }
			})
		} else if(true === fingerPrintTogglePress) {
			// 지문인식 사용설정 변경
			// 지문인식 사용 on 설정
			console.log(`지문인식 사용 off -> on`)

			// 지문인식 biotoken 변경
			WebApp.BiometricManager.updateBiometricToken(pin, TgWebAppUpdateBioTokenCallBack);			
		}
	}

	// bioToken update
	const TgWebAppUpdateBioTokenCallBack = async (isSuccess:boolean) => {
		// bioToken 업데이트 결과 확인
		if(true === isSuccess) {
			// 지문사용 설정 저장
			await setFingerprintEnabled((!isEnabled).toString())
			setIsEnabled(previousState => !previousState)

			setFingerPrintTogglePress(false)
			setShowPinCodeAuth(false)
			setPinCodeScreenVisible(false)
		} else {
			// 지문사용 안함으로 저장
			await setFingerprintEnabled('false')

			setFingerPrintTogglePress(false)
			setShowPinCodeAuth(false)
			setPinCodeScreenVisible(false)
		}
	}

	const styles = {
		container: {
			padding: "60px 5px 24px",
			position: "relative"
		} as React.CSSProperties,
		sectionTitleTop: {
			fontSize: 14,
			color: theme.text_15,
			width: "100%",
			paddingTop: "10px",
			paddingBottom: "10px",
			paddingLeft: "10px",
			paddingRight: "24px",
			boxSizing: "border-box"
		} as React.CSSProperties,
		sectionTitle: {
			fontSize: 14,
			color: theme.text_15,
			width: "100%",
			paddingTop: "20px",
			paddingBottom: "10px",
			paddingLeft: "10px",
			paddingRight: "24px",
			boxSizing: "border-box"
		} as React.CSSProperties,
		groupContainer: {
			borderRadius: 10,
			marginVertical: 8,
			overflow: 'hidden',
			padding: "0 15px",
		} as React.CSSProperties,
		item: {
			display: "flex",
			alignItems: 'center',
			justifyContent: "space-between",
			padding: "19px 24px",
			borderBottom: `1px solid ${theme.border_04}`,
			cursor: "pointer"
		} as React.CSSProperties,
		itemText: {
			fontSize: 18,
			color: theme.text_15,
			flex: 1
		} as React.CSSProperties,
		extraText: {
			color: theme.text_08,
			marginRight: 8
		} as React.CSSProperties,
		arrowIcon: {
			width: 13,
			height: 21,
			tintColor: theme.text_03
		} as React.CSSProperties,
		topRounded: {
			borderTopLeftRadius: 10,
			borderTopRightRadius: 10
		} as React.CSSProperties,
		bottomRounded: {
			borderBottomLeftRadius: 10,
			borderBottomRightRadius: 10,
			borderBottom: "0px"
		} as React.CSSProperties,
		deleteText: {
			color: theme.text_17
		} as React.CSSProperties,
		modalBackground: {
			display: "flex",
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: theme.modal_background_01,
			position: "absolute",
			width: "100%",
			height: "100%",
			top: 0,
			left: 0
		} as React.CSSProperties,
		modalContainer: {
			width: '90%',
			backgroundColor: 'white',
			borderRadius: 10,
			padding: '20px',
			border: `1px solid ${theme.border_09}`,
			boxSizing: "border-box"
		} as React.CSSProperties,
		modalTitle: {
			fontSize: 18,
			color: theme.text_17,
			textAlign: 'center',
			marginBottom: 10
		} as React.CSSProperties,
		modalMessage: {
			fontSize: 14,
			color: theme.text_02,
			textAlign: 'center'
		} as React.CSSProperties,
		modalButtons: {
			display: "flex",
			width: '100%',
			borderTop: `1px solid ${theme.border_10}`,
			marginTop: 20
		} as React.CSSProperties,
		cancelButton: {
			width: "100%",
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center',
			padding: "10px 0",
			borderRight: `1px solid ${theme.border_10}`,
		} as React.CSSProperties,
		cancelButtonText: {
			color: theme.text_15,
			fontSize: 16
		} as React.CSSProperties,
		confirmButton: {
			width: "100%",
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center',
		} as React.CSSProperties,
		confirmButtonText: {
			color: theme.text_17,
			fontSize: 18
		} as React.CSSProperties,
	}

	return (
		<div className='container' style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
			<div style={{ width: "100%" }}>
				<CustomText style={{ ...styles.sectionTitleTop, color: theme.groupingTtileColor }}>
					{t('security_setting_screen_message_01')}
				</CustomText>
				<div style={{ ...styles.groupContainer, backgroundColor: theme.groupingCardBackgroundColor }}>
					{items.map((item, index) => (
						<div
							key={index}
							style={{
								...styles.item,
								borderColor: theme.groupingDividerColor,
								backgroundColor: theme.groupingCardBackgroundColor,
								...(index === 0 ? styles.topRounded : {}),
								...(index === items.length - 1 ? styles.bottomRounded : {}),
							}}
							onClick={() => handleItemPress(item.screen)}
						>
							<CustomText style={{ ...styles.itemText, color: theme.primaryText }}>{item.text}</CustomText>
							{item.hasSwitch && <CustomSwtich onToggle={toggleSwitch} isOn={isEnabled} />}
							{item.extraText && (
								<CustomText style={{ ...styles.extraText, color: theme.primary }}>{item.extraText}</CustomText>
							)}
							{!item.hasSwitch && (
								<img src="/img/icon_small_arrow_right.png" alt="arrow" style={styles.arrowIcon} />
							)}
						</div>
					))}
				</div>
				<CustomText style={{ ...styles.sectionTitle, color: theme.groupingTtileColor }}>
					{t('security_setting_screen_message_05')}
				</CustomText>
				<div style={{ ...styles.groupContainer, backgroundColor: theme.groupingCardBackgroundColor }}>
					<div
						style={{
							...styles.item,
							borderColor: theme.groupingDividerColor,
							backgroundColor: theme.groupingCardBackgroundColor,
							...styles.bottomRounded,
						}}
						onClick={() => navigate('/SecuritySecretPhraseWarning')}
					>
						<CustomText style={{ ...styles.itemText, color: theme.primaryText }}>
							{t('security_setting_screen_message_06')}
						</CustomText>
						<img src="/img/icon_small_arrow_right.png" alt="arrow" style={styles.arrowIcon} />
					</div>
				</div>

				<CustomText style={{ ...styles.sectionTitle, color: theme.groupingTtileColor }}>
					{t('security_setting_screen_message_07')}
				</CustomText>
				<div style={{ ...styles.groupContainer, backgroundColor: theme.groupingCardBackgroundColor }}>
					<div
						style={{
							...styles.item,
							borderColor: theme.groupingDividerColor,
							backgroundColor: theme.groupingCardBackgroundColor,
							...styles.bottomRounded,
						}}
						onClick={() => setDeleteModalVisible(true)}
					>
						<CustomText
							style={{
								...styles.confirmButtonText,
								...styles.deleteText,
								color: theme.deleteWalletTitleColor,
							}}
						>
							{t('security_setting_screen_message_15')}
						</CustomText>
					</div>
				</div>
			</div>

			{
				isDeleteModalVisible && (
					<div style={styles.modalBackground}>
						<div style={{ ...styles.modalContainer, backgroundColor: theme.modalBackgroundColor }}>
							<CustomText weight="bold" style={{ ...styles.modalTitle, color: theme.deleteWalletTitleColor }}>
								{t('security_setting_screen_message_09')}
							</CustomText>
							<CustomText style={{ ...styles.modalMessage, color: theme.primaryText }}>
								{t('security_setting_screen_message_10')}
							</CustomText>
							<CustomText style={{ ...styles.modalMessage, color: theme.primaryText }}>
								{t('security_setting_screen_message_11')}
							</CustomText>
							<CustomText style={{ ...styles.modalMessage, color: theme.primaryText }}>
								{t('security_setting_screen_message_12')}
							</CustomText>
							<CustomText style={{ ...styles.modalMessage, color: theme.primaryText }}>
								{t('security_setting_screen_message_13')}
							</CustomText>

							<div style={styles.modalButtons}>
								<button
									style={styles.cancelButton}
									onClick={() => setDeleteModalVisible(false)}
								>
									<CustomText style={{ ...styles.cancelButtonText, color: theme.primaryText }}>
										{t('security_setting_screen_message_14')}
									</CustomText>
								</button>
								<button
									style={styles.confirmButton}
									onClick={handleDeletePress}
								>
									<CustomText style={{ ...styles.confirmButtonText, color: theme.deleteWalletTitleColor }}>
										{t('security_setting_screen_message_15')}
									</CustomText>
								</button>
							</div>
						</div>
					</div>
				)
			}
			{
				showPinCodeAuth && (
					<div style={styles.modalBackground}>
						<PinCodeScreen onSuccess={handlePinCodeAuthSuccess} unlock={false} />
					</div>
				)
			}
		</div >
	)
}

export default SecuritySettings

import { useState, useCallback, useEffect } from 'react'
import { ethers } from 'ethers'

const FULL_URL = `${process.env.REACT_APP_ETH_RPC_URL}${process.env.REACT_APP_INFURA_PROJECT_ID}`
const sourceProvider = new ethers.providers.JsonRpcProvider(FULL_URL)
const destProvider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL)

const SWAP_ABI = [
	'function swapCoinToToken() external payable',
	'function swapTokenToCoin(uint256 tokenAmount) external',
	'function coinToTokenRate() view returns (uint256)',
	'function tokenToCoinRate() view returns (uint256)',
	'function getEthBalance() view returns (uint256)',
	'function getTokenBalance() view returns (uint256)'
]

const ERC20_ABI = [
	'function name() view returns (string)',
	'function symbol() view returns (string)',
	'function decimals() view returns (uint8)',
	'function balanceOf(address owner) view returns (uint256)',
	'function transfer(address to, uint256 amount) returns (bool)',
    'function approve(address spender, uint256 amount) public returns (bool)',
	'function allowance(address owner, address spender) public view returns (uint256)'
]

const useSmartTokenSwap = (tokenAddress: string) => {
	const [loading, setLoading] = useState(false)
	const [transactionHash, setTransactionHash] = useState<string | null>(null)
	const [error, setError] = useState<string | null>(null)
	// const [contract, setContract] = useState<ethers.Contract | null>(null)
	const [tokenContract, setTokenContract] = useState<ethers.Contract | null>(null)
	const [coinToTokenRate, setCoinToTokenRate] = useState<number | null>(null)
	const [tokenToCoinRate, setTokenToCoinRate] = useState<number>(0)
	const [pendingSwap, setPendingSwap] = useState<{ amount: string; signer: ethers.Signer } | null>(null)
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		if (tokenAddress) {
			const newTokenContract = new ethers.Contract(tokenAddress, ERC20_ABI, sourceProvider)
			console.log('newTokenContract tokenAddress')
			console.log(tokenAddress)
			setTokenContract(newTokenContract)
			setTokenToCoinRate(1.5)
		}
	}, [tokenAddress])

    /**
     * SSC 소스 네트워크 ETH 잔량 확인
     * @param address 
     * 
     * @returns string balance
     */
	const getSourceBalance = useCallback(async (address: string) => {
            try {
                const balance = await sourceProvider.getBalance(address)
                const formattedBalance: any = ethers.utils.formatEther(balance)

                return formattedBalance
            } catch (error) {
                console.error(`Error fetching balance for ${address}:`, error)
                return '0.0'
            }
        },
        [sourceProvider]
    )

    /**
     * SSC 목적지 네트워크 메인넷 코인 잔량 확인
     * @param address 
     * 
     * @returns string balance
     */
	const getDestBalance = useCallback(async (address: string) => {
			try {
				const balance = await destProvider.getBalance(address)
				const formattedBalance: any = ethers.utils.formatEther(balance)

				return formattedBalance
			} catch (error) {
				console.error(`Error fetching balance for ${address}:`, error)
				return '0.0'
			}
		},
		[destProvider]
	)

    /**
     * SSC 소스 네트워크 토큰 잔량 확인
     * @param address 
     * 
     * @returns string balance
     */
    const getSourceTokenBalance = useCallback(async (walletAddress: string, contractAddress: string) => {
		try {
			const contract = new ethers.Contract(contractAddress, ERC20_ABI, sourceProvider)
			const [balance, decimals] = await Promise.all([contract.balanceOf(walletAddress), contract.decimals()])
			const formattedBalance = ethers.utils.formatUnits(balance, decimals)

			return formattedBalance
		} catch (error) {
			console.log('Error getting source token balance:', error)
			return '0.0'
		}
	}, [])

    /**
     * SSC 목적지 네트워크 토큰 잔량 확인
     * @param address 
     * 
     * @returns string balance
     */
    const getDestTokenBalance = useCallback(async (walletAddress: string, contractAddress: string) => {
		try {
			const contract = new ethers.Contract(contractAddress, ERC20_ABI, destProvider)
			const [balance, decimals] = await Promise.all([contract.balanceOf(walletAddress), contract.decimals()])
			const formattedBalance = ethers.utils.formatUnits(balance, decimals)

			return formattedBalance
		} catch (error) {
			console.log('Error getting destination token balance:', error)
			return '0.0'
		}
	}, [])

    /**
     * 지정한 소스 네트워크 토큰 정보 가져오기
     * @param contractAddress 
     * 
     * @returns tokenInfo
     */
    const getSourceTokenInfo = useCallback(
        async (contractAddress: string) => {
		try {
			const contract = new ethers.Contract(contractAddress, ERC20_ABI, sourceProvider)
			const tokenName = await contract.name()
			const decimal = await contract.decimals()
			const symbol = await contract.symbol()

			const tokenInfo = {
				name: tokenName,
				symbol: symbol,
				decimals: decimal,
			};

			return tokenInfo
		} catch (error) {
			console.log('Error getting SourceTokenInfo info:', error)
			return null
		}
	}, [])

    /**
     * 지정한 목적지 네트워크 토큰 정보 가져오기
     * @param contractAddress 
     * 
     * @returns tokenInfo
     */
    const getDestTokenInfo = useCallback(
        async (contractAddress: string) => {
		try {
			const contract = new ethers.Contract(contractAddress, ERC20_ABI, destProvider)
			const tokenName = await contract.name()
			const decimal = await contract.decimals()
			const symbol = await contract.symbol()

			const tokenInfo = {
				name: tokenName,
				symbol: symbol,
				decimals: decimal,
			};

            console.log('useSmartTokenSwap getDestTokenInfo')
            console.log(tokenInfo)

			return tokenInfo
		} catch (error) {
			console.log('Error getting Destination token info:', error)
			return null
		}
	}, [])

    /**
     * 소스 네트워크 gasFee 가져오기
     * 
     * @returns tokenInfo
     */
	const sourceEstimateGas = useCallback(async () => {
		try {
			const gasPrice = await sourceProvider.getGasPrice()
			const gasLimit = 21000
			const gasFee = ethers.utils.formatEther(gasPrice.mul(gasLimit))

			return gasFee
		} catch (error) {
			console.error('Error sourceEstimateGas gas fee:', error)
			return '0.0'
		}
	}, [sourceProvider])

    /**
     * 목적지 네트워크 gasFee 가져오기
     * 
     * @returns tokenInfo
     */
	const destEstimateGas = useCallback(async () => {
		try {
			const gasPrice = await destProvider.getGasPrice()
			const gasLimit = 21000
			const gasFee = ethers.utils.formatEther(gasPrice.mul(gasLimit))

			return gasFee
		} catch (error) {
			console.error('Error destProvider gas fee:', error)
			return '0.0'
		}
	}, [destProvider])

    /**
     * 소스 네트워크 토큰을 타겟 네트워크 코인으로 교환
     * 
     * @parma privateKey : 비밀키
     * @parma toAddress : 토큰 보낼 지갑 주소
     * @parma sourceTokenAmount : 스왑할 소스 토큰 수량
     * @parma tokenSymbol : 보낼 토큰 심볼
     * @parma tokenAddress : 보낼 토큰 컨트랙트 주소
     * @returns tokenInfo
     */
	const swapTokenToCoin = useCallback(
        async (privateKey: string, 
                toAddress: string, 
                sourceTokenAmount: string, 
                sourceTokenSymbol: string, 
                sourceTokenAddress: string, 
                destWithdrawWalletAddress: string) => {
			
			setLoading(true)
			setError(null)
			try {
                // SSC 입금확인 지갑 주소 확인
				if (!ethers.utils.isAddress(toAddress)) {
                    console.log('Error toAddress format:')
                    return null;
                }

                // 출금전송 지갑주소 확인
                if (!ethers.utils.isAddress(destWithdrawWalletAddress)) {
                    console.log('Error destWithdrawWalletAddress format:')
                    return null;
                }

                // 스왑할 소스 토큰 정보
                // const sourceTokenInfo = await getSourceTokenInfo(tokenAddress)
                // if(null == sourceTokenInfo) {
                //     console.log('Error sourceTokenInfo is null')
                //     return
                // }

                // 스왑할 소스 토큰 의 수량
                // const amountInWei = ethers.utils.parseUnits(amount, sourceTokenInfo.decimals)

                // SSC 출금 전송 지갑의 코인 보유량 확인
                const sscWithdrawBalance = await getDestBalance(destWithdrawWalletAddress);

                if(sscWithdrawBalance <= sourceTokenAmount) {
                    console.log(`Error dest withdraw wallet balance is low [${sscWithdrawBalance}]`)
                    return null;
                }

                // SSC 입금 확인 지갑으로 토큰 전송
                const receipt = await sendTransactionWithPrivateKeyETH(
                                        privateKey, 
                                        toAddress, 
                                        sourceTokenAmount, 
                                        sourceTokenSymbol, 
                                        sourceTokenAddress)
				return receipt
			} catch (error: any) {
				console.log('Error swapping token to coin:', error)
				setError(error.message)
			} finally {
				setLoading(false)
			}
		},
		[tokenContract]
	)

    const sendTransactionWithPrivateKeyETH = useCallback(
		async (privateKey: string, toAddress: string, amount: string, tokenSymbol: string, tokenAddress: string = '') => {
			try {
				const wallet = new ethers.Wallet(privateKey, sourceProvider)

				let tx
				if (tokenSymbol === 'USDT') {
					const contract = new ethers.Contract(tokenAddress, ERC20_ABI, wallet)
                    const decimals = await contract.decimals() // 소수점
					const amountInWei = ethers.utils.parseUnits(amount, decimals) // 전송수량

					tx = await contract.populateTransaction.transfer(toAddress, amountInWei)

                    const response = await wallet.sendTransaction(tx)
                    console.log(`Transaction hash: ${response.hash}`)
    
                    const receipt = await response.wait()
                    console.log(`Transaction was confirmed in block ${receipt.blockNumber}`)
                    return receipt
				} else {
                    console.log(`No Support Source Token`)
                    return null
                }
			} catch (error) {
				console.log('Error sending transaction:', error)
				throw error
			}
		},
		[sourceProvider]
	)

	return {
		swapTokenToCoin,
		getSourceBalance,
		getDestBalance,
		getSourceTokenBalance,
		getDestTokenBalance,
		getSourceTokenInfo,
		getDestTokenInfo,
		sourceEstimateGas,
		destEstimateGas,
		loading,
		transactionHash,
		error,
		coinToTokenRate,
		tokenToCoinRate,
		isLoading,
		setIsLoading,
	}
}

export default useSmartTokenSwap

import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import { Check } from 'lucide-react';

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
import Container from '../Common/Container';
import useWallet from '../../hooks/useWallet';
import useSecureStorage from '../../hooks/useSecureStorage';

import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkPincode, checkMnemonic, RootState } from '../../store';
import { BounceLoader, MoonLoader } from "react-spinners";

const WalletRecoveryLoading: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	const { t } = useTranslation();
	let dispatch = useDispatch();

	const { makeWallet, restoreWallet } = useWallet()
	const { setLogin, storeEncryptedData } = useSecureStorage()

	const pincode_ = useSelector((state: RootState) => state.pincode.value);
	const mnemonic_ = useSelector((state: RootState) => state.mnemonic.value);

	useEffect(() => {
		const generateWallet = async () => {
			try {
				const storedMnemonic = mnemonic_;

				if (storedMnemonic) {
					const mnemonicArray = JSON.parse(storedMnemonic)
					const mnemonic = Array.isArray(mnemonicArray) ? mnemonicArray.join(' ') : mnemonicArray

					if (mnemonic.split(' ').length % 3 === 0) {
						const wallet = await restoreWallet(mnemonic)

						const walletAddress = wallet.address
						const publicKey = wallet.publicKey
						const privateKey = wallet.privateKey
						const createdPin = pincode_;

						if (null == walletAddress || null == publicKey || null == privateKey) {
							console.error('Error restoring wallet')
							return
						}

						const result = await storeEncryptedData(walletAddress, privateKey, publicKey, mnemonicArray.join(' '), createdPin)
						if (false == result) {
							console.error('Error restoring wallet save')
							return
						}

						setTimeout(() => {
							navigate(`/WalletRecoveryComplete`)
						}, 2000)

					} else {
						throw new Error('Invalid mnemonic length')
					}
				}
			} catch (error) {
				console.error('Error restoring wallet:', error)
			}
		}

		generateWallet()
	}, [])

	const styles = {
		container: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			marginTop: "150px"
		} as React.CSSProperties,
		title: {
			fontSize: 24,
			color: theme.text_01,
			textAlign: 'center',
			margin: "5px 0 0"
		} as React.CSSProperties,
		subTitle1: {
			fontSize: 18,
			color: theme.text_01,
			textAlign: 'center',
			margin: "5px 0 0"
		} as React.CSSProperties,
		subTitle2: {
			fontSize: 18,
			color: theme.text_01,
			textAlign: 'center',
			margin: "5px 0 0"
		} as React.CSSProperties
	}
	
	return (
		<div className='container' style={{ backgroundColor: theme.primaryBackgroundColor }}>
			<div style={styles.container}>
				<div style={{ marginBottom: "10px" }}>
					<BounceLoader color={"blue"} size={100} />
				</div>

				<CustomText weight="medium" style={{ ...styles.title}}>
					{t('wallet_recovery_complete_message_01')}
				</CustomText>

				<CustomText style={{ ...styles.subTitle1 }}>
					{t('wallet_recovery_complete_message_02')}
				</CustomText>

				<CustomText style={{ ...styles.subTitle2}}>
					{t('wallet_recovery_complete_message_03')}
				</CustomText>
			</div>
		</div>
	)
}

export default WalletRecoveryLoading

import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import { Check } from 'lucide-react';

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
import Container from '../Common/Container';
import CourierText from '../../styles/CourierText';
import CustomListCardWithIcon from '../Componentization/Card/CustomListCardWithIcon';
import CustomListCard from '../Componentization/Card/CustomListCard';
import CustomSwtich from '../Componentization/Switch/CustomSwtich';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkHeaderText, checkPincode, RootState } from '../../store';

import useWallet from '../../hooks/useWallet';
import useEthereum from '../../hooks/useEthereum';
import useEthereumOther from '../../hooks/useEthereumOther';
import useSecureStorage from '../../hooks/useSecureStorage';
import useDefaultNetworkAndToken from '../../hooks/useDefaultNetworkAndToken';

import MainPageHeader from '../../nav/MainPageHeader';
import NavigationBar from '../../nav/NavigationBar';

import i18next from 'i18next'

interface Asset {
    id: number
    name: string
    symbol: string
    balance: string
    icon: any
    address: string | null
    networkid: string
    chainid: string
    networkname: string
    useswap: boolean
    usertoken: boolean
}

const TokenList: React.FC = () => {
    const { theme } = useContext(ThemeContext)
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const { t } = useTranslation();

    const { networkInfo, tokenInfo, userTokenInfo, otherTokenInfo, searchToken, searchNetwork, removeToken, forceTokenDataUpdate } = useDefaultNetworkAndToken();
    const [defaultAssetsList, setDefaultAssetsList] = useState<Asset[]>([]);
    const [assets, setAssets] = useState<Asset[]>([]);
    const [defaultOtherAssetsList, setDefaultOtherAssetsList] = useState<Asset[]>([]);
    const [otherAssets, setOtherAssets] = useState<Asset[]>([]);
    const [isConfirmModalVisible, setConfirmModalVisible] = useState(false)
    const [isInfoModalVisible, setInfoModalVisible] = useState(false)
    const [deleteTokenAddress, setDeleteTokenAddress] = useState('');
    const [infoTokenAddress, setInfoTokenAddress] = useState('');

    // const dismissKeyboard = () => {
    //     Keyboard.dismiss()
    // }

    // const imageMap = {
    //     '@assets/img/icon_buff.png': require('@assets/img/icon_buff.png'),
    //     '@assets/img/icon_qco.png': require('@assets/img/icon_qco.png'),
    //     '@assets/img/icon_tether_usdt.png': require('@assets/img/icon_tether_usdt.png')
    // };

    useEffect(() => {
        dispatch(checkHeaderText({ "link": "TokenList", "title": t('see_more_screen_message_13') }))
    }, [])

    useEffect(() => {
        const initializeAssets = () => {
            const defaultAssets = tokenInfo.map((token, index) => ({
                id: index,
                name: token.name,
                symbol: token.symbol,
                balance: '0.000000',
                icon: token.icon || '/img/icon_bsc.png',
                address: token.contract,
                networkid: token.networkid,
                chainid: token.chainid,
                networkname: token.networkname,
                useswap: token.useswap,
                usertoken: false
            }));

            const defaultLength = defaultAssets.length || 1
            const userAssets = userTokenInfo.map((token, index) => ({
                id: index + defaultLength,
                name: token.name,
                symbol: token.symbol,
                balance: '0.000000',
                icon: token.icon || '/img/icon_bsc.png',
                address: token.contract,
                networkid: token.networkid,
                chainid: token.chainid,
                networkname: token.networkname,
                useswap: token.useswap,
                usertoken: true
            }));

            setDefaultAssetsList([
                ...defaultAssets,
                ...userAssets
            ]);

            setAssets([
                ...defaultAssets,
                ...userAssets
            ]);

            // const defaultOtherAssets = otherTokenInfo.map((token, index) => ({
            //     id: index,
            //     name: token.name,
            //     symbol: token.symbol,
            //     balance: '0.000000',
            //     icon: token.icon || '/img/icon_bsc.png',
            //     address: token.contract,
            //     networkid: token.networkid,
            //     chainid: token.chainid,
            //     networkname: token.networkname,
            //     useswap: token.useswap,
            // }));

            // setDefaultOtherAssetsList([
            //     ...defaultOtherAssets
            // ]);
        };

        initializeAssets();
    }, [tokenInfo, userTokenInfo, otherTokenInfo]);

    const handleDelToken = async (contract: any) => {
        // 삭제할 토큰 주소 저장
        setDeleteTokenAddress(contract);

        // 삭제 확인 모달창 표시
        setConfirmModalVisible(true)
    }

    const handleConfirmDelete = async () => {
        setConfirmModalVisible(false)

        const delTokenContract = deleteTokenAddress

        // 토큰 삭제
        const result = await removeToken(delTokenContract);
        if ('success' == result) {
            // Toast.show({
            //     type: 'success',
            //     text1: i18next.t('list_token_screen_message_01'),
            //     position: 'bottom',
            //     visibilityTime: 800
            // })
            alert(i18next.t('list_token_screen_message_01'));
        }
        else {
            // Toast.show({
            //     type: 'error',
            //     text1: i18next.t('list_token_screen_message_02'),
            //     position: 'bottom',
            //     visibilityTime: 800
            // })
            alert(i18next.t('list_token_screen_message_02'));
        }
    }

    const handleShowInfoToken = async (contract: any) => {
        // 상세정보 표시할 토큰 정보 저장
        setInfoTokenAddress(contract);

        setInfoModalVisible(true);
    }

    const renderDeleteModal = () => (
        isConfirmModalVisible && (
            <div style={styles.modalBackground}>
                <div style={{ ...styles.modalContainer, backgroundColor: theme.modalBackgroundColor }}>
                    <CustomText weight="bold" style={{ ...styles.modalTitle, color: theme.deleteWalletTitleColor }}>
                        {t('security_setting_screen_message_09')}
                    </CustomText>
                    <CustomText style={{ ...styles.modalMessage, color: theme.primaryText }}>
                        {t('security_setting_screen_message_10')}
                    </CustomText>
                    <CustomText style={{ ...styles.modalMessage, color: theme.primaryText }}>
                        {t('security_setting_screen_message_11')}
                    </CustomText>
                    <CustomText style={{ ...styles.modalMessage, color: theme.primaryText }}>
                        {t('security_setting_screen_message_12')}
                    </CustomText>
                    <CustomText style={{ ...styles.modalMessage, color: theme.primaryText }}>
                        {t('security_setting_screen_message_13')}
                    </CustomText>

                    <div style={styles.modalButtons}>
                        <button
                            style={styles.cancelButton}
                            onClick={() => setConfirmModalVisible(false)}
                        >
                            <CustomText style={{ ...styles.cancelButtonText, color: theme.primaryText }}>
                                {t('security_setting_screen_message_14')}
                            </CustomText>
                        </button>
                        <button
                            style={styles.confirmButton}
                            onClick={handleConfirmDelete}
                        >
                            <CustomText style={{ ...styles.confirmButtonText, color: theme.deleteWalletTitleColor }}>
                                {t('security_setting_screen_message_15')}
                            </CustomText>
                        </button>
                    </div>
                </div>
            </div>
        )
    )

    // 토큰정보 모달
    // const renderInfoModal = () => (
    //     <RNModal isVisible={isInfoModalVisible} onBackdropPress={() => setInfoModalVisible(false)}>
    // 		<View style={styles.infoModalContent}>
    // 			<View style={styles.infoModalHeader}>
    // 				<CustomText weight='medium' style={[styles.modalTitle]}>
    // 					{i18next.t('template_wallet_screen_message_11')}
    // 				</CustomText>
    // 				<TouchableOpacity onPress={() => setInfoModalVisible(false)}>
    // 					<Icon name='close' size={24} color=theme.text_05 />
    // 				</TouchableOpacity>
    // 			</View>

    // 			{/* <CustomButton
    // 				containerStyle={[styles.dateSubmitButton, { backgroundColor: theme.primary }]}
    // 				textStyle={[styles.dateSubmitButtonText]}
    // 				onPress={handleDateSubmit}
    // 				text={i18next.t('template_wallet_screen_message_14')}
    // 				weight='medium'
    // 				withBackground={true}
    // 				backgroundColor={theme.primaryTouchBackgroundColor}
    // 			/>*/}
    // 		</View>
    // 	</RNModal>
    // )

    const styles = {
        container: {
            padding: "60px 5px 24px",
        } as React.CSSProperties,
        inner: {
            display: "flex",
            justifyContent: 'space-between',
            width: "90%"
        } as React.CSSProperties,
        text: {
            fontSize: 16,
            marginBottom: 16,
            textAlign: 'center'
        } as React.CSSProperties,
        button: {
            padding: "24px 12px",
            borderRadius: 10,
            width: '100%',
            position: 'absolute',
            bottom: 60,
            marginBottom: 0,
            justifyContent: 'center'
        } as React.CSSProperties,
        transactionItem: {
            display: "flex",
            alignItems: 'center',
            padding: "13px 15px",
            backgroundColor: theme.card_background_04,
            borderRadius: 10,
            marginBottom: 10,
            border: `1px solid ${theme.border_04}`
        } as React.CSSProperties,
        transactionIcon: {
            width: 30,
            height: 30,
            backgroundColor: theme.img_background_01,
            borderRadius: 25,
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 15
        } as React.CSSProperties,
        transactionDetails: {
            flex: 1
        } as React.CSSProperties,
        transactionName: {
            fontSize: 15
        } as React.CSSProperties,
        transactionAmountContainer: {
            display: "flex",
            alignItems: 'center'
        } as React.CSSProperties,
        xIcon: {
            width: 15,
            height: 15
        } as React.CSSProperties,
        modalBackground: {
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.modal_background_01,
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0
        } as React.CSSProperties,
        modalContainer: {
            width: '90%',
            backgroundColor: 'white',
            borderRadius: 10,
            padding: '20px',
            border: `1px solid ${theme.border_09}`,
            boxSizing: "border-box"
        } as React.CSSProperties,
        modalTitle: {
            fontSize: 18,
            color: theme.text_17,
            textAlign: 'center',
            marginBottom: 10
        } as React.CSSProperties,
        modalMessage: {
            fontSize: 14,
            color: theme.text_02,
            textAlign: 'center'
        } as React.CSSProperties,
        modalButtons: {
            display: "flex",
            width: '100%',
            borderTop: `1px solid ${theme.border_10}`,
            marginTop: 20
        } as React.CSSProperties,
        cancelButton: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            paddingVertical: 10,
            borderRight: `1px solid ${theme.border_10}`,
        } as React.CSSProperties,
        cancelButtonText: {
            color: theme.text_15,
            fontSize: 16
        } as React.CSSProperties,
        confirmButton: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            paddingVertical: 10
        } as React.CSSProperties,
        confirmButtonText: {
            color: theme.text_17,
            fontSize: 16
        } as React.CSSProperties,
        infoModalContent: {
            backgroundColor: 'white',
            padding: 20,
            borderRadius: 20,
            alignItems: 'center'
        } as React.CSSProperties,
        infoModalHeader: {
            display: "flex",
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
            marginBottom: 20,
            padding: "0 10px",
        } as React.CSSProperties,
        deleteButton: {
            background: 'none',
            border: 'none',
            cursor: 'pointer',
        } as React.CSSProperties,
    }

    return (
        <div className='container' style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
            <div onClick={() => { }} style={styles.inner}>
                <div style={{ flex: 1, justifyContent: 'flex-start', gap: '5px' }}>
                    {assets.map((item, index) => (
                        <div key={index} style={{ ...styles.transactionItem, backgroundColor: theme.transactionHistoryListBackgroundColor }}>
                            <img
                                src={item.icon}
                                alt={`${item.name} icon`}
                                style={styles.transactionIcon}
                            />
                            <div style={styles.transactionDetails}>
                                <span style={{ ...styles.transactionName, color: theme.primaryText }}>
                                    {item.name}
                                </span>
                            </div>
                            {item.usertoken && (
                                <div style={styles.transactionAmountContainer}>
                                    <button onClick={() => handleDelToken(item.address)} style={styles.deleteButton}>
                                        <img
                                            src="/img/icon_x.png" // 실제 경로로 변경 필요
                                            alt="Delete token"
                                            style={styles.xIcon}
                                        />
                                    </button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            {/* {renderInfoModal()} */}
            {renderDeleteModal()}
        </div>
    )
}

export default TokenList

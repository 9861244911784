import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeContext } from '../../context/ThemeContext';
import { Check, CircleAlert } from 'lucide-react';
import { ethers } from 'ethers'

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
import Container from '../Common/Container';
import CourierText from '../../styles/CourierText';
import CustomListCardWithIcon from '../Componentization/Card/CustomListCardWithIcon';
import CustomListCard from '../Componentization/Card/CustomListCard';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkHeaderText, checkPincode, RootState } from '../../store';

import useWallet from '../../hooks/useWallet';
import useWalletSetup from '../../hooks/useWalletSetup';
import useEthereum from '../../hooks/useEthereum';
import useEthereumOther from '../../hooks/useEthereumOther';
import useSecureStorage from '../../hooks/useSecureStorage';
import useDefaultNetworkAndToken from '../../hooks/useDefaultNetworkAndToken';
import useSmartTokenSwap from '../../hooks/useSmartTokenSwap';

import MainPageHeader from '../../nav/MainPageHeader';
import NavigationBar from '../../nav/NavigationBar';
import i18next from 'i18next'

import PinCodeScreen from '../Authentication/PinCodeScreen';
import Spinner from '../Componentization/Modal/Spinner';

// const DEFAULT_SWAP_CONTRACT_ADDRESS = '0xbe23565E20f261e447ee49cC549daB299bA712e2' // DIVC - BUFF 스왑 컨트랙트
const TOKEN_ADDRESS = ''

const DonationScreen: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const { t } = useTranslation();

	const { getWalletAddress } = useSecureStorage()

	// // const { sendTransactionWithPrivateKey, estimateGas } = useEthereum()
	const { sendTransactionWithPrivateKeyETH, getEthBalance, getUsdtBalance, estimateGasEth, usdtBalance, ethBalance } = useEthereumOther()

	const [isCrossChain, setIsCrossChain] = useState(false)
	const [isDonationed, setIsDonationed] = useState(false)
	const [amount, setAmount] = useState('')
	const [isApproving, setIsApproving] = useState(false)
	const [donationAmount, setDonationAmount] = useState('')

	// const [signer, setSigner] = useState<ethers.Signer | null>(null)
	// const [privateKey, setPrivateKey] = useState<string | null>(null)
	const [modalVisible, setModalVisible] = useState(false)
	const [modalContent, setModalContent] = useState<'approve' | 'swap'>('approve')
	const [isDonationDisable, setisDonationDisable] = useState(false)
	const [termsAccepted, setTermsAccepted] = useState(false)
	// const [swapContractAddress, setswapContractAddress] = useState<string>('')
	const [swapReceiveAddress, setSwapReceiveAddress] = useState<string>('')
	const [gasFee, setGasFee] = useState<string | undefined>('0.0')
	const [sourceTokenInfo, setSourceTokenInfo] = useState<any>()
	const [modalVisible1, setModalVisible1] = useState(false);
	const [isAmountValid, setIsAmountValid] = useState(false)
	const [amountHelperMessage, setAmountHelperMessage] = useState('')
	const [walletAddress, setWalletAddress] = useState('')

	const [showPinCodeAuth, setShowPinCodeAuth] = useState(false)

	let signer: ethers.Signer | null = null

	const location = useLocation();
	const [donation, setDonation] = useState(location.state === null ? null : location.state.donation);
	const [token, setToken] = useState(location.state === null ? null : location.state.token);
	useEffect(() => {
		if (location.state) {
			setToken(location.state.token);
			setDonation(location.state.donation);

			console.log(`후원하기 토큰정보 : `, location.state.token)
			console.log(`후원하기 설정정보 : `, location.state.donation)
		}
	}, []);

	useEffect(() => {
		dispatch(checkHeaderText({ "link": "DonationScreen", "title": t('stack_header_message_19') }))
	}, [])

	// let signer:ethers.Signer | null = null
	const tokenAddress = token.address || TOKEN_ADDRESS

	const toggleModal1 = () => {
		setModalVisible1(!modalVisible1);
	};

	// const imageMap = {
	// 	'@assets/img/icon_buff.png': require('@assets/img/icon_buff.png'),
	// 	'@assets/img/icon_qco.png': require('@assets/img/icon_qco.png'),
	// 	'@assets/img/icon_tether_usdt.png': require('@assets/img/icon_tether_usdt.png')
	// };

	const {
		swapTokenToCoin,
		getSourceBalance,
		getDestBalance,
		getSourceTokenBalance,
		getDestTokenBalance,
		getSourceTokenInfo,
		getDestTokenInfo,
		sourceEstimateGas,
		destEstimateGas,
		loading,
		transactionHash,
		error,
		coinToTokenRate,
		tokenToCoinRate,
		isLoading,
		setIsLoading,
	} = useSmartTokenSwap(tokenAddress)

	const { retrieveEncryptedData } = useSecureStorage()

	useEffect(() => {
		const initDonation = async () => {
			const newToken = location.state.token
			const newDonationUI = location.state.donation

			if (newToken) {
				setAmount('')
				setDonationAmount('')
				setToken(newToken)

				const receiveWallet = process.env.REACT_APP_SMARTSWAP_RECEIVE_WALLET!
				console.log(`useEffect receiveWallet : ${receiveWallet}`)

				console.log(`useEffect SMARTSWAP_RECEIVE_WALLET : ${receiveWallet}`)
				setSwapReceiveAddress(receiveWallet);
				console.log(`useEffect swapReceiveAddress : ${receiveWallet}`)

				if (0 >= receiveWallet.length) {
					setisDonationDisable(true)
				} else {
					setisDonationDisable(false)
				}
			}
			if (newDonationUI) {
				setDonation(newDonationUI)
				console.log(`useFocusEffect setDonation`)
				console.log(donation)
			}
		}
		initDonation()
	}, [])

	useEffect(() => {
		const fetchAddress = async () => {
			const address: any = await getWalletAddress()

			if (address) {
				const estimatedGasFee = await estimateGasEth()
				setGasFee(estimatedGasFee)
				setWalletAddress(address)
			}
			await getEthBalance(address);
		}

		fetchAddress()
	}, [])

	// useEffect(() => {
	// 	const connectWallet = async () => {
	// 		try {
	// 			const provider = new ethers.providers.JsonRpcProvider(RPC_URL)
	// 			if (privateKey) {
	// 				const wallet = new ethers.Wallet(privateKey, provider)
	// 				setSigner(wallet)
	// 			}
	// 		} catch (error) {
	// 			console.log('Error connecting wallet:', error)
	// 		}
	// 	}

	// 	if (privateKey) connectWallet()
	// }, [privateKey])

	// useEffect(() => {
	// 	validateDonationAmount(donationAmount)
	// }, [donationAmount])

	useEffect(() => {
		const initSourceTokenInfo = async () => {
			const sourceTokenInfo: any = await getSourceTokenInfo(tokenAddress)
			setSourceTokenInfo(sourceTokenInfo);
		}

		initSourceTokenInfo()
	}, [])

	useEffect(() => {
		const fetchAccountDetails = async () => {
			try {
				const walletAddress = await getWalletAddress()
				if (walletAddress) {
					setWalletAddress(walletAddress)
					await Promise.all([getUsdtBalance(walletAddress, token.address), getEthBalance(walletAddress)])
				}
			} catch (error) {
				console.error('Error fetching account details:', error)
			}
		}

		if (token.symbol === 'USDT') fetchAccountDetails()
	}, [getUsdtBalance, getEthBalance, token.symbol])

	const isDonationButtonEnabled = !isDonationDisable && isAmountValid && termsAccepted

	// const handleSigner = async (pin: string) => {
	// 	try {
	// 		const walletAddress: any = await getWalletAddress()
	// 		const data = await retrieveEncryptedData(walletAddress, pin)
	// 		const pvKey: any = data.privateKey

	// 		if(null == pvKey) {
	// 			alert(i18next.t('template_swap_screen_message_03'))
	// 			setModalVisible(false)
	// 			return false
	// 		}

	// 		const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL)
	// 		const wallet = new ethers.Wallet(pvKey, provider)
	// 		signer = wallet
	// 		// setSigner(wallet)
	// 		return true
	// 	} catch (error) {
	// 		console.log('Error connecting wallet:', error)
	// 		// Toast.show({
	// 		// 	type: 'error',
	// 		// 	position: 'bottom',
	// 		// 	text1: t('template_swap_screen_message_03'),
	// 		// 	text2: t('template_swap_screen_message_04'),
	// 		// 	visibilityTime: 1000
	// 		// })
	// 		alert(i18next.t('template_swap_screen_message_03'))
	// 		setModalVisible(false)
	// 	}
	// 	return false
	// }

	const handleDonation = async (pin: string) => {
		setModalVisible(true)
		if (!amount || isNaN(parseFloat(amount))) {
			// Toast.show({
			// 	type: 'error',
			// 	position: 'bottom',
			// 	text1: t('template_donation_screen_message_01'),
			// 	text2: t('template_donation_screen_message_02'),
			// 	visibilityTime: 1000
			// })
			alert(i18next.t('template_donation_screen_message_01'))
			setModalVisible(false)
			return
		}

		// if (!signer) {
		// 	Toast.show({
		// 		type: 'error',
		// 		position: 'bottom',
		// 		text1: t('template_donation_screen_message_03'),
		// 		text2: t('template_donation_screen_message_04'),
		// 		visibilityTime: 1000
		// 	})
		// 	setModalVisible(false)
		// 	return
		// }

		// const owner = await signer.getAddress()

		if (!swapReceiveAddress) {
			// Toast.show({
			// 	type: 'error',
			// 	position: 'bottom',
			// 	text1: t('template_donation_screen_message_05'),
			// 	visibilityTime: 1000
			// })
			alert(i18next.t('template_donation_screen_message_05'))
			setModalVisible(false)
			return
		}

		// SSC DB 설정에서 받아오는 데이터 사용하게 변경해야 함
		const tokenToCoinRate = 1.5;

		console.log(`sourceTokenAddress : ${tokenAddress}`)
		const sourceTokenInfo = await getSourceTokenInfo(tokenAddress)
		console.log(`sourceTokenInfo1`)
		console.log(sourceTokenInfo)

		if (!sourceTokenInfo) {
			// Toast.show({
			// 	type: 'error',
			// 	position: 'bottom',
			// 	text1: t('template_donation_screen_message_26'),
			// 	visibilityTime: 1000
			// })
			alert(i18next.t('template_donation_screen_message_26'))
			setModalVisible(false)
			return
		}

		try {
			setIsLoading(true)
			setModalContent('swap')

			const amountSourceToken = amount.replace(/,/g, '')
			console.log(`amountSourceToken : ${amountSourceToken}`)

			const amountInWei = ethers.utils.parseUnits(amountSourceToken, sourceTokenInfo.decimals)
			console.log(`requiredTokenAmount : ${amount} | ${amountInWei}`)

			const mulValue = ethers.BigNumber.from(Math.floor(tokenToCoinRate * 1000));
			const requiredDestCoinAmount = amountInWei.mul(mulValue).div(1000);
			// console.log(`필요한 목적지코인 수량 : ${requiredDestCoinAmount}`)

			const destWithdrawCoinBalance = await getDestBalance(process.env.REACT_APP_SMARTSWAP_WITHDRAW_WALLET!);
			// console.log(`목적지 코인 출금지갑 보유량 : ${destWithdrawCoinBalance}`)

			const destCoinInWei = ethers.utils.parseUnits(destWithdrawCoinBalance.replace(/,/g, ''), 18)
			// console.log(`목적지 코인 출금지갑 보유량 wei " ${destCoinInWei}`)

			if (destCoinInWei < requiredDestCoinAmount) {
				// Toast.show({
				// 	type: 'error',
				// 	position: 'bottom',
				// 	text1: t('template_donation_screen_message_27'),
				// 	visibilityTime: 1000
				// })
				alert(i18next.t('template_donation_screen_message_27'))
				setModalVisible(false)
				return
			}

			// 후원하기 동의정보 저장
			const response = await fetch(process.env.REACT_APP_WALLET_DONATION_AGREE_URL!, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: new URLSearchParams({
					wallet_address: walletAddress,
					donation_code: donation.code,
					donation_aggree: '1'
				}).toString()
			})

			const result = await response.json()
			console.log(result);

			if (true != result.result) {
				// Toast.show({
				// 	type: 'error',
				// 	position: 'bottom',
				// 	text1: t('template_donation_screen_message_38'),
				// 	visibilityTime: 1000
				// })
				alert(i18next.t('template_donation_screen_message_38'))
				setModalVisible(false)
				return
			}

			// 비밀키 데이터 복호화
			let privateKey: any
			try {
				const data = await retrieveEncryptedData(walletAddress, pin)
				privateKey = data.privateKey

				if (!privateKey) {
					// Toast.show({
					// 	type: 'error',
					// 	text1: t('template_withdraw_screen_message_22'),
					// 	position: 'bottom',
					// 	visibilityTime: 1000
					// })
					alert(i18next.t('template_withdraw_screen_message_22'))
					return
				}
			} catch (error) {
				console.log('error handleWithdraw', error)
				// Toast.show({
				// 	type: 'error',
				// 	text1: t('template_withdraw_screen_message_22'),
				// 	position: 'bottom',
				// 	visibilityTime: 1000
				// })
				alert(i18next.t('template_withdraw_screen_message_22'))
				return
			}

			// USDT를 SSC receive 지갑으로 입금 처리
			let receipt = await swapTokenToCoin(
				privateKey,
				swapReceiveAddress,
				amountSourceToken,
				token.symbol,
				tokenAddress,
				process.env.REACT_APP_SMARTSWAP_WITHDRAW_WALLET!)

			console.log('donation receipt')
			console.log(receipt)

			if (receipt) {
				setIsLoading(false)
				setModalVisible(false)

				navigate('/DonationCompleteScreen', {
					state: {
						transactionData: {
							transactionHash: receipt.transactionHash,
							amount: amount,
							isDonationed: isDonationed,
							donationUI: donation
						}
					}
				})

				// @ts-ignore
				// navigation.navigate('DonationCompleteScreen', {
				// 	transactionData: {
				// 		transactionHash: receipt.transactionHash,
				// 		amount,
				// 		isDonationed,
				// 		donationUI: donation
				// 	}
				// })
			}
		} catch (error) {
			console.error('Error during swap:', error)
			setIsLoading(false)
			setModalVisible(false)
		}

	}

	// const handleApprove = async () => {
	// 	if (!signer) return
	// 	setIsLoading(true)
	// 	setIsApproving(true)
	// 	setModalContent('approve')
	// 	setModalVisible(true)

	// 	try {
	// 		const response = await approveTokens(approveAmount.replace(/,/g, ''), signer)
	// 		if (response) {
	// 			setIsApproving(false)
	// 			setModalContent('swap')
	// 			handleSwap()
	// 		} else {
	// 			setIsLoading(false)
	// 			setModalVisible(false)
	// 		}
	// 	} catch (error) {
	// 		console.error('Error during token approval:', error)
	// 		setIsLoading(false)
	// 		setModalVisible(false)
	// 	}
	// }

	const handleMax = async () => {
		if (signer) {
			let balance = token.balance
			let balanceInTokens = parseFloat(balance).toFixed(4)

			setDonationAmount(formatNumberWithCommas(Number(balanceInTokens)))
			setAmount(balanceInTokens)
		}
	}

	const validateDonationAmount = (amount: string) => {
		let isValid = false
		let message = ''

		const tokenBalance = Number(token.balance)
		const gasPrice = Number(gasFee)

		const availableBalance = tokenBalance
		const fee = gasPrice ? gasPrice : 0
		const donationAmount = parseFloat(amount)
		const avalibleEth = ethBalance || '0.0'
		console.log(`avalibleEth : ${avalibleEth}`)

		if (avalibleEth == '0.0' || avalibleEth < fee) {
			isValid = false;
			message = t('template_donation_screen_message_37')
		} else if (amount.length === 0) {
			isValid = false
			message = t('template_donation_screen_message_32')
		} else if (isNaN(donationAmount) || donationAmount <= 0) {
			isValid = false
			message = t('template_donation_screen_message_33')
		} else if (donationAmount > availableBalance - fee) {
			isValid = false
			message = t('template_donation_screen_message_34')
		} else {
			isValid = true
		}

		setIsAmountValid(isValid)
		setAmountHelperMessage(message)
	}

	const verificationBeforeDonation = async () => {
		setShowPinCodeAuth(true)
	}

	const handlePinCodeintAuthSuccess = (pin: string) => {
		setShowPinCodeAuth(false)
		handleDonation(pin)
	}

	const formatNumberWithCommasString = (number: string) => {
		if (!number) return ''
		const parts = number.split('.')
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		return parts.join('.')
	}

	const formatNumberWithCommas = (number: number): string => {
		const fixedNumber = number.toFixed(6)
		const [integerPart, decimalPart] = fixedNumber.split('.')
		const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		return `${formattedIntegerPart}.${decimalPart}`
	}

	const styles = {
		container: {
			padding: "60px 0px 24px",
			// padding: "60px 0px 70px",
		} as React.CSSProperties,
		contentContainer: {
			flexGrow: 1,
			padding: "0 20px",
			paddingTop: 30,
			borderTop: `1px solid ${theme.border_04}`,
			borderLeft: `1px solid ${theme.border_04}`,
			borderRight: `1px solid ${theme.border_04}`,
			borderTopLeftRadius: 30,
			borderTopRightRadius: 30,
			width: "95%",
			boxSizing: "border-box",
		} as React.CSSProperties,
		header: {
			width: "100%",
			display: "flex",
			justifyContent: 'flex-start',
			textAlign: "left",
			marginBottom: 10,
			gap: 20,
			padding: "0 37px",
			boxSizing: "border-box"
		} as React.CSSProperties,
		tab: {
			textAlign: "left"
		} as React.CSSProperties,
		tabText: {
			fontSize: 16,
			color: theme.text_03
		} as React.CSSProperties,
		swapContainer: {
			backgroundColor: theme.background_06
		} as React.CSSProperties,
		maxInput: {
			fontSize: 14,
			color: theme.text_08,
			textDecorationLine: 'underline'
		} as React.CSSProperties,
		coinSectionWrapper: {
			position: 'relative'
		} as React.CSSProperties,
		sendCoinBox: {
			backgroundColor: theme.card_background_01,
			padding: "15px 18px",
			borderRadius: 10,
		} as React.CSSProperties,
		coinContainer: {
			display: "flex",
			justifyContent: 'space-between',
			alignItems: 'center'
		} as React.CSSProperties,
		coinInfoContainer: {
			display: "flex",
			alignItems: 'center',
			marginBottom: 20,
			width: "100%"
		} as React.CSSProperties,
		coinImageContainer: {
			width: 38,
			height: 38,
			borderRadius: 19,
			backgroundColor: theme.img_background_01,
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center'
		} as React.CSSProperties,
		coinIcon: {
			width: 38,
			height: 38
		} as React.CSSProperties,
		coinInfo: {
			marginLeft: 10,
			flex: 1
		} as React.CSSProperties,
		coinName: {
			fontSize: 15,
			color: theme.text_01
		} as React.CSSProperties,
		coinSubText: {
			fontSize: 14,
			color: theme.text_03
		} as React.CSSProperties,
		coinAmount: {
			width: "100%",
			borderTop: "none",
			borderRight: "none",
			borderLeft: "none",
			outline: "none",
			fontSize: 16,
			color: theme.text_01,
			height: "40px",
			textAlign: 'left',
			borderBottom: `1px solid ${theme.border_08}`,
			background: "inherit"
		} as React.CSSProperties,
		note: {
			fontSize: 12,
			color: theme.text_03,
			marginLeft: 2
		} as React.CSSProperties,
		noteContainer: {
			padding: "28px 0",
			gap: 8
		} as React.CSSProperties,
		noteRow: {
			display: "flex",
			alignItems: 'center',
			marginBottom: "15px"
		} as React.CSSProperties,
		emptyContainer: {
			display: "flex",
			justifyContent: 'center',
			alignItems: 'center'
		} as React.CSSProperties,
		emptyText: {
			fontSize: 16
		} as React.CSSProperties,
		button: {
			backgroundColor: theme.button_02,
			borderRadius: 10,
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center',
			height: 50
		} as React.CSSProperties,
		modalContainer: {
			display: "flex",
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: theme.modal_background_01
		} as React.CSSProperties,
		modalContent: {
			backgroundColor: theme.modal_background_02,
			padding: 15,
			borderRadius: 10,
			display: "flex",
			alignItems: 'center',
			width: '80%'
		} as React.CSSProperties,
		modalTitle: {
			fontSize: 18,
			marginBottom: 10
		} as React.CSSProperties,
		modalText: {
			fontSize: 14,
			color: theme.text_02,
			marginBottom: 20,
			textAlign: 'center'
		} as React.CSSProperties,
		banner: {
			width: "100%",
			height: 150,
			borderRadius: 10,
			marginBottom: 30,
		} as React.CSSProperties,
		checkboxContainer: {
			display: "flex",
			alignItems: 'center',
			justifyContent: 'flex-start'
		} as React.CSSProperties,
		checkboxBase: {
			width: "17px",
			height: "17px",
			borderRadius: 5,
			border: `1px solid ${theme.border_05}`,
			display: "flex",
			justifyContent: 'center',
			alignItems: 'center',
			marginRight: 5
		} as React.CSSProperties,
		checkboxText: {
			fontSize: 16,
			color: theme.text_09,
			marginLeft: 5
		} as React.CSSProperties,
		modalContainer1: {
			display: "flex",
			position: "fixed",
			zIndex: "1000",
			top: "0",
			left: '0',
			width: "100%",
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: theme.modal_background_03,
		} as React.CSSProperties,
		fullscreenImageWrapper: {
			width: '428px',  // 팝업이 작을 경우에 대비하여 100% 너비로 설정
			height: '100vh',  // 부모 높이로 설정
			display: "flex",
			justifyContent: 'center',
			alignItems: 'center',
		} as React.CSSProperties,
		fullscreenImage: {
			width: '428px', // 고정 너비
			height: 'auto', // 비율에 맞게 높이를 자동으로 설정
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			maxWidth: "100%",
			maxHeight: "100%",
			objectFit: "contain"
		} as React.CSSProperties,
		infoBox: {
			backgroundColor: theme.card_background_01,
			borderRadius: 10,
			padding: 10,
			margin: "10px 0"
		} as React.CSSProperties,
		infoContainer: {
			display: "flex",
			justifyContent: 'space-between',
			padding: "5px"
		} as React.CSSProperties,
		infoText: {
			fontSize: 14,
			color: theme.text_03
		} as React.CSSProperties,
		infoAmount: {
			fontSize: 14,
			color: theme.text_03,
			display: "flex",
			gap: "10px"
		} as React.CSSProperties,
		infoSymbol: {
			fontSize: 14,
			color: theme.text_03
		} as React.CSSProperties,
		donationButton: {
			width: "100%",
			backgroundColor: theme.button_02,
			padding: 15,
			borderRadius: 10,
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center',
			height: 50
		} as React.CSSProperties,
		donationButtonText: {
			color: 'white',
			fontSize: 16
		} as React.CSSProperties,
		helperText: {
			color: 'red',
			fontSize: 12,
			marginTop: 5,
			marginLeft: 0
		} as React.CSSProperties,
		modalBackground: {
			display: "flex",
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: theme.modal_background_03,
			position: "absolute",
			width: "100%",
			height: "100%",
			top: 0,
			left: 0,
			zIndex: 100
		} as React.CSSProperties,
	}

	return (
		<>
			{/* <NavigationBar /> */}
			<div className='container' style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
				{
					!showPinCodeAuth
						?
						<>
							<div style={styles.header}>
								<div
									onClick={() => setIsCrossChain(false)}
									style={{
										...styles.tab,
										...(isCrossChain ? {} : { borderColor: theme.primary }),
									}}
								>
									<span style={{ ...styles.tabText, ...(isCrossChain ? {} : { color: theme.primary, fontWeight: 'bold' }) }}>
										{donation.title}
									</span>
								</div>
							</div>
							<div style={{ ...styles.contentContainer, backgroundColor: theme.primaryBackgroundColor }}>
								{isCrossChain ? (
									<div style={styles.emptyContainer}>
										<CustomText style={{ ...styles.emptyText, color: theme.primaryText }}>
											{i18next.t('template_donation_screen_message_12')}
										</CustomText>
									</div>
								) : (
									<div style={{ ...styles.swapContainer, backgroundColor: theme.primaryBackgroundColor }}>
										<div style={styles.coinSectionWrapper}>
											<div>
												<button onClick={toggleModal1} style={{ width: "100%" }}>
													<img
														src={donation.imageUrl}
														alt="Donation Banner"
														style={{ ...styles.banner, objectFit: 'cover' }}
													/>
												</button>
												<div style={styles.sendCoinBox}>
													<div style={styles.coinContainer}>
														<div style={styles.coinInfoContainer}>
															<div style={styles.coinImageContainer}>
																<img
																	src="/img/icon_tether_usdt.png"
																	alt="USDT Icon"
																	style={styles.coinIcon}
																/>
															</div>
															<div style={styles.coinInfo}>
																<div style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }}>
																	<CustomText style={styles.coinName}>{token.symbol}</CustomText>
																	<div onClick={handleMax} style={styles.maxInput}>
																		<CustomText
																			style={{
																				color: theme.primary,
																				textDecoration: 'underline',
																				textDecorationColor: theme.primary,
																			}}
																		>
																			{i18next.t('template_donation_screen_message_14')}
																		</CustomText>
																	</div>
																</div>
																<CustomText style={styles.coinSubText}>{token.networkname}</CustomText>
															</div>
														</div>
													</div>
													<input
														type="text"
														onChange={(e) => {
															const text = e.target.value;
															const numericValue = text.replace(/[^0-9.]/g, '');
															const parts = numericValue.split('.');
															if (parts.length > 2) {
																setAmount(numericValue);
															} else if (parts.length === 2 && parts[1].length > 6) {
																const decimalPart = parts[1].slice(0, 6);
																setAmount(`${parts[0]}.${decimalPart}`);
															} else {
																setAmount(numericValue);
															}
															setDonationAmount(numericValue);
															validateDonationAmount(numericValue);
														}}
														value={formatNumberWithCommasString(donationAmount)}
														placeholder={i18next.t('template_donation_screen_message_15')}
														style={styles.coinAmount}
													/>
													<p style={styles.helperText}>
														{!isAmountValid && donationAmount.length > 0 && amountHelperMessage}
													</p>
												</div>
												<div style={styles.infoBox}>
													<div style={styles.infoContainer}>
														<CustomText style={styles.infoText}>{i18next.t('template_donation_screen_message_35')}</CustomText>
														<CourierText weight="bold" style={styles.infoAmount}>
															{formatNumberWithCommas(Number(token.balance))}{' '}
															<CustomText weight="medium" style={styles.infoSymbol}>
																{token.symbol}
															</CustomText>
														</CourierText>
													</div>
													<div style={styles.infoContainer}>
														<CustomText style={styles.infoText}>{i18next.t('template_withdraw_screen_message_13')}</CustomText>
														<CourierText weight="bold" style={styles.infoAmount}>
															{formatNumberWithCommas(Number(ethBalance))}{' '}
															<CustomText weight="medium" style={styles.infoSymbol}>
																ETH
															</CustomText>
														</CourierText>
													</div>
													<div style={styles.infoContainer}>
														<CustomText style={styles.infoText}>{i18next.t('template_withdraw_screen_message_10')}</CustomText>
														<CourierText weight="bold" style={styles.infoAmount}>
															{formatNumberWithCommas(Number(gasFee))}{' '}
															<CustomText weight="medium" style={styles.infoSymbol}>
																{token.name === 'USDT' ? 'ETH' : process.env.REACT_APP_NETWORK_CURRENCY}
															</CustomText>
														</CourierText>
													</div>
												</div>
											</div>
										</div>
										<div style={styles.noteContainer}>
											<div style={styles.noteRow}>
												<CircleAlert name="alert-circle-outline" size={16} color={theme.text_03} />
												<CustomText style={styles.note}>
													{donation.text}
												</CustomText>
											</div>
											<div style={styles.noteRow}>
												<CircleAlert name="alert-circle-outline" size={16} color={theme.text_03} />
												<CustomText style={styles.note}>
													{donation.text}
												</CustomText>
											</div>
											<div style={styles.checkboxContainer}>
												<div
													onClick={() => setTermsAccepted(!termsAccepted)}
													style={{
														...styles.checkboxBase,
														backgroundColor: termsAccepted ? theme.primary : theme.check_background_01,
														borderColor: termsAccepted
															? theme.enableWithdrawCheckBoxBorderColor
															: theme.disableWithdrawCheckBoxBorderColor,
													}}
												>
													{termsAccepted &&
														<Check
															name="checkmark"
															size={18}
															color={termsAccepted ? theme.enableCheckIconColor : theme.disableCheckIconColor}
														/>
													}
												</div>
												<CustomText style={{ ...styles.checkboxText, color: theme.primaryText }}>
													{donation.check}
												</CustomText>
											</div>
										</div>
										<CustomButton
											containerStyle={{ ...styles.donationButton, backgroundColor: theme.primary }}
											textStyle={styles.donationButtonText}
											onPress={() => { verificationBeforeDonation() }}
											text={t('template_donation_screen_message_31')}
											disabled={!isDonationButtonEnabled}
											disabledStyle={{ backgroundColor: theme.button_03 }}
											withBackground={true}
											backgroundColor={theme.primaryTouchBackgroundColor}
										/>
									</div>
								)}

							</div>
							{/* <Modal transparent={true} animationType='fade' visible={modalVisible}>
				<View style={styles.modalContainer}>
					<View style={styles.modalContent}>
						{modalContent === 'approve' ? (
							<>
								<CustomText style={[styles.modalTitle, { color: theme.darkModeModalTextColor }]}>
									{t('template_donation_screen_message_21')}
								</CustomText>
								<CustomText style={[styles.modalText, { color: theme.darkModeModalTextColor }]}>
									{t('template_donation_screen_message_22')}
								</CustomText>
								<ActivityIndicator size='large' color={theme.primary} />
							</>
						) : (
							<>
								<CustomText style={[styles.modalTitle, { color: theme.darkModeModalTextColor }]}>
									{t('template_donation_screen_message_23')}
								</CustomText>
								<CustomText style={[styles.modalText, { color: theme.darkModeModalTextColor }]}>
									{t('template_donation_screen_message_24')}
								</CustomText>
								<ActivityIndicator size='large' color={theme.primary} />
							</>
						)}
					</View>
				</View>
			</Modal> */}

							{/* <Modal
				visible={modalVisible1}
				transparent={true}
				onRequestClose={toggleModal1}
			> */}
							{modalVisible1 && (
								<div style={styles.modalContainer1}>
									<div onClick={toggleModal1} style={styles.fullscreenImageWrapper}>
										<img
											src={donation.imageUrl}
											alt="Donation Fullscreen"
											style={{ ...styles.fullscreenImage }}
										/>
									</div>
								</div>
							)}

							{modalVisible && (
								<Spinner />
							)}
						</>
						:
						<div style={styles.modalBackground}>
							<PinCodeScreen onSuccess={handlePinCodeintAuthSuccess} unlock={false} />
						</div>
				}
			</div>
		</>
	)
}

export default DonationScreen

import { useState, useCallback } from 'react'
import { ethers } from 'ethers'

import axios from 'axios'

const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL)

const ERC20_ABI = [
	'function name() view returns (string)',
	'function symbol() view returns (string)',
	'function decimals() view returns (uint8)',
	'function balanceOf(address owner) view returns (uint256)',
	'function transfer(address to, uint256 amount) returns (bool)'
]

const cache: any = {}
const gasPriceCache: any = {}
const tokenBalanceCache: any = {}

const cacheExpiryTime = 10000
const gasPriceCacheExpiryTime = 10000

const useEthereum = () => {
	const [balance, setBalance] = useState(null)
	const [transactionReceipt, setTransactionReceipt] = useState(null)
	const [blockDetails, setBlockDetails] = useState(null)
	const [transactionDetails, setTransactionDetails] = useState(null)

	const getBalance = useCallback(
		async (address: string) => {
			try {
				const currentTime = Date.now()
				const cachedData = cache[address]

				if (cachedData && currentTime - cachedData.timestamp < cacheExpiryTime) {
					console.log('Using cached balance')
					return cachedData.balance
				}

				const balance = await provider.getBalance(address)
				const formattedBalance: any = ethers.utils.formatEther(balance)

				cache[address] = {
					balance: formattedBalance,
					timestamp: currentTime
				}

				setBalance(formattedBalance)
				return formattedBalance
			} catch (error) {
				console.error(`Error fetching balance for ${address}:`, error)
				return '0.0'
			}
		},
		[provider]
	)

	const getTokenBalance = useCallback(async (walletAddress: string, contractAddress: string) => {
		try {
			const cacheKey = `${walletAddress}_${contractAddress}`
			const currentTime = Date.now()
			const cachedData = tokenBalanceCache[cacheKey]

			if (cachedData && currentTime - cachedData.timestamp < cacheExpiryTime) {
				console.log('Using cached token balance')
				return cachedData.balance
			}

			const contract = new ethers.Contract(contractAddress, ERC20_ABI, provider)
			const [balance, decimals] = await Promise.all([contract.balanceOf(walletAddress), contract.decimals()])

			const formattedBalance = ethers.utils.formatUnits(balance, decimals)

			tokenBalanceCache[cacheKey] = {
				balance: formattedBalance,
				timestamp: currentTime
			}

			return formattedBalance
		} catch (error) {
			console.log('Error getting token balance:', error)
			return '0.0'
		}
	}, [])

	const getTokenInfo = useCallback(async (contractAddress: string) => {
		try {
			const contract = new ethers.Contract(contractAddress, ERC20_ABI, provider)
			const tokenName = await contract.name()
			const decimal = await contract.decimals()
			const symbol = await contract.symbol()

			const tokenInfo = {
				name: tokenName,
				symbol: symbol,
				decimals: decimal,
			};

			return tokenInfo
		} catch (error) {
			console.log('Error useEthereum getting token info:', error)
			return null
		}
	}, [])

	const estimateGas = useCallback(async () => {
		try {
			const currentTime = Date.now()
			const cachedData = gasPriceCache['gasPrice']

			if (cachedData && currentTime - cachedData.timestamp < gasPriceCacheExpiryTime) {
				console.log('Using cached gas price')
				return cachedData.gasFee
			}

			const gasPrice = await provider.getGasPrice()
			const gasLimit = 21000
			const gasFee = ethers.utils.formatEther(gasPrice.mul(gasLimit))

			gasPriceCache['gasPrice'] = {
				gasFee,
				timestamp: currentTime
			}

			return gasFee
		} catch (error) {
			console.error('Error estimating gas fee:', error)
			return '0.0'
		}
	}, [provider])

	const sendTransactionWithPrivateKey = useCallback(
		async (privateKey: string, toAddress: string, amount: string, tokenSymbol: string, tokenAddress: string = '') => {
			try {
				const wallet = new ethers.Wallet(privateKey, provider)
				let tx
				if (tokenSymbol === process.env.REACT_APP_NETWORK_CURRENCY) {
					tx = {
						to: toAddress,
						value: ethers.utils.parseEther(amount),
						gasLimit: ethers.utils.hexlify(21000)
					}
				} else {
					const contract = new ethers.Contract(tokenAddress, ERC20_ABI, wallet)
					const decimal = await contract.decimals()
					const amountInWei = ethers.utils.parseUnits(amount, decimal)

					tx = await contract.populateTransaction.transfer(toAddress, amountInWei)
				}
				const response = await wallet.sendTransaction(tx)
				console.log(`Transaction hash: ${response.hash}`)
				const receipt = await response.wait()
				console.log(`Transaction was confirmed in block ${receipt.blockNumber}`)
				return receipt
			} catch (error) {
				console.log('Error sending transaction:', error)
				throw error
			}
		},
		[provider]
	)

	const getBlockDetails = useCallback(
		async (blockNumber: string) => {
			try {
				const block: any = await provider.getBlock(blockNumber)
				console.log(block)
				setBlockDetails(block)
			} catch (error) {
				console.log('Error getting block details:', error)
			}
		},
		[provider]
	)

	const getTransactionDetails = useCallback(
		async (txHash: string) => {
			try {
				const transaction: any = await provider.getTransaction(txHash)
				console.log('transaction', transaction)
				setTransactionDetails(transaction)
				return transaction
			} catch (error) {
				console.log('Error getting transaction details:', error)
			}
		},
		[provider]
	)

	const getTransactionHistory = useCallback(
		async (walletAddress: string, contractAddress: string = '') => {
			try {
				const history = []
				const currentBlock = await provider.getBlockNumber()
				for (let i = currentBlock; i >= currentBlock - 10000; i--) {
					const block = await provider.getBlockWithTransactions(i)
					for (const tx of block.transactions) {
						if (
							tx.from.toLowerCase() === walletAddress.toLowerCase() ||
							(tx.to && tx.to.toLowerCase() === walletAddress.toLowerCase())
						) {
							if (contractAddress) {
								if (tx.to && tx.to.toLowerCase() === contractAddress.toLowerCase()) {
									history.push(tx)
								}
							} else {
								history.push(tx)
							}
						}
					}
				}
				return history
			} catch (error) {
				console.log('Error getting transaction history:', error)
				return []
			}
		},
		[provider]
	)

	const getTransactions = async (address: string) => {
		try {
			const response = await axios.get(`${process.env.REACT_APP_NETWORK_SCAN_URL}/api/v2/transactions`, {
				params: {
					filter: 'pending | validated',
					type: 'token_transfer,coin_transfer',
					method: 'transfer',
					address: address
				},
				headers: {
					accept: 'application/json'
				}
			})
			console.log('API Response:', response.data)

			if (response.data.items) {
				return response.data.items.map((tx: any) => {
					let amount = '0'
					let currency = 'UNKNOWN'
					let type = 'unknown'
					let symbol = 'UNKNOWN'

					if (tx.token_transfers && tx.token_transfers.length > 0) {
						const tokenTransfer = tx.token_transfers[0]
						amount = ethers.utils.formatUnits(tokenTransfer.total?.value || '0', tokenTransfer.token?.decimals || 18)
						currency = tokenTransfer.token?.symbol || 'UNKNOWN'
						type = '출금'
						symbol = tokenTransfer.token?.symbol || 'UNKNOWN'
					} else if (tx.raw_input && tx.method === '0xa9059cbb') {
						const iface = new ethers.utils.Interface(['function transfer(address to, uint256 value)'])
						const decodedInput = iface.decodeFunctionData('transfer', tx.raw_input)
						amount = ethers.utils.formatUnits(decodedInput.value, 18)
						currency = 'TOKEN'
						type = '출금'
						symbol = 'TOKEN'
					} else if (tx.value) {
						amount = ethers.utils.formatUnits(tx.value, 18)
						currency = process.env.REACT_APP_NETWORK_CURRENCY || 'QCO'
						type = '출금'
						symbol = process.env.REACT_APP_NETWORK_CURRENCY || 'QCO'
					}

					return {
						id: tx.hash || '',
						type: type,
						amount: amount,
						currency: currency,
						symbol: symbol,
						date: tx.timestamp ? new Date(tx.timestamp).toLocaleString() : '',
						direction: tx.to && tx.to.hash === address ? 'in' : 'out'
					}
				})
			} else {
				return []
			}
		} catch (error) {
			console.log('Error fetching transactions:', error)
			return []
		}
	}

	return {
		balance,
		transactionReceipt,
		blockDetails,
		transactionDetails,
		getBalance,
		getTokenBalance,
		estimateGas,
		sendTransactionWithPrivateKey,
		getBlockDetails,
		getTransactionDetails,
		getTransactionHistory,
		getTransactions,
		getTokenInfo
	}
}

export default useEthereum

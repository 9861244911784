import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../context/ThemeContext';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronLeft, ArrowLeft } from 'lucide-react';
import { RootState } from '../store';
import { useSelector } from 'react-redux';
import CustomText from '../styles/CustomText';

const Header: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	let location = useLocation();
	const { t } = useTranslation();
	let headerText = useSelector((state: RootState) => state.headerText);

	const styles = {
		container: {
			width: "calc(100% - 48px)",
			height: "30px",
			padding: "12px 24px",
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center',
			top: "0",
			left: "0",
			background: theme.background_03,
			position: "fixed",
			zIndex: 100
		} as React.CSSProperties,
		icon: {
			position: "absolute",
			left: "13px",
			top: "50%",
			transform: "translateY(-50%)",
			width: "27px",
			height: "27px",
			cursor: 'pointer'
		} as React.CSSProperties,
		text: {
			color: theme.text_01,
			fontSize: "16px",
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center',
			// paddingBottom: "3px"
		} as React.CSSProperties,
	}

	return (
		<div style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
			{/* <div style={{ ...styles.icon }} onClick={() => navigate(-1)}> */}
				{/* <ChevronLeft size={27} color={theme.text_01} /> */}
				{/* <ArrowLeft size={27} color={theme.text_01} /> */}
			{/* </div> */}
			<CustomText style={{ ...styles.text }}>{headerText?.title ?? "Test"}</CustomText>
		</div>
	)
}

export default Header

import { useState, useEffect } from 'react'
// import AsyncStorage from '@react-native-async-storage/async-storage';

import jsonTokenData from '../data/tokenData.json'
import jsonOtherTokenData from '../data/otherTokenData.json';
import jsonNetworkData from '../data/networkData.json';

interface NetworkInfo {
    name: string;
    symbol: string;
    description: string;
    networkid: string;
    chainid: string;
    currency: string;
    scan: string;
    icon: string;
}

interface TokenInfo {
    name: string;
    symbol: string;
    description: string;
    type: string;
    contract: string;
    decimal: string;
    icon: string;
    networkid: string;
    chainid: string;
    networkname: string;
    useswap: boolean;
}

// Chrome Storage helper functions
const setChromeStorage = (data: { [key: string]: any }): Promise<void> => {
    return new Promise((resolve, reject) => {
        chrome.storage.local.set(data, () => {
            if (chrome.runtime.lastError) {
                reject(new Error(chrome.runtime.lastError.message)); // lastError를 Error 객체로 변환
            } else {
                resolve(); // 데이터가 성공적으로 저장된 경우
            }
        });
    });
};

const getChromeStorage = async (key: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        chrome.storage.local.get(key, (result) => {
            if (chrome.runtime.lastError) {
                reject(new Error(chrome.runtime.lastError.message));
            } else {
                resolve(result[key]);
            }
        });
    });
};

const useDefaultNetworkAndToken = () => {
    const [networkInfo, setNetworkInfo] = useState<NetworkInfo[]>([]);
    const [tokenInfo, setTokenInfo] = useState<TokenInfo[]>([]);
    const [userTokenInfo, setUserTokenInfo] = useState<TokenInfo[]>([]);
    const [otherTokenInfo, setOtherTokenInfo] = useState<TokenInfo[]>([]);

    useEffect(() => {
        const initializeData = async () => {
            // console.log(`useDefaultNetworkAndToken initializeData`)
            const fetchedNetworkData = await getDefaultNetwork();
            const fetchedTokenData = await getDefaultToken();
            const fetchedOtherTokenData = await getDefaultOtherToken();
            const fetchedUserTokenData = await getUserToken();

            if (fetchedNetworkData) {
                // console.log(`initializeData fetchedNetworkData`, fetchedNetworkData)
                const parsedNetworkData = JSON.parse(fetchedNetworkData);
                const networkArray = Object.keys(parsedNetworkData).map(key => parsedNetworkData[key]);
                setNetworkInfo(networkArray);
            }
            if (fetchedTokenData) {
                // console.log(`initializeData fetchedTokenData`)
                const parsedTokenData = JSON.parse(fetchedTokenData);
                const tokenArray = Object.keys(parsedTokenData).map(key => parsedTokenData[key]);
                setTokenInfo(tokenArray);
            }
            if (fetchedUserTokenData) {
                // console.log(`initializeData fetchedUserTokenData`)
                const parsedUserTokenData = JSON.parse(fetchedUserTokenData);
                const userTokenArray = Object.keys(parsedUserTokenData).map(key => parsedUserTokenData[key]);
                setUserTokenInfo(userTokenArray);
            }
            if (fetchedOtherTokenData) {
                // console.log(`initializeData fetchedOtherTokenData`)
                const parsedTokenData = JSON.parse(fetchedOtherTokenData);
                const tokenArray = Object.keys(parsedTokenData).map(key => parsedTokenData[key]);
                setOtherTokenInfo(tokenArray);
            }
        };
        
        // console.log(`useDefaultNetworkAndToken useEffect`)
        initializeData();
    }, []);

    const fetchData = async (fallbackData: any, storageKey: string, forceUpdate:boolean=false): Promise<any> => {
        try {
            // 로컬 스토리지에 저장된 기본 데이터 있는지 확인
            let storedData
            if (process.env.REACT_APP_NODE_ENV === 'development') {
                storedData = localStorage.getItem(storageKey)
            } else {
                storedData = await getChromeStorage(storageKey);
            }
            
            if (!storedData || forceUpdate) {
                // 로컬 스토리지에 저장된 기본 데이터가 없으면 json 데이터를 localstorage 에 저장
                if(null != fallbackData) {
                    if (process.env.REACT_APP_NODE_ENV === 'development') {
                        localStorage.setItem(storageKey, JSON.stringify(fallbackData));
                    } else {
                        await setChromeStorage({[storageKey]: JSON.stringify(fallbackData),});
                    }
                }

                let savedData
                if (process.env.REACT_APP_NODE_ENV === 'development') {
                    savedData = localStorage.getItem(storageKey)
                } else {
                    savedData = await getChromeStorage(storageKey);
                }

                // console.log(`set DefaultData success ${storageKey} | ${savedData}`);
                return savedData;
            } else {
                // 로컬 스토리지에 저장된 데이터 반환
                let savedData
                if (process.env.REACT_APP_NODE_ENV === 'development') {
                    savedData = localStorage.getItem(storageKey)
                } else {
                    savedData = await getChromeStorage(storageKey);
                }
                return savedData;
            }
        } catch (error) {
            console.error(`Fetching ${storageKey} failed:`, error);
            return null;
        }
    };

    const getDefaultNetwork = async (forceUpdate:boolean=false): Promise<any> => {
        const networkDataKey = 'networkData';
        
        const returnData = await fetchData( jsonNetworkData, networkDataKey, forceUpdate);
        return returnData;
    };

    const getDefaultToken = async (forceUpdate:boolean=false): Promise<any> => {
        const tokenDataKey = 'tokenData';
    
        const returnData = await fetchData(jsonTokenData, tokenDataKey, forceUpdate);
        return returnData;
    };

    const getDefaultOtherToken = async (forceUpdate:boolean=false): Promise<any> => {
        const tokenDataKey = 'otherTokenData';
    
        const returnData = await fetchData(jsonOtherTokenData, tokenDataKey, forceUpdate);
        return returnData;
    };

    const getUserToken = async (): Promise<any> => {
        const tokenDataKey = 'userTokenData';

        const returnData = await fetchData(null, tokenDataKey);
        return returnData;
    };


    const searchToken = (symbol: string): TokenInfo | null => {
        if (!tokenInfo && ! userTokenInfo) return null;

        const defaultToken = tokenInfo.find(token => token.symbol.toLowerCase() === symbol.toLowerCase()) || null;
        const userToken = userTokenInfo.find(token => token.symbol.toLowerCase() === symbol.toLowerCase()) || null;

        // return tokenInfo.find(token => token.symbol.toLowerCase() === symbol.toLowerCase()) || null;
        return defaultToken || userToken;
    };

    const searchOtherToken = (symbol: string): TokenInfo | null => {
        if (!otherTokenInfo) return null;
        return otherTokenInfo.find(token => token.symbol.toLowerCase() === symbol.toLowerCase()) || null;
    };

    const searchNetwork = (symbol: string): NetworkInfo | null => {
        if (!networkInfo) return null;
        return networkInfo.find(network => network.symbol.toLowerCase() === symbol.toLowerCase()) || null;
    };

    // 새로운 토큰을 추가하는 메소드
    const addNewToken = async (newToken: TokenInfo): Promise<string> => {
        const tokenDataKey = 'userTokenData';
        const currentTokens = userTokenInfo;
        
        // 중복된 토큰 contract 주소가 있는지 확인
        const tokenExists = currentTokens.some(token => token.contract === newToken.contract);
        
        if (!tokenExists) {
            const updatedTokens = [...currentTokens, newToken];            

            if (process.env.REACT_APP_NODE_ENV === 'development') {
                localStorage.setItem(tokenDataKey, JSON.stringify(updatedTokens));
            } else {
                await setChromeStorage({[tokenDataKey]: JSON.stringify(updatedTokens),});
            }

            setTokenInfo(updatedTokens);

            console.log(`New token added to ${tokenDataKey}`);
            return 'success';
        } else {
            return 'overlap';
        }
        return 'fail';
    };

    // 토큰을 삭제하는 메소드
    const removeToken = async (contractAddress: string): Promise<string> => {
        const tokenDataKey = 'userTokenData';
        const currentTokens = userTokenInfo;

        // 해당 contract 주소를 가진 토큰 찾기
        const updatedTokens = currentTokens.filter(token => token.contract !== contractAddress);

        // 업데이트된 토큰 리스트를 로컬 스토리지에 저장
        if (process.env.REACT_APP_NODE_ENV === 'development') {
            localStorage.setItem(tokenDataKey, JSON.stringify(updatedTokens));
        } else {
            await setChromeStorage({[tokenDataKey]: JSON.stringify(updatedTokens),});
        }

        // 상태 업데이트
        setUserTokenInfo(updatedTokens);

        console.log(`Token with contract address ${contractAddress} removed from ${tokenDataKey}`);
        return 'success';
    };

    // 강제 업데이트
    const forceTokenDataUpdate = async (): Promise<void> => {
        // console.log(`useDefaultNetworkAndToken forceUpdate`)

        const fetchedNetworkData = await getDefaultNetwork(true);
        const fetchedTokenData = await getDefaultToken(true);
        const fetchedOtherTokenData = await getDefaultOtherToken(true);
        const fetchedUserTokenData = await getUserToken();

        if (fetchedNetworkData) {
            // console.log(`forceTokenDataUpdate fetchedNetworkData`, fetchedNetworkData)
            const parsedNetworkData = JSON.parse(fetchedNetworkData);
            const networkArray = Object.keys(parsedNetworkData).map(key => parsedNetworkData[key]);
            setNetworkInfo(networkArray);
        }
        if (fetchedTokenData) {
            // console.log(`forceTokenDataUpdate fetchedTokenData`, fetchedTokenData)
            const parsedTokenData = JSON.parse(fetchedTokenData);
            const tokenArray = Object.keys(parsedTokenData).map(key => parsedTokenData[key]);
            setTokenInfo(tokenArray);
        }
        if (fetchedUserTokenData) {
            // console.log(`forceTokenDataUpdate fetchedUserTokenData`, fetchedUserTokenData)
            const parsedUserTokenData = JSON.parse(fetchedUserTokenData);
            const userTokenArray = Object.keys(parsedUserTokenData).map(key => parsedUserTokenData[key]);
            setUserTokenInfo(userTokenArray);
        }
        if (fetchedOtherTokenData) {
            // console.log(`forceTokenDataUpdate fetchedOtherTokenData`, fetchedOtherTokenData)
            const parsedTokenData = JSON.parse(fetchedOtherTokenData);
            const tokenArray = Object.keys(parsedTokenData).map(key => parsedTokenData[key]);
            setOtherTokenInfo(tokenArray);
        }
    };

    return {
        networkInfo,
        tokenInfo,
        userTokenInfo,
        otherTokenInfo,
        searchToken,
        searchOtherToken,
        searchNetwork,
        addNewToken,
        removeToken,
        forceTokenDataUpdate
    };

}

export default useDefaultNetworkAndToken;

import { useState, useCallback, useEffect } from 'react'
import { ethers } from 'ethers'

const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL)

const SWAP_ABI = [
	'function swapCoinToToken() external payable',
	'function swapTokenToCoin(uint256 tokenAmount) external',
	'function coinToTokenRate() view returns (uint256)',
	'function tokenToCoinRate() view returns (uint256)',
	'function getEthBalance() view returns (uint256)',
	'function getTokenBalance() view returns (uint256)'
]

const ERC20_ABI = [
	'function approve(address spender, uint256 amount) public returns (bool)',
	'function allowance(address owner, address spender) public view returns (uint256)',
	'function decimals() view returns (uint8)',
]

const useTokenSwap = (swapContractAddress: string, tokenAddress: string) => {
	const [loading, setLoading] = useState(false)
	const [transactionHash, setTransactionHash] = useState<string | null>(null)
	const [error, setError] = useState<string | null>(null)
	const [contract, setContract] = useState<ethers.Contract | null>(null)
	const [tokenContract, setTokenContract] = useState<ethers.Contract | null>(null)
	const [coinToTokenRate, setCoinToTokenRate] = useState<number | null>(null)
	const [tokenToCoinRate, setTokenToCoinRate] = useState<number | null>(null)
	const [isApprovalNeeded, setIsApprovalNeeded] = useState<boolean>(false)
	const [pendingSwap, setPendingSwap] = useState<{ amount: string; signer: ethers.Signer } | null>(null)
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		if (swapContractAddress) {
			const newContract = new ethers.Contract(swapContractAddress, SWAP_ABI, provider)
			setContract(newContract)
			fetchRates(newContract)
		}
		if (tokenAddress) {
			const newTokenContract = new ethers.Contract(tokenAddress, ERC20_ABI, provider)
			setTokenContract(newTokenContract)
		}
	}, [swapContractAddress, tokenAddress])

	const fetchRates = async (contract: ethers.Contract) => {
		try {
			const coinToTokenRate = await contract.coinToTokenRate()
			const tokenToCoinRate = await contract.tokenToCoinRate()
			setCoinToTokenRate(coinToTokenRate.toNumber())
			setTokenToCoinRate(tokenToCoinRate.toNumber())
		} catch (error) {
			console.log('Error fetching rates:', error)
		}
	}

	const checkAllowance = async (amountInTokens: string, owner: string) => {
		if (!tokenContract) return false
		const allowance = await tokenContract.allowance(owner, swapContractAddress)
		const decimal = await tokenContract.decimals();
		const amountInWei = ethers.utils.parseUnits(amountInTokens, decimal)
		return allowance.gte(amountInWei)
	}

	const approveTokens = useCallback(
		async (amountInTokens: string, signer: ethers.Signer) => {
			if (!tokenContract) return

			try {
				const decimal = await tokenContract.decimals();
				const amountInWei = ethers.utils.parseUnits(amountInTokens, decimal)
				const tx = await tokenContract.connect(signer).approve(swapContractAddress, amountInWei)
				await tx.wait()
				setIsApprovalNeeded(false)
				if (tx) {
					return true
				}
			} catch (error: any) {
				console.log('Error approving tokens:', error)
				setError(error.message)
			}
		},
		[tokenContract, swapContractAddress, pendingSwap]
	)

	const swapCoinToToken = useCallback(
		async (amountInEth: string, signer: ethers.Signer) => {
			if (!contract) return

			setLoading(true)
			setError(null)
			try {
				const value = ethers.utils.parseEther(amountInEth)
				const gasPrice = await provider.getGasPrice()

				const gasLimit = ethers.BigNumber.from('100000')
				const balance = await signer.getBalance()
				const totalCost = value.add(gasPrice.mul(gasLimit))

				if (balance.lt(totalCost)) {
					throw new Error('Insufficient funds for gas * price + value')
				}

				const tx = await contract.connect(signer).swapCoinToToken({
					value,
					gasPrice,
					gasLimit
				})
				const receipt = await tx.wait()
				console.log('Transaction receipt', receipt)

				return receipt
			} catch (error: any) {
				console.log('Error swapping coin to token:', error)
				if (error.receipt) {
					console.log('Transaction receipt', error.receipt)
				}
				setError(error.message)
			} finally {
				setLoading(false)
			}
		},
		[contract]
	)

	const swapTokenToCoin = useCallback(
		async (amountInTokens: string, signer: ethers.Signer) => {
			if (!contract) return
			if (!tokenContract) return

			setLoading(true)
			setError(null)
			try {
				const owner = await signer.getAddress()
				const isAllowed = await checkAllowance(amountInTokens, owner)

				if (!isAllowed) {
					setIsApprovalNeeded(true)
					setPendingSwap({ amount: amountInTokens, signer })
					setLoading(false)
					return
				}
				const decimal = await tokenContract.decimals();
				const amountInWei = ethers.utils.parseUnits(amountInTokens, decimal)
				const tx = await contract.connect(signer).swapTokenToCoin(amountInWei)
				const receipt = await tx.wait()
				console.log('Transaction receipt', receipt)

				return receipt
			} catch (error: any) {
				console.log('Error swapping token to coin:', error)
				setError(error.message)
			} finally {
				setLoading(false)
			}
		},
		[contract, checkAllowance]
	)

	const fetchContractData = async () => {
		if (!contract) return
		try {
			const [ethBalance, tokenBalance, coinToTokenRate, tokenToCoinRate] = await Promise.all([
				contract.getEthBalance(),
				contract.getTokenBalance(),
				contract.coinToTokenRate(),
				contract.tokenToCoinRate()
			])

			return {
				ethBalance,
				tokenBalance,
				coinToTokenRate,
				tokenToCoinRate
			}
		} catch (error) {
			console.log('Error fetching contract data:', error)
		}
	}

	return {
		swapCoinToToken,
		swapTokenToCoin,
		approveTokens,
		isApprovalNeeded,
		loading,
		transactionHash,
		error,
		coinToTokenRate,
		tokenToCoinRate,
		setIsApprovalNeeded,
		isLoading,
		setIsLoading,
		checkAllowance,
		fetchContractData
	}
}

export default useTokenSwap

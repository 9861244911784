import React, { useContext, useEffect, useState, useCallback } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import { useLocation } from 'react-router-dom';
import { Check } from 'lucide-react';

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
import Container from '../Common/Container';
import CourierText from '../../styles/CourierText';
import CustomListCardWithIcon from '../Componentization/Card/CustomListCardWithIcon';
import CustomListCard from '../Componentization/Card/CustomListCard';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkHeaderText, checkPincode, RootState } from '../../store';

import useWallet from '../../hooks/useWallet';
import useEthereum from '../../hooks/useEthereum';
import useEthereumOther from '../../hooks/useEthereumOther';
import useNFTs from '../../hooks/useNfts';
import useSecureStorage from '../../hooks/useSecureStorage';
import MainPageHeader from '../../nav/MainPageHeader';
import NavigationBar from '../../nav/NavigationBar';

import { ethers } from 'ethers'

import i18next from 'i18next'

import PinCodeScreen from '../Authentication/PinCodeScreen';
import Spinner from '../Componentization/Modal/Spinner';

const REWARD_STORAGE_KEY = 'NFT_DATA'
const OTHER_STORAGE_KEY = 'OTHER_DATA'
const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL)

const NFTDetailsScreen: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const { t } = useTranslation();

	const { estimateGas } = useEthereum()
	const { getWalletAddress, retrieveEncryptedData, setNfts, getNfts } = useSecureStorage()

	const [walletAddress, setWalletAddress] = useState('')
	const [recipientAddress, setRecipientAddress] = useState('')
	const [loading, setLoading] = useState(false)
	const [modalVisible, setModalVisible] = useState(false)
	const [showPinCodeAuth, setShowPinCodeAuth] = useState(false)

	// const { nft } = route.params as any

	const location = useLocation();

	useEffect(() => {
		dispatch(checkHeaderText({ "link": "NFTDetailsScreen", "title": t('stack_header_message_16') }))
	}, [])

	const [nft, setNft] = useState(location.state === null ? null : location.state.nft);
	useEffect(() => {
		if (location.state) {
			setNft(location.state.nft);
			console.log(`NFT 상세 페이지 nft 정보`, nft)
		}
	}, [location.state]);

	useEffect(() => {
		const fetchAddress = async () => {
			const address: any = await getWalletAddress()
			if (address) {
				setWalletAddress(address)
			}
		}

		fetchAddress()
	}, [])

	const isValidAddress = (address: string) => {
		return ethers.utils.isAddress(address)
	}

	const isContractAddress = async (address: string) => {
		const code = await provider.getCode(address)
		return code !== '0x'
	}

	const verificationBeforeTransfer = async () => {
		if (!isValidAddress(recipientAddress)) {
			alert(i18next.t('nft_screen_message_07'))
			return
		}

		if (await isContractAddress(recipientAddress)) {
			alert(i18next.t('nft_screen_message_08'))
			return
		}

		setShowPinCodeAuth(true)
	}

	const handleTransfer = async (pin: string) => {
		setShowPinCodeAuth(false)

		if (!isValidAddress(recipientAddress)) {
			alert(i18next.t('nft_screen_message_07'))
			return
		}

		if (await isContractAddress(recipientAddress)) {
			alert(i18next.t('nft_screen_message_08'))
			return
		}

		setModalVisible(true)

		try {
			const data = await retrieveEncryptedData(walletAddress, pin)
			const privateKey = data.privateKey

			if (!privateKey) {
				alert(i18next.t('template_withdraw_screen_message_22'))
				return
			}

			const wallet = new ethers.Wallet(privateKey, provider)
			const contract = new ethers.Contract(
				nft.contractAddress,
				['function transferFrom(address from, address to, uint256 tokenId)'],
				wallet
			)

			let currentData: any[] = []
			let storageKey = ''

			if (nft.contractAddress === process.env.REACT_APP_REWARD_NFT_CONTRACT_ADDRESS) {
				storageKey = REWARD_STORAGE_KEY
			} else {
				storageKey = OTHER_STORAGE_KEY
			}

			currentData = JSON.parse((await getNfts(storageKey)) || '[]')

			if (storageKey === OTHER_STORAGE_KEY) {
				const newData = currentData.filter((item: any) => item.tokenId.toString() !== nft.tokenId.toString())
				await setNfts(storageKey, JSON.stringify(newData))
			}

			const tx = await contract.transferFrom(wallet.address, recipientAddress, nft.tokenId)
			await tx.wait()
			const temp = await estimateGas()
			const gasFee = parseFloat(temp).toFixed(6);
			// const gasFee = await estimateGas()

			// alert(i18next.t('nft_screen_message_11'))

			const serializedTx = {
				from: tx.from,
				to: tx.to,
				hash: tx.hash,
				// gasLimit: tx.gasLimit.toString(),
				gasLimit: gasFee.toString(),
				value: tx.value._hex
			}

			setTimeout(() => {
				navigate('/NFTTransferCompleteScreen', { state: { tx: serializedTx } })
			}, 1000)
		} catch (error) {
			alert(i18next.t('nft_screen_message_12'))
		} finally {
			setModalVisible(false)
		}
	}

	const handleOpenExplorer = () => {
		const url = `${process.env.REACT_APP_NETWORK_SCAN_URL}/token/${nft.contractAddress}/instance/${nft.tokenId}`
		window.open(url, '_blank');
	}

	const styles = {
		container: {
			backgroundColor: theme.background_03,
			padding: "60px 24px 0",
		} as React.CSSProperties,
		imageContainer: {
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center',
			marginBottom: 20,
			borderRadius: 10,
			width: '100%',
			height: 300,
			overflow: 'hidden'
		} as React.CSSProperties,
		nftImage: {
			width: '100%',
			height: '100%',
			borderRadius: 10
		} as React.CSSProperties,
		detailsContainer: {
			borderRadius: 10,
			backgroundColor: theme.background_03,
			marginBottom: 20,
			padding: 20
		} as React.CSSProperties,
		nftTitle: {
			fontSize: 20,
			fontWeight: 'bold',
			marginBottom: 20,
			textAlign: 'center'
		} as React.CSSProperties,
		nftTitleEdition: {
			fontSize: 12,
			color: theme.text_03
		} as React.CSSProperties,
		nftEdition: {
			minHeight: "20px",
			fontSize: 15
		} as React.CSSProperties,
		metadataContainer: {
			marginBottom: 10
		} as React.CSSProperties,
		input: {
			display: "flex",
			borderRadius: 10,
			padding: "15px 10px",
			justifyContent: 'center',
			alignItems: 'center',
			marginRight: 10,
			width: "60%",
			border: `1px solid ${theme.border_04}`,
			background: "inherit"
		} as React.CSSProperties,
		buttonContainer: {
			display: "flex",
			alignItems: "center",
			justifyContent: 'space-between',
			gap: 10
		} as React.CSSProperties,
		button: {
			padding: 15,
			borderRadius: 10,
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center',
			marginBottom: 20,
			width: '100%',
			border: `1px solid ${theme.border_04}`,
		} as React.CSSProperties,
		transferContainer: {
			display: "flex",
			marginBottom: 10
		} as React.CSSProperties,
		transferButton: {
			display: "flex",
			borderRadius: 10,
			alignItems: 'center',
			justifyContent: 'center',
			width: "40%",
			border: `1px solid ${theme.border_04}`,
			background: "inherit",
			cursor: "pointer"
		} as React.CSSProperties,
		buttonText: {
			fontSize: 16
		} as React.CSSProperties,
		modalContainer: {
			display: "flex",
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: theme.modal_background_01
		} as React.CSSProperties,
		modalContent: {
			backgroundColor: theme.modal_background_02,
			padding: 15,
			borderRadius: 10,
			display: "flex",
			alignItems: 'center',
			width: '80%'
		} as React.CSSProperties,
		modalTitle: {
			fontSize: 18,
			marginBottom: 10
		},
		modalText: {
			fontSize: 14,
			color: theme.text_02,
			marginBottom: 20,
			textAlign: 'center'
		},
		modalBackground: {
			display: "flex",
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: theme.modal_background_01,
			position: "absolute",
			width: "100%",
			height: "100%",
			top: "0",
			left: "0"
		} as React.CSSProperties,
	}
	
	return (
		<div className='container' style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
			{
				!showPinCodeAuth
					?
					<>
						<div>
							<CustomText style={{ ...styles.nftTitle, color: theme.detailCardTextColor }}>
								{nft.name}
							</CustomText>
							<div style={{ ...styles.imageContainer, backgroundColor: theme.nftImageBackgroundColor }}>
								<img
									src={nft.image}
									alt="NFT"
									style={styles.nftImage}
								/>
							</div>
							<div style={{ ...styles.detailsContainer, backgroundColor: theme.detailCardBackgroundColor }}>
								<div style={styles.metadataContainer}>
									<CustomText style={styles.nftTitleEdition}>
										{i18next.t('nft_screen_message_16')}
									</CustomText>
									<CustomText style={{ ...styles.nftEdition, color: theme.detailCardTextColor }}>
										{nft.description}
									</CustomText>
								</div>
								<div style={styles.metadataContainer}>
									<CustomText style={styles.nftTitleEdition}>
										{i18next.t('nft_screen_message_17')}
									</CustomText>
									<CustomText style={{ ...styles.nftEdition, color: theme.detailCardTextColor }}>
										{nft.type}
									</CustomText>
								</div>
								<div>
									<CustomText style={styles.nftTitleEdition}>
										{i18next.t('nft_screen_message_18')}
									</CustomText>
									<CustomText style={{ ...styles.nftEdition, color: theme.detailCardTextColor }}>
										{`${nft.contractAddress.slice(0, 6)}...${nft.contractAddress.slice(-4)}`}
									</CustomText>
								</div>
							</div>
							<div style={styles.transferContainer}>
								<input
									type="text"
									placeholder={i18next.t('nft_screen_message_19')}
									style={{ ...styles.input, color: theme.primaryText, borderColor: theme.customTabBarBorderColor }}
									value={recipientAddress}
									onChange={(e) => setRecipientAddress(e.target.value)}
								/>
								<div
									style={{
										...styles.transferButton,
										backgroundColor: theme.primaryBackgroundColor,
										borderColor: theme.customTabBarBorderColor,
									}}
									onClick={() => (verificationBeforeTransfer())}
								// disabled={loading}
								>
									<CustomText style={{ ...styles.buttonText, color: theme.detailButtonTextColor }}>
										{i18next.t('nft_screen_message_20')}
									</CustomText>
								</div>
							</div>
							<div style={styles.buttonContainer}>
								<div
									style={{
										...styles.button,
										backgroundColor: theme.primaryBackgroundColor,
										borderColor: theme.customTabBarBorderColor,
									}}
									onClick={() => (handleOpenExplorer())}
								// disabled={loading}
								>
									<CustomText style={{ ...styles.buttonText, color: theme.primary }}>
										{i18next.t('nft_screen_message_21')}
									</CustomText>
								</div>
							</div>
						</div>
						{modalVisible && (
							<div style={styles.modalBackground}>
								<Spinner />
							</div>
						)}

						{/* <Modal transparent={true} animationType='fade' visible={modalVisible}>
					<View style={styles.modalContainer}>
						<View style={styles.modalContent}>
							<CustomText style={[styles.modalTitle, { color: theme.darkModeModalTextColor }]}>
								{i18next.t('nft_screen_message_22')}
							</CustomText>
							<CustomText style={[styles.modalText, { color: theme.darkModeModalTextColor }]}>
								{i18next.t('nft_screen_message_23')}
							</CustomText>
							<ActivityIndicator size='large' color={theme.primary} />
						</View>
					</View>
				</Modal> */}
					</>
					:
					<div style={styles.modalBackground}>
						<PinCodeScreen onSuccess={handleTransfer} unlock={false} />
					</div>
			}
		</div >
	)
}

export default NFTDetailsScreen

// import { randomBytes } from 'react-native-randombytes'
import { Buffer } from 'buffer';
import * as bip39 from 'bip39'
import '@ethersproject/shims'
import * as ethers from 'ethers'

if (typeof window !== 'undefined') {
    window.Buffer = Buffer;  // 브라우저에서 Buffer를 전역 객체로 설정
}

const generateWallet = () => {
    const startTime = Date.now()

    const entropyArray = new Uint8Array(16)
    crypto.getRandomValues(entropyArray)
    const entropy = Buffer.from(entropyArray).toString('hex')

    const entropyTime = Date.now()
    const entropyTake = entropyTime - startTime
    console.log(`entropyTake : ${entropyTake} ms`)

    const mnemonic = bip39.entropyToMnemonic(entropy)
    const mnemonicTime = Date.now()
    const mnemonicTake = mnemonicTime - entropyTime
    console.log(`mnemonicTake : ${mnemonicTake} ms`)

    const hdNode = ethers.utils.HDNode.fromMnemonic(mnemonic)
    const hdNodeTime = Date.now()
    const hdNodeTake = hdNodeTime - mnemonicTime
    console.log(`hdNodeTake : ${hdNodeTake} ms`)

    const wallet = hdNode.derivePath(`m/44'/60'/0'/0/0`)
    const walletTime = Date.now()
    const walletTake = walletTime - hdNodeTime
    console.log(`walletTake : ${walletTake} ms`)

    return {
        privateKey: wallet.privateKey,
        publicKey: wallet.publicKey,
        address: wallet.address,
        mnemonic: mnemonic
    }
}

const recoveryWallet = (secretPhrase:string) => {
	const trimmedSecretPhrase = secretPhrase.trim()

	if (false == bip39.validateMnemonic(trimmedSecretPhrase)) {
		console.log(`bip39.validateMnemonic 실패`)
		return {
			privateKey: null,
			publicKey: null,
			address: null
		}
	}

	const arrayMnemonic = trimmedSecretPhrase.split(/\s+/)
	const storedMnemonic = JSON.stringify(arrayMnemonic)

	const mnemonicArray = JSON.parse(storedMnemonic)
	const mnemonic = Array.isArray(mnemonicArray) ? mnemonicArray.join(' ') : mnemonicArray

	if (mnemonic.split(' ').length % 3 === 0) {
		const wallet = generateWalletFromMnemonic(mnemonic)
		return {
			privateKey: wallet.privateKey,
			publicKey: wallet.publicKey,
			address: wallet.address
		}
	}

	return {
		privateKey: null,
		publicKey: null,
		address: null
	}
}

const generateWalletFromMnemonic = (mnemonic: string) => {
	const startTime = Date.now()
	const hdNode = ethers.utils.HDNode.fromMnemonic(mnemonic)
	const hdNodeTime = Date.now()
	const hdNodeTake = hdNodeTime - startTime
	// console.log(`hdNodeTake : ${hdNodeTake} ms`)
	// console.log(`hdNode Create`)

	const wallet = hdNode.derivePath(`m/44'/60'/0'/0/0`)
	const walletTime = Date.now()
	const walletTake = walletTime - hdNodeTime
	// console.log(`walletTake : ${walletTake} ms`)
	// console.log(`derivePath wallet Created : `, wallet.address)
	// console.log(`derivePath wallet PrivateKey : `, wallet.privateKey)

	return {
		privateKey: wallet.privateKey,
		publicKey: wallet.publicKey,
		address: wallet.address,
		mnemonic: mnemonic
	}
}

const useWallet = () => {
    const makeWallet = () => {
        return generateWallet(); // 지갑을 다시 생성
    }

	const restoreWallet = (mnemonic:string) => {
		return recoveryWallet(mnemonic); // 지갑을 복구
	}

    return {
        makeWallet,  // 다시 생성할 수 있는 메서드 제공
		restoreWallet
    }
}

export default useWallet

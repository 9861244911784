import { useState, useEffect } from 'react'

// 이벤트 배너 정보
interface EventInfo {
    idx: number;
    title_en: string;
    title_ko: string;
    contents_en: string;
    contents_ko: string;
    banner_image_ko_url: string;
    banner_image_en_url: string;
    ext_url: string;
    app_screen: string;
    donation_idx: string;
    is_active: string;
}

// 후원하기 페이지 UI 구성
interface DonationInfo {
    idx: number;
    code: string;
    title_en: string;
    title_ko: string;
    image_en_url: string;
    image_ko_url: string;
    ext_url: string;
    text_en: string;
    text_ko: string;
    check_en: string;
    check_ko: string;
    complete_image_url: string;
    complete_title_en: string;
    complete_title_ko: string;
    complete_contents_en: string;
    complete_contents_ko: string;
    is_active: string;
}

const useWalletSetup = () => {
    const [eventInfo, setEventInfo] = useState<EventInfo[]>([]);
    const [donationInfo, setDonationInfo] = useState<DonationInfo[]>([]);

    useEffect(() => {
        const initializeData = async () => {

            const fetchEvents = await getDefaultEvents()
            if (fetchEvents) {
                const parsedEventData = fetchEvents;
                const eventsArray = Object.keys(parsedEventData).map(key => parsedEventData[key]);
                setEventInfo(eventsArray);
            }

            const fetchDonation = await getDefaultDonation()
            if (fetchDonation) {
                const parsedDonationData = fetchDonation;
                const donationArray = Object.keys(parsedDonationData).map(key => parsedDonationData[key]);
                setDonationInfo(donationArray);
            }
        };
        
        initializeData();
    }, []);

    const fetchData = async (url: string, storageKey: string): Promise<any> => {
        try {
            const response = await fetch(url);
            let data
            if (response.ok) {
                let temp = await response.json();
                if(true == temp.result) {
                    // 데이터 검색 성공
                    data = JSON.stringify(temp.data)
                } else {
                    console.log(`Fetching ${storageKey} is null`);
                    data = null;
                }
                return data;
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.log(`Fetching ${storageKey} failed:`, error);
        }

        return null;
    };

    // Chrome Storage helper functions
    const setChromeStorage = (data: { [key: string]: any }): Promise<void> => {
        return new Promise((resolve, reject) => {
            chrome.storage.local.set(data, () => {
                if (chrome.runtime.lastError) {
                    reject(new Error(chrome.runtime.lastError.message)); // lastError를 Error 객체로 변환
                } else {
                    resolve(); // 데이터가 성공적으로 저장된 경우
                }
            });
        });
    };

    const getChromeStorage = async (keys: string[]) => {
        return new Promise<any>((resolve, reject) => {
            chrome.storage.local.get(keys, (result) => {
                if (chrome.runtime.lastError) {
                    return reject(chrome.runtime.lastError);
                }
                resolve(result);
            });
        });
    };

    const saveData = async (key:string, data:any): Promise<any> => {
        if (process.env.REACT_APP_NODE_ENV === 'development') {
            // localStorage.setItem(key, JSON.stringify(data));
            localStorage.setItem(key, data);
        } else {
            // await setChromeStorage({key: JSON.stringify(data),});
            await setChromeStorage({[key]: data,});
        }
    }
    
    const loadData = async (key:string): Promise<any> => {
        let loadData
        if (process.env.REACT_APP_NODE_ENV === 'development') {
            loadData = localStorage.getItem(key)
        } else {
            // console.log(`useWalletSetup ChromeStorage loadData key : ${key}`)
            loadData = await getChromeStorage([key]);
        }
        return loadData;
    }

    const getDefaultEvents = async (forceUpdate:boolean=false): Promise<any> => {
        const eventDataKey = 'eventData';
          // 로컬에 저장된 이벤트 데이터 있는지 확인
            let returnData = await loadData(eventDataKey)
            if(!returnData || true == forceUpdate) {
                
                // 서버에 이벤트 설정 데이터 요청
                if(null != process.env.REACT_APP_WALLET_SETUP_EVENTS_URL && '' !== process.env.REACT_APP_WALLET_SETUP_EVENTS_URL) {
                    returnData = await fetchData(process.env.REACT_APP_WALLET_SETUP_EVENTS_URL, eventDataKey );
                } else {
                    // 설정된 URL 없을 경우 이벤트리스트 데이터 초기화
                    returnData = ''
                }

                // 로컬 스토리지에 이벤트 데이터 저장
                await saveData(eventDataKey, returnData || '')
            }
            return returnData;
    };

    const getDefaultDonation = async (forceUpdate:boolean=false): Promise<any> => {
        const donationDataKey = 'donationData';

        // 로컬에 저장된 후원페이지 데이터 있는지 확인
        let returnData = await loadData(donationDataKey)

        if(!returnData || true == forceUpdate) {
            // 서버에 후원페이지 설정 데이터 요청
            if(null != process.env.REACT_APP_WALLET_SETUP_DONATION_URL && '' !== process.env.REACT_APP_WALLET_SETUP_DONATION_URL) {
                returnData = await fetchData(process.env.REACT_APP_WALLET_SETUP_DONATION_URL, donationDataKey );
            } else {
                // 설정된 URL 없을 경우 후원리스트 데이터 초기화
                returnData = ''
            }

            // 로컬 스토리지에 후원페이지 구성 데이터 저장
            await saveData(donationDataKey, returnData || '')
        }

        return returnData;
    };

    const searchEvent = (idx: number): EventInfo | null => {
        if (!eventInfo) return null;
        return eventInfo.find(event => event.idx === idx) || null;
    };

    const searchDonation = (code: string): DonationInfo | null => {
        if (!donationInfo) return null;
        return donationInfo.find(event => event.code.toLowerCase() === code.toLowerCase()) || null;
    };

    // 강제 업데이트
    const forceEventUpdate = async (): Promise<void> => {
        const fetchEvents = await getDefaultEvents(true)
        if (fetchEvents) {
            const parsedEventData = JSON.parse(fetchEvents);
            const eventsArray = Object.keys(parsedEventData).map(key => parsedEventData[key]);
            setEventInfo(eventsArray);
        }

        const fetchDonation = await getDefaultDonation(true)
        if (fetchDonation) {
            const parsedDonationData = JSON.parse(fetchDonation);
            const donationArray = Object.keys(parsedDonationData).map(key => parsedDonationData[key]);
            setDonationInfo(donationArray);
        }
    };

    return {
        eventInfo,
        donationInfo,
        searchEvent,
        searchDonation,
        forceEventUpdate
    };

}

export default useWalletSetup;

import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import { CircleX } from 'lucide-react';

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
import Container from '../Common/Container';
import useWallet from '../../hooks/useWallet';
import CustomTextInput from '../Componentization/CustomTextInput';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkMnemonic, RootState } from '../../store';

import i18next from 'i18next'
import * as bip39 from 'bip39'

const WalletRecovery: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	const { t } = useTranslation();
	let dispatch = useDispatch();

	const [secretPhrase, setSecretPhrase] = useState<string>('')
	const [error, setError] = useState<boolean>(false)

	const handleRecovery = async () => {
		const trimmedSecretPhrase = secretPhrase.trim()
		const storeMnemonic = trimmedSecretPhrase.split(/\s+/)

		if (bip39.validateMnemonic(trimmedSecretPhrase)) {
			setError(false)
			// redux 에 비밀복구코드 배열 임시 저장
			const jsonMnemonic = JSON.stringify(storeMnemonic)
			dispatch(checkMnemonic(jsonMnemonic))

			navigate('/WalletRecoveryProtection')
		} else {
			setError(true)
			// Toast.show({
			// 	type: 'error',
			// 	position: 'bottom',
			// 	text1: i18next.t('wallet_recovery_message_09'),
			// 	text2: i18next.t('wallet_recovery_message_10'),
			// 	visibilityTime: 500
			// })
		}
	}

	const clearInput = () => {
		setSecretPhrase('')
		setError(false)
	}

	const styles = {
		header: {
			width: "100%",
			marginBottom: 20
		} as React.CSSProperties,
		title: {
			fontSize: 24,
			color: theme.text_05,
			textAlign: 'left',
			marginBottom: 10
		} as React.CSSProperties,
		subtitle1: {
			fontSize: 18,
			color: theme.text_05,
			textAlign: 'left'
		} as React.CSSProperties,
		subtitle2: {
			fontSize: 18,
			color: theme.text_05,
			textAlign: 'left'
		} as React.CSSProperties,
		subtitle3: {
			fontSize: 18,
			color: theme.text_05,
			textAlign: 'left',
			marginBottom: 5
		} as React.CSSProperties,
		warning1: {
			fontSize: 14,
			color: theme.text_03,
			textAlign: 'left',
			marginTop: 10
		} as React.CSSProperties,
		warning2: {
			fontSize: 14,
			color: theme.text_03,
			textAlign: 'left',
			marginBottom: 20
		} as React.CSSProperties,
		body: {
			width: "100%",
		} as React.CSSProperties,
		inputContainer: {
			border: `1px solid ${theme.border_06}`,
			borderRadius: 8,
			margin: "20px 0",
			display: 'flex',
			alignItems: 'center'
		} as React.CSSProperties,
		input: {
			fontSize: 16,
			color: theme.text_05,
			height: 150,
			textAlignVertical: 'top'
		} as React.CSSProperties,
		inputError: {
			borderColor: theme.border_01
		} as React.CSSProperties,
		footer: {
			width: "100%",
			justifyContent: 'flex-end',
		} as React.CSSProperties,
		clearButton: {
			padding: 5
		} as React.CSSProperties,
		button: {
			backgroundColor: theme.button_02,
			borderRadius: 10,
			alignItems: 'center',
			width: '100%',
			height: 54,
			justifyContent: 'center'
		} as React.CSSProperties,
		buttonText: {
			color: theme.text_01,
			fontSize: 16
		} as React.CSSProperties
	}
	
	return (
		<div className='container' style={{ backgroundColor: theme.primaryBackgroundColor }}>
			<div style={styles.header}>
				<CustomText weight="medium" style={{ ...styles.title, color: theme.primaryText }}>
					{t('wallet_recovery_message_01')}
				</CustomText>
				<CustomText style={{ ...styles.subtitle1, color: theme.primaryText }}>
					{t('wallet_recovery_message_02')}
				</CustomText>
				<CustomText style={{ ...styles.subtitle2, color: theme.primaryText }}>
					{t('wallet_recovery_message_03')}
				</CustomText>
				<CustomText style={{ ...styles.subtitle3, color: theme.primaryText }}>
					{t('wallet_recovery_message_04')}
				</CustomText>
				<CustomText style={{ ...styles.warning1, color: theme.secondaryText }}>
					{t('wallet_recovery_message_05')}
				</CustomText>
				<CustomText style={{ ...styles.warning2, color: theme.secondaryText }}>
					{t('wallet_recovery_message_06')}
				</CustomText>

				<div style={styles.body}>
					<div style={{ ...styles.inputContainer, ...(error && styles.inputError) }}>
						<CustomTextInput
							value={secretPhrase}
							onChangeText={setSecretPhrase}
							placeholder={t('wallet_recovery_message_07')}
							containerStyle={{ ...styles.input, color: theme.primaryText }}
							invalidStyle={styles.inputError}
							isValid={!error}
							useMultiLine={true}
						/>
						{secretPhrase.length > 0 && (
							<div onClick={() => clearInput()} style={styles.clearButton}>
								<CircleX size={20} color={theme.text_03} />
							</div>
						)}
					</div>
				</div>
			</div>

			<div style={styles.footer}>
				<CustomButton
					containerStyle={{ ...styles.button, backgroundColor: theme.primary }}
					textStyle={styles.buttonText}
					onPress={handleRecovery}
					text={t('wallet_recovery_message_08')}
					withBackground={true}
					backgroundColor={theme.primaryTouchBackgroundColor}
				/>
			</div>
		</div>
	)
}

export default WalletRecovery

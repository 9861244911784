import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeContext } from '../../context/ThemeContext';
import { Check, CircleAlert } from 'lucide-react';
import { ethers } from 'ethers'

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
import Container from '../Common/Container';
import CourierText from '../../styles/CourierText';
import CustomListCardWithIcon from '../Componentization/Card/CustomListCardWithIcon';
import CustomListCard from '../Componentization/Card/CustomListCard';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkHeaderText, checkPincode, RootState } from '../../store';

import useWallet from '../../hooks/useWallet';
import useWalletSetup from '../../hooks/useWalletSetup';
import useEthereum from '../../hooks/useEthereum';
import useEthereumOther from '../../hooks/useEthereumOther';
import useSecureStorage from '../../hooks/useSecureStorage';
import useDefaultNetworkAndToken from '../../hooks/useDefaultNetworkAndToken';
import useSmartTokenSwap from '../../hooks/useSmartTokenSwap';

import MainPageHeader from '../../nav/MainPageHeader';
import NavigationBar from '../../nav/NavigationBar';
import i18next from 'i18next'

import PinCodeScreen from '../Authentication/PinCodeScreen';
import Spinner from '../Componentization/Modal/Spinner';
import MontserratText from '../../styles/MontserratText';

type TransactionData = {
	transactionHash: string
	amount: string
	isDonationed: boolean
	donationUI: any
}

type DonationUIData = {
	imageUrl: string
	completeImageUrl: string
	completeTitle: string
	completeContents: string
}

const DonationCompleteScreen: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const { t } = useTranslation();

	const location = useLocation();
	const [transactionData, setTransactionData] = useState(location.state === null ? null : location.state.transactionData);
	const [transactionHash, setTransactionHash] = useState('')
	const [amount, setAmount] = useState('')
	const [donationUI, setDonationUI] = useState<any>(
		{
			imageUrl: "",
			completeImageUrl: "",
			completeTitle: "",
			completeContents: "",
		}
	);
	const [isDonationed, setIsDonationed] = useState('')
	const [approveAmount, setApproveAmount] = useState('')

	useEffect(()=>{
		dispatch(checkHeaderText({ "link": "DonationCompleteScreen", "title": "" }))
	},[])

	useEffect(() => {
		if (location.state) {
			const txData = location.state.transactionData
			console.log(`후원하기 완료 페이지 전달 데이터`, location.state.transactionData)
			setTransactionData(txData);
			setTransactionHash(txData.transactionHash)
			setAmount(txData.amount)
			// console.log(`후원하기 완료 페이지 donationUI`, txData.donationUI)
			setDonationUI(txData.donationUI)
		}
	}, [location.state]);

	const handleComplete = () => {
		navigate('/BalanceScreen')
	}

	const handleOpenExplorer = () => {
		const url = `${process.env.REACT_APP_ETHERSCAN_URL}/tx/${transactionHash}`
		window.open(url, '_blank');
	}

	const handleCopyHash = () => {
		if (transactionHash) {
			// Clipboard.setString(transactionHash)
			// Toast.show({
			// 	type: 'success',
			// 	text1: i18next.t('template_smartswap_complete_screen_message_01'),
			// 	position: 'bottom',
			// 	visibilityTime: 500
			// })
		}
	}

	const formatNumberWithCommas = (number: number): string => {
		const fixedNumber = number.toFixed(6)
		const [integerPart, decimalPart] = fixedNumber.split('.')
		const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		return `${formattedIntegerPart}.${decimalPart}`
	}

	const styles = {
		container: {
		} as React.CSSProperties,
		header: {
			display: "flex",
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: "column",
		} as React.CSSProperties,
		title: {
			fontSize: 24,
			color: theme.text_05,
			textAlign: 'center',
		} as React.CSSProperties,
		banner: {
			width: "100%",
			height: 150,
			borderRadius: 10,
			marginBottom: 30,
		} as React.CSSProperties,
		infoContainer: {
			width: '100%',
			borderRadius: 10,
			marginBottom: "20px"
		} as React.CSSProperties,
		infoRow1: {
			display: "flex",
			justifyContent: 'space-between',
			marginBottom: 10,
		} as React.CSSProperties,
		label: {
			fontSize: 16,
			color: theme.text_09
		} as React.CSSProperties,
		valueContainer: {
			flex: 1
		} as React.CSSProperties,
		value: {
			fontSize: 16,
			color: theme.text_05,
			textAlign: 'right'
		} as React.CSSProperties,
		footer: {
			width: '100%',
			display: "flex",
			alignItems: 'center',
			justifyContent: 'flex-end'
		} as React.CSSProperties,
		explorerButton: {
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center',
			marginBottom: 20,
			border: `1px solid ${theme.border_03}`,
			borderRadius: 10,
			padding: "10px 15px",
			width: '100%',
			marginTop: 20,
			background: "inherit"
		} as React.CSSProperties,
		explorerText: {
			color: theme.text_08,
			fontSize: 16
		} as React.CSSProperties,
		completeButton: {
			backgroundColor: theme.button_02,
			padding: "15px 0",
			borderRadius: 27,
			width: '100%',
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center'
		} as React.CSSProperties,
		completeButtonText: {
			color: 'white',
			fontSize: 16
		} as React.CSSProperties,
		infoNotice: {
			textAlign: "center",
			marginBottom: 20
		} as React.CSSProperties,
		infoNoticeText: {
			fontSize: 20,
			color: theme.text_14,
			textAlign: "center",
		} as React.CSSProperties,
		infoNoticeText1: {
			fontSize: 16,
			color: theme.text_14,
			textAlign: "center",
			marginBottom: 5
		} as React.CSSProperties
	}
	
	return (
		<div className='container' style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
			<div style={{ width: "100%" }}>
				<div style={styles.header}>
					<img
						src={donationUI.completeImageUrl}
						alt="Complete"
						style={styles.banner}
					/>
					<CustomText weight='medium' style={{ ...styles.title, color: theme.primaryText }}>
						{donationUI.completeTitle}
					</CustomText>

					<div style={styles.infoContainer}>
						<div style={styles.infoNotice}>
							<CustomText style={styles.infoNoticeText}>* {donationUI.completeContents}</CustomText>
						</div>
						<div style={styles.infoNotice}>
							<CustomText style={styles.infoNoticeText1}>{donationUI.completeContents}</CustomText>
							<CustomText style={styles.infoNoticeText1}>{donationUI.completeContents}</CustomText>
						</div>
					</div>

					<div style={styles.infoContainer}>
						<div style={styles.infoRow1}>
							<CustomText style={styles.label}>TXID</CustomText>
							<div onClick={handleCopyHash} style={styles.valueContainer}>
								<MontserratText
									weight='medium'
									style={{ ...styles.value, color: theme.primaryText }}
									title={transactionHash}
								>
									{`${transactionHash.slice(0, 10)}...${transactionHash.slice(-6)}`}
								</MontserratText>
							</div>
						</div>

						<div style={styles.infoRow1}>
							<CustomText style={styles.label}>보낸 수량</CustomText>
							<MontserratText weight='bold' style={{ ...styles.value, color: theme.primaryText }}>
								{formatNumberWithCommas(Number(amount))}{' '}
							</MontserratText>
						</div>

						<div style={styles.infoRow1}>
							<CustomText style={styles.label}>전송 수수료</CustomText>
							<MontserratText weight='bold' style={{ ...styles.value, color: theme.primaryText }}>
								{formatNumberWithCommas(Number(amount))}{' '}
							</MontserratText>
						</div>

						<div style={styles.infoRow1}>
							<CustomText style={styles.label}>날짜</CustomText>
							<MontserratText weight='bold' style={{ ...styles.value, color: theme.primaryText }}>
								{formatNumberWithCommas(Number(amount))}{' '}
							</MontserratText>
						</div>
					</div>

					<CustomButton
						containerStyle={{ ...styles.explorerButton, borderColor: theme.primary, gap: 5 }}
						textStyle={{ ...styles.explorerText, color: theme.primary }}
						onPress={handleOpenExplorer}
						iconSize={20}
						text="이더스캔 보러가기"
						iconName='open-outline'
						withBackground={true}
						backgroundColor={theme.withoutBackgroundTouchBackgroundColor}
					/>

					<div style={styles.footer}>
						<CustomButton
							containerStyle={{ ...styles.completeButton, backgroundColor: theme.primary }}
							textStyle={styles.completeButtonText}
							onPress={handleComplete}
							text="돌아가기"
							withBackground={true}
							backgroundColor={theme.primaryTouchBackgroundColor}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DonationCompleteScreen

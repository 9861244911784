import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import { Check } from 'lucide-react';

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
import Container from '../Common/Container';
import { useNavigate, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import WebApp from '@twa-dev/sdk'
import { text } from 'stream/consumers';

interface Props {
    // 필요한 prop 타입 정의 (필요에 따라 추가)
}

const CreateWallet: React.FC<Props> = () => {
    const { theme } = useContext(ThemeContext)
    let location = useLocation();
    let navigate = useNavigate();
    const { t } = useTranslation();

    const [isSelected, setSelection] = useState(false)

    useEffect(() => {
        const initializeTgBack = async () => {
            const fullPath = location.pathname + location.hash
            console.log(`텔레그램 백버튼 초기화 : ${fullPath}`)

            const platform = WebApp.platform
            if (fullPath === '/CreateWallet') {
                // BalanceScreen으로 들어오면 텔레그램 백 버튼 숨김 (닫기 버튼으로 변경됨)
                if('android' === platform || 'android_x' === platform || 'ios' === WebApp.platform || 'tdesktop' === WebApp.platform) {
                    WebApp.BackButton.hide();
                }
            } else {
                // 텔레그램 미니앱의 백 버튼 표시
                if('android' === platform || 'android_x' === platform || 'ios' === WebApp.platform || 'tdesktop' === WebApp.platform) {
                    WebApp.BackButton.show();
                }
            }
        };

        initializeTgBack()
    }, []);

    const initializeWallet = async () => {
        navigate(`/WalletProtection`)
    }

    const recoveryWallet = async () => {
        navigate(`/WalletRecovery`)
    }

    const styles = {
        container: {
            paddingTop: "24px"
        } as React.CSSProperties,
        header: {
            width: "100%"
        } as React.CSSProperties,
        title: {
            fontSize: '24px', // px 단위 추가
            color: theme.text_05,
            textAlign: 'left',
        } as React.CSSProperties,
        subtitle: {
            fontSize: '16px',
            color: theme.text_03,
            textAlign: 'left',
            marginTop: '15px',
        } as React.CSSProperties,
        imageContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        } as React.CSSProperties,
        image: {
            width: '140px',
            height: '162px',
        } as React.CSSProperties,
        footer: {
            width: "100%"
        } as React.CSSProperties,
        checkboxContainer: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '24px',
            width: '100%',
        } as React.CSSProperties,
        checkboxBase: {
            width: '22px',
            height: '22px',
            borderRadius: '5px',
            border: `1px solid ${theme.border_05}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '5px',
        } as React.CSSProperties,
        label: {
            fontSize: '16px',
            color: theme.text_05,
            display: 'flex',
            gap: '5px'
        } as React.CSSProperties,
        underline: {
            textDecoration: 'underline', // React 웹에서는 textDecorationLine 대신 textDecoration 사용
        } as React.CSSProperties,
        buttonPrimary: {
            backgroundColor: theme.button_02,
            padding: '12px',
            borderRadius: '10px',
            marginBottom: '12px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            height: '54px',
            justifyContent: 'center',
        } as React.CSSProperties,
        buttonPrimaryText: {
            color: theme.text_01,
            fontSize: '16px',
            textAlign: 'center', // textAlignVertical은 웹에서는 textAlign으로 처리
        } as React.CSSProperties,
        buttonSecondary: {
            border: `1px solid ${theme.border_03}`,
            padding: '12px',
            borderRadius: '10px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            height: '54px',
            justifyContent: 'center',
        } as React.CSSProperties,
        buttonSecondaryText: {
            color: theme.text_05,
            fontSize: '16px',
            textAlign: 'center', // textAlignVertical을 대체
        } as React.CSSProperties,
    };

    return (
        <div className='container' style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
            <div style={styles.header}>
                <CustomText weight="medium" style={{ ...styles.title, color: theme.primaryText }}>
                    {t('main_message_01')}
                </CustomText>
                <CustomText weight="medium" style={{ ...styles.title, color: theme.primaryText }}>
                    {t('main_message_02')}
                </CustomText>
                <CustomText style={{ ...styles.subtitle, color: theme.secondaryText }}>
                    {t('main_message_03')}
                </CustomText>
            </div>
            <div style={styles.imageContainer}>
                <img
                    src={`/img/login_wallet.png`} // process.env.PUBLIC_URL로 경로 설정
                    alt="Login Wallet"
                    style={{ ...styles.image, objectFit: 'contain' }} // objectFit으로 이미지 크기 조정
                />
            </div>
            <div style={styles.footer}>
                <div style={styles.checkboxContainer}>
                    <div
                        onClick={() => setSelection(!isSelected)}
                        style={{
                            ...styles.checkboxBase,
                            backgroundColor: isSelected
                                ? theme.enableCheckBoxBackgroundColor
                                : theme.disableCheckBoxBackgroundColor,
                            borderColor: isSelected ? theme.enableCheckBoxBorderColor : theme.disableCheckBoxBorderColor,
                            cursor: 'pointer', // React 웹에서는 onClick에 cursor 스타일이 필요
                        }}
                    >
                        {isSelected && <Check style={{ width: "18px", color: theme.text_12 }} />}
                    </div>
                    <CustomText style={{ ...styles.label, color: theme.primaryText }}>
                        <CustomText
                            weight="medium"
                            style={{ ...styles.underline, color: theme.primaryText }}
                            onClick={() => { }} // React 웹에서는 onPress 대신 onClick 사용
                        >
                            {t('main_message_04')}
                        </CustomText>
                        {t('main_message_05')}
                    </CustomText>
                </div>
                <CustomButton
                    containerStyle={{
                        ...styles.buttonPrimary, // 기존 스타일
                        backgroundColor: theme.primary, // 추가 스타일
                    }}
                    textStyle={{ ...styles.buttonPrimaryText }}
                    onPress={() => { initializeWallet() }}
                    text={t('main_message_06')}
                    disabled={!isSelected}
                    disabledStyle={isSelected ? {} : { backgroundColor: theme.disableButtonBackgroundColor }}
                    withBackground={true}
                    backgroundColor={theme.primaryTouchBackgroundColor}
                />
                <CustomButton
                    containerStyle={{
                        ...styles.buttonSecondary, // 기존 스타일 병합
                        borderColor: theme.primary, // 추가 스타일 병합
                    }}
                    textStyle={{
                        ...styles.buttonSecondaryText, // 기존 스타일 병합
                        color: theme.primaryText, // 추가 스타일 병합
                    }}
                    onPress={() => { recoveryWallet() }}
                    text={t('main_message_07')}
                    disabled={!isSelected}
                    disabledStyle={
                        !isSelected
                            ? {
                                backgroundColor: theme.disableButtonBackgroundColor,
                                borderColor: theme.disableButtonBackgroundColor,
                            }
                            : undefined
                    }
                    withBackground={true}
                    backgroundColor={theme.withoutBackgroundTouchBackgroundColor}
                />

            </div>
        </div>
    )
}

export default CreateWallet

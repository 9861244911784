import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeContext } from '../../context/ThemeContext';
import { Copy, CircleAlert } from 'lucide-react';
import { ethers } from 'ethers'
import QRCode from 'react-qr-code'
import QRCodeStyling from "qr-code-styling";

import DatePicker from 'react-datepicker'; // react-datepicker 사용
import 'react-datepicker/dist/react-datepicker.css'; // DatePicker 스타일 추가
import { Spin, Modal, Button } from 'antd';

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
import Container from '../Common/Container';
import CourierText from '../../styles/CourierText';
import CustomListCardWithIcon from '../Componentization/Card/CustomListCardWithIcon';
import CustomListCard from '../Componentization/Card/CustomListCard';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkHeaderText, checkPincode, RootState } from '../../store';
import { getCustomChainTransaction } from '../../hooks/useFetchBalance';

import useWallet from '../../hooks/useWallet';
import useEthereum from '../../hooks/useEthereum';
import useEthereumOther from '../../hooks/useEthereumOther';
import useSecureStorage from '../../hooks/useSecureStorage';
import MainPageHeader from '../../nav/MainPageHeader';
import NavigationBar from '../../nav/NavigationBar';
import i18next from 'i18next'
import moment from 'moment'

import { ParamType } from 'ethers/lib/utils'
import CustomTextInput from '../Componentization/CustomTextInput';
import MontserratText from '../../styles/MontserratText';

interface Asset {
	id: number
	name: string
	symbol: string
	balance: string
	icon: any
}

const emptyToken: Asset = {
	id: 0,
	name: '',
	symbol: '',
	balance: '',
	icon: '/img/icon_buff.png'
}

const ReceiveScreen = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const { t } = useTranslation();

	const { getWalletAddress } = useSecureStorage()

	const location = useLocation();
	const [token, setToken] = useState(location.state === null ? null : location.state.token);
	const [address, setAddress] = useState('')

	useEffect(() => {
		dispatch(checkHeaderText({ "link": "DonationScreen", "title": t('stack_header_message_01') }))
	}, [])

	useEffect(() => {
		setToken(emptyToken)
		if (location.state) {
			setToken(location.state.token);
		}
	}, [location.state]);

	useEffect(() => {
		const fetchAddress = async () => {
			const walletAddress = await getWalletAddress()
			console.log(`walletAddress : ${walletAddress}`)
			setAddress(walletAddress)
			if (walletAddress && qrCodeRef.current) {
				qrCode.update({ data: walletAddress });
				qrCode.append(qrCodeRef.current);
			}
		}
		fetchAddress()
	}, [])

	// qrcode styling
	const qrCodeRef = useRef<HTMLDivElement>(null);
	const qrCode = new QRCodeStyling({
		width: 200,
		height: 200,
		type: 'svg',
		data: "",
		image: "/img/icon_qwallet_symbol_small.png",
		imageOptions: {
			crossOrigin: "anonymous",
			margin: 5
		},
		dotsOptions: {
			color: theme.text_05,
		},
	});

	const copyToClipboard = () => {
		console.log(`copyToClipboard address : ${address}`)
		if (address) {
			navigator.clipboard.writeText(address)
				.then(() => {
					alert(i18next.t('template_receive_screen_message_01'));
				})
				.catch(err => {
					console.error('클립보드 복사 실패:', err);
				});
		}
	}

	const shareAddress = () => {
		if (address) {
			navigator.clipboard.writeText(`${i18next.t('template_receive_screen_message_02')}\n${address}`)
				.then(() => {
					alert(i18next.t('template_receive_screen_message_01')); // 복사 완료 메시지
				})
				.catch(error => {
					console.error('클립보드 복사 실패:', error);
					alert(i18next.t('template_receive_screen_message_03')); // 오류 메시지
				});
		}
	}

	const formatAddress = (address: string | null) => {
		if (!address) return ''
		return `${address.slice(0, 6)}...${address.slice(-6)}`
	}

	const styles = {
		container: {
			backgroundColor: theme.background_03,
			padding: "60px 24px 0",
		} as React.CSSProperties,
		tokenHeader: {
			fontSize: 18,
			textAlign: 'center',
			color: theme.text_05,
			marginBottom: 10
		} as React.CSSProperties,
		tokenSubHeader: {
			fontSize: 15,
			textAlign: 'center',
			color: theme.text_05
		} as React.CSSProperties,
		qrContainer: {
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center',
			marginBottom: 35,
			marginTop: 35,
			border: `8px solid ${theme.border_02}`,
			width: 200,
			alignSelf: 'center',
			borderRadius: 10,
			height: 200
		} as React.CSSProperties,
		addressLabel: {
			fontSize: 12,
			textAlign: 'left',
			marginBottom: 8,
			color: theme.text_03
		} as React.CSSProperties,
		addressContainer: {
			marginBottom: 27,
			wordBreak: "break-word"
		} as React.CSSProperties,
		address: {
			fontSize: 15,
			textAlign: 'left',
			color: theme.text_01
		} as React.CSSProperties,
		noticeContainer: {
			marginTop: 37
		} as React.CSSProperties,
		noticeHeaderContainer: {
			display: "flex",
			alignItems: 'center',
			marginBottom: 8
		} as React.CSSProperties,
		noticeHeader: {
			fontSize: 15,
			color: theme.text_05
		} as React.CSSProperties,
		bulletContainer: {
			display: "flex",
			alignItems: 'flex-start'
		} as React.CSSProperties,
		bulletPoint: {
			fontSize: 18,
			color: theme.text_06
		} as React.CSSProperties,
		noticeText: {
			flex: 1,
			fontSize: 14,
			color: theme.text_06,
			marginLeft: 5,
		} as React.CSSProperties,
		buttonShare: {
			width: "100%",
			padding: 18,
			marginTop: 14,
			borderRadius: 10,
			alignItems: 'center',
			display: "flex",
			justifyContent: 'center',
			height: 50
		} as React.CSSProperties,
		buttonTextShare: {
			color: theme.text_01,
			fontSize: 15
		} as React.CSSProperties,
		icon: {
			marginRight: 5
		} as React.CSSProperties,
		cardContainer: {
			backgroundColor: theme.primaryBackgroundColor,
			borderTopLeftRadius: 30,
			borderTopRightRadius: 30,
			padding: "24px 12px",
			border: `1px solid ${theme.border_04}`,
			borderBottom: "none"
		} as React.CSSProperties,
		clipboardButton: {
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center',
			gap: "10px",
			borderRadius: 10,
			marginTop: 10,
			border: `1px solid ${theme.border_03}`,
			height: 48,
			cursor: "pointer"
		} as React.CSSProperties,
		clipboardButtonText: {
			color: theme.text_08,
			fontSize: 14
		} as React.CSSProperties,
	}

	return (
		<div className='container' style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
			<div>
				<CustomText weight="medium" style={{ ...styles.tokenHeader, color: theme.primaryText }}>
					{token.name}{' '}
					{token.name === process.env.REACT_APP_NETWORK_CURRENCY
						? i18next.t('template_receive_screen_message_04')
						: i18next.t('template_receive_screen_message_05')}
				</CustomText>
				<CustomText style={{ ...styles.tokenSubHeader, color: theme.primaryText }}>
					{i18next.t('template_receive_screen_message_06')}{' '}
					{token.name === process.env.REACT_APP_NETWORK_CURRENCY
						? i18next.t('template_receive_screen_message_04')
						: i18next.t('template_receive_screen_message_05')}{' '}
					{i18next.t('template_receive_screen_message_07')}
				</CustomText>
			</div>

			{/* <div style={styles.qrContainer}>
				{address ? (
					<QRCode value={address} size={192} fgColor={theme.text_05} />
				) : (
					<p>{i18next.t('template_receive_screen_message_08')}...</p>
				)}
			</div> */}

			<div style={styles.qrContainer} ref={qrCodeRef}></div>


			<div style={styles.cardContainer}>
				<CustomText style={styles.addressLabel}>
					{i18next.t('template_receive_screen_message_09')}
				</CustomText>
				<div style={styles.addressContainer}>
					<MontserratText style={styles.address}>{address}</MontserratText>
				</div>

				{/* 클립보드 복사 버튼 */}
				<div style={{ ...styles.clipboardButton, ...styles.clipboardButtonText, borderColor: theme.primary, color: theme.primary }} onClick={() => { copyToClipboard() }}>
					<Copy size={15} />
					<div>{t('template_receive_screen_message_10')}</div>
				</div>

				{/* 공유 버튼 */}
				<CustomButton
					containerStyle={{ ...styles.buttonShare, backgroundColor: theme.primary }}
					textStyle={styles.buttonTextShare}
					onPress={() => { shareAddress() }}
					text={i18next.t('template_receive_screen_message_11')}
					withBackground={true}
					backgroundColor={theme.primaryTouchBackgroundColor}
				/>

				<div style={styles.noticeContainer}>
					<div style={styles.noticeHeaderContainer}>
						<CircleAlert name="alert-circle" size={16} color={theme.text_07} style={styles.icon} />
						<CustomText weight="medium" style={{ ...styles.noticeHeader, color: theme.primaryText }}>
							{i18next.t('template_receive_screen_message_12')}
						</CustomText>
					</div>

					<div style={styles.bulletContainer}>
						<span style={styles.bulletPoint}>•</span>
						<CustomText style={styles.noticeText}>
							{i18next.t('template_receive_screen_message_13')}
						</CustomText>
					</div>

					<div style={styles.bulletContainer}>
						<span style={styles.bulletPoint}>•</span>
						<CustomText style={styles.noticeText}>
							{i18next.t('template_receive_screen_message_14')}
						</CustomText>
					</div>

					<div style={styles.bulletContainer}>
						<span style={styles.bulletPoint}>•</span>
						<CustomText style={styles.noticeText}>
							{i18next.t('template_receive_screen_message_15')}
						</CustomText>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ReceiveScreen

import React, { useContext, useEffect, useState, useCallback } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import { useLocation } from 'react-router-dom';
import { Check } from 'lucide-react';

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
import Container from '../Common/Container';
import CourierText from '../../styles/CourierText';
import CustomListCardWithIcon from '../Componentization/Card/CustomListCardWithIcon';
import CustomListCard from '../Componentization/Card/CustomListCard';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkHeaderText, checkPincode, RootState } from '../../store';

import useWallet from '../../hooks/useWallet';
import useEthereum from '../../hooks/useEthereum';
import useEthereumOther from '../../hooks/useEthereumOther';
import useNFTs from '../../hooks/useNfts';
import useSecureStorage from '../../hooks/useSecureStorage';
import MainPageHeader from '../../nav/MainPageHeader';
import NavigationBar from '../../nav/NavigationBar';

import i18next from 'i18next'
import MontserratText from '../../styles/MontserratText';

type serializedTx = {
	from: string
	to: string
	hash: string
	gasLimit: string
	maxFeePerGas: string
	value: string
}

const NFTTransferCompleteScreen: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const { t } = useTranslation();

	const location = useLocation();
	const [tx, setTx] = useState(location.state === null ? null : location.state.tx);

	useEffect(() => {
		dispatch(checkHeaderText({ "link": "NFTTransferCompleteScreen", "title": "" }))
	}, [])

	useEffect(() => {
		if (location.state) {
			setTx(location.state.tx);
			console.log(`NFT 상세 페이지 tx 정보`, tx)
		}
	}, [location.state]);

	const handleComplete = () => {
		// @ts-ignore
		navigate('/NFTScreen')
	}

	const handleOpenExplorer = () => {
		const fullURL = `${process.env.REACT_APP_NETWORK_SCAN_URL}/tx/${tx.hash}`
		window.open(fullURL, '_blank');
	}

	const handleCopyAddress = (address: string) => {
		if (address) {
			navigator.clipboard.writeText(address)
				.then(() => {
					alert(i18next.t('nft_transfer_complete_screen_message_01'));
				})
				.catch(err => {
					console.error('클립보드 복사 실패:', err);
				});
		}
	}

	const styles = {
		container: {
			backgroundColor: theme.background_03,
			padding: "60px 24px 24px",
		} as React.CSSProperties,
		header: {
			display: "flex",
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: "column",
			margin: "40px 0",
		} as React.CSSProperties,
		title: {
			fontSize: 24,
			color: theme.text_05,
			textAlign: 'center',
			marginTop: 20
		} as React.CSSProperties,
		image: {
			width: 75,
			height: 68
		} as React.CSSProperties,
		infoContainer: {
			width: '100%',
			borderRadius: 10,
		} as React.CSSProperties,
		infoRow1: {
			display: "flex",
			justifyContent: 'space-between',
			marginBottom: 10,
		} as React.CSSProperties,
		label: {
			fontSize: 16,
			color: theme.text_09
		} as React.CSSProperties,
		valueContainer: {
			flex: 1,
			width: "100%"
		} as React.CSSProperties,
		value: {
			fontSize: 16,
			color: theme.text_05,
			textAlign: 'right'
		} as React.CSSProperties,
		footer: {
			width: '100%',
			display: "flex",
			alignItems: 'center',
			justifyContent: 'flex-end'
		} as React.CSSProperties,
		explorerButton: {
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center',
			marginBottom: 20,
			border: `1px solid ${theme.border_03}`,
			borderRadius: 10,
			padding: "10px 15px",
			width: '100%',
			marginTop: 20,
			background: "inherit"
		} as React.CSSProperties,
		explorerText: {
			color: theme.text_08,
			fontSize: 16
		} as React.CSSProperties,
		completeButton: {
			backgroundColor: theme.button_02,
			padding: "15px 0",
			borderRadius: 10,
			width: '100%',
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center'
		} as React.CSSProperties,
		completeButtonText: {
			color: 'white',
			fontSize: 16
		}
	}

	return (
		<div className='container' style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
			<div style={{ width: "100%" }}>
				<div style={styles.header}>
					<img
						src="/img/icon_nft_complete.png"
						alt="NFT Complete"
						style={styles.image}
					/>
					<CustomText weight="medium" style={{ ...styles.title, color: theme.primaryText }}>
						{i18next.t('nft_transfer_complete_screen_message_03')}
					</CustomText>
				</div>

				<div style={styles.infoContainer}>
					<div style={styles.infoRow1}>
						<CustomText style={styles.label}>
							{i18next.t('nft_transfer_complete_screen_message_04')}
						</CustomText>
						<button onClick={() => handleCopyAddress(tx.from)} style={styles.valueContainer}>
							<MontserratText weight="medium" style={{ ...styles.value, color: theme.primaryText }}>
								{`${tx.from.slice(0, 10)}...${tx.from.slice(-6)}`}
							</MontserratText>
						</button>
					</div>

					<div style={styles.infoRow1}>
						<CustomText style={styles.label}>
							{i18next.t('nft_transfer_complete_screen_message_05')}
						</CustomText>
						<button onClick={() => handleCopyAddress(tx.to)} style={styles.valueContainer}>
							<MontserratText weight="medium" style={{ ...styles.value, color: theme.primaryText }}>
								{`${tx.to.slice(0, 10)}...${tx.to.slice(-6)}`}
							</MontserratText>
						</button>
					</div>

					<div style={styles.infoRow1}>
						<CustomText style={styles.label}>
							{i18next.t('nft_transfer_complete_screen_message_06')}
						</CustomText>
						<button onClick={handleOpenExplorer} style={styles.valueContainer}>
							<MontserratText weight="medium" style={{ ...styles.value, color: theme.primaryText }}>
								{`${tx.hash.slice(0, 10)}...${tx.hash.slice(-6)}`}
							</MontserratText>
						</button>
					</div>

					<div style={styles.infoRow1}>
						<CustomText style={styles.label}>
							{i18next.t('nft_transfer_complete_screen_message_09')}
						</CustomText>
						<MontserratText weight="medium" style={{ ...styles.value, color: theme.primaryText }}>
							{tx.gasLimit.toString()}
						</MontserratText>
					</div>

					<CustomButton
						containerStyle={{ ...styles.explorerButton, borderColor: theme.primary }}
						textStyle={{ ...styles.explorerText, color: theme.primary }}
						onPress={handleOpenExplorer}
						iconSize={20}
						text={i18next.t('nft_transfer_complete_screen_message_07')}
						iconName="open-outline"
						withBackground={true}
						backgroundColor={theme.withoutBackgroundTouchBackgroundColor}
					/>
				</div>
			</div>

			<div style={styles.footer}>
				<CustomButton
					containerStyle={{ ...styles.completeButton, backgroundColor: theme.primary }}
					textStyle={styles.completeButtonText}
					onPress={handleComplete}
					text={i18next.t('nft_transfer_complete_screen_message_08')}
					withBackground={true}
					backgroundColor={theme.primaryTouchBackgroundColor}
				/>
			</div>
		</div >
	)
}

export default NFTTransferCompleteScreen

import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeContext } from '../../context/ThemeContext';
import { Check } from 'lucide-react';

import DatePicker from 'react-datepicker'; // react-datepicker 사용
import 'react-datepicker/dist/react-datepicker.css'; // DatePicker 스타일 추가

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkUnlock, RootState } from '../../store';
import { getCustomChainTransaction } from '../../hooks/useFetchBalance';

// import useWallet from '../../hooks/useWallet';
// import useEthereum from '../../hooks/useEthereum';
// import useEthereumOther from '../../hooks/useEthereumOther';
import useSecureStorage from '../../hooks/useSecureStorage';

const ResetWalletScreen: React.FC = () => {
    const { theme } = useContext(ThemeContext)
    let navigate = useNavigate();
    const { t } = useTranslation();
    let dispatch = useDispatch();

    const [isSelected, setSelection] = useState(false)
    const [isSelected1, setSelection1] = useState(false)
    const [isSelected2, setSelection2] = useState(false)
    const [isSelected3, setSelection3] = useState(false)

    const [modalVisible, setModalVisible] = useState(false)
    const [modalInput, setModalInput] = useState("")

    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setModalInput(event.target.value);
    }


    const { logOut } = useSecureStorage()

    useEffect(() => {
        const initUnlock = async () => {
        }
        initUnlock()
    }, [])

    const handleResetWallet = () => {
        if (modalInput == "RESET") {
            // localstorage 삭제
            logOut()

            // 언락 상태 false 변경
            dispatch(checkUnlock(false))

            setTimeout(() => {
                // 지갑 생성 페이지로 이동
                navigate(`/CreateWallet`)
            }, 500)
        }
    }

    const styles = {
        container: {
            paddingTop: "24px"
        } as React.CSSProperties,
        header: {
            width: "100%"
        } as React.CSSProperties,
        title: {
            fontSize: '24px', // px 단위 추가
            color: theme.text_05,
            textAlign: 'left',
        } as React.CSSProperties,
        imageContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        } as React.CSSProperties,
        image: {
            width: '140px',
            height: '162px',
        } as React.CSSProperties,
        footer: {
            width: "100%"
        } as React.CSSProperties,
        checkboxContainer: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '24px',
            width: '100%',
        } as React.CSSProperties,
        checkboxBase: {
            width: '22px',
            height: '22px',
            borderRadius: '5px',
            border: `1px solid ${theme.border_05}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '5px',
        } as React.CSSProperties,
        label: {
            width: "calc(100% - 22px)",
            fontSize: '12px',
            color: theme.text_05,
            display: 'flex',
            gap: '5px'
        } as React.CSSProperties,
        underline: {
            textDecoration: 'underline', // React 웹에서는 textDecorationLine 대신 textDecoration 사용
        } as React.CSSProperties,
        buttonPrimary: {
            backgroundColor: theme.button_02,
            padding: '12px',
            borderRadius: '10px',
            marginBottom: '12px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            height: '54px',
            justifyContent: 'center',
        } as React.CSSProperties,
        buttonPrimaryText: {
            color: theme.text_01,
            fontSize: '16px',
            textAlign: 'center', // textAlignVertical은 웹에서는 textAlign으로 처리
        } as React.CSSProperties,
        modalOverlay: {
            width: '100%',
            height: '100%',
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.modal_background_01,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: "translate(-50%, -50%)"
        } as React.CSSProperties,
        modalContent: {
            width: '75%',
            backgroundColor: theme.modal_background_02,
            borderRadius: 28,
            padding: 20,
            alignItems: 'center',
            textAlign: 'center'
        } as React.CSSProperties,
        modalTitle: {
            fontSize: 18,
            marginBottom: 5,
            color: theme.text_05
        } as React.CSSProperties,
        modalDescription1: {
            fontSize: 12,
            color: theme.text_03,
            textAlign: 'left',
            marginTop: "15px"
        } as React.CSSProperties,
        modalButtons: {
            display: "flex",
            justifyContent: 'space-between',
            width: '100%'
        } as React.CSSProperties,
        modalButton: {
            padding: 10,
            margin: 5,
            backgroundColor: theme.button_04,
            height: 56,
            borderRadius: 10,
            justifyContent: "center",
            width: '50%'
        } as React.CSSProperties,
        modalButtonText: {
            fontSize: 14,
            color: theme.text_04
        } as React.CSSProperties,
        modalButtonPrimaryText: {
            color: theme.text_01
        } as React.CSSProperties,
        modalInput: {
            width: '100%',
            border: `1px solid ${theme.border_11}`,
            borderRadius: "10px",
            padding: "10px",
            boxSizing: "border-box",
            marginTop: "5px",
            marginBottom: "10px"
        } as React.CSSProperties,
    };

    return (
        <div className='container' style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
            <div style={styles.header}>
                <CustomText weight="medium" style={{ ...styles.title, color: theme.primaryText }}>
                    {t('template_reset_wallet_screen_message_01')}
                </CustomText>
                <CustomText weight="medium" style={{ ...styles.title, color: theme.primaryText }}>
                    {t('template_reset_wallet_screen_message_02')}
                </CustomText>

            </div>
            <div style={styles.imageContainer}>
                <img
                    src={`/img/login_wallet.png`} // process.env.PUBLIC_URL로 경로 설정
                    alt="Login Wallet"
                    style={{ ...styles.image, objectFit: 'contain' }} // objectFit으로 이미지 크기 조정
                />
            </div>
            <div style={styles.footer}>
                <div style={styles.checkboxContainer}>
                    <div
                        onClick={() => setSelection1(!isSelected1)}
                        style={{
                            ...styles.checkboxBase,
                            backgroundColor: isSelected
                                ? theme.enableCheckBoxBackgroundColor
                                : theme.disableCheckBoxBackgroundColor,
                            borderColor: isSelected1 ? theme.enableCheckBoxBorderColor : theme.disableCheckBoxBorderColor,
                            cursor: 'pointer', // React 웹에서는 onClick에 cursor 스타일이 필요
                        }}
                    >
                        {isSelected1 && <Check style={{ width: "18px", color: theme.text_12 }} />}
                    </div>
                    <CustomText style={{ ...styles.label, color: theme.primaryText }}>
                        <CustomText
                            weight="medium"
                            style={{ ...styles.underline, color: theme.primaryText }}
                            onClick={() => { }} // React 웹에서는 onPress 대신 onClick 사용
                        >
                            {/* {t('main_message_04')} */}
                        </CustomText>
                        {t('template_reset_wallet_screen_message_04')}
                    </CustomText>
                </div>
                <div style={styles.checkboxContainer}>
                    <div
                        onClick={() => setSelection2(!isSelected2)}
                        style={{
                            ...styles.checkboxBase,
                            backgroundColor: isSelected
                                ? theme.enableCheckBoxBackgroundColor
                                : theme.disableCheckBoxBackgroundColor,
                            borderColor: isSelected2 ? theme.enableCheckBoxBorderColor : theme.disableCheckBoxBorderColor,
                            cursor: 'pointer', // React 웹에서는 onClick에 cursor 스타일이 필요
                        }}
                    >
                        {isSelected2 && <Check style={{ width: "18px", color: theme.text_12 }} />}
                    </div>
                    <CustomText style={{ ...styles.label, color: theme.primaryText }}>
                        <CustomText
                            weight="medium"
                            style={{ ...styles.underline, color: theme.primaryText }}
                            onClick={() => { }} // React 웹에서는 onPress 대신 onClick 사용
                        >
                            {/* {t('main_message_04')} */}
                        </CustomText>
                        {t('template_reset_wallet_screen_message_05')}
                    </CustomText>
                </div>
                <div style={styles.checkboxContainer}>
                    <div
                        onClick={() => setSelection3(!isSelected3)}
                        style={{
                            ...styles.checkboxBase,
                            backgroundColor: isSelected
                                ? theme.enableCheckBoxBackgroundColor
                                : theme.disableCheckBoxBackgroundColor,
                            borderColor: isSelected3 ? theme.enableCheckBoxBorderColor : theme.disableCheckBoxBorderColor,
                            cursor: 'pointer', // React 웹에서는 onClick에 cursor 스타일이 필요
                        }}
                    >
                        {isSelected3 && <Check style={{ width: "18px", color: theme.text_12 }} />}
                    </div>
                    <CustomText style={{ ...styles.label, color: theme.primaryText }}>
                        <CustomText
                            weight="medium"
                            style={{ ...styles.underline, color: theme.primaryText }}
                            onClick={() => { }} // React 웹에서는 onPress 대신 onClick 사용
                        >
                            {/* {t('main_message_04')} */}
                        </CustomText>
                        {t('template_reset_wallet_screen_message_06')}
                    </CustomText>
                </div>
                <CustomButton
                    containerStyle={{
                        ...styles.buttonPrimary, // 기존 스타일
                        backgroundColor: theme.primary, // 추가 스타일
                    }}
                    textStyle={{ ...styles.buttonPrimaryText }}
                    onPress={() => { setModalVisible(true) }}
                    text={t('template_reset_wallet_screen_message_03')}
                    disabled={!isSelected1 || !isSelected2 || !isSelected3}
                    disabledStyle={isSelected1 && isSelected2 && isSelected3 ? {} : { backgroundColor: theme.disableButtonBackgroundColor }}
                    withBackground={true}
                    backgroundColor={theme.primaryTouchBackgroundColor}
                />
            </div>
            {
                modalVisible && (
                    <div style={styles.modalOverlay}>
                        <div style={styles.modalContent}>
                            <CustomText weight="bold" style={{ ...styles.modalTitle, color: theme.darkModeModalTextColor }}>
                                {/* {t('create_wallet_message_08')} */}
                                Reset Wallet
                            </CustomText>
                            <CustomText style={{ ...styles.modalDescription1, color: theme.secondaryText }}>
                                {/* {t('create_wallet_message_10')} */}
                                To Confirm wallet reset, please enter: <span style={{ color: 'red' }}>RESET</span>
                            </CustomText>
                            <input style={{ ...styles.modalInput }} placeholder='RESET' value={modalInput} onChange={onChangeInput} />
                            <div style={styles.modalButtons}>
                                <CustomButton
                                    containerStyle={styles.modalButton}
                                    textStyle={{ ...styles.modalButtonText, color: theme.darkModeModalTextColor }}
                                    onPress={() => { setModalVisible(false) }}
                                    text={t('template_reset_wallet_screen_message_07')}
                                    weight="medium"
                                    withBackground={true}
                                    backgroundColor={theme.notUseTouchBackgroundColor}
                                />
                                <CustomButton
                                    containerStyle={{ ...styles.modalButton, backgroundColor: theme.primary }}
                                    textStyle={{ ...styles.modalButtonText, ...styles.modalButtonPrimaryText }}
                                    onPress={() => { handleResetWallet() }}
                                    text={t('template_reset_wallet_screen_message_08')}
                                    weight="medium"
                                    withBackground={true}
                                    backgroundColor={theme.primaryTouchBackgroundColor}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default ResetWalletScreen

import React, { useContext } from 'react'
import CustomCard from './CustomCard'
import CustomText from '../../../styles/CustomText'
import { ThemeContext } from '../../../context/ThemeContext';

interface CustomListCardProps {
	onPress?: () => void;
	title: string;
	subtitle?: string;
	icon?: string; // 웹에서는 string으로 이미지 경로를 사용
	iconBackgroundColor?: string;
	titleColor?: string;
	subtitleColor?: string;
	style?: React.CSSProperties;
}

const CustomListCard: React.FC<CustomListCardProps> = ({
	onPress,
	title,
	subtitle,
	icon,
	iconBackgroundColor,
	titleColor,
	subtitleColor,
	style,
}) => {
	const isNullOption = subtitle === undefined || subtitle === '';
	const { theme } = useContext(ThemeContext)

	// 기본값을 theme에서 가져오도록 설정
	const finalIconBackgroundColor = iconBackgroundColor || theme.card_background_03;
	const finalTitleColor = titleColor || theme.text_03;
	const finalSubtitleColor = subtitleColor || theme.text_01;
	
	const styles = {
		title: {
			fontFamily: 'Pretendard-Medium',
			fontSize: '12px',
		} as React.CSSProperties,
		subtitle: {
			fontFamily: 'Pretendard-Regular',
			fontSize: '15px',
		} as React.CSSProperties,
		nullTitle: {
			textAlign: 'center' as const,
			color: theme.text_03,
			fontSize: '15px',
		} as React.CSSProperties,
		nullSubtitle: {
			textAlign: 'center' as const,
		} as React.CSSProperties,
		centeredTextContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flex: 1,
		} as React.CSSProperties,
		nullText: {
			textAlign: 'center' as const,
		} as React.CSSProperties,
		arrowContainer: {
			position: 'absolute' as const,
			top: "50%",
			transform: 'translateY(-50%)',
			right: '15px',
			justifyContent: 'center',
			alignItems: 'center',
			height: "18px",
		} as React.CSSProperties,
		arrowIcon: {
			width: '13px',
			height: '21px',
			tintColor: theme.text_02,
		} as React.CSSProperties,
	};

	return (
		<CustomCard
			onPress={onPress}
			title={title}
			subtitle={subtitle}
			icon={icon}
			cardStyle={{ backgroundColor: finalIconBackgroundColor, ...style }}
			titleStyle={{ ...styles.title, color: finalTitleColor, ...(isNullOption ? styles.nullTitle : {}) }}
			subtitleStyle={{ ...styles.subtitle, color: finalSubtitleColor, ...(isNullOption ? styles.nullSubtitle : {}) }}
		>
			{isNullOption ? (
				<div style={styles.centeredTextContainer}>
					<div style={{ ...styles.nullText, color: titleColor }}>{title}</div>
				</div>
			) : (
				<div style={styles.arrowContainer}>
					<img src="/img/icon_arrow_right.png" alt="arrow" style={styles.arrowIcon} />
				</div>
			)}
		</CustomCard>
	);
};

export default CustomListCard

import React, { useContext, useEffect, useState, useCallback } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import { RefreshCw } from 'lucide-react';
import { Spin, Modal, Button } from 'antd';

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
import Container from '../Common/Container';
import CourierText from '../../styles/CourierText';
import CustomListCardWithIcon from '../Componentization/Card/CustomListCardWithIcon';
import CustomListCard from '../Componentization/Card/CustomListCard';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkHeaderText, checkPincode, RootState } from '../../store';

import useWallet from '../../hooks/useWallet';
import useEthereum from '../../hooks/useEthereum';
import useEthereumOther from '../../hooks/useEthereumOther';
import useNFTs from '../../hooks/useNfts';
import useSecureStorage from '../../hooks/useSecureStorage';
import MainPageHeader from '../../nav/MainPageHeader';
import NavigationBar from '../../nav/NavigationBar';

// import { RPC_URL, REWARD_NFT_CONTRACT_ADDRESS } from '@env'
import { ethers } from 'ethers'

import i18next from 'i18next'

const REWARD_STORAGE_KEY = 'NFT_DATA'
const OTHER_STORAGE_KEY = 'OTHER_DATA'
const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL)

interface NFT {
	name: string
	description: string
	image: string
	external_url: string
	animation_url: string | null
	edition: string
	code: string
	category: string
	type: string
	contractAddress: string
	tokenId: string
}

const fetchNFTMetadataWithData = async (
	contractAddress: string,
	tokenId: string,
	ownerAddress: string
): Promise<NFT | null> => {
	try {
		const contract = new ethers.Contract(
			contractAddress,
			[
				'function tokenURI(uint256 tokenId) view returns (string)',
				'function ownerOf(uint256 tokenId) view returns (address)'
			],
			provider
		)

		const tokenURI = await contract.tokenURI(tokenId)
		const owner = await contract.ownerOf(tokenId)

		if (owner.toLowerCase() !== ownerAddress.toLowerCase()) {
			throw new Error('You are not the owner of this NFT')
		}

		const response = await fetch(tokenURI)
		if (!response.ok) {
			throw new Error('Failed to fetch metadata')
		}
		const data = await response.json()
		return { ...data, contractAddress, tokenId: tokenId.toString() } as NFT
	} catch (error) {
		console.log('Error fetching NFT metadata:', error)
		return null
	}
}

const fetchNFTMetadata = async (url: string, tokenId: string): Promise<NFT | null> => {
	try {
		const response = await fetch(url)
		if (!response.ok) {
			throw new Error('Failed to fetch')
		}
		const data = await response.json()
		// console.log('Fetched metadata:', data)
		return { ...data, contractAddress: process.env.REACT_APP_REWARD_NFT_CONTRACT_ADDRESS, tokenId: tokenId.toString() } as NFT
	} catch (error) {
		console.log('Error fetching NFT metadata:', error)
		return null
	}
}

const CACHE_DURATION = 60000

const NFTListScreen: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const { t } = useTranslation();

	const { getWalletAddress, setNfts, getNfts } = useSecureStorage()

	const [selectedTab, setSelectedTab] = useState<'NFT' | 'NFT 가져오기'>('NFT')
	const [accountAddress, setAccountAddress] = useState<string | null>(null)
	const [nftData, setNftData] = useState<NFT[]>([])

	const [isEmptynftData, setisEmptynftData] = useState(true)
	const [localLoading, setLocalLoading] = useState(false)
	const [error, setError] = useState<string | null>(null)
	const [refreshing, setRefreshing] = useState(false)

	const nftABI = [
		'function balanceOf(address owner) view returns (uint256)',
		'function tokenOfOwnerByIndex(address owner, uint256 index) view returns (uint256)',
		'function tokenURI(uint256 tokenId) view returns (string)'
	]

	useEffect(() => {
		// console.log('1-1')
		const fetchAccountDetails = async () => {
			const walletAddress = await getWalletAddress()
			if (walletAddress) {
				setAccountAddress(walletAddress)
				await loadData(walletAddress)
			}
		}
		// console.log('1-2')
		fetchAccountDetails()
	}, [])

	useEffect(() => {
		const forceRefresh = async () => {
			if (accountAddress && true == refreshing) {
				console.log('------------------------------------------')
				console.log(`NFTListScreen NFT 리스트 forceRefresh 시작`)
				console.log('------------------------------------------')
				await loadData(accountAddress)
			}
		}
		forceRefresh()
	}, [refreshing])

	// nft 보유 데이터 요청
	const getOnchainNfts = async (contractAddress: string, walletAddress: string) => {
		try {
			const contract = new ethers.Contract(contractAddress, nftABI, provider)
			const balance: number = await contract.balanceOf(walletAddress)

			if (balance === 0) {
				return null
			}

			const nftPromises = []

			for (let i = 0; i < balance; i++) {
				const tokenIdPromise = contract.tokenOfOwnerByIndex(walletAddress, i)
				nftPromises.push(tokenIdPromise)
			}

			const tokenIds = await Promise.all(nftPromises)
			const tokenURIPromises = tokenIds.map(async (tokenId: any) => {
				const tokenURI = await contract.tokenURI(tokenId)
				return { tokenId: tokenId.toString(), tokenURI }
			})
			const tokens = await Promise.all(tokenURIPromises)

			return tokens
		} catch (err: any) {
			console.log('Error getNfts fail', err)
		}
	}

	// nft 캐싱 데이터 갱신
	const updateNfts = async (contractAddress: string, walletAddress: string) => {
		let isNoNftData = false;
		try {
			console.log('------------------------------------------')
			console.log(`NFTListScreen updateNfts() 시작`)
			console.log('------------------------------------------')

			const nfts = await getOnchainNfts(contractAddress, walletAddress);

			let fetchedNFTs: any[] = [];
			if (null != nfts) {
				fetchedNFTs = await Promise.all(
					nfts.map(async ({ tokenId, tokenURI }) => {
						const metadata = await fetchNFTMetadata(tokenURI, tokenId)
						return metadata
					})
				)
			}

			const validFetchedNFTs = fetchedNFTs.filter(nft => nft !== null) as NFT[]

			// const storedOtherNFTs = await AsyncStorage.getItem(OTHER_STORAGE_KEY)
			const storedOtherNFTs = await getNfts(OTHER_STORAGE_KEY)
			const storedOtherNFTsParsed: NFT[] = storedOtherNFTs ? JSON.parse(storedOtherNFTs) : []

			const combinedNFTs = [...validFetchedNFTs, ...storedOtherNFTsParsed]

			if (null == combinedNFTs || 0 == combinedNFTs.length) {
				isNoNftData = true;
			} else {
				isNoNftData = false;
			}

			setisEmptynftData(isNoNftData)


			setNftData(combinedNFTs)
			// await AsyncStorage.setItem(REWARD_STORAGE_KEY, JSON.stringify({ data: combinedNFTs, timestamp: Date.now() }))
			await setNfts(REWARD_STORAGE_KEY, JSON.stringify({ data: combinedNFTs, timestamp: Date.now() }))
		} catch (error) {
			setError('Failed to fetch NFT metadata')
		} finally {
			setLocalLoading(false)
		}
	}

	const loadData = async (walletAddress: string) => {
		// const cachedData = await AsyncStorage.getItem(REWARD_STORAGE_KEY)
		const cachedData = await getNfts(REWARD_STORAGE_KEY)

		if (cachedData && walletAddress) {
			const parsedCache = JSON.parse(cachedData)
			const currentTime = Date.now()

			if (false == parsedCache.hasOwnProperty('data') || '' == parsedCache.data) {
				setNftData([])
				setisEmptynftData(true)
				console.log(`1`)
			} else {
				// nft 데이터 없음 표시 제거
				setisEmptynftData(false)

				// 캐시 데이터가 있을 경우 이전 데이터 바로 표시
				setNftData(parsedCache.data)
			}

			if (currentTime - parsedCache.timestamp > CACHE_DURATION || true === refreshing) {
				await updateNfts(process.env.REACT_APP_REWARD_NFT_CONTRACT_ADDRESS!, walletAddress || '')
			}
		} else if (walletAddress) {
			// nft 데이터 없음 표시
			setNftData([])
			setLocalLoading(true)
			await updateNfts(process.env.REACT_APP_REWARD_NFT_CONTRACT_ADDRESS!, walletAddress || '')
		}
	}

	const onRefresh = useCallback(() => {
		setRefreshing(true)
		setTimeout(() => {
			setRefreshing(false)
		}, 2000)
	}, [])

	const renderItem = ({ item }: { item: NFT }) => {
		if (!item) return null
		return (
			<div
				onClick={() => navigate('/NFTDetailsScreen', { state: { nft: item } })}
				style={styles.nftItem}
			>
				<div style={{ ...styles.nftImageContainer }}>
					<img src={item.image} alt={item.name} style={styles.nftImage} />
				</div>
				<CustomText style={{ ...styles.nftText, color: theme.itemTextColor }}>{item.name}</CustomText>
			</div>
		)
	}

	const renderSkeleton = () => (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
			<Spin size="large" /> {/* Show the spin loader in the center of the page */}
		</div>
	)

	// const renderSkeleton = () => (
	// 	<SkeletonPlaceholder>
	// 		<View style={styles.skeletonContainer}>
	// 			{Array.from({ length: 6 }).map((_, index) => (
	// 				<View key={index} style={styles.skeletonItem}>
	// 					<View style={styles.skeletonImage} />
	// 					<View style={styles.skeletonText} />
	// 				</View>
	// 			))}
	// 		</View>
	// 	</SkeletonPlaceholder>
	// )

	// const renderEmptyMessage = () => (
	// 	<>
	// 		<View style={{ flex: 1 }}>
	// 			<View style={styles.emptyContainer}>
	// 			<CustomText style={[styles.emptyText, { color: theme.noItemsTextColor }]}>
	// 				{i18next.t('nft_screen_message_15')}.
	// 			</CustomText>
	// 			</View>
	// 			<CustomTouchableHighlight
	// 				containerStyle={[styles.importIconButton, { backgroundColor: theme.primaryBackgroundColor }]}
	// 				onPress={() => setSelectedTab('NFT 가져오기')}
	// 			>
	// 				<View style={styles.iconContainer}>
	// 					<Icon name='add' size={16} color={theme.text_18} />
	// 				</View>
	// 				<CustomText style={styles.text}>{i18next.t('nft_screen_message_02')}</CustomText>
	// 			</CustomTouchableHighlight>
	// 		</View>
	// 	</>
	// )

	const styles = {
		container: {
			backgroundColor: theme.background_03,
			padding: "60px 24px 24px",
			// padding: "60px 24px 70px",
		} as React.CSSProperties,
		nftItem: {
			width: '100%',
			cursor: "pointer"
		} as React.CSSProperties,
		nftImageContainer: {
			width: "95%",
			aspectRatio: 1 / 1,
			borderRadius: 10,
			marginTop: 10,
		} as React.CSSProperties,
		nftImage: {
			width: "100%",
			aspectRatio: 1 / 1,
			marginBottom: 10,
			borderRadius: 10
		} as React.CSSProperties,
		nftText: {
			width: "100%",
			fontSize: 16,
			textAlign: 'left',
			fontWeight: "bold"
		} as React.CSSProperties,
		listContent: {
			display: "grid",
			justifyContent: 'space-between',
			paddingBottom: 80,
			gridTemplateColumns: "repeat(2, 1fr)"
		} as React.CSSProperties,
		skeletonContainer: {
			marginTop: 10,
			display: "flex",
			flexWrap: 'wrap',
			justifyContent: 'space-between'
		} as React.CSSProperties,
		skeletonItem: {
			width: '50%',
			alignItems: 'center',
			marginBottom: 20
		} as React.CSSProperties,
		skeletonImage: {
			width: "95%",
			aspectRatio: 1 / 1,
			borderRadius: 10,
			marginBottom: 10
		} as React.CSSProperties,
		skeletonText: {
			width: "95%",
			height: 20,
			borderRadius: 4
		} as React.CSSProperties,
		emptyContainer: {
			height: 79,
			borderRadius: 10,
			justifyContent: 'center',
			alignItems: 'center',
		} as React.CSSProperties,
		emptyText: {
			fontSize: 12,
			color: theme.text_03,
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center',
		} as React.CSSProperties,
		inputContainer: {
			justifyContent: 'center'
		} as React.CSSProperties,
		input: {
			border: `1px solid ${theme.border_02}`,
			borderRadius: 10,
			padding: 10,
			marginBottom: 20,
			background: "inherit",
			width: "100%",
			boxSizing: "border-box"
		} as React.CSSProperties,
		buttonContainer: {
			display: "flex",
			justifyContent: 'space-between',
			gap: "10px",
		} as React.CSSProperties,
		button: {
			display: "flex",
			padding: 15,
			borderRadius: 10,
			alignItems: 'center',
			justifyContent: "center",
			marginHorizontal: 5,
			width: "100%"
		} as React.CSSProperties,
		cancelButton: {
			backgroundColor: theme.button_05
		} as React.CSSProperties,
		buttonText: {
			fontSize: 16
		} as React.CSSProperties,
		importIconButton: {
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center',
			border: `1px solid ${theme.border_04}`,
			borderRadius: 30,
			backgroundColor: theme.button_08,
			height: 55,
			marginTop: 20,
			width: "calc(100% - 48px)",
			cursor: "pointer",
			position: "fixed",
			bottom: "75px",
			left: "50%",
			transform: 'translateX(-50%)'
		} as React.CSSProperties,
		iconContainer: {
			marginRight: 5,
		} as React.CSSProperties,
		text: {
			color: theme.text_18,
			fontSize: 16,
			fontWeight: 'bold'
		} as React.CSSProperties,
		gridItem: {
			padding: '10px',
			borderRadius: '5px',
		} as React.CSSProperties,
		refresh: {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: "flex-end"
		} as React.CSSProperties,
	}

	return (
		<>
			{
				localLoading ? (
					renderSkeleton()
				) :
					selectedTab === 'NFT' ? (
						// isEmptynftData ? (
						// 	renderEmptyMessage()
						// ) : (
						<div style={{ width: "100%" }}>
							<div style={styles.refresh}>
								<div style={{ padding: "5px", display: "flex", alignItems: 'center', justifyContent: 'center' }} onClick={() => { onRefresh() }}>
									<RefreshCw
										color={theme.text_01}
										size={18}
										className={refreshing ? 'rotating-icon' : ''}
									/>
								</div>
							</div>
							<div className='containerScroll' style={styles.listContent}>
								{nftData.map((item, index) => (
									<div key={index.toString()} style={styles.gridItem}>
										{renderItem({ item })}
									</div>
								))}
							</div>
							<div
								style={{ ...styles.importIconButton, backgroundColor: theme.primaryBackgroundColor }}
								onClick={() => setSelectedTab('NFT 가져오기')}
							>
								<div style={styles.iconContainer}>
									<i className="fas fa-plus" style={{ fontSize: '16px', color: theme.text_18 }}></i>
								</div>
								<CustomText style={styles.text}>{t('nft_screen_message_02')}</CustomText>
							</div>
						</div>
						// )
					) : (
						<NFTImportScreen setSelectedTab={setSelectedTab} />
					)}
		</>
	)
}

const NFTImportScreen: React.FC<{ setSelectedTab: (tab: 'NFT' | 'NFT 가져오기') => void }> = ({ setSelectedTab }) => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const { t } = useTranslation();

	const { getWalletAddress, setNfts, getNfts } = useSecureStorage()

	const [address, setAddress] = useState('')
	const [tokenId, setTokenId] = useState('')
	const [accountAddress, setAccountAddress] = useState<string | null>(null)

	useEffect(() => {
		const fetchAccountDetails = async () => {
			const walletAddress = await getWalletAddress()
			if (walletAddress) {
				setAccountAddress(walletAddress)
			}
		}
		fetchAccountDetails()
	}, [])

	const handleImport = async () => {
		if (!address || !tokenId) {
			alert(i18next.t('nft_screen_message_24'))
			return
		}

		// const currentData = JSON.parse((await AsyncStorage.getItem(OTHER_STORAGE_KEY)) || '[]')

		const storedOtherNFTs = await getNfts(OTHER_STORAGE_KEY)
		const currentData: NFT[] = storedOtherNFTs ? JSON.parse(storedOtherNFTs) : []

		const isDuplicate = currentData.some((nft: NFT) => nft.contractAddress === address && nft.tokenId === tokenId)
		if (isDuplicate) {
			alert(i18next.t('nft_screen_message_25'))
			return
		}

		if (!accountAddress) {
			alert(`Account address not found`)
			return
		}

		const metadata = await fetchNFTMetadataWithData(address, tokenId, accountAddress)
		if (metadata) {
			const newData = [...currentData, metadata]
			await setNfts(OTHER_STORAGE_KEY, JSON.stringify(newData))

			alert(i18next.t('nft_screen_message_26'))

			setSelectedTab('NFT')
		} else {

			alert(i18next.t('nft_screen_message_27'))
		}
	}

	const styles = {
		container: {
			backgroundColor: theme.background_03,
			padding: "60px 24px 24px",
			// padding: "60px 24px 70px",
		} as React.CSSProperties,
		nftItem: {
			width: '100%',
			cursor: "pointer"
		} as React.CSSProperties,
		nftImageContainer: {
			width: "95%",
			aspectRatio: 1 / 1,
			borderRadius: 10,
			marginTop: 10,
		} as React.CSSProperties,
		nftImage: {
			width: "100%",
			aspectRatio: 1 / 1,
			marginBottom: 10,
			borderRadius: 10
		} as React.CSSProperties,
		nftText: {
			width: "100%",
			fontSize: 16,
			textAlign: 'left',
			fontWeight: "bold"
		} as React.CSSProperties,
		listContent: {
			display: "grid",
			justifyContent: 'space-between',
			paddingBottom: 80,
			gridTemplateColumns: "repeat(2, 1fr)"
		} as React.CSSProperties,
		skeletonContainer: {
			marginTop: 10,
			display: "flex",
			flexWrap: 'wrap',
			justifyContent: 'space-between'
		} as React.CSSProperties,
		skeletonItem: {
			width: '50%',
			alignItems: 'center',
			marginBottom: 20
		} as React.CSSProperties,
		skeletonImage: {
			width: "95%",
			aspectRatio: 1 / 1,
			borderRadius: 10,
			marginBottom: 10
		} as React.CSSProperties,
		skeletonText: {
			width: "95%",
			height: 20,
			borderRadius: 4
		} as React.CSSProperties,
		emptyContainer: {
			height: 79,
			borderRadius: 10,
			justifyContent: 'center',
			alignItems: 'center',
		} as React.CSSProperties,
		emptyText: {
			fontSize: 12,
			color: theme.text_03,
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center',
		} as React.CSSProperties,
		inputContainer: {
			justifyContent: 'center'
		} as React.CSSProperties,
		input: {
			border: `1px solid ${theme.border_02}`,
			borderRadius: 10,
			padding: 10,
			marginBottom: 20,
			background: "inherit",
			width: "100%",
			boxSizing: "border-box"
		} as React.CSSProperties,
		buttonContainer: {
			display: "flex",
			justifyContent: 'space-between',
			gap: "10px",
		} as React.CSSProperties,
		button: {
			display: "flex",
			padding: 15,
			borderRadius: 10,
			alignItems: 'center',
			justifyContent: "center",
			marginHorizontal: 5,
			width: "100%"
		} as React.CSSProperties,
		cancelButton: {
			backgroundColor: theme.button_05
		} as React.CSSProperties,
		buttonText: {
			fontSize: 16
		} as React.CSSProperties,
		importIconButton: {
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center',
			border: `1px solid ${theme.border_04}`,
			borderRadius: 30,
			backgroundColor: theme.button_08,
			height: 55,
			marginTop: 20,
			width: "calc(100% - 48px)",
			cursor: "pointer",
			position: "fixed",
			bottom: "75px",
			left: "50%",
			transform: 'translateX(-50%)'
		} as React.CSSProperties,
		iconContainer: {
			marginRight: 5,
		} as React.CSSProperties,
		text: {
			color: theme.text_18,
			fontSize: 16,
			fontWeight: 'bold'
		} as React.CSSProperties,
		gridItem: {
			padding: '10px',
			borderRadius: '5px',
		} as React.CSSProperties,
		refresh: {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: "flex-end"
		} as React.CSSProperties,
	}

	
	return (
		<div style={styles.inputContainer}>
			<input
				style={{ ...styles.input, color: theme.primaryText, borderColor: theme.primaryText }}
				placeholder={t('nft_screen_message_03')}
				value={address}
				onChange={(e) => setAddress(e.target.value)}
			/>
			<input
				style={{ ...styles.input, color: theme.primaryText, borderColor: theme.primaryText }}
				placeholder={t('nft_screen_message_04')}
				value={tokenId}
				onChange={(e) => setTokenId(e.target.value)}
			/>
			<div style={styles.buttonContainer}>
				<button
					style={{ ...styles.button, ...styles.cancelButton }}
					onClick={() => setSelectedTab('NFT')}
				>
					<CustomText style={{ ...styles.buttonText, color: theme.detailButtonTextColor }}>
						{t('nft_screen_message_05')}
					</CustomText>
				</button>
				<button
					style={{ ...styles.button, backgroundColor: theme.primary }}
					onClick={() => { handleImport() }}
				>
					<CustomText style={{ ...styles.buttonText, color: theme.detailButtonTextColor }}>
						{t('nft_screen_message_06')}
					</CustomText>
				</button>
			</div>
		</div>
	)
}

const NFTScreen: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false)
	useEffect(() => {
		dispatch(checkHeaderText({ "link": "NFTScreen", "title": "NFT" }))
	}, [])


	// useFocusEffect(
	// 	useCallback(() => {
	// 		setLoading(true)
	// 		const delayLoading = async () => {
	// 			await new Promise(resolve => setTimeout(resolve, 100))
	// 			setLoading(false)
	// 		}
	// 		delayLoading()
	// 	}, [])
	// )

	const renderTabContent = () => {
		return <NFTListScreen />
	}

	// const handleIconPress = () => {
	// 	// 더보기 스택으로 이동시 강제로 첫번째 스크린 설정
	// 	navigation.dispatch(
	// 		CommonActions.reset({
	// 			index: 0,
	// 			routes: [{ name: 'More', params: { screen: 'SeeMoreScreen' } }]
	// 		})
	// 	);

	// 	// @ts-ignore
	// 	// navigation.navigate('More', {
	// 	// 	screen: 'SeeMoreScreen'
	// 	// })
	// }

	const styles = {
		container: {
			backgroundColor: theme.background_03,
			padding: "60px 24px 24px",
			// padding: "60px 24px 70px",
		} as React.CSSProperties,
		nftItem: {
			width: '100%',
			cursor: "pointer"
		} as React.CSSProperties,
		nftImageContainer: {
			width: "95%",
			aspectRatio: 1 / 1,
			borderRadius: 10,
			marginTop: 10,
		} as React.CSSProperties,
		nftImage: {
			width: "100%",
			aspectRatio: 1 / 1,
			marginBottom: 10,
			borderRadius: 10
		} as React.CSSProperties,
		nftText: {
			width: "100%",
			fontSize: 16,
			textAlign: 'left',
			fontWeight: "bold"
		} as React.CSSProperties,
		listContent: {
			display: "grid",
			justifyContent: 'space-between',
			paddingBottom: 80,
			gridTemplateColumns: "repeat(2, 1fr)"
		} as React.CSSProperties,
		skeletonContainer: {
			marginTop: 10,
			display: "flex",
			flexWrap: 'wrap',
			justifyContent: 'space-between'
		} as React.CSSProperties,
		skeletonItem: {
			width: '50%',
			alignItems: 'center',
			marginBottom: 20
		} as React.CSSProperties,
		skeletonImage: {
			width: "95%",
			aspectRatio: 1 / 1,
			borderRadius: 10,
			marginBottom: 10
		} as React.CSSProperties,
		skeletonText: {
			width: "95%",
			height: 20,
			borderRadius: 4
		} as React.CSSProperties,
		emptyContainer: {
			height: 79,
			borderRadius: 10,
			justifyContent: 'center',
			alignItems: 'center',
		} as React.CSSProperties,
		emptyText: {
			fontSize: 12,
			color: theme.text_03,
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center',
		} as React.CSSProperties,
		inputContainer: {
			justifyContent: 'center'
		} as React.CSSProperties,
		input: {
			border: `1px solid ${theme.border_02}`,
			borderRadius: 10,
			padding: 10,
			marginBottom: 20,
			background: "inherit",
			width: "100%",
			boxSizing: "border-box"
		} as React.CSSProperties,
		buttonContainer: {
			display: "flex",
			justifyContent: 'space-between',
			gap: "10px",
		} as React.CSSProperties,
		button: {
			display: "flex",
			padding: 15,
			borderRadius: 10,
			alignItems: 'center',
			justifyContent: "center",
			marginHorizontal: 5,
			width: "100%"
		} as React.CSSProperties,
		cancelButton: {
			backgroundColor: theme.button_05
		} as React.CSSProperties,
		buttonText: {
			fontSize: 16
		} as React.CSSProperties,
		importIconButton: {
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center',
			border: `1px solid ${theme.border_04}`,
			borderRadius: 30,
			backgroundColor: theme.button_08,
			height: 55,
			marginTop: 20,
			width: "calc(100% - 48px)",
			cursor: "pointer",
			position: "fixed",
			bottom: "75px",
			left: "50%",
			transform: 'translateX(-50%)'
		} as React.CSSProperties,
		iconContainer: {
			marginRight: 5,
		} as React.CSSProperties,
		text: {
			color: theme.text_18,
			fontSize: 16,
			fontWeight: 'bold'
		} as React.CSSProperties,
		gridItem: {
			padding: '10px',
			borderRadius: '5px',
		} as React.CSSProperties,
		refresh: {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: "flex-end"
		} as React.CSSProperties,
	}

	
	return (
		<>
			<MainPageHeader />
			{/* <NavigationBar /> */}
			<div className='container' style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
				{!loading && renderTabContent()}
			</div>
		</>
	)
}

export default NFTScreen

import React, { useState, useEffect, useContext } from 'react'
import { ThemeContext } from '../../context/ThemeContext';
import { Delete } from 'lucide-react';

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkPincode, RootState } from '../../store';

import WebApp from '@twa-dev/sdk'

const PIN_LENGTH = 6

const WalletRecoveryProtection: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	const { t } = useTranslation();
	const navigate = useNavigate();
	let dispatch = useDispatch();

	const [pin, setPin] = useState<string[]>([])
	const [confirmPin, setConfirmPin] = useState<string[]>([])
	const [isConfirming, setIsConfirming] = useState(false)
	const [error, setError] = useState(false)
	const [modalVisible, setModalVisible] = useState(false)
	const [fingerprintAuth, setFingerprintAuth] = useState(false)

	const [shuffledKeys, setShuffledKeys] = useState<string[]>([])

	useEffect(() => {
		setShuffledKeys(shuffleArray(['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']))
	}, [])

	useEffect(() => {
		if (pin.length === PIN_LENGTH && !isConfirming) {
			setTimeout(() => {
				setIsConfirming(true)
			}, 200)
		} else if (confirmPin.length === PIN_LENGTH && isConfirming) {
			setTimeout(() => {
				handleConfirm()
			}, 200)
		}
	}, [pin, confirmPin, isConfirming])

	useEffect(() => {
		if (isConfirming) {
			setShuffledKeys(shuffleArray(['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']))
		}
	}, [isConfirming])


	// 텔레그램 생체인증 시작
	const TgWebAppStartBioAuth = async () => {
		try {
			// 생체인증 가능하고 접근권한 신청하지 않은 상태
			if(true === WebApp.BiometricManager.isBiometricAvailable) {
				console.log(`//-------------------------------------------------`)
				console.log(`WebApp.BiometricManager.isBiometricAvailable TRUE`)

				if(false === WebApp.BiometricManager.isAccessGranted) {
					// 생체인증 권한이 없는 경우
					console.log(`//-------------------------------------------------`)
					console.log(`WebApp.BiometricManager.isAccessRequested FALSE`)

					console.log(`WebApp.BiometricManager.requestAccess 권한 요청 시작`)
					const bioParam = {reason: "Telegram BiometricManager requestAccess"}
					WebApp.BiometricManager.requestAccess(bioParam, TgWebAppBioRequestAccessCallBack)
				} else if(true === WebApp.BiometricManager.isAccessGranted) {
					// 생체인증 권한이 부여된 경우
					console.log(`//-------------------------------------------------`)
					console.log(`WebApp.BiometricManager.isAccessRequested TRUE`)

					console.log(`WebApp.BiometricManager.authenticate 생체인증 요청 시작`)
					const bioParam = {reason: "Telegram BiometricManager authenticate"}
					WebApp.BiometricManager.authenticate(bioParam, TgWebAppBioAuthenticateCallBack)
				}
			} else {

			}
		} catch(error) {
			console.error(`Error Start BioAuth`, error)
		}
	}

	// 텔레그램 생체인식 권한요청 callback()
	const TgWebAppBioRequestAccessCallBack = async (isSuccess: boolean) => {
		try {
			console.log(`//-------------------------------------------------`)
			console.log(`TgWebAppBioRequestAccessCallBack() !!!!!`)
			console.log(`텔레그램 BiometricManager 권한요청 결과 : ${isSuccess}`)
		} catch (error) {
			console.error(`Error Tg Req Bio Access`, error)
		}
	};

	// 텔레그램 생체인식 인증요청 callback()
	const TgWebAppBioAuthenticateCallBack = async (isSuccess:boolean, bioToken:string) => {
		try {
			console.log(`//-------------------------------------------------`)
			console.log(`TgWebAppBioAuthenticateCallBack() !!!!!`)
			console.log(`텔레그램 BiometricManager 인증요청 결과 : ${isSuccess}`)

			console.log(`UnlockScreen 텔레그램 SDK API 생체인식 상태`)
			console.log(`WebApp.BiometricManager.isBiometricAvailable : ${WebApp.BiometricManager.isBiometricAvailable}`)
			console.log(`WebApp.BiometricManager.biometricType : ${WebApp.BiometricManager.biometricType}`)
			console.log(`WebApp.BiometricManager.isAccessRequested : ${WebApp.BiometricManager.isAccessRequested}`)
			console.log(`WebApp.BiometricManager.isAccessGranted : ${WebApp.BiometricManager.isAccessGranted}`)
			console.log(`WebApp.BiometricManager.isBiometricTokenSaved : ${WebApp.BiometricManager.isBiometricTokenSaved}`)
			
			if(true === isSuccess) {
				// 인증성공 pincode 저장
				if (confirmPin.join('') === pin.join('')) {
					const pinCode = pin.join('')
					WebApp.BiometricManager.updateBiometricToken(pinCode, TgWebAppUpdateBioTokenCallBack);
				}
			} else {

			}

		} catch(error) {
			console.error(`Error Tg Bio Auth`, error)
		}
	}

	// bioToken update
	const TgWebAppUpdateBioTokenCallBack = async (isSuccess:boolean) => {
		// bioToken 업데이트 결과 확인
		if(true == isSuccess) {
			// 지문사용 설정 저장
			await localStorage.setItem('fingerprintEnabled', 'true')

			// 다음 페이지로 이동
			setTimeout(() => {
				// @ts-ignore
				navigate(`/WalletRecoveryLoading`)
				setError(false)
			}, 500)
		}
	}

	const handleKeyPress = (key: string) => {
		if (key === 'backspace') {
			if (isConfirming) {
				setConfirmPin(prevPin => prevPin.slice(0, -1))
			} else {
				setPin(prevPin => prevPin.slice(0, -1))
			}
		} else {
			if (isConfirming) {
				if (confirmPin.length < PIN_LENGTH) {
					setConfirmPin(prevPin => [...prevPin, key])
				}
			} else {
				if (pin.length < PIN_LENGTH) {
					setPin(prevPin => [...prevPin, key])
				}
			}
		}
	}

	const handleConfirm = async () => {
		// setModalVisible(true)

		if (confirmPin.join('') === pin.join('')) {
			// redux 에 pincode 임시 저장
			const pinCode = pin.join('')
			
			// 입력된 핀코드 저장
			dispatch(checkPincode(pinCode))

			if(true === WebApp.BiometricManager.isInited) {
				// 지문인식 사용가능
				setModalVisible(true)
			} else {
			// 지문인식 사용불가
				setTimeout(() => {
					navigate(`/WalletRecoveryLoading`)
					setError(false)
				}, 500)
			}
		} else {
			setError(true)
			// Vibration.vibrate()
			setTimeout(() => {
				setConfirmPin([])
				setError(false)
			}, 1000)
		}
	}

	const handleModalClose = async (useFingerprint: boolean) => {
		setModalVisible(false)
		if (true === useFingerprint) {
			// 지문인식 초기화 시작
			TgWebAppStartBioAuth();
		} else {
			// 지문인식 사용 안함
			await localStorage.setItem('fingerprintEnabled', 'false')
			
			// @ts-ignore
			navigate('/WalletRecoveryLoading')
		}
	}

	const handleFingerprintAuth = () => {
		setModalVisible(false)
		setFingerprintAuth(true)
	}

	const shuffleArray = (array: string[]) => {
		let currentIndex = array.length,
			randomIndex

		while (currentIndex !== 0) {
			randomIndex = Math.floor(Math.random() * currentIndex)
			currentIndex--
				;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
		}

		return array
	}

	const renderKey = (key: string, index: number) => (
		<div style={styles.keyWrapper} key={index}>
			<div
				onClick={() => { handleKeyPress(key) }}
				style={{
					// backgroundColor: theme.keyPadBackgroundColor,
					borderRadius: '50%',
				}}
			>
				<div style={styles.key}>
					<CustomText weight='medium' style={{ ...styles.keyText, color: theme.primaryText }}>
						{key}
					</CustomText>
				</div >
			</div >
		</div >
	)

	const styles = {
		header: {
			height: "135px"
		} as React.CSSProperties,
		title1: {
			fontSize: 24,
			textAlign: 'center',
			color: theme.text_05
		} as React.CSSProperties,
		title2: {
			fontSize: 24,
			textAlign: 'center',
			color: theme.text_05,
			marginBottom: 10
		} as React.CSSProperties,
		subtitle1: {
			fontSize: 14,
			color: theme.text_03,
			textAlign: 'center'
		} as React.CSSProperties,
		subtitle2: {
			fontSize: 14,
			color: theme.text_03,
			textAlign: 'center',
			marginBottom: 20
		} as React.CSSProperties,
		pinContainer: {
			display: "flex",
			justifyContent: 'center',
			alignItems: 'center',
		} as React.CSSProperties,
		pinDot: {
			width: 16,
			height: 16,
			borderRadius: 8,
			backgroundColor: theme.pinCode_background_01,
			margin: 8
		} as React.CSSProperties,
		pinDotError: {
			backgroundColor: theme.pinCode_background_03,
			borderColor: theme.border_01
		} as React.CSSProperties,
		errorText: {
			color: theme.error_text_01,
			fontSize: 14,
			height: "20px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center"
		} as React.CSSProperties,
		keypadContainer: {
			width: '100%',
		} as React.CSSProperties,
		keypadRow: {
			display: "flex",
			justifyContent: 'space-around',
			width: '100%',
			marginBottom: 5
		} as React.CSSProperties,
		keyWrapper: {} as React.CSSProperties,
		key: {
			display: "flex",
			width: 70,
			height: 70,
			justifyContent: 'center',
			alignItems: 'center',
			cursor: "pointer"
		} as React.CSSProperties,
		keyPlaceholder: {
			width: 70,
			height: 70,
		} as React.CSSProperties,
		keyText: {
			fontSize: 24,
			color: theme.text_05
		} as React.CSSProperties,
		modalOverlay: {
			width: '100%',
			display: "flex",
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: theme.modal_background_01,
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: "translate(-50%, -50%)"
		} as React.CSSProperties,
		modalContent: {
			width: '75%',
			backgroundColor: theme.modal_background_02,
			borderRadius: 28,
			padding: 20,
			alignItems: 'center',
			textAlign: 'center'
		} as React.CSSProperties,
		modalTitle: {
			fontSize: 18,
			marginBottom: 5,
			color: theme.text_05
		} as React.CSSProperties,
		modalSubtitle: {
			fontSize: 16,
			marginBottom: 10,
			color: theme.text_05
		} as React.CSSProperties,
		modalDescription1: {
			fontSize: 14,
			color: theme.text_03,
			textAlign: 'center'
		} as React.CSSProperties,
		modalDescription2: {
			fontSize: 14,
			color: theme.text_03,
			marginBottom: 20,
			textAlign: 'center'
		} as React.CSSProperties,
		modalButtons: {
			display: "flex",
			justifyContent: 'space-between',
			width: '100%'
		} as React.CSSProperties,
		modalButton: {
			padding: 10,
			margin: 5,
			backgroundColor: theme.button_04,
			height: 56,
			borderRadius: 10,
			justifyContent: "center",
			width: '50%'
		} as React.CSSProperties,
		modalButtonText: {
			fontSize: 14,
			color: theme.text_04
		} as React.CSSProperties,
		modalButtonPrimaryText: {
			color: theme.text_01
		} as React.CSSProperties
	}

	return (
		<div className='container' style={{ backgroundColor: theme.primaryBackgroundColor }}>
			<div style={styles.header}>
				<CustomText weight="medium" style={{ ...styles.title1, color: theme.primaryText }}>
					{isConfirming ? t('recovery_wallet_message_03') : t('recovery_wallet_message_01')}
				</CustomText>
				<CustomText weight="medium" style={{ ...styles.title2, color: theme.primaryText }}>
					{isConfirming ? t('recovery_wallet_message_04') : t('recovery_wallet_message_02')}
				</CustomText>
				<CustomText style={{ ...styles.subtitle1, color: theme.secondaryText }}>
					{t('recovery_wallet_message_05')}
				</CustomText>
				<CustomText style={{ ...styles.subtitle1, color: theme.secondaryText }}>
					{t('recovery_wallet_message_06')}
				</CustomText>
			</div>
			<div>
				<div style={styles.pinContainer}>
					{Array.from({ length: PIN_LENGTH }).map((_, index) => {
						const baseStyle: React.CSSProperties = {
							...styles.pinDot,
							backgroundColor: theme.pinDotBackgroundColor,
						};

						const activeStyle: React.CSSProperties =
							(isConfirming ? confirmPin.length > index : pin.length > index)
								? { backgroundColor: theme.primary }
								: {};

						const errorStyle: React.CSSProperties =
							error && isConfirming && confirmPin.length > index ? styles.pinDotError : {};

						return <div key={index} style={{ ...baseStyle, ...activeStyle, ...errorStyle }} />;
					})}
				</div>
				<CustomText style={{ ...styles.errorText, color: theme.error }}>
					{error && t('recovery_wallet_message_07')}
				</CustomText>
			</div>
			<div style={styles.keypadContainer}>
				{Array.from({ length: 3 }).map((_, rowIndex) => (
					<div key={rowIndex} style={styles.keypadRow}>
						{shuffledKeys.slice(rowIndex * 3, rowIndex * 3 + 3).map((key, index) => renderKey(key, index))}
					</div>
				))}
				<div style={styles.keypadRow}>
					<div style={styles.keyPlaceholder}></div>
					{renderKey(shuffledKeys[9], 9)}
					<div style={styles.keyWrapper} key="backspace">
						<div
							onClick={() => { handleKeyPress('backspace') }}
							style={styles.key}
						>
							<Delete size={24} color={theme.primaryText} />
						</div>
					</div>
				</div>
			</div>
			{
				modalVisible && (
					<div style={styles.modalOverlay}>
						<div style={styles.modalContent}>
							<CustomText weight="bold" style={{ ...styles.modalTitle, color: theme.darkModeModalTextColor }}>
								{t('recovery_wallet_message_08')}
							</CustomText>
							<CustomText weight="bold" style={{ ...styles.modalSubtitle, color: theme.darkModeModalTextColor }}>
								{t('recovery_wallet_message_09')}
							</CustomText>
							<CustomText style={{ ...styles.modalDescription1, color: theme.secondaryText }}>
								{t('recovery_wallet_message_10')}
							</CustomText>
							<CustomText style={{ ...styles.modalDescription2, color: theme.secondaryText }}>
								{t('recovery_wallet_message_11')}
							</CustomText>
							<div style={styles.modalButtons}>
								<CustomButton
									containerStyle={styles.modalButton}
									textStyle={{ ...styles.modalButtonText, color: theme.darkModeModalTextColor }}
									onPress={() => handleModalClose(false)}
									text={t('recovery_wallet_message_12')}
									weight="medium"
									withBackground={true}
									backgroundColor={theme.notUseTouchBackgroundColor}
								/>
								<CustomButton
									containerStyle={{ ...styles.modalButton, backgroundColor: theme.primary }}
									textStyle={{ ...styles.modalButtonText, ...styles.modalButtonPrimaryText }}
									onPress={() => handleModalClose(true)}
									text={t('recovery_wallet_message_13')}
									weight="medium"
									withBackground={true}
									backgroundColor={theme.primaryTouchBackgroundColor}
								/>
							</div>
						</div>
					</div>
				)
			}
		</div>
	)
}

export default WalletRecoveryProtection

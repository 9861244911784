import { useCallback } from 'react';
import CryptoJS from 'crypto-js';
import WebApp from '@twa-dev/sdk'

// Chrome Storage helper functions
const setChromeStorage = (data: { [key: string]: any }): Promise<void> => {
    return new Promise((resolve, reject) => {
        chrome.storage.local.set(data, () => {
            if (chrome.runtime.lastError) {
                reject(new Error(chrome.runtime.lastError.message)); // lastError를 Error 객체로 변환
            } else {
                resolve(); // 데이터가 성공적으로 저장된 경우
            }
        });
    });
};

const getChromeStorage = async (keys: string[]) => {
    return new Promise<any>((resolve, reject) => {
        chrome.storage.local.get(keys, (result) => {
            if (chrome.runtime.lastError) {
                return reject(chrome.runtime.lastError);
            }
            resolve(result);
        });
    });
};

const getChromeStorageSingle = async (key: string): Promise<any> => {
	return new Promise((resolve, reject) => {
			chrome.storage.local.get(key, (result) => {
					if (chrome.runtime.lastError) {
							reject(new Error(chrome.runtime.lastError.message));
					} else {
							resolve(result[key]);
					}
			});
	});
};

const clearChromeStorage = (): Promise<void> => {
	return new Promise((resolve, reject) => {
			chrome.storage.local.clear(() => {
					if (chrome.runtime.lastError) {
							reject(new Error(chrome.runtime.lastError.message));
					} else {
							resolve();
					}
			});
	});
};

const useSecureStorage = () => {
    // PBKDF2 키 유도 함수 (동기적으로 동작)
    const deriveKey = (password: string, salt: string): CryptoJS.lib.WordArray => {
        // PBKDF2 with 10000 iterations and 256-bit key
        const key = CryptoJS.PBKDF2(password, CryptoJS.enc.Hex.parse(salt), {
            keySize: 256 / 32, // 256-bit key
            iterations: 10000,
            hasher: CryptoJS.algo.SHA256
        });
        return key;
    };

    // 데이터 암호화 함수
    const encryptValue = (password: string, data: string): { encrypted: string, salt: string, iv: string } => {
        try {
            // 랜덤한 salt 생성 (16바이트)
            const salt = CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Hex);

            // PBKDF2를 사용하여 키 유도
            const key = deriveKey(password, salt);

            // 랜덤한 IV 생성 (16바이트)
            const iv = CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Hex);

            // AES-CBC 모드를 사용하여 데이터 암호화
            const encrypted = CryptoJS.AES.encrypt(data, key, {
                iv: CryptoJS.enc.Hex.parse(iv),
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }).toString();

            // 암호화된 데이터와 함께 salt, iv 반환
            return {
                encrypted,
                salt,
                iv
            };
        } catch (error) {
            console.error(`Error encryptValue`, error);
        }

        return { encrypted: '', salt: '', iv: '' };
    };

    // 데이터 복호화 함수
    const decryptValue = (password: string, encryptedData: { encrypted: string, salt: string, iv: string }): string => {
        try {
            const { encrypted, salt, iv } = encryptedData;

            // PBKDF2를 사용하여 키 유도
            const key = deriveKey(password, salt);

            // AES-CBC 모드를 사용하여 데이터 복호화
            const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
                iv: CryptoJS.enc.Hex.parse(iv),
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });

            // 복호화된 데이터를 UTF-8 문자열로 변환
            const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
            return decryptedText;
        } catch (error) {
            console.error(`Error decryptValue`, error);
        }

        return '';
    };

    // 데이터 암호화 및 저장 함수
    const storeEncryptedData = useCallback(
        async (walletAddress: string, privateKey: string, publicKey: string, mnemonic: string | string[], pin: string) => {
            try {
                if (pin != null && pin.length === 6) {
                    const encKey = pin.trim();
                    const mnemonicString = Array.isArray(mnemonic) ? mnemonic.join(' ') : mnemonic;

                    // 각 데이터 암호화
                    const encryptedPrivateKey = encryptValue(encKey, privateKey);
                    const encryptedPublicKey = encryptValue(encKey, publicKey);
                    const encryptedMnemonic = encryptValue(encKey, mnemonicString);

					// 암호화된 데이터를 localStorage에 저장
					if (process.env.REACT_APP_NODE_ENV === 'development') {
						localStorage.setItem('encryptedPrivateKey', JSON.stringify(encryptedPrivateKey));
						localStorage.setItem('encryptedPublicKey', JSON.stringify(encryptedPublicKey));
						localStorage.setItem('encryptedMnemonic', JSON.stringify(encryptedMnemonic));
					}
					else {
						console.log(`chromestorage`)
						// 암호화된 데이터를 Chrome Storage에 저장
						await setChromeStorage({
							encryptedPrivateKey: JSON.stringify(encryptedPrivateKey),
							encryptedPublicKey: JSON.stringify(encryptedPublicKey),
							encryptedMnemonic: JSON.stringify(encryptedMnemonic),
						});
					}

					// 지갑주소는 일반 저장
					setWalletAddress(walletAddress)

                    return true;
                }
            } catch (error) {
                console.error('fail storeEncryptedData', error);
            }
            return false;
        }, []);

    // 암호화된 데이터 복호화 및 조회 함수
    const retrieveEncryptedData = useCallback(async (walletAddress: string, pin: string) => {
		let privatekey
		let publicKey
		let mnemonic
		if (process.env.REACT_APP_NODE_ENV === 'development') {
			privatekey = localStorage.getItem('encryptedPrivateKey')
			publicKey = localStorage.getItem('encryptedPublicKey')
			mnemonic = localStorage.getItem('encryptedMnemonic')
		} else {
			console.log(`chromestorage`)
			const result = await getChromeStorage(['encryptedPrivateKey', 'encryptedPublicKey', 'encryptedMnemonic']);
			privatekey = result.encryptedPrivateKey;
			publicKey = result.encryptedPublicKey;
			mnemonic = result.encryptedMnemonic;
		}

        if (privatekey != null && publicKey != null && mnemonic != null) {
            const privateKeyData = JSON.parse(privatekey);
            const publicKeyData = JSON.parse(publicKey);
            const mnemonicKeyData = JSON.parse(mnemonic);

            const encKey = pin.trim();
            const decryptedPrivateKey = decryptValue(encKey, privateKeyData);
            const decryptedPublicKey = decryptValue(encKey, publicKeyData);
            const decryptedMnemonicKey = decryptValue(encKey, mnemonicKeyData);

            return { privateKey: decryptedPrivateKey, publicKey: decryptedPublicKey, mnemonic: decryptedMnemonicKey };
        }

        throw new Error('Failed to retrieve encrypted data');
    }, []);

    // 암호화된 데이터 원본 조회 함수
    const retrieveRawEncryptedData = useCallback(async () => {
		let privateKeyData
		let publicKeyData
		let mnemonicKeyData
		if (process.env.REACT_APP_NODE_ENV === 'development') {
			privateKeyData = localStorage.getItem('encryptedPrivateKey')
			publicKeyData = localStorage.getItem('encryptedPublicKey')
			mnemonicKeyData = localStorage.getItem('encryptedMnemonic')
		} else {
			console.log(`ChromeStorage`)
			const result = await getChromeStorage(['encryptedPrivateKey', 'encryptedPublicKey', 'encryptedMnemonic']);
			privateKeyData = result.encryptedPrivateKey;
			publicKeyData = result.encryptedPublicKey;
			mnemonicKeyData = result.encryptedMnemonic;
		}

        return { privateKeyData, publicKeyData, mnemonicKeyData };
    }, []);

	// 핀코드 일치 확인
	const checkPincodeMatch = useCallback(async (pin: string) => {
		let privatekey
		let publicKey
		let mnemonic

		if (process.env.REACT_APP_NODE_ENV === 'development') {
			privatekey = localStorage.getItem('encryptedPrivateKey')
			publicKey = localStorage.getItem('encryptedPublicKey')
			mnemonic = localStorage.getItem('encryptedMnemonic')
		} else {
			const result = await getChromeStorage(['encryptedPrivateKey', 'encryptedPublicKey', 'encryptedMnemonic']);
			privatekey = result.encryptedPrivateKey;
			publicKey = result.encryptedPublicKey;
			mnemonic = result.encryptedMnemonic;
		}

        if (privatekey != null && publicKey != null && mnemonic != null) {
            const privateKeyData = JSON.parse(privatekey);
            const publicKeyData = JSON.parse(publicKey);
            const mnemonicKeyData = JSON.parse(mnemonic);

            const encKey = pin.trim();
            const decryptedPrivateKey = decryptValue(encKey, privateKeyData);
            const decryptedPublicKey = decryptValue(encKey, publicKeyData);
            const decryptedMnemonicKey = decryptValue(encKey, mnemonicKeyData);

						if('' !== decryptedPrivateKey && '' !== decryptedPublicKey && '' !== decryptedMnemonicKey) {
							return true;
						}

            return false;
        }

        throw new Error('Failed to retrieve encrypted data');
    }, []);

    // 비밀번호 변경 함수
    const changePassword = useCallback(async (walletAddress: string, currentPin: string, newPin: string) => {
        try {
            if (currentPin.length !== 6 || newPin.length !== 6) {
                console.log(`Error currentPin or newPin length check ${currentPin} | ${newPin}`);
                return false;
            }

            if (currentPin.length > 0 && newPin.length > 0) {
                const encKey = currentPin.trim();

                // 기존 암호화된 데이터 가져오기
								let privateKeyData
								let publicKeyData
								let mnemonicKeyData
								if (process.env.REACT_APP_NODE_ENV === 'development') {
									privateKeyData = localStorage.getItem('encryptedPrivateKey')
									publicKeyData = localStorage.getItem('encryptedPublicKey')
									mnemonicKeyData = localStorage.getItem('encryptedMnemonic')
								} else {
									console.log(`ChromeStorage`)
									const result = await getChromeStorage(['encryptedPrivateKey', 'encryptedPublicKey', 'encryptedMnemonic']);
									privateKeyData = result.encryptedPrivateKey;
									publicKeyData = result.encryptedPublicKey;
									mnemonicKeyData = result.encryptedMnemonic;
								}

								if (privateKeyData && publicKeyData && mnemonicKeyData) {
									// 기존 데이터 복호화
									const decryptedPrivateKey = decryptValue(encKey, JSON.parse(privateKeyData));
									const decryptedPublicKey = decryptValue(encKey, JSON.parse(publicKeyData));
									const decryptedMnemonicKey = decryptValue(encKey, JSON.parse(mnemonicKeyData));

			            			// 새 PIN으로 데이터 재암호화 및 저장
									if(true === await storeEncryptedData(walletAddress, decryptedPrivateKey, decryptedPublicKey, decryptedMnemonicKey, newPin)) {
										
										const useFingerPrint = await localStorage.getItem('fingerprintEnabled')
										
										// 지문인증을 사용 중인 경우 
										if('true' === useFingerPrint) {
											// bioToken 핀코드 변경
											// console.log(`useSecureStorage changePassword 텔레그램 SDK API 생체인식 상태`)
											// console.log(`WebApp.BiometricManager.isBiometricAvailable : ${WebApp.BiometricManager.isBiometricAvailable}`)
											// console.log(`WebApp.BiometricManager.biometricType : ${WebApp.BiometricManager.biometricType}`)
											// console.log(`WebApp.BiometricManager.isAccessRequested : ${WebApp.BiometricManager.isAccessRequested}`)
											// console.log(`WebApp.BiometricManager.isAccessGranted : ${WebApp.BiometricManager.isAccessGranted}`)
											// console.log(`WebApp.BiometricManager.isBiometricTokenSaved : ${WebApp.BiometricManager.isBiometricTokenSaved}`)

											// WebApp.BiometricManager.updateBiometricToken(newPin);
										}
									}

									console.log('success changePassword');

									return true;
								}
					}
        } catch (error) {
            console.error('fail changePassword', error);
        }

        return false;
    }, []);

	// 지갑 주소 저장
	const setWalletAddress = useCallback(async (walletAddress:string) => {
		try {
			if (walletAddress != null && 1 < walletAddress.length) {
				const address = walletAddress.trim();

				if (process.env.REACT_APP_NODE_ENV === 'development') {
					localStorage.setItem('walletAddress', walletAddress);
				} else {
					// Chrome Storage에 저장
					await setChromeStorage({
						walletAddress: address,
					});
				}

				return true;
			}
		} catch (error) {
			console.error('fail setWalletAddress', error);
		}
		return false;
	}, []);

	// 저장된 지갑 주소 리턴
	const getWalletAddress = useCallback(async () => {
		try {
			let address
			if (process.env.REACT_APP_NODE_ENV === 'development') {
				// local Storage
				address = localStorage.getItem('walletAddress')
			} else {
				// Chrome Storage
				const result = await getChromeStorage(['walletAddress']);
				address = result.walletAddress;
			}

			return address;
		} catch (error) {
			console.error('fail getWalletAddress', error);
		}

		return null;
	}, []);

	// 지갑생성 완료 상태 설정
	const setLogin = useCallback(async () => {
		console.log('setLogin');
		if (process.env.REACT_APP_NODE_ENV === 'development') {
			localStorage.setItem('register', 'true');
		} else {
			// Chrome Storage에 저장
			await setChromeStorage({
				register: 'true',
			});
		}
	}, []);

	// 지갑생성 완료 상태 해지
	const setLogout = useCallback(async (walletAddress:string) => {
		console.log('setLogout');
		if (process.env.REACT_APP_NODE_ENV === 'development') {
			localStorage.setItem('register', 'false');
		} else {
			// Chrome Storage에 저장
			await setChromeStorage({
				register: 'false',
			});
		}
	}, []);

	// 로그인 상태 확인
	const getLogin = useCallback(async () => {
		let register
		if (process.env.REACT_APP_NODE_ENV === 'development') {
			register = localStorage.getItem('register');
		} else {
			// Chrome Storage에 저장
			const result = await getChromeStorage(['register']);
			register = result.register;
		}

		if('true' === register) {
			return true
		}

		return false
	}, []);

	// 코인/토큰 전송 내역 저장
	const setCacheLog = useCallback(async (cacheKey:string, data:string) => {
		console.log('setCacheLog');
		if (process.env.REACT_APP_NODE_ENV === 'development') {
			localStorage.setItem(cacheKey, data);
		} else {
			// Chrome Storage에 저장
			await setChromeStorage({[cacheKey]: data,});
			console.log('Chrome Storage setCacheLog : ', cacheKey);
		}
	}, []);

	// 코인/토큰 전송 내역 불러오기
	const getCacheLog = useCallback(async (cacheKey:string) => {
		console.log('getCacheLog');
		let cache
		if (process.env.REACT_APP_NODE_ENV === 'development') {
			cache = localStorage.getItem(cacheKey);
		} else {
			// Chrome Storage에 저장
			const result = await getChromeStorageSingle(cacheKey);
			cache = result;
		}

		return cache
	}, []);

	// nft 캐시 저장하기
	const setNfts = useCallback(async (nftKey:string, data:string) => {
		console.log('setNfts');
		if (process.env.REACT_APP_NODE_ENV === 'development') {
			localStorage.setItem(nftKey, data);
		} else {
			// Chrome Storage에 저장
			await setChromeStorage({[nftKey]: data,});
			console.log('Chrome Storage setNfts : ', nftKey);
		}
	}, []);

	// nft 캐시 불러오기
	const getNfts = useCallback(async (nftKey:string) => {
		console.log('getNfts');
		let cache
		if (process.env.REACT_APP_NODE_ENV === 'development') {
			cache = localStorage.getItem(nftKey);
		} else {
			// Chrome Storage에 저장
			const result = await getChromeStorageSingle(nftKey);
			cache = result;
		}

		return cache
	}, []);

	// 자동 잠금 설정 저장
	const setAutoLockValue = useCallback(async (lockSetting:string) => {
		try {
			if (lockSetting != null && 1 < lockSetting.length) {
				const address = lockSetting.trim();

				if (process.env.REACT_APP_NODE_ENV === 'development') {
					localStorage.setItem('autoLockSetting', lockSetting);
				} else {
					// Chrome Storage에 저장
					await setChromeStorage({
						autoLockSetting: address,
					});
				}

				return true;
			}
		} catch (error) {
			console.error('fail autoLockSetting', error);
		}
		return false;
	}, []);

	// 저장된 자동 잠금 설정 리턴
	const getAutoLockValue = useCallback(async () => {
		try {
			let lockSetting
			if (process.env.REACT_APP_NODE_ENV === 'development') {
				// local Storage
				lockSetting = localStorage.getItem('autoLockSetting')
			} else {
				// Chrome Storage
				const result = await getChromeStorage(['autoLockSetting']);
				lockSetting = result.lockSetting;
			}

			return lockSetting;
		} catch (error) {
			console.error('fail getAutoLockSetting', error);
		}

		return null;
	}, []);
	
	// 지문인식 설정 저장
	const setFingerprintEnabled = useCallback(async (useSetting:string) => {
		try {
			if (useSetting != null && 1 < useSetting.length) {
				const value = useSetting.trim();

				if (process.env.REACT_APP_NODE_ENV === 'development') {
					localStorage.setItem('fingerprintEnabled', value);
				} else {
					// Chrome Storage에 저장
					await setChromeStorage({
						fingerprintEnabled: value,
					});
				}

				return true;
			}
		} catch (error) {
			console.error('fail fingerprintEnabled', error);
		}
		return false;
	}, []);

	// 저장된 지문인식 설정 리턴
	const getFingerprintEnabled = useCallback(async () => {
		try {
			let useSetting
			if (process.env.REACT_APP_NODE_ENV === 'development') {
				// local Storage
				useSetting = localStorage.getItem('fingerprintEnabled')
			} else {
				// Chrome Storage
				const result = await getChromeStorage(['fingerprintEnabled']);
				useSetting = result.lockSetting;
			}

			return useSetting;
		} catch (error) {
			console.error('fail getFingerprintEnabled', error);
		}

		return null;
	}, []);

	// 스토리지 저장된 데이터 모두 clear
	const logOut = useCallback(async () => {
		console.log('clearStorage');
		localStorage.clear()
		
		// bioToken 초기화
		// console.log(`useSecureStorage logOut 텔레그램 SDK API 생체인식 상태`)
		// console.log(`WebApp.BiometricManager.isBiometricAvailable : ${WebApp.BiometricManager.isBiometricAvailable}`)
		// console.log(`WebApp.BiometricManager.biometricType : ${WebApp.BiometricManager.biometricType}`)
		// console.log(`WebApp.BiometricManager.isAccessRequested : ${WebApp.BiometricManager.isAccessRequested}`)
		// console.log(`WebApp.BiometricManager.isAccessGranted : ${WebApp.BiometricManager.isAccessGranted}`)
		// console.log(`WebApp.BiometricManager.isBiometricTokenSaved : ${WebApp.BiometricManager.isBiometricTokenSaved}`)
		// WebApp.BiometricManager.updateBiometricToken("");

		if (process.env.REACT_APP_NODE_ENV !== 'development') {
			await clearChromeStorage()
		}
	}, []);

	return {
		storeEncryptedData,
		retrieveEncryptedData,
		retrieveRawEncryptedData,
		checkPincodeMatch,
		changePassword,
		setWalletAddress,
		getWalletAddress,
		setLogin,
		setLogout,
		getLogin,
		setCacheLog,
		getCacheLog,
		setNfts,
		getNfts,
		setAutoLockValue,
		getAutoLockValue,
		setFingerprintEnabled,
		getFingerprintEnabled,
		logOut
	};
}

export default useSecureStorage;

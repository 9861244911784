import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../../context/ThemeContext';
import { Check } from 'lucide-react';

import CustomButton from '../../Componentization/CustomButton';
import CustomText from '../../../styles/CustomText';
import Container from '../../Common/Container';
import CourierText from '../../../styles/CourierText';
import CustomListCardWithIcon from '../../Componentization/Card/CustomListCardWithIcon';
import CustomListCard from '../../Componentization/Card/CustomListCard';
import CustomSwtich from '../../Componentization/Switch/CustomSwtich';

import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkHeaderText, checkPincode, RootState } from '../../../store';

import useWallet from '../../../hooks/useWallet';
import useEthereum from '../../../hooks/useEthereum';
import useEthereumOther from '../../../hooks/useEthereumOther';
import useSecureStorage from '../../../hooks/useSecureStorage';
import useDefaultNetworkAndToken from '../../../hooks/useDefaultNetworkAndToken';

import MainPageHeader from '../../../nav/MainPageHeader';
import NavigationBar from '../../../nav/NavigationBar';

import i18next from 'i18next'
import PinCodeScreen from '../../Authentication/PinCodeScreen';
import MontserratText from '../../../styles/MontserratText';

const SecuritySecretPhraseConfirmation = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const { t } = useTranslation();

	const location = useLocation();
	const [currentPin, setCurrentPin] = useState(location.state === null ? "" : location.state.pincode);

	const { getWalletAddress, retrieveEncryptedData } = useSecureStorage()

	const [secretPhrase, setSecretPhrase] = useState<string[]>([])

	useEffect(() => {
		dispatch(checkHeaderText({ "link": "SecuritySecretPhraseConfirmation", "title": t('stack_header_message_09') }))
	}, [])

	useEffect(() => {
		const fetchMnemonic = async () => {
			const walletAddress = await getWalletAddress()
			const pinCode = currentPin
			if (walletAddress && pinCode) {
				try {
					console.log(`secretPhrase`, secretPhrase)
					console.log(`currentPin`, currentPin)
					const data = await retrieveEncryptedData(walletAddress, pinCode)
					if (data && data.mnemonic) {
						const mnemonicArray = data.mnemonic.split(' ')
						setSecretPhrase(mnemonicArray)
					}

					// 처리가 끝난 후 location.state 초기화
					navigate(location.pathname, { replace: true, state: {} });
				} catch (error) {
					console.error('Failed to retrieve mnemonic 2 :', error)
				}
			} else {
				console.log(`pin data empty`)
			}
		}
		fetchMnemonic()
	}, [])

	const handleCopyToClipboard = () => {
		// Clipboard.setString(secretPhrase.join(' '))
		// Toast.show({
		// 	type: 'success',
		// 	text1: i18next.t('security_recovery_message_01'),
		// 	position: 'bottom',
		// 	visibilityTime: 500
		// })
	}

	const handleDone = () => {
		setCurrentPin('')
		setSecretPhrase([])
		navigate('/SecuritySettings')
	}

	const renderPhrasePairs = () => {
		const pairs = []
		for (let i = 0; i < secretPhrase.length; i += 2) {
			pairs.push(
				<div key={i} style={styles.phraseRow}>
					<div style={styles.phraseItem}>
						<MontserratText weight="medium" style={{ ...styles.phraseIndex, color: theme.primary }}>
							{i + 1}
						</MontserratText>
						<MontserratText weight="medium" style={{ ...styles.phraseText, color: theme.primaryText }}>
							{secretPhrase[i]}
						</MontserratText>
					</div>

					{i + 1 < secretPhrase.length && (
						<div style={styles.phraseItem}>
							<MontserratText weight="medium" style={{ ...styles.phraseIndex, color: theme.primary }}>
								{i + 2}
							</MontserratText>
							<MontserratText weight="medium" style={{ ...styles.phraseText, color: theme.primaryText }}>
								{secretPhrase[i + 1]}
							</MontserratText>
						</div>
					)}
				</div>
			)
		}
		return pairs
	}

	const styles = {
		container: {
			padding: "60px 24px 24px",
		} as React.CSSProperties,
		content: {
			width: "100%"
		} as React.CSSProperties,
		phraseContainer: {
			padding: 5,
			marginBottom: 32
		} as React.CSSProperties,
		phraseRow: {
			display: 'flex',
			justifyContent: 'space-between',
			marginBottom: 8
		} as React.CSSProperties,
		phraseItem: {
			display: 'flex',
			alignItems: 'center',
			padding: 8,
			paddingTop: 3,
			paddingBottom: 3,
			border: `1px solid ${theme.border_06}`,
			borderRadius: 31,
			width: '48%'
		} as React.CSSProperties,
		phraseIndex: {
			color: theme.text_08,
			padding: 5,
			marginRight: 5,
			fontSize: 16
		} as React.CSSProperties,
		phraseText: {
			color: theme.text_15,
			fontSize: 18,
			paddingLeft: 4
		} as React.CSSProperties,
		copyButton: {
			width: "100%",
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			padding: 12,
			border: `1px solid ${theme.border_03}`,
			borderRadius: 10,
			marginBottom: 32
		} as React.CSSProperties,
		copyButtonText: {
			fontSize: 16,
			color: theme.text_08
		} as React.CSSProperties,
		confirmButton: {
			width: "100%",
			backgroundColor: theme.button_02,
			padding: "15px",
			borderRadius: 10,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		} as React.CSSProperties,
		confirmButtonText: {
			fontSize: 16,
			color: theme.text_01
		}
	}

	return (
		<div className='container' style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
			<div style={styles.content}>
				{/* 구문 페어 렌더링 */}
				<div style={styles.phraseContainer}>{renderPhrasePairs()}</div>

				{/* 복사 버튼 */}
				<CustomButton
					containerStyle={{ ...styles.copyButton, borderColor: theme.primary }}
					textStyle={{ ...styles.copyButtonText, color: theme.primary }}
					onPress={() => { }
						// handleCopyToClipboard
					}
					iconSize={15}
					text={t('security_recovery_message_02')}
					iconName="copy-outline"
					withBackground={true}
					backgroundColor={theme.withoutBackgroundTouchBackgroundColor}
				/>
			</div>

			{/* 확인 버튼 */}
			<CustomButton
				containerStyle={{ ...styles.confirmButton, backgroundColor: theme.primary }}
				textStyle={styles.confirmButtonText}
				onPress={() => handleDone()}
				text={t('security_recovery_message_03')}
				withBackground={true}
				backgroundColor={theme.primaryTouchBackgroundColor}
			/>
		</div>
	)
}

export default SecuritySecretPhraseConfirmation

import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeContext } from '../../context/ThemeContext';
import { Check } from 'lucide-react';
import { ethers } from 'ethers'

import DatePicker from 'react-datepicker'; // react-datepicker 사용
import 'react-datepicker/dist/react-datepicker.css'; // DatePicker 스타일 추가

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
import Container from '../Common/Container';
import CourierText from '../../styles/CourierText';
import CustomListCardWithIcon from '../Componentization/Card/CustomListCardWithIcon';
import CustomListCard from '../Componentization/Card/CustomListCard';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkHeaderText, checkPincode, RootState } from '../../store';
import { getCustomChainTransaction } from '../../hooks/useFetchBalance';

import useWallet from '../../hooks/useWallet';
import useEthereum from '../../hooks/useEthereum';
import useEthereumOther from '../../hooks/useEthereumOther';
import useSecureStorage from '../../hooks/useSecureStorage';
import MainPageHeader from '../../nav/MainPageHeader';
import NavigationBar from '../../nav/NavigationBar';
import i18next from 'i18next'
import moment from 'moment'

import { ParamType } from 'ethers/lib/utils'
import CustomTextInput from '../Componentization/CustomTextInput';
import PinCodeScreen from '../Authentication/PinCodeScreen';
import Spinner from '../Componentization/Modal/Spinner';

const ONE_SECOND_IN_MS = 1000

const WithdrawScreen: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const { t } = useTranslation();

	const { getWalletAddress } = useSecureStorage()

	const [withdrawAddress, setWithdrawAddress] = useState('')
	const [withdrawAmount, setWithdrawAmount] = useState('')
	const [walletAddress, setWalletAddress] = useState('')
	const [termsAccepted, setTermsAccepted] = useState(false)
	const [isProcessing, setIsProcessing] = useState(false)
	const [isKeyboardVisible, setKeyboardVisible] = useState(false)
	const [gasFee, setGasFee] = useState<string | undefined>('0.0')
	const [isAddressValid, setIsAddressValid] = useState(false)
	const [isAmountValid, setIsAmountValid] = useState(false)
	const [addressHelperMessage, setAddressHelperMessage] = useState('')
	const [amountHelperMessage, setAmountHelperMessage] = useState('')

	const [showPinCodeAuth, setShowPinCodeAuth] = useState(false)

	const location = useLocation();
	const [token, setToken] = useState(location.state === null ? null : location.state.token);
	useEffect(() => {
		if (location.state) {
			setToken(location.state.token);
		}
	}, [location.state]);

	const { sendTransactionWithPrivateKey, estimateGas, getBalance, balance } = useEthereum()
	const { sendTransactionWithPrivateKeyETH, getEthBalance, getUsdtBalance, estimateGasEth, usdtBalance, ethBalance } = useEthereumOther()
	const { retrieveEncryptedData } = useSecureStorage()

	const [privateKey, setPrivateKey] = useState<string | null>('')

	useEffect(()=>{
		dispatch(checkHeaderText({ "link": "WithdrawScreen", "title": t('stack_header_message_03') }))
	},[])

	useEffect(() => {
		const fetchAddress = async () => {
			const address: any = await getWalletAddress()
			if (address) {
				if(token.symbol === 'USDT') {
					const estimatedGasFee = await estimateGasEth()
					setGasFee(estimatedGasFee)	
				} else {
					const estimatedGasFee = await estimateGas()
					setGasFee(estimatedGasFee)	
				}
				
				setWalletAddress(address)
			}
		}
		fetchAddress()
	}, [estimateGas, getWalletAddress])

	useEffect(() => {
		validateAddress(withdrawAddress)
	}, [withdrawAddress])

	useEffect(() => {
		validateAmount(withdrawAmount)
	}, [withdrawAmount])

	useEffect(() => {
		const fetchAccountDetails = async () => {
			try {
				const walletAddress = await getWalletAddress()
				if (walletAddress) {
					setWalletAddress(walletAddress)
					if (token.symbol === 'USDT') {
						await Promise.all([getUsdtBalance(walletAddress, token.address), getEthBalance(walletAddress)])
					} else {
						await Promise.all([getBalance(walletAddress)])
					}
					console.log(`ethBalance : ${ethBalance}`)
				}
			} catch (error) {
				console.error('Error fetching account details:', error)
			}
		}

		fetchAccountDetails()
	}, [getUsdtBalance, getEthBalance, token.symbol])

	// const handlePasteAddress = async () => {
	// 	const clipboardContent = await Clipboard.getString()
	// 	setWithdrawAddress(clipboardContent)
	// 	Toast.show({
	// 		type: 'success',
	// 		text1: i18next.t('template_withdraw_screen_message_15'),
	// 		position: 'bottom',
	// 		visibilityTime: 500
	// 	})
	// }

	const validateAddress = (address: string) => {
		let isValid = true
		let message = ''

		if (address.length === 0) {
			isValid = false
			message = i18next.t('template_withdraw_screen_message_16')
		} else if (address.length !== 42 || !address.startsWith('0x')) {
			isValid = false
			message = i18next.t('template_withdraw_screen_message_17')
		}

		setIsAddressValid(isValid)
		setAddressHelperMessage(message)
	}

	const validateAmount = (amount: string) => {
		let isValid = true
		let message = ''

		const tokenBalance = Number(token.balance)
		const gasPrice = Number(gasFee)

		const availableBalance = tokenBalance
		const fee = gasPrice ? gasPrice : 0
		const withdrawAmount = parseFloat(amount)

		if (amount.length === 0) {
			isValid = false
			message = i18next.t('template_withdraw_screen_message_18')
		} else if (isNaN(withdrawAmount) || withdrawAmount <= 0) {
			isValid = false
			message = i18next.t('template_withdraw_screen_message_19')
		} else if (withdrawAmount > availableBalance - fee) {
			isValid = false
			message = i18next.t('template_withdraw_screen_message_20')
		}

		setIsAmountValid(isValid)
		setAmountHelperMessage(message)
	}

	const verificationBeforeWithdrawal = async () => {
		// ETH USDT의 경우
		console.log(`ethBalance : ${ethBalance}`)
		
		if(token.symbol === 'USDT' && (0 == ethBalance || null == ethBalance)) {
			const text = i18next.t('template_withdraw_screen_message_32') + 
						' ETH ' + 
						i18next.t('template_withdraw_screen_message_33')
			alert(text)
			return
		} else if(token.symbol !== 'USDT' && (0 == balance || null == balance)) {
			const text = i18next.t('template_withdraw_screen_message_32') + 
						' ' + process.env.NETWORK_CURRENCY + ' ' +
						i18next.t('template_withdraw_screen_message_33')
			alert(text)
			return
		}

		setShowPinCodeAuth(true)
	}

	const handleWithdraw = async (pin: string) => {
		if (!termsAccepted) {
			alert(i18next.t('template_withdraw_screen_message_21'))
			return
		}

		// 비밀키 데이터 복호화
		let privateKey: any
		try {
			const data = await retrieveEncryptedData(walletAddress, pin)
			privateKey = data.privateKey

			if (!privateKey) {
				alert(i18next.t('template_withdraw_screen_message_22'))
				return
			}
		} catch (error) {
			console.log('error handleWithdraw', error)
			alert(i18next.t('template_withdraw_screen_message_22'))
			return
		}

		setIsProcessing(true)

		try {
			let receipt
			if (token.symbol === 'USDT') {
				receipt = await sendTransactionWithPrivateKeyETH(
					privateKey,
					withdrawAddress,
					withdrawAmount,
					token.symbol,
					token.address || ''
				)
			} else {
				receipt = await sendTransactionWithPrivateKey(
					privateKey,
					withdrawAddress,
					withdrawAmount,
					token.symbol,
					token.address || ''
				)
			}
			setIsProcessing(false)
			// Toast.show({
			// 	type: 'success',
			// 	text1: i18next.t('template_withdraw_screen_message_23'),
			// 	position: 'bottom',
			// 	visibilityTime: 500
			// })
			// alert(i18next.t('template_withdraw_screen_message_23'))

			const options: Intl.DateTimeFormatOptions = {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric',
				hour12: false
			};

			const padZero = (value: number) => {
				return value < 10 ? `0${value}` : value;
			}

			const date = new Date();

			// 각 시간 단위의 값을 얻어옵니다.
			const year = date.getFullYear();
			const month = padZero(date.getMonth() + 1);
			const day = padZero(date.getDate());
			const hours = padZero(date.getHours());
			const minutes = padZero(date.getMinutes());
			const seconds = padZero(date.getSeconds());

			const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

			const withdrawData = {
				address: withdrawAddress,
				amount: `${withdrawAmount}`,
				fee: `${gasFee}`,
				date: formattedDate,
				txid: receipt.transactionHash,
				symbol: token.symbol
			}

			setTimeout(() => {
				// @ts-ignore
				navigate('/WithdrawCompleteScreen', { state: { withdrawResult: withdrawData } })
			}, 1000)
		} catch (error: any) {
			setIsProcessing(false)
			if (error.code === 'INSUFFICIENT_FUNDS') {
				// Toast.show({
				// 	type: 'error',
				// 	text1: i18next.t('template_withdraw_screen_message_20'),
				// 	position: 'bottom',
				// 	visibilityTime: 500
				// })
				alert(i18next.t('template_withdraw_screen_message_20'))
			} else {
				// Toast.show({
				// 	type: 'error',
				// 	text1: i18next.t('template_withdraw_screen_message_24'),
				// 	position: 'bottom',
				// 	visibilityTime: 500
				// })
				alert(i18next.t('template_withdraw_screen_message_24'))
			}
		} finally {
		}
	}

	const handleMaxAmount = () => {
		const availableBalance = parseFloat(token.balance.toString())
		const fee = gasFee ? parseFloat(gasFee) : 0
		const margin = parseFloat('0.0001');
		const maxAmount = availableBalance - (fee + margin)

		setWithdrawAmount(maxAmount > 0 ? maxAmount.toFixed(6) : '0.0')
	}

	const isWithdrawButtonEnabled = isAddressValid && isAmountValid && termsAccepted

	const handlePinCodeintAuthSuccess = (pin: string) => {
		setShowPinCodeAuth(false)
		handleWithdraw(pin)
	}

	const formatNumberWithCommas = (number: number): string => {
		// null이나 NaN인 경우 "0.000000" 반환
		if (number === null || isNaN(number)) {
			return "0.000000";
		}
		
		const fixedNumber = number.toFixed(6)
		const [integerPart, decimalPart] = fixedNumber.split('.')
		const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		return `${formattedIntegerPart}.${decimalPart}`
	}

	const styles = {
		container: {
			backgroundColor: theme.background_03,
			padding: "60px 0px 0",
		} as React.CSSProperties,
		contentContainer: {
			flexGrow: 1,
			padding: "0 20px",
			paddingTop: 30,
			borderTop: `1px solid ${theme.border_04}`,
			borderLeft: `1px solid ${theme.border_04}`,
			borderRight: `1px solid ${theme.border_04}`,
			borderTopLeftRadius: 30,
			borderTopRightRadius: 30,
			width: "95%",
			boxSizing: "border-box"
		} as React.CSSProperties,
		label: {
			width: "100%",
			color: theme.text_05,
			fontSize: 18
		} as React.CSSProperties,
		inputRow: {
			display: 'flex',
			alignItems: 'center',
			marginTop: 26
		} as React.CSSProperties,
		inputAddress: {
			width: "100%",
			borderTop: "none",
			borderRight: "none",
			borderLeft: "none",
			borderBottom: `1px solid ${theme.border_04}`,
			outline: "none",
			fontSize: 16,
			height: "40px",
			background: "inherit"
		} as React.CSSProperties,
		helperText: {
			color: 'red',
			fontSize: 12,
			marginTop: 5,
			marginLeft: 0,
			height: 1
		} as React.CSSProperties,
		iconRow: {
			display: 'flex',
			justifyContent: 'flex-start',
			marginBottom: 40,
			marginTop: 13
		} as React.CSSProperties,
		iconButton: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: "100%"
		} as React.CSSProperties,
		iconText: {
			marginLeft: 5,
			fontSize: 13
		} as React.CSSProperties,
		amountRow: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center'
		} as React.CSSProperties,
		inputAmount: {
			width: "100%",
			borderTop: "none",
			borderRight: "none",
			borderLeft: "none",
			borderBottom: `1px solid ${theme.border_04}`,
			outline: "none",
			fontSize: 16,
			height: "40px",
			background: "inherit"
		} as React.CSSProperties,
		currency: {
			position: 'absolute',
			right: 15,
			fontSize: 16,
			color: theme.text_04
		} as React.CSSProperties,
		maxAmountText: {
			fontSize: 14,
			color: theme.text_08,
			textDecorationLine: 'underline',
			textAlign: "right"
		} as React.CSSProperties,
		infoBox: {
			backgroundColor: theme.card_background_01,
			borderRadius: 10,
			padding: 10,
			margin: "10px 0"
		} as React.CSSProperties,
		infoContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			padding: "5px",
		} as React.CSSProperties,
		infoText: {
			fontSize: 14,
			color: theme.text_03
		} as React.CSSProperties,
		infoAmount: {
			fontSize: 14,
			color: theme.text_03,
			display: "flex",
			gap: "10px"
		} as React.CSSProperties,
		infoSymbol: {
			fontSize: 14,
			color: theme.text_03
		} as React.CSSProperties,
		footerContainer: {
			padding:"0 24px 25px",
			width:"100%",
			boxSizing:"border-box"
		} as React.CSSProperties,
		checkboxContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start'
		} as React.CSSProperties,
		checkboxBase: {
			width: "17px",
			height: "17px",
			borderRadius: 5,
			border: `1px solid ${theme.border_05}`,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginRight: 5
		} as React.CSSProperties,
		checkboxText: {
			fontSize: 16,
			color: theme.text_09,
			marginLeft: 5
		} as React.CSSProperties,
		footer: {
			marginTop: 15
		} as React.CSSProperties,
		withdrawButton: {
			width: "100%",
			backgroundColor: theme.button_02,
			padding: 15,
			borderRadius: 10,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: 50
		} as React.CSSProperties,
		withdrawButtonText: {
			color: 'white',
			fontSize: 16
		} as React.CSSProperties,
		modalBackground: {
			display: "flex",
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: theme.modal_background_01,
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%"
		} as React.CSSProperties,
		activityIndicatorWrapper: {
			backgroundColor: theme.card_background_02,
			height: "100%",
			width: "100%",
			borderRadius: 10,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		} as React.CSSProperties,
		processingText: {
			marginTop: 10,
			fontSize: 16
		} as React.CSSProperties,
		boxIconScan: {
			width: 15,
			height: 15,
			marginLeft: 10
		} as React.CSSProperties,
		boxIconPaste: {
			width: 15,
			height: 15
		} as React.CSSProperties,
	}
	
	return (
		<div className='container' style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
			<div style={{ width: '100%', display: 'flex', flexDirection: "column", alignItems: "center" }}>
				<div style={{ padding: '10px 24px', marginBottom: '15px 0', width: "100%", boxSizing: "border-box" }}>
					<CustomText style={{ color: theme.text_10, fontSize: '14px' }}>
						{i18next.t('template_withdraw_screen_message_01')}
					</CustomText>
				</div>
				<div style={styles.contentContainer}>
					<div>
						<CustomText weight="medium" style={{ ...styles.label, color: theme.primaryText }}>
							{i18next.t('template_withdraw_screen_message_02')}
						</CustomText>
						<div style={styles.inputRow}>
							<input
								value={withdrawAddress}
								onChange={(e) => setWithdrawAddress(e.target.value)}
								placeholder={i18next.t('template_withdraw_screen_message_03')}
								style={{
									...styles.inputAddress,
									borderBottomColor: theme.textFieldBorderColor,
									color: theme.primaryText,
								}}
								aria-invalid={!isAddressValid}
							/>
						</div>
						<p style={styles.helperText}>
							{!isAddressValid && withdrawAddress.length > 0 && (
								addressHelperMessage
							)}
						</p>
						<div style={styles.iconRow}>
							<button style={styles.iconButton}
							// onClick={handleQRScan}
							>
								{/* QR 코드 인식 버튼 */}
								{/* <img
									src={'/img/icon_withdraw_qr_scan.png'}
									alt="QR Scan"
									style={styles.boxIconScan}
								/>
								<CustomText style={{ ...styles.iconText, color: theme.text_10 }}>
									{i18next.t('template_withdraw_screen_message_04')}
								</CustomText> */}
							</button>
							<button style={styles.iconButton}
							//  onClick={handlePasteAddress}
							>
								{/* 붙여넣기 버튼 */}
								{/* <img
									src={'/img/icon_withdraw_paste.png'}
									alt="Paste Address"
									style={styles.boxIconPaste}
								/>
								<CustomText style={{ ...styles.iconText, color: theme.text_10 }}>
									{i18next.t('template_withdraw_screen_message_05')}
								</CustomText> */}
							</button>
						</div>
						<div style={styles.amountRow}>
							<CustomText weight="medium" style={{ ...styles.label, color: theme.primaryText }}>
								{i18next.t('template_withdraw_screen_message_06')}
							</CustomText>
							<button
								onClick={handleMaxAmount}
								style={{ background: 'none', border: 'none', cursor: 'pointer', width: "100%" }}>
								<CustomText style={{ ...styles.maxAmountText, color: theme.primary }}>
									{i18next.t('template_withdraw_screen_message_07')}
								</CustomText>
							</button>
						</div>
						<div style={styles.inputRow}>
							<input
								value={withdrawAmount}
								onChange={(e) => setWithdrawAmount(e.target.value)}
								placeholder={i18next.t('template_withdraw_screen_message_08')}
								style={{
									...styles.inputAmount,
									borderBottomColor: theme.textFieldBorderColor,
									color: theme.primaryText,
								}}
								aria-invalid={!isAmountValid}
								maxLength={20}
							/>
							{/* <CustomText weight="medium" style={styles.currency}>
								{token.symbol}
							</CustomText> */}
						</div>
						{!isAmountValid && withdrawAmount.length > 0 && (
							<p style={styles.helperText}>{amountHelperMessage}</p>
						)}
						<div style={styles.infoBox}>
							<div style={styles.infoContainer}>
								<CustomText style={styles.infoText}>
									{i18next.t('template_withdraw_screen_message_09')}
								</CustomText>
								<CourierText weight="bold" style={styles.infoAmount}>
									{formatNumberWithCommas(Number(token.balance))}{' '}
									<CustomText weight="medium" style={styles.infoSymbol}>
										{token.symbol}
									</CustomText>
								</CourierText>
							</div>
							{token.symbol === 'USDT' && (
								<div style={styles.infoContainer}>
									{0 == (ethBalance ?? 0) ? (
										<CustomText style={[styles.infoText, {color: theme.text_11}]}>
											{i18next.t('template_withdraw_screen_message_13')}
										</CustomText>
									)
									:(
										<CustomText style={[styles.infoText]}>
											{i18next.t('template_withdraw_screen_message_13')}
										</CustomText>
									)}

									{0 == (ethBalance ?? 0) ? (
										<CourierText weight='bold' style={[styles.infoAmount, {color: theme.text_11}]}>
											{formatNumberWithCommas(Number(ethBalance))}{' '}
											<CustomText weight='medium' style={[styles.infoSymbol, {color: theme.text_11}]}>
												ETH
											</CustomText>
										</CourierText>
									)
									:(
										<CourierText weight='bold' style={[styles.infoAmount]}>
											{formatNumberWithCommas(Number(ethBalance))}{' '}
											<CustomText weight='medium' style={styles.infoSymbol}>
												ETH
											</CustomText>
										</CourierText>
									)}

								</div>
							)}
							<div style={styles.infoContainer}>
								<CustomText style={styles.infoText}>
									{i18next.t('template_withdraw_screen_message_10')}
								</CustomText>
								<CourierText weight="bold" style={styles.infoAmount}>
									{formatNumberWithCommas(Number(gasFee))}{' '}
									<CustomText weight="medium" style={styles.infoSymbol}>
										{token.name === 'USDT' ? 'ETH' : process.env.REACT_APP_NETWORK_CURRENCY}
									</CustomText>
								</CourierText>
							</div>
						</div>
					</div>

				</div>
			</div>
			{!isKeyboardVisible && (
				<div style={styles.footerContainer}>
					<div style={styles.checkboxContainer}>
						<div
							style={{
								...styles.checkboxBase,
								backgroundColor: termsAccepted ? theme.primary : theme.check_background_01,
								borderColor: termsAccepted
									? theme.enableWithdrawCheckBoxBorderColor
									: theme.disableWithdrawCheckBoxBorderColor,
							}}
							onClick={() => setTermsAccepted(!termsAccepted)}
						>
							{termsAccepted &&
								<Check
									name="checkmark"
									size={18}
									color={termsAccepted ? theme.enableCheckIconColor : theme.disableCheckIconColor}
								/>
							}
						</div>
						<CustomText style={{ ...styles.checkboxText, color: theme.primaryText }}>
							{i18next.t('template_withdraw_screen_message_11')}
						</CustomText>
					</div>
					<div style={styles.footer}>
						<CustomButton
							containerStyle={{ ...styles.withdrawButton, backgroundColor: theme.primary }}
							textStyle={styles.withdrawButtonText}
							onPress={() => { verificationBeforeWithdrawal() }}
							text={i18next.t('template_withdraw_screen_message_12')}
							disabled={!isWithdrawButtonEnabled}
							disabledStyle={{ backgroundColor: theme.button_03 }}
							withBackground={true}
							backgroundColor={theme.primaryTouchBackgroundColor}
						/>
					</div>
				</div>
			)}
			{isProcessing && (
				<Spinner />
			)}

			{/* {isProcessing && (
			<Modal transparent={true} visible={isProcessing}>
				<View style={styles.modalBackground}>
					<View style={styles.activityIndicatorWrapper}>
						<ActivityIndicator size={50} color={theme.primary} />
						<CustomText style={styles.processingText}>{i18next.t('template_withdraw_screen_message_25')}</CustomText>
					</View>
				</View>
			</Modal>
			)} */}

			{showPinCodeAuth && (
				<div style={styles.modalBackground}>
					<PinCodeScreen onSuccess={handlePinCodeintAuthSuccess} unlock={false} />
				</div>
			)}
		</div >
	)
}

export default WithdrawScreen

import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import { Check } from 'lucide-react';

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
import Container from '../Common/Container';
import CourierText from '../../styles/CourierText';
import CustomListCardWithIcon from '../Componentization/Card/CustomListCardWithIcon';
import CustomListCard from '../Componentization/Card/CustomListCard';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkHeaderText, checkPincode, RootState } from '../../store';

import useWallet from '../../hooks/useWallet';
import useWalletSetup from '../../hooks/useWalletSetup';
import useEthereum from '../../hooks/useEthereum';
import useEthereumOther from '../../hooks/useEthereumOther';
import useSecureStorage from '../../hooks/useSecureStorage';
import useDefaultNetworkAndToken from '../../hooks/useDefaultNetworkAndToken';

import MainPageHeader from '../../nav/MainPageHeader';
import NavigationBar from '../../nav/NavigationBar';

interface Asset {
	id: number
	name: string
	symbol: string
	balance: string
	icon: any
	address: string | null
	networkid: string
	chainid: string
	networkname: string
	useswap: boolean
}

interface Event {
	id: number;
	title: string;
	contents: string;
	bannerImageUrl: string;
	extUrl: string;
	appScreen: string;
	donationIdx: string;
	isActive: string;
}

interface Donation {
	id: number;
	code: string;
	title: string;
	imageUrl: string;
	extUrl: string;
	text: string;
	check: string;
	completeImageUrl: string;
	completeTitle: string;
	completeContents: string;
	is_active: string;
}
let NETWORK_CURRENCY = process.env.REACT_APP_NETWORK_CURRENCY;

const BalanceScreen = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const { t } = useTranslation();
	// const languageContext = useContext(LanguageContext)

	const { getWalletAddress } = useSecureStorage()
	const { balance, getBalance, getTokenBalance } = useEthereum()
	const { getUsdtBalance, usdtBalance } = useEthereumOther()

	const [accountAddress, setAccountAddress] = useState('')
	const [coinBalance, setCoinBalance] = useState('0.000000')
	const [buffBalance, setBuffBalance] = useState('0.000000')
	const [wowBalance, setWowBalance] = useState('0.000000')
	// const [buffBalance, setBuffBalance] = useState('0.000000')
	// const [wowBalance, setWowBalance] = useState('0.000000')
	const [activeTab, setActiveTab] = useState(NETWORK_CURRENCY)
	const [refreshing, setRefreshing] = useState(false)
	// const [refreshing, setRefreshing] = useState(false)
	const { networkInfo, tokenInfo, userTokenInfo, otherTokenInfo, searchToken, searchNetwork, forceTokenDataUpdate } = useDefaultNetworkAndToken();
	const { eventInfo, donationInfo, searchEvent, searchDonation, forceEventUpdate } = useWalletSetup()
	const [defaultAssetsList, setDefaultAssetsList] = useState<Asset[]>([]);
	const [assets, setAssets] = useState<Asset[]>([]);
	const [events, setEvents] = useState<Event[]>([]);
	const [donationUI, setDonationUI] = useState<Donation[]>([]);
	const [defaultOtherAssetsList, setDefaultOtherAssetsList] = useState<Asset[]>([]);
	const [otherAssets, setOtherAssets] = useState<Asset[]>([]);

	// const imageMap = {
	// 	'@assets/img/icon_buff.png': require('@assets/img/icon_buff.png'),
	// 	'@assets/img/icon_qco.png': require('@assets/img/icon_qco.png'),
	// 	'@assets/img/icon_tether_usdt.png': require('@assets/img/icon_tether_usdt.png')
	// };

	// if (!languageContext) return null
	// const { language, changeLanguage } = languageContext

	useEffect(() => {
		const initializeForceUpdate = async () => {
			await forceTokenDataUpdate()
			await forceEventUpdate();
		};

		initializeForceUpdate()
	}, []);

	useEffect(() => {
		dispatch(checkHeaderText({ "link": "BalanceScreen", "title": t('template_balance_screen_message_01') }))
	}, [])

	// defaultAsset 초기화
	useEffect(() => {
		const initializeAssets = () => {
			const defaultAssets = tokenInfo.map((token, index) => ({
				id: index + 1,
				name: token.name,
				symbol: token.symbol,
				balance: '0.000000',
				icon: token.icon,
				address: token.contract,
				networkid: token.networkid,
				chainid: token.chainid,
				networkname: token.networkname,
				useswap: token.useswap,
			}));

			const defaultLength = defaultAssets.length || 1
			const userAssets = userTokenInfo.map((token, index) => ({
				id: index + defaultLength + 1,
				name: token.name,
				symbol: token.symbol,
				balance: '0.000000',
				icon: token.icon,
				address: token.contract,
				networkid: token.networkid,
				chainid: token.chainid,
				networkname: token.networkname,
				useswap: token.useswap,
			}));

			setDefaultAssetsList([
				{
					id: 0,
					name: NETWORK_CURRENCY || 'QCO',
					symbol: NETWORK_CURRENCY || 'QCO',
					balance: '0.000000',
					icon: '/img/icon_qco.png',
					address: null,
					networkid: process.env.REACT_APP_NETWORK_ID!,
					chainid: process.env.CHAIN_ID!,
					networkname: process.env.NETWORK_NAME!,
					useswap: false,
				},
				...defaultAssets,
				...userAssets
			]);

			const defaultOtherAssets = otherTokenInfo.map((token, index) => ({
				id: index,
				name: token.name,
				symbol: token.symbol,
				balance: '0.000000',
				icon: token.icon,
				address: token.contract,
				networkid: token.networkid,
				chainid: token.chainid,
				networkname: token.networkname,
				useswap: token.useswap,
			}));

			setDefaultOtherAssetsList([
				...defaultOtherAssets
			]);
		};

		initializeAssets();
	}, [tokenInfo, otherTokenInfo]);

	useEffect(() => {
		const initializeEvents = () => {
			let defaultEvents = null
			let defaultDonations = null
			let language = 'en'
			if ('en' == language) {
				defaultEvents = eventInfo.map((event, index) => ({
					id: event.idx,
					title: event.title_en,
					contents: event.contents_en,
					bannerImageUrl: event.banner_image_en_url,
					extUrl: event.ext_url,
					appScreen: event.app_screen,
					donationIdx: event.donation_idx,
					isActive: event.is_active
				})
				);

				defaultDonations = donationInfo.map((donation, index) => ({
					id: donation.idx,
					code: donation.code,
					title: donation.title_en,
					imageUrl: donation.image_en_url,
					extUrl: donation.ext_url,
					text: donation.text_en,
					check: donation.check_en,
					completeImageUrl: donation.complete_image_url,
					completeTitle: donation.complete_title_en,
					completeContents: donation.complete_contents_en,
					is_active: donation.is_active,
				})
				);

			} else {
				defaultEvents = eventInfo.map((event, index) => ({
					id: event.idx,
					title: event.title_ko,
					contents: event.contents_ko,
					bannerImageUrl: event.banner_image_ko_url,
					extUrl: event.ext_url,
					appScreen: event.app_screen,
					donationIdx: event.donation_idx,
					isActive: event.is_active
				})
				);

				defaultDonations = donationInfo.map((donation, index) => ({
					id: donation.idx,
					code: donation.code,
					title: donation.title_ko,
					imageUrl: donation.image_ko_url,
					extUrl: donation.ext_url,
					text: donation.text_ko,
					check: donation.check_ko,
					completeImageUrl: donation.complete_image_url,
					completeTitle: donation.complete_title_ko,
					completeContents: donation.complete_contents_ko,
					is_active: donation.is_active,
				})
				);
			}

			setEvents(defaultEvents);
			setDonationUI(defaultDonations);
		};

		initializeEvents();
	}, [eventInfo, donationInfo]);

	useEffect(() => {
		const fetchAccountDetails = async () => {
			const walletAddress = await getWalletAddress()

			if (walletAddress && 0 < defaultAssetsList.length) {

				const response = await getBalance(walletAddress)

				setCoinBalance(parseFloat(response).toFixed(6))

				// QCO 잔액 업데이트 (index 0)
				defaultAssetsList[0].balance = parseFloat(response).toFixed(6)

				// 나머지 토큰들의 잔액 업데이트
				const updatedAssets = await Promise.all(
					defaultAssetsList.slice(1).map(async (asset, index) => {
						if (asset.address) {
							const balance = await getTokenBalance(walletAddress, asset.address)
							return {
								...asset,
								balance: parseFloat(balance).toFixed(6),
								icon: asset.icon
							}
						}
						return asset
					})
				)

				// QCO 와 업데이트된 다른 토큰들을 합쳐서 새로운 배열 생성
				const newDefaultAssetsList = [
					defaultAssetsList[0],
					...updatedAssets
				]

				setAssets(newDefaultAssetsList);

				// other 네트워크
				const updatedOtherAssets = await Promise.all(
					defaultOtherAssetsList.map(async (asset, index) => {
						if (asset.address) {
							const balance = await getUsdtBalance(walletAddress, asset.address)
							return {
								...asset,
								balance: parseFloat(balance).toFixed(6)
							}
						}
						return asset
					})
				)

				// otherAsset 배열
				const newDefaultOtherAssetsList = [
					...updatedOtherAssets
				]

				setOtherAssets(newDefaultOtherAssetsList);

				// await getUsdtBalance(walletAddress)
			}
		}
		fetchAccountDetails()
	}, [
		// getBalance,
		// getTokenBalance,
		defaultAssetsList,
		// defaultOtherAssetsList,
		// refreshing
	])

	// const copyToClipboard = () => {
	// 	Clipboard.setString(accountAddress)
	// }

	// 토큰 클릭 wallet 페이지 이동
	const handleTokenPress = () => {
		if (null != otherAssets[0]) {
			navigate('/WalletScreen', {
				state: { token: otherAssets[0] }
			})
		}
	}

	// 이벤트 버튼 클릭 페이지 이동
	const handleEventPress = (eventData: Event) => {
		// eventData의 donationIdx와 일치하는 donationUI 데이터 검색
		const foundDonation = donationUI.find(donation => donation.id == Number(eventData.donationIdx));

		if (null != otherAssets[0] && null != foundDonation) {
			navigate('/DonationScreen', {
				state: { token: otherAssets[0], donation: foundDonation }
			})
		}
	}

	const formatNumberWithCommas = (number: number): string => {
		const fixedNumber = number.toFixed(6)
		const [integerPart] = fixedNumber.split('.')
		const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

		return `${formattedIntegerPart}`
	}

	// const handleIconPress = () => {
	// 	// 더보기 스택으로 이동시 강제로 첫번째 스크린 설정
	// 	navigation.dispatch(
	// 		CommonActions.reset({
	// 			index: 0,
	// 			routes: [{ name: 'More', params: { screen: 'SeeMoreScreen' } }]
	// 		})
	// 	);

	// 	// @ts-ignore
	// 	// navigation.navigate('More', {
	// 	// 	screen: 'SeeMoreScreen'
	// 	// })
	// }

	const getCurrentAsset = () => {
		return assets.find(asset => asset.symbol === activeTab)
	}

	const currentAsset = getCurrentAsset()
	const currentBalance = parseFloat(currentAsset?.balance || '0')
	const currentIcon = currentAsset?.icon

	// const onRefresh = useCallback(() => {
	// 	setRefreshing(true)
	// 	setTimeout(() => {
	// 		setRefreshing(false)
	// 	} as React.CSSProperties, 2000)
	// } as React.CSSProperties, [])


	const styles = {
		container: {
			// paddingBottom: "70px"
		} as React.CSSProperties,
		cardContainer: {
			backgroundColor: theme.background_02,
			borderRadius: 12,
			padding: 16,
			marginBottom: 15
		} as React.CSSProperties,
		totalBalanceCard: {
			display: "flex",
			alignItems: 'center',
			marginBottom: 5
		} as React.CSSProperties,
		iconContainer: {
			width: 35,
			height: 35,
			borderRadius: 25,
			backgroundColor: theme.img_background_01,
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center',
			marginRight: 15
		} as React.CSSProperties,
		balanceIcon: {
			width: 38,
			height: 38
		} as React.CSSProperties,
		balanceInfo: {
			display: "flex",
			flexDirection: 'column'
		} as React.CSSProperties,
		balanceRow: {
			display: "flex",
			alignItems: 'center',
		} as React.CSSProperties,
		balanceRowPressable: {
			display: "flex",
			alignItems: 'center',
		} as React.CSSProperties,
		balanceText: {
			fontSize: 26,
			color: theme.text_01,
			height: 28,
			// paddingTop: 10,
			cursor: "pointer"
		} as React.CSSProperties,
		balanceSubText: {
			fontSize: 14,
			color: theme.text_02,
		} as React.CSSProperties,
		arrowIcon: {
			width: 10,
			height: 16,
			marginLeft: 8,
		} as React.CSSProperties,
		sectionTitle: {
			fontSize: 15,
			color: theme.text_04,
			marginBottom: 16
		} as React.CSSProperties,
		tabContainer: {
			display: "flex",
			marginBottom: 20,
			borderRadius: 10,
			justifyContent: 'flex-start',
		} as React.CSSProperties,
		tabButton: {
			marginRight: 20
		} as React.CSSProperties,
		tabText: {
			color: theme.text_03,
			fontSize: 16,
			marginTop: 4,
			cursor: "pointer"
		} as React.CSSProperties,
		tabText2: {
			color: theme.text_03,
			fontSize: 20,
			cursor: "pointer"
		} as React.CSSProperties,
		activeTabText: {
			color: theme.text_01
		} as React.CSSProperties,
		buttonRow: {
			display: "flex",
			marginTop: 20,
			gap: 11,
			width: "100%"
		} as React.CSSProperties,
		buttonLeftRowContainer: {
			display: "flex",
			backgroundColor: theme.button_01,
			borderRadius: 10,
			height: 42,
			width: "70%"
		} as React.CSSProperties,
		buttonRightRowContainer: {
			backgroundColor: theme.button_01,
			borderRadius: 10,
			height: 42,
			width: "30%"
		} as React.CSSProperties,
		buttonStyle: {
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center',
			background: "inherit"
		} as React.CSSProperties,
		sendButtonStyle: {
			borderTopLeftRadius: 10,
			borderBottomLeftRadius: 10,
			padding: "10px 10px",
			width: "45%"
		} as React.CSSProperties,
		receiveButtonStyle: {
			borderTopRightRadius: 10,
			borderBottomRightRadius: 10,
			padding: "10px 10px",
			width: "55%"
		} as React.CSSProperties,
		swapButtonStyle: {
			borderRadius: 10,
			padding: "10px 10px"
		} as React.CSSProperties,
		divider: {
			width: 1,
			backgroundColor: theme.divider_background_01
		} as React.CSSProperties,
		buttonTextStyle: {
			fontSize: 14,
		} as React.CSSProperties,
		otherChainStyle: {
			color: theme.text_01,
			fontSize: 18
		} as React.CSSProperties,
		otherChainUnitStyle: {
			color: theme.text_01,
			fontSize: 15
		} as React.CSSProperties,
		scrollView: {
			display: "flex",
		} as React.CSSProperties,
		scrollContent: {
			display: 'inline-flex',
		} as React.CSSProperties,
	}

	return (
		<>
			<MainPageHeader />
			{/* <NavigationBar /> */}
			{/* <PinCodeScreenModal setPincode={setPincode}/> */}
			<div className='container' style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
				<div style={{ width: "100%" }}>
					<div style={styles.tabContainer}>
						{/* 첫 번째 Tab */}
						<div
							style={styles.tabButton}
							onClick={() => setActiveTab(process.env.REACT_APP_NETWORK_CURRENCY!)}
						>
							<CustomText
								weight="bold"
								style={{
									...styles.tabText2,
									color: activeTab === process.env.REACT_APP_NETWORK_CURRENCY ? theme.activeTabText : theme.nonActiveTabText
								}}
							>
								{NETWORK_CURRENCY}
							</CustomText>
						</div>

						{/* 가로 스크롤 가능한 영역 */}
						<div style={styles.scrollView}>
							<div style={styles.scrollContent}>
								{assets.slice(1).map(item => (
									<div
										key={item.id}
										style={styles.tabButton}
										onClick={() => setActiveTab(item.symbol)}
									>
										<CustomText
											weight="bold"
											style={{
												...styles.tabText,
												color: activeTab === item.symbol ? theme.activeTabText : theme.nonActiveTabText
											}}
										>
											{item.name}
										</CustomText>
									</div>
								))}
							</div>
						</div>
					</div>
					<div style={styles.cardContainer}>
						<div style={styles.totalBalanceCard}>
							<div style={styles.iconContainer}>
								<img src={currentIcon} alt="Icon" style={styles.balanceIcon} />
							</div>
							<div style={styles.balanceInfo}>
								<div style={styles.balanceRow}>
									<div
										style={styles.balanceRowPressable}
										onClick={() =>
											navigate('/WalletScreen', {
												state: { token: currentAsset }
											})
										}
									>
										<CourierText weight="bold" style={styles.balanceText}>
											{formatNumberWithCommas(Number(currentBalance))}
										</CourierText>
										<img src="/img/icon_arrow_right.png" alt="Arrow Icon" style={styles.arrowIcon} />
									</div>
								</div>
								{/* <CustomText style={styles.balanceSubText}>
									{activeTab === NETWORK_CURRENCY
										? `${NETWORK_CURRENCY} = 0.000000`
										: `${currentAsset?.symbol} = 0.000000 ${NETWORK_CURRENCY}`}
								</CustomText> */}
							</div>
						</div>
						<div style={styles.buttonRow}>
							<div style={styles.buttonLeftRowContainer}>
								<CustomButton
									text={t('template_balance_screen_message_02')}
									textStyle={{ ...styles.buttonTextStyle, width: "auto" }}
									iconImage="/img/icon_send.png" // 실제 경로로 수정 필요
									iconSize={15}
									onPress={() => {
										const selectedToken = assets.find(asset => asset.symbol === activeTab);
										const currentBalance = selectedToken?.balance || '0.000000';
										const updatedToken = { ...selectedToken, balance: currentBalance };
										navigate('/WithdrawScreen', { state: { token: updatedToken } });
									}}
									containerStyle={{ ...styles.buttonStyle, ...styles.sendButtonStyle }}
								/>
								<div style={styles.divider} />
								<CustomButton
									text={t('template_balance_screen_message_03')}
									textStyle={{ ...styles.buttonTextStyle, width: "auto" }}
									iconImage="/img/icon_receive.png" // 실제 경로로 수정 필요
									iconSize={15}
									onPress={() => navigate('/ReceiveScreen', { state: { token: currentAsset } })}
									containerStyle={{ ...styles.buttonStyle, ...styles.receiveButtonStyle }}
								/>
							</div>
							<div style={styles.buttonRightRowContainer}>
								<CustomButton
									text={t('template_balance_screen_message_04')}
									textStyle={{ ...styles.buttonTextStyle, width: "auto" }}
									iconImage="/img/icon_swap.png" // 실제 경로로 수정 필요
									iconSize={15}
									onPress={() => navigate('/SwapScreen', { state: { token: currentAsset } })}
									containerStyle={{ ...styles.buttonStyle, ...styles.swapButtonStyle }}
								/>
							</div>
						</div>
					</div>
					<CustomText weight="bold" style={{ ...styles.sectionTitle, color: theme.primaryText }}>
						{t('template_balance_screen_message_07')}
					</CustomText>

					<CustomListCardWithIcon
						icon={'/img/icon_tether_usdt.png'} // 실제 경로로 수정
						title={otherAssets[0]?.symbol || 'USDT'}
						value={formatNumberWithCommas(Number(otherAssets[0]?.balance || 0))}
						unit={otherAssets[0]?.symbol || 'USDT'}
						cardStyle={{ marginBottom: 25 }}
						titleStyle={styles.otherChainStyle}
						valueStyle={styles.otherChainStyle}
						unitStyle={styles.otherChainUnitStyle}
						onPress={() => { handleTokenPress() }}
					/>

					<CustomText weight="bold" style={{ ...styles.sectionTitle, color: theme.primaryText }}>
						{t('template_balance_screen_message_08')}
					</CustomText>

					{events.map((item) => (
						<CustomListCard
							key={item.id}
							onPress={() => {
								if (item.extUrl && !item.appScreen) {
									const url = item.extUrl || 'https://example.com'; // 실제 URL로 수정
									window.open(url, '_blank'); // 외부 URL로 이동
								} else if (item.appScreen && !item.extUrl) {
									handleEventPress(item);
								}
							}}
							title={item.title}
							subtitle={item.contents}
							style={{ marginBottom: '10px', position: "relative" }}
						/>
					))}
				</div>
			</div>
		</>
	)
}

export default BalanceScreen

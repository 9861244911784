import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../../context/ThemeContext';
import { Check } from 'lucide-react';

import CustomButton from '../../Componentization/CustomButton';
import CustomText from '../../../styles/CustomText';
import Container from '../../Common/Container';
import CourierText from '../../../styles/CourierText';
import CustomListCardWithIcon from '../../Componentization/Card/CustomListCardWithIcon';
import CustomListCard from '../../Componentization/Card/CustomListCard';
import CustomSwtich from '../../Componentization/Switch/CustomSwtich';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkHeaderText, checkPincode, RootState } from '../../../store';

import useWallet from '../../../hooks/useWallet';
import useEthereum from '../../../hooks/useEthereum';
import useEthereumOther from '../../../hooks/useEthereumOther';
import useSecureStorage from '../../../hooks/useSecureStorage';
import useDefaultNetworkAndToken from '../../../hooks/useDefaultNetworkAndToken';

import MainPageHeader from '../../../nav/MainPageHeader';
import NavigationBar from '../../../nav/NavigationBar';

import i18next from 'i18next'
import PinCodeScreen from '../../Authentication/PinCodeScreen';

const AutoLockScreen = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const { t } = useTranslation();

	const { getAutoLockValue, setAutoLockValue } = useSecureStorage()
	const [selectedOption, setSelectedOption] = useState(i18next.t('auto_lock_screen_message_04'))

	const options = [
		{
			key: 'auto_lock_screen_message_04',
			value: i18next.t('auto_lock_screen_message_04')
		},
		{
			key: 'auto_lock_screen_message_05',
			value: i18next.t('auto_lock_screen_message_05')
		},
		{
			key: 'auto_lock_screen_message_06',
			value: i18next.t('auto_lock_screen_message_06')
		},
		{
			key: 'auto_lock_screen_message_07',
			value: i18next.t('auto_lock_screen_message_07')
		},
		{
			key: 'auto_lock_screen_message_08',
			value: i18next.t('auto_lock_screen_message_08')
		},
		{
			key: 'auto_lock_screen_message_09',
			value: i18next.t('auto_lock_screen_message_09')
		}
	]

	useEffect(()=>{
		dispatch(checkHeaderText({ "link": "AutoLockScreen", "title": t('stack_header_message_07') }))
	},[])

	useEffect(() => {
		const getAutoLockSetting = async () => {
			try {
				// const value = await AsyncStorage.getItem('autoLockSetting')
				const value = await getAutoLockValue()
				if (value !== null) {
					setSelectedOption(value)
				}
			} catch (error) {
				console.error('Failed to load the auto lock setting from storage', error)
			}
		}
		getAutoLockSetting()
	}, [])

	const handleOptionPress = async (option: string) => {
		setSelectedOption(option)
		try {
			await setAutoLockValue(option)
		} catch (error) {
			console.error('Failed to save the auto lock setting to storage', error)
		}
	}

	const getOptionText = (optionKey: string) => {
		if ('auto_lock_screen_message_04' == optionKey) {
			return i18next.t('auto_lock_screen_message_04')
		} else if ('auto_lock_screen_message_05' == optionKey) {
			return i18next.t('auto_lock_screen_message_05')
		} else if ('auto_lock_screen_message_06' == optionKey) {
			return i18next.t('auto_lock_screen_message_06')
		} else if ('auto_lock_screen_message_07' == optionKey) {
			return i18next.t('auto_lock_screen_message_07')
		} else if ('auto_lock_screen_message_08' == optionKey) {
			return i18next.t('auto_lock_screen_message_08')
		} else if ('auto_lock_screen_message_09' == optionKey) {
			return i18next.t('auto_lock_screen_message_09')
		}
	}

	const styles = {
		container: {
			padding: "60px 5px 24px",
		} as React.CSSProperties,
		description: {
			padding: "16px 24px",
			fontSize: 14,
			color: theme.text_02
		} as React.CSSProperties,
		option: {
			display: "flex",
			alignItems: 'center',
			padding: "16px 24px",
			borderBottom: `1px solid ${theme.border_09}`
		} as React.CSSProperties,
		optionText: {
			fontSize: 16,
			color: theme.text_15,
			flex: 1
		} as React.CSSProperties,
	}
	
	return (
		<div className='container' style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
			<div style={{ width: "100%" }}>
				<div style={{ ...styles.description, color: theme.primaryText }}>
					{t('auto_lock_screen_message_01')}
					<br />
					{t('auto_lock_screen_message_02')}
					<br />
					{t('auto_lock_screen_message_03')}
				</div>

				{options.map((option, index) => (
					<div
						key={index}
						style={{
							...styles.option,
							borderBottomColor: theme.dividerColor,
							cursor: 'pointer',
						}}
						onClick={() => handleOptionPress(option.key)}
					>
						<span
							style={{
								...styles.optionText,
								color: selectedOption === option.key ? theme.primary : theme.primaryText,
							}}
						>
							{getOptionText(option.key)}
						</span>
						{selectedOption === option.key && <Check name="checkmark-outline" size={24} color={theme.primary} />}
					</div>
				))}
			</div>
		</div>
	)
}

export default AutoLockScreen

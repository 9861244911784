import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../../context/ThemeContext';
import { Check } from 'lucide-react';

import CustomButton from '../../Componentization/CustomButton';
import CustomText from '../../../styles/CustomText';
import Container from '../../Common/Container';
import useWallet from '../../../hooks/useWallet';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { BounceLoader, MoonLoader } from "react-spinners";

const WalletCreationLoading: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	const { t } = useTranslation();

	useEffect(() => {
		const generateWallet = async () => {
			try {
				setTimeout(() => {
					navigate('/WalletCreationComplete')
				}, 2000)
			} catch (error) {
				console.error('Error generating wallet:', error)
			}
		}

		generateWallet()
	}, [])

	const styles = {
		container: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			marginTop: "150px"
		} as React.CSSProperties,
		title: {
			fontSize: 24,
			color: theme.text_01,
			textAlign: 'center',
			margin: "5px 0 0",
		} as React.CSSProperties,
		subTitle1: {
			fontSize: 18,
			color: theme.text_01,
			textAlign: 'center',
			margin: "5px 0 0"
		} as React.CSSProperties,
		subTitle2: {
			fontSize: 18,
			color: theme.text_01,
			textAlign: 'center',
			margin: "5px 0 0"
		} as React.CSSProperties
	}

	return (
		<div className='container' style={{ backgroundColor: theme.primaryBackgroundColor }}>
			<div style={styles.container}>
				<div style={{ marginBottom: "10px" }}>
					<BounceLoader color={"blue"} size={100} />
				</div>

				<CustomText weight="medium" style={{ ...styles.title}}>
					{t('wallet_creation_completed_message_01')}
				</CustomText>

				<CustomText style={{ ...styles.subTitle1}}>
					{t('wallet_creation_completed_message_02')}
				</CustomText>

				<CustomText style={{ ...styles.subTitle2}}>
					{t('wallet_creation_completed_message_03')}
				</CustomText>
			</div>
		</div>
	)
}

export default WalletCreationLoading

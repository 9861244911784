import React, { useContext } from 'react'
import CourierText from '../../../styles/CourierText'
import CustomText from '../../../styles/CustomText'
import { ThemeContext } from '../../../context/ThemeContext';

interface CustomListCardWithIconProps {
	icon: string | React.ReactNode;
	title: string;
	value: string;
	unit: string;
	cardStyle?: React.CSSProperties;
	iconStyle?: React.CSSProperties;
	titleStyle?: React.CSSProperties;
	valueStyle?: React.CSSProperties;
	unitStyle?: React.CSSProperties;
	onPress?: () => void;
}

const CustomListCardWithIcon: React.FC<CustomListCardWithIconProps> = ({
	icon,
	title,
	value,
	unit,
	cardStyle,
	iconStyle,
	titleStyle,
	valueStyle,
	unitStyle,
	onPress = () => { }
}) => {
	const { theme } = useContext(ThemeContext)

	const styles = {
		card: {
			display: 'flex',
			flexDirection: 'row' as const,
			alignItems: 'center',
			padding: '15px',
			backgroundColor: theme.card_background_01,
			borderRadius: '10px',
			margin: '5px 0',
			cursor: 'pointer',
		} as React.CSSProperties,
		icon: {
			width: '38px',
			height: '38px',
			marginRight: '15px',
		} as React.CSSProperties,
		textContainer: {
			flex: 1,
			display: 'flex',
			flexDirection: 'row' as const,
			justifyContent: 'space-between',
			alignItems: 'center',
		} as React.CSSProperties,
		title: {
			fontFamily: 'Pretendard-Regular',
			fontSize: '18px',
			color: theme.text_01,
		} as React.CSSProperties,
		valueContainer: {
			display: 'flex',
			flexDirection: 'row' as const,
			alignItems: 'center',
		} as React.CSSProperties,
		value: {
			fontFamily: 'Courier New',
			fontSize: '18px',
			color: theme.text_01,
			marginRight: '5px',
		} as React.CSSProperties,
		unit: {
			fontFamily: 'Pretendard-Regular',
			fontSize: '15px',
			color: theme.text_01,
		} as React.CSSProperties,
	};

	return (
		<div style={{ ...styles.card, ...cardStyle }} onClick={onPress}>
			{/* 이미지 처리 */}
			<img src={typeof icon === 'string' ? icon : undefined} alt="icon" style={{ ...styles.icon, ...iconStyle }} />
			<div style={styles.textContainer}>
				<div style={{ ...styles.title, ...titleStyle }}>{title}</div>
				<div style={styles.valueContainer}>
					<div style={{ ...styles.value, ...valueStyle }}>{value}</div>
					<div style={{ ...styles.unit, ...unitStyle }}>{unit}</div>
				</div>
			</div>
		</div>
	);
};

export default CustomListCardWithIcon

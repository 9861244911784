import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../../context/ThemeContext';
import { Delete } from 'lucide-react';

import CustomButton from '../../Componentization/CustomButton';
import CustomText from '../../../styles/CustomText';
import Container from '../../Common/Container';
import CourierText from '../../../styles/CourierText';
import CustomListCardWithIcon from '../../Componentization/Card/CustomListCardWithIcon';
import CustomListCard from '../../Componentization/Card/CustomListCard';
import CustomSwtich from '../../Componentization/Switch/CustomSwtich';

import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkHeaderText, checkPincode, RootState } from '../../../store';

import useWallet from '../../../hooks/useWallet';
import useEthereum from '../../../hooks/useEthereum';
import useEthereumOther from '../../../hooks/useEthereumOther';
import useSecureStorage from '../../../hooks/useSecureStorage';
import useDefaultNetworkAndToken from '../../../hooks/useDefaultNetworkAndToken';

import MainPageHeader from '../../../nav/MainPageHeader';
import NavigationBar from '../../../nav/NavigationBar';

import i18next from 'i18next'

import WebApp from '@twa-dev/sdk'

const ChangePassword: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const { t } = useTranslation();
	const location = useLocation();
	const [currentPin, setCurrentPin] = useState(location.state === null ? "" : location.state.pincode);

	const { getWalletAddress, changePassword, getFingerprintEnabled } = useSecureStorage()

	const [pin, setPin] = useState<string[]>([])
	const [confirmPin, setConfirmPin] = useState<string[]>([])
	const [isConfirming, setIsConfirming] = useState(false)
	const [error, setError] = useState(false)
	const [shuffledKeys, setShuffledKeys] = useState<string[]>([])

	useEffect(()=>{
		dispatch(checkHeaderText({ "link": "ChangePassword", "title": "" }))
	},[])

	useEffect(() => {
		setShuffledKeys(shuffleArray(['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']))
	}, [])

	useEffect(() => {
		if (pin.length === 6 && !isConfirming) {
			setTimeout(() => {
				setIsConfirming(true)
			}, 200)
		} else if (confirmPin.length === 6 && isConfirming) {
			setTimeout(() => {
				handleConfirm()
			}, 200)
		}
	}, [pin, confirmPin, isConfirming])

	useEffect(() => {
		setShuffledKeys(shuffleArray(['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']))
	}, [isConfirming])

	const handleKeyPress = (key: string) => {
		if (key === 'backspace') {
			if (isConfirming) {
				setConfirmPin(prevPin => prevPin.slice(0, -1))
			} else {
				setPin(prevPin => prevPin.slice(0, -1))
			}
		} else {
			if (isConfirming) {
				if (confirmPin.length < 6) {
					setConfirmPin(prevPin => [...prevPin, key])
				}
			} else {
				if (pin.length < 6) {
					setPin(prevPin => [...prevPin, key])
				}
			}
		}
	}

	const handleConfirm = async () => {
		if (confirmPin.join('') === pin.join('')) {
			// 핀코드 비밀번호 변경
			const newPin = pin.join('')
			if(true === await savePinToStorage(newPin)) {
				setError(false)
				
				const fingerprintEnabled = await getFingerprintEnabled()
				if('true' === fingerprintEnabled) {
					// 지문인식 사용중
					// biotoken 변경
					WebApp.BiometricManager.updateBiometricToken(newPin, TgWebAppUpdateBioTokenCallBack)
				} else {
					// 지문인식 사용안함
					navigate('/SecuritySettings')
				}
			}
		} else {
			setError(true)
			setTimeout(() => {
				setConfirmPin([])
				setError(false)
			}, 1000)
		}
	}

	const savePinToStorage = async (pin: string) => {
		try {
			if(null == currentPin) {
				console.log('Wrong Current Pin')
				return false
			}

			const newPin = pin;
			const walletAddress = await getWalletAddress()
			if(false === await changePassword(walletAddress, currentPin, newPin)) {
				console.log('Error Change Password')
				return false
			}
			console.log('Pin saved successfully')
			return true
		} catch (error) {
			console.error('Failed to save the pin to storage [ChangePassword]', error)
		}
		return false
	}

	const shuffleArray = (array: string[]) => {
		let currentIndex = array.length,
			randomIndex

		while (currentIndex !== 0) {
			randomIndex = Math.floor(Math.random() * currentIndex)
			currentIndex--
				;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
		}

		return array
	}

	const renderKey = (key: string, index: number) => (
		<div style={styles.keyWrapper} key={index}>
			<div
				onClick={() => { handleKeyPress(key) }}
				style={{
					// backgroundColor: theme.keyPadBackgroundColor,
					borderRadius: '50%',
				}}
			>
				<div style={styles.key}>
					<CustomText weight='medium' style={{ ...styles.keyText, color: theme.primaryText }}>
						{key}
					</CustomText>
				</div >
			</div >
		</div >
	)

	// bioToken update
	const TgWebAppUpdateBioTokenCallBack = async (isSuccess:boolean) => {
		// bioToken 업데이트 결과 확인
		if(true === isSuccess) {
			navigate('/SecuritySettings')
		}
	}

	const styles = {
		header: {
			height: "135px",
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column'
		} as React.CSSProperties,
		title1: {
			fontSize: 24,
			textAlign: 'center',
			color: theme.text_05
		} as React.CSSProperties,
		title2: {
			fontSize: 24,
			textAlign: 'center',
			color: theme.text_05,
			marginBottom: 10
		} as React.CSSProperties,
		pinContainer: {
			display: "flex",
			justifyContent: 'center',
			alignItems: 'center'
		} as React.CSSProperties,
		pinDot: {
			width: 16,
			height: 16,
			borderRadius: 8,
			backgroundColor: theme.pinCode_background_01,
			margin: 8
		} as React.CSSProperties,
		pinDotError: {
			backgroundColor: theme.pinCode_background_03,
			borderColor: theme.border_01
		} as React.CSSProperties,
		errorText: {
			color: theme.error_text_01,
			fontSize: 14,
			height: "20px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center"
		} as React.CSSProperties,
		keypadContainer: {
			width: '100%',
		} as React.CSSProperties,
		keypadRow: {
			display: "flex",
			justifyContent: 'space-around',
			width: '100%',
			marginBottom: 10
		} as React.CSSProperties,
		keyWrapper: {} as React.CSSProperties,
		key: {
			display: "flex",
			width: 70,
			height: 70,
			justifyContent: 'center',
			alignItems: 'center',
			cursor: "pointer"
		} as React.CSSProperties,
		keyPlaceholder: {
			width: 70,
			height: 70,
		} as React.CSSProperties,
		keyText: {
			fontSize: 24,
			color: theme.text_05
		} as React.CSSProperties,
	}
	
	return (
		<div className='container' style={{ backgroundColor: theme.primaryBackgroundColor }}>
			<div style={styles.header}>
				<CustomText weight='medium' style={[styles.title1, { color: theme.primaryText }]}>
					{isConfirming ? i18next.t('new_password_message_03') : i18next.t('new_password_message_01')}
				</CustomText>
				<CustomText weight='medium' style={[styles.title2, { color: theme.primaryText }]}>
					{isConfirming ? i18next.t('new_password_message_04') : i18next.t('new_password_message_02')}
				</CustomText>
			</div>
			<div>
			<div style={styles.pinContainer}>
				{Array.from({ length: 6 }).map((_, index) => {
					const baseStyle: React.CSSProperties = {
						...styles.pinDot,
						backgroundColor: theme.pinDotBackgroundColor,
					};

					const activeStyle: React.CSSProperties =
						(isConfirming ? confirmPin.length > index : pin.length > index)
							? { backgroundColor: theme.primary }
							: {};

					const errorStyle: React.CSSProperties =
						error && isConfirming && confirmPin.length > index ? styles.pinDotError : {};

					return <div key={index} style={{ ...baseStyle, ...activeStyle, ...errorStyle }} />;
				})}
			</div>
			<CustomText style={[styles.errorText, { color: theme.error }]}>
				{error && i18next.t('new_password_message_05')}
			</CustomText>
			</div>
			<div style={styles.keypadContainer}>
				{Array.from({ length: 3 }).map((_, rowIndex) => (
					<div key={rowIndex} style={styles.keypadRow}>
						{shuffledKeys.slice(rowIndex * 3, rowIndex * 3 + 3).map((key, index) => renderKey(key, index))}
					</div>
				))}
				<div style={styles.keypadRow}>
					<div style={styles.keyPlaceholder}></div>
					{renderKey(shuffledKeys[9], 9)}
					<div style={styles.keyWrapper} key="backspace">
						<div
							onClick={() => { handleKeyPress('backspace') }}
							style={styles.key}
						>
							<Delete size={24} color={theme.primaryText} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ChangePassword

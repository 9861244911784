import React, { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';

interface CustomButtonProps {
	onPress: () => void;
	containerStyle?: React.CSSProperties;
	disabledStyle?: React.CSSProperties;
	textStyle?: React.CSSProperties;
	iconStyle?: React.CSSProperties;
	imageStyle?: React.CSSProperties;
	iconSize?: number;
	text: string | React.ReactNode;
	iconName?: string;
	iconImage?: string; // React에서 이미지 경로는 string으로 처리
	iconPosition?: 'left' | 'right';
	disabled?: boolean;
	weight?: 'bold' | 'medium' | 'regular' | 'semi' | undefined;
	withBackground?: boolean;
	backgroundColor?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({
	onPress,
	containerStyle,
	disabledStyle,
	textStyle,
	iconStyle,
	imageStyle,
	iconSize = 20,
	text,
	iconName,
	iconImage,
	iconPosition = 'left',
	disabled,
	weight,
	withBackground = false,
	backgroundColor,
}) => {
	const { theme } = useContext(ThemeContext)

	const styles = {
		button: {
			display: 'flex',
			alignItems: 'center',
			padding: '10px 20px',
			border: 'none',
			cursor: 'pointer',
			backgroundColor: theme.background_03,
			color: 'white',
			margin: '0'
		} as React.CSSProperties, // TypeScript에서 CSSProperties 타입 지정
		disabled: {
			cursor: 'not-allowed',
			opacity: 0.5,
		} as React.CSSProperties,
		iconLeft: {
			marginRight: '10px',
		} as React.CSSProperties,
		iconRight: {
			marginLeft: '10px',
		} as React.CSSProperties,
		imageIcon: {
			width: '20px',
			height: '20px',
			marginRight: '10px',
		} as React.CSSProperties,
		buttonText: {
			fontSize: '16px',
		} as React.CSSProperties,
	};

	return (
		<button
			style={{
				...styles.button,
				...containerStyle,
				...(disabled ? styles.disabled : {}),
				...(disabled ? disabledStyle : {}),
				backgroundColor,
			}}
			onClick={onPress}
			disabled={disabled}
		>
			{iconName && !iconImage && iconPosition === 'left' && (
				<i style={{ ...styles.iconLeft, fontSize: iconSize, color: theme.text_04, ...iconStyle }} />
			)}
			{iconImage && iconPosition === 'left' && <img src={iconImage} alt="icon" style={{ ...styles.imageIcon, ...imageStyle }} />}
			<span style={{ ...styles.buttonText, ...textStyle }}>{text}</span>
			{iconName && !iconImage && iconPosition === 'right' && (
				<i style={{ ...styles.iconRight, fontSize: iconSize, color: theme.text_04, ...iconStyle }} />
			)}
			{iconImage && iconPosition === 'right' && <img src={iconImage} alt="icon" style={{ ...styles.imageIcon, ...imageStyle }} />}
		</button>
	);
};


export default CustomButton;
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeContext } from '../../context/ThemeContext';
import { Check } from 'lucide-react';
import { ethers } from 'ethers'

import DatePicker from 'react-datepicker'; // react-datepicker 사용
import 'react-datepicker/dist/react-datepicker.css'; // DatePicker 스타일 추가
import { Spin, Modal, Button } from 'antd';

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
import Container from '../Common/Container';
import CourierText from '../../styles/CourierText';
import CustomListCardWithIcon from '../Componentization/Card/CustomListCardWithIcon';
import CustomListCard from '../Componentization/Card/CustomListCard';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkHeaderText, checkPincode, RootState } from '../../store';
import { getCustomChainTransaction } from '../../hooks/useFetchBalance';

import useWallet from '../../hooks/useWallet';
import useEthereum from '../../hooks/useEthereum';
import useEthereumOther from '../../hooks/useEthereumOther';
import useSecureStorage from '../../hooks/useSecureStorage';
import MainPageHeader from '../../nav/MainPageHeader';
import NavigationBar from '../../nav/NavigationBar';
import i18next from 'i18next'
import moment from 'moment'
import axios from 'axios'

import { ParamType } from 'ethers/lib/utils'
import CustomTextInput from '../Componentization/CustomTextInput';

interface Wallet {
    id: string;
    type: string;
    amount: string;
    currency: string;
    date: any;
    detaildate: any;
    direction: 'in' | 'out';
    from: string;
    to: string;
    fee: string;
}

interface Props {
    item: string;
}

type RootStackParamList = {
    WalletScreenDetail: { item: Wallet };
};

// type WalletScreenDetailRouteProp = RouteProp<RootStackParamList, 'WalletScreenDetail'>;

function shortenString(str: string): string {
    if (null == str) {
        return ``;
    }

    if (str.length <= 10) {
        return str;
    }

    const start = str.slice(0, 6);
    const end = str.slice(-4);

    return `${start}...${end}`;
}

const WalletScreenDetail: React.FC = () => {
    const { theme } = useContext(ThemeContext)
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const { t } = useTranslation();

    const location = useLocation();
    const [item, setItem] = useState(location.state === null ? null : location.state.item);

    const [gasFee, setGasFee] = useState('0')

    useEffect(() => {
        dispatch(checkHeaderText({ "link": "WalletScreenDetail", "title": t('wallet_detail_screen_message_01') }))
    }, [])

    const copyToClipboard = useCallback(() => {
        if (item) {
            navigator.clipboard.writeText(item)
                .then(() => {
                    alert(i18next.t('template_receive_screen_message_01'));
                })
                .catch(err => {
                    console.error('클립보드 복사 실패:', err);
                });
        }
    }, [item]);

    const handleOpenExplorer = () => {
        let fullURL
        console.log(`item.currency : ${item.currency}`)
        if (item.currency === 'USDT') {
            fullURL = `${process.env.REACT_APP_ETHERSCAN_URL}/tx/${item.id.split('-')[0]}`
        } else {
            fullURL = `${process.env.REACT_APP_NETWORK_SCAN_URL}/tx/${item.id.split('-')[0]}`
        }

        window.open(fullURL, '_blank');
    }

    useEffect(() => {
        const getTxGasFee = async () => {
            if (item.currency === process.env.NETWORK_CURRENCY) {
                setGasFee(item.fee)
            } else if ("out" == item.direction && item.currency != 'USDT') {
                // 블록 익스플로러의 트랜잭션 정보
                const blockExpTx = await axios.get(
                    `${process.env.REACT_APP_NETWORK_SCAN_URL}/api/v2/transactions/${item.id.split('-')[0]}`
                )

                let gasFee = '0';
                if (blockExpTx && null != blockExpTx?.data && undefined != blockExpTx.data) {
                    const blockExpTransactions = blockExpTx.data
                    gasFee = ethers.utils.formatEther(blockExpTransactions.fee.value)
                    setGasFee(blockExpTransactions.fee.value)
                }
            } else if ("out" === item.direction && item.currency === 'USDT') {
                setGasFee(item.fee)
            }
        }
        getTxGasFee()
    }, [gasFee])

    const styles = {
        container: {
            padding: "60px 0px 24px",
        } as React.CSSProperties,
        top: {
            padding: "20px 30px",
            width: "100%",
            backgroundColor: theme.background_07,
            alignItems: "center",
            borderBottomRightRadius: 20,
            borderBottomLeftRadius: 20,
            boxSizing: "border-box",
            display: 'flex',
            flexDirection: "column",
            alignSelf: "center",
            justifyContent: "center"
        } as React.CSSProperties,
        topIcon: {
            width: 30,
            height: 30,
            marginBottom: 10
        } as React.CSSProperties,
        transactionAmountContainer: {
            display: 'flex',
            height: 30,
            gap: 10,
            alignItems: 'center',
        } as React.CSSProperties,
        transactionAmount: {
            fontSize: 20,
        } as React.CSSProperties,
        transactionSymbol: {
            color: theme.text_01,
            fontSize: 18,
        } as React.CSSProperties,
        fee: {
            display: 'flex',
            width: "100%",
            justifyContent: "space-between",
            alignItems: 'center'
        } as React.CSSProperties,
        fee1: {
            width: "100%",
            textAlign: "right"
        } as React.CSSProperties,
        top2: {
            width: "100%",
            paddingTop: 20,
        } as React.CSSProperties,
        top2Box: {
            padding: "0 30px",
            width: "100%",
            fontSize: 16,
            height: 35,
            marginBottom: 5,
            display: 'flex',
            alignItems: "center",
            justifyContent: "space-between",
            boxSizing: "border-box"
        } as React.CSSProperties,
        top3Box: {
            padding: "0 20px",
            display: 'flex',
            width: "100%",
            justifyContent: "space-between",
            fontSize: 16,
            height: 40,
            alignItems: "center",
            boxSizing: "border-box"
        } as React.CSSProperties,
        bottom: {
            paddingTop: 10,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        } as React.CSSProperties,
        explorerButton: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 20,
            border: `1px solid ${theme.border_03}`,
            borderRadius: 30,
            padding: "10px 15px",
            width: '50%',
            justifyContent: 'center',
            marginTop: 20,
            background: 'inherit'
        } as React.CSSProperties,
        explorerText: {
            color: theme.text_08,
            fontSize: 16
        } as React.CSSProperties,
    }

    return (
        <div className='container' style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
            <div style={{ width: "100%" }}>
                <div style={styles.top}>
                    <img
                        src={item.direction === 'out' ? "/img/out_icon.png" : "/img/in_icon.png"}
                        alt="transaction icon"
                        style={styles.topIcon}
                    />
                    <CustomText style={{ color: theme.text_01, fontSize: 24, marginBottom: 15, fontWeight: 'bold' }}>
                        {item.direction === 'out'
                            ? `${i18next.t('wallet_detail_screen_message_02')}`
                            : `${i18next.t('wallet_detail_screen_message_03')}`}
                    </CustomText>
                    <div style={styles.transactionAmountContainer}>
                        <CustomText weight="bold" style={[styles.transactionAmount, { color: item.direction === 'out' ? theme.text_18 : theme.text_07 }]}>
                            {item.direction === 'out' ? '-' : '+'}
                            {(Math.floor(parseFloat(item.amount) * 1000000) / 1000000).toFixed(6)}
                        </CustomText>
                        <CustomText style={styles.transactionSymbol}>{item.currency}</CustomText>
                    </div>
                    {/* <CustomText style={{ color: theme.text_01, fontSize: 14, fontFamily: 'Courier New', marginBottom: 15 }}>
                        <span style={{ fontSize: 16 }}></span>
                    </CustomText> */}
                    <div style={styles.fee}>
                        <CustomText style={styles.transactionSymbol}>
                            {i18next.t('wallet_detail_screen_message_04')}
                        </CustomText>
                        <div style={styles.transactionAmountContainer}>
                            <CustomText weight="bold" style={[styles.transactionAmount, { color: theme.text_18 }]}>
                                {item.direction === 'out'
                                    ? '-' + (Math.floor(parseFloat(ethers.utils.formatEther(gasFee)) * 1000000) / 1000000).toFixed(6)
                                    : '0'}
                            </CustomText>
                            <CustomText style={styles.transactionSymbol}>{process.env.NETWORK_CURRENCY}</CustomText>
                        </div>
                    </div>
                    {/* <CustomText style={[styles.fee1, { color: theme.text_01, fontSize: 14, fontFamily: 'Courier New', marginBottom: 30 }]}>
                        <span style={{ fontSize: 16 }}></span>
                    </CustomText> */}
                </div>

                <div style={styles.top2}>
                    <div style={styles.top2Box}>
                        <CustomText style={{ color: theme.text_01, fontSize: 16 }}>
                            {i18next.t('wallet_detail_screen_message_05')}
                        </CustomText>
                        <CustomText style={{ color: theme.text_18, fontSize: 16 }}>
                            {i18next.t('wallet_detail_screen_message_06')}
                        </CustomText>
                    </div>
                    <div style={styles.top2Box}>
                        <CustomText style={{ color: theme.text_01, fontSize: 16 }}>
                            {i18next.t('wallet_detail_screen_message_07')}
                        </CustomText>
                        <CustomText style={{ color: theme.text_01, fontSize: 16 }}>{item.detaildate}</CustomText>
                    </div>

                    <div style={{ backgroundColor: theme.card_background_04, borderRadius: 20, padding: "10px 0", margin: "0 10px" }}>
                        <div style={styles.top3Box}>
                            <CustomText style={{ color: theme.text_01, fontSize: 16 }}>
                                {i18next.t('wallet_detail_screen_message_08')}
                            </CustomText>
                            <div style={styles.transactionAmountContainer}>
                                <CustomText style={{ color: theme.text_01, fontSize: 16 }}>{shortenString(item.from)}</CustomText>
                                <button
                                // onClick={() => copyToClipboard({ item: item.from })}
                                >
                                    <img src={'/img/icon_withdraw_paste.png'} alt="paste icon" style={{ width: 15, height: 15 }} />
                                </button>
                            </div>
                        </div>

                        <div style={styles.top3Box}>
                            <CustomText style={{ color: theme.text_01, fontSize: 16 }}>
                                {i18next.t('wallet_detail_screen_message_09')}
                            </CustomText>
                            <div style={styles.transactionAmountContainer}>
                                <CustomText style={{ color: theme.text_01, fontSize: 16 }}>{shortenString(item.to)}</CustomText>
                                <button
                                //  onClick={() => copyToClipboard({ item: item.to })}
                                >
                                    <img src={'/img/icon_withdraw_paste.png'} alt="paste icon" style={{ width: 15, height: 15 }} />
                                </button>
                            </div>
                        </div>

                        <div style={styles.top3Box}>
                            <CustomText style={{ color: theme.text_01, fontSize: 16 }}>
                                {i18next.t('wallet_detail_screen_message_10')}
                            </CustomText>
                            <div style={styles.transactionAmountContainer}>
                                <CustomText style={{ color: theme.text_01, fontSize: 16 }}>{shortenString(item.id.split('-')[0])}</CustomText>
                                <button
                                // onClick={() => copyToClipboard({ item: item.id.split('-')[0] })}
                                >
                                    <img src={'/img/icon_withdraw_paste.png'} alt="paste icon" style={{ width: 15, height: 15 }} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={styles.bottom}>
                    <CustomButton
                        containerStyle={{
                            ...styles.explorerButton,
                            borderColor: theme.primary
                        }}
                        textStyle={{
                            ...styles.explorerText,
                            color: theme.primary
                        }}
                        onPress={handleOpenExplorer}
                        iconSize={20}
                        text={i18next.t('withdraw_complete_screen_message_07')}
                        iconName="open-outline"
                        withBackground={true}
                        backgroundColor={theme.withoutBackgroundTouchBackgroundColor}
                    />
                </div>
            </div>
        </div>
    )
}

export default WalletScreenDetail


import React, { useState, useCallback } from 'react'
import { ethers } from 'ethers'
import axios from 'axios'

const FULL_URL = `${process.env.REACT_APP_ETH_RPC_URL}${process.env.REACT_APP_INFURA_PROJECT_ID}`

const ERC20_ABI = [
	'function name() view returns (string)',
	'function symbol() view returns (string)',
	'function decimals() view returns (uint8)',
	'function balanceOf(address owner) view returns (uint256)',
	'function transfer(address to, uint256 amount) returns (bool)'
]

const balanceCache: any = {}
const cacheExpiryTime = 10000

const useEthereumOther = () => {
	const [usdtBalance, setUsdtBalance] = useState(null)
	const [ethBalance, setEthBalance] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [transactions, setTransactions] = useState([])
	const provider = new ethers.providers.JsonRpcProvider(FULL_URL)

	const getEthBalance = useCallback(
		async (walletAddress: string) => {
			if (!walletAddress) {
				throw new Error('Wallet address is required to get balance')
			}

			const currentTime = Date.now()
			const cachedData = balanceCache[walletAddress]

			if (cachedData && currentTime - cachedData.timestamp < cacheExpiryTime) {
				console.log('Using cached ETH balance')
				setEthBalance(cachedData.ethBalance)
				return cachedData.ethBalance
			}

			console.log(`Fetching ETH balance for address: ${walletAddress}`)
			const ethBalanceRaw = await provider.getBalance(walletAddress)
			const formattedEthBalance: any = ethers.utils.formatEther(ethBalanceRaw)

			balanceCache[walletAddress] = {
				...balanceCache[walletAddress],
				ethBalance: formattedEthBalance,
				timestamp: currentTime
			}

			setEthBalance(formattedEthBalance)
			return formattedEthBalance
		},
		[provider]
	)
	
	const getUsdtBalance = useCallback(
		async (walletAddress: string, contractAddress: string) => {

			try {
				if (!walletAddress) {
					throw new Error('Wallet address is required to get balance')
				}
	
				const cacheKey = `${walletAddress}_${contractAddress}`
				const currentTime = Date.now()
				const cachedData = balanceCache[cacheKey]
				
				if (cachedData && currentTime - cachedData.timestamp < cacheExpiryTime) {
					console.log('Using cached USDT balance')
					setUsdtBalance(cachedData.usdtBalance)
					return cachedData.usdtBalance
				}

				const contract = new ethers.Contract(contractAddress, ERC20_ABI, provider)
				const usdtBalanceRaw = await contract.balanceOf(walletAddress)
				const decimals = await contract.decimals()
				const formattedUsdtBalance: any = ethers.utils.formatUnits(usdtBalanceRaw, decimals)
				
				// console.log(`contract`);
				// console.log(contract);
				// console.log(`decimals : ${decimals}`);
				// console.log(`formattedUsdtBalance : ${formattedUsdtBalance}`);

				balanceCache[cacheKey] = {
					usdtBalance: formattedUsdtBalance,
					timestamp: currentTime
				}
	
				setUsdtBalance(formattedUsdtBalance)
				return formattedUsdtBalance
			} catch (error) {
				console.error('getUsdtBalance');
				console.error(error);
			} finally {
			}
		},
		[provider]
	)
	
	const getEthTokenInfo = useCallback(async (contractAddress: string) => {
		try {
			const contract = new ethers.Contract(contractAddress, ERC20_ABI, provider)
			const tokenName = await contract.name()
			const decimal = await contract.decimals()
			const symbol = await contract.symbol()

			const tokenInfo = {
				name: tokenName,
				symbol: symbol,
				decimals: decimal,
			};

			return tokenInfo
		} catch (error) {
			console.log('Error useEthereumSepolia getting token info:', error)
			return null
		}
	}, [])

	const getTransactionHistory = async (address: string, tokenAddress: string) => {
		try {
            if(null == process.env.REACT_APP_ETHERSCAN_API_URL)
                return []
            
			setIsLoading(true)

			const response = await axios.get(process.env.REACT_APP_ETHERSCAN_API_URL, {
				params: {
					module: 'account',
					action: 'tokentx',
					contractaddress: tokenAddress,
					address,
					startblock: 0,
					endblock: 99999999,
					sort: 'desc',
					apikey: process.env.REACT_APP_ETHERSCAN_API_KEY
				}
			})

			if (response.data.status === '1') {
				const transactions = response.data.result || []
				setTransactions(transactions)
				return transactions
			} else {
				setTransactions([])
				return []
			}
		} catch (error) {
			console.error('Error fetching transaction history:', error)
			setTransactions([])
			return []
		} finally {
			setIsLoading(false)
		}
	}

	const sendTransactionWithPrivateKeyETH = useCallback(
		async (privateKey: string, toAddress: string, amount: string, tokenSymbol: string, tokenAddress: string = '') => {
			try {
				const wallet = new ethers.Wallet(privateKey, provider)

				let tx
				if (tokenSymbol === 'USDT') {
					const contract = new ethers.Contract(tokenAddress, ERC20_ABI, wallet)
					const decimal = await contract.decimals()
					const amountInWei = ethers.utils.parseUnits(amount, decimal)

					tx = await contract.populateTransaction.transfer(toAddress, amountInWei)

					// 가스 한도를 수동으로 설정 (50,000 ~ 100,000 사이에서 적절한 값 설정)
					tx.gasLimit = ethers.BigNumber.from(21000);
				} else {
					tx = {
						to: toAddress,
						value: ethers.utils.parseEther(amount),
						gasLimit: ethers.utils.hexlify(21000)
					}
				}

				const response = await wallet.sendTransaction(tx)
				console.log(`Transaction hash: ${response.hash}`)
				const receipt = await response.wait()
				console.log(`Transaction was confirmed in block ${receipt.blockNumber}`)
				return receipt
			} catch (error) {
				console.log('Error sending transaction:', error)
				throw error
			}
		},
		[provider]
	)

	const estimateGasEth = useCallback(async () => {
		try {
			const gasPrice = await provider.getGasPrice()
			const gasLimit = 21000
			const gasFee = ethers.utils.formatEther(gasPrice.mul(gasLimit))

			return gasFee
		} catch (error) {
			console.error('Error estimating gas fee:', error)
			return '0.0'
		}
	}, [provider])
	
	return {
		usdtBalance,
		ethBalance,
		getEthBalance,
		getUsdtBalance,
		getEthTokenInfo,
		getTransactionHistory,
		sendTransactionWithPrivateKeyETH,
		estimateGasEth,
		isLoading,
		transactions
	}
}

export default useEthereumOther

import React, { createContext, useState, useEffect, ReactNode } from 'react'
import { lightTheme, darkTheme, Theme } from '../config/themes'

interface ThemeContextProps {
	theme: Theme
	toggleTheme: () => void
}

const defaultContext: ThemeContextProps = {
	theme: darkTheme,
	toggleTheme: () => { }
}

export const ThemeContext = createContext<ThemeContextProps>(defaultContext)

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
	const [theme, setTheme] = useState<Theme>(darkTheme)

	useEffect(() => {
		const loadTheme = () => {
			const storedTheme = localStorage.getItem('theme')
			console.log(`ThemeProvider storedTheme : ${storedTheme}`)
			if (storedTheme) {
				setTheme(storedTheme === 'dark' ? darkTheme : lightTheme)
			} else {
				const prefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
				console.log(`ThemeProvider prefersDark : ${prefersDark}`)

				// setTheme(prefersDark ? darkTheme : lightTheme)
				// TEST 임시 dark 모드 고정
				setTheme(darkTheme)
			}
		}
		loadTheme()
	}, [])

	const toggleTheme = async () => {
		// const newTheme = theme === lightTheme ? darkTheme : lightTheme
		// await AsyncStorage.setItem('theme', newTheme === darkTheme ? 'dark' : 'light')\
		
		const newTheme = darkTheme
		localStorage.setItem('theme', newTheme === darkTheme ? 'dark' : 'light')
		setTheme(newTheme)
	}

	return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>
}

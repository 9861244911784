import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import { Delete } from 'lucide-react';

import CustomText from '../../styles/CustomText';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import useSecureStorage from '../../hooks/useSecureStorage';

import WebApp from '@twa-dev/sdk'

interface PinCodeScreenProps {
	onSuccess: (decPin: string) => void;
	unlock: boolean;
}

const PIN_LENGTH = 6

const PinCodeScreen: React.FC<PinCodeScreenProps> = ({ onSuccess, unlock }) => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	const { t } = useTranslation();

	let dispatch = useDispatch();


	const { checkPincodeMatch, retrieveEncryptedData } = useSecureStorage()
	const [pin, setPin] = useState<string[]>([])
	const [error, setError] = useState(false)
	const [showFingerprintAuth, setShowFingerprintAuth] = useState(false)
	const [fingerprintEnabled, setFingerprintEnabled] = useState(false)
	const [shuffledKeys, setShuffledKeys] = useState<string[]>([])

	useEffect(() => {
		setShuffledKeys(shuffleArray(['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']))

		// 생체인증 초기화
		const initializeTgBio = async () => {
			if(true === WebApp.BiometricManager.isInited) {
				await TgWebAppStartBioAuth()
			} else {
				console.log(`텔레그램 생체인증 초기화 실패 상태`)
			}
		};

		initializeTgBio()
	}, [])

	useEffect(() => {
		if (pin.length === PIN_LENGTH) {
			handleConfirm()
		}
	}, [pin])


	// 텔레그램 생체인증 시작
	const TgWebAppStartBioAuth = async () => {
		try {
			const useFingerprintEnabled = await localStorage.getItem('fingerprintEnabled')

			// 지문인증 사용설정, 지문인증 기능 사용 가능하고 접근권한 신청하지 않은 상태
			if('true' === useFingerprintEnabled && true === WebApp.BiometricManager.isBiometricAvailable) {
				console.log(`//-------------------------------------------------`)
				console.log(`WebApp.BiometricManager.isBiometricAvailable TRUE`)
				if(false === WebApp.BiometricManager.isAccessRequested) {
					console.log(`//-------------------------------------------------`)
					console.log(`WebApp.BiometricManager.isAccessRequested FALSE`)

					console.log(`WebApp.BiometricManager.requestAccess 권한 요청 시작`)
					const bioParam = {reason: "Telegram BiometricManager requestAccess"}
					WebApp.BiometricManager.requestAccess(bioParam, TgWebAppBioRequestAccessCallBack)
				} else if(true === WebApp.BiometricManager.isAccessRequested) {
					console.log(`//-------------------------------------------------`)
					console.log(`WebApp.BiometricManager.isAccessRequested TRUE`)

					console.log(`WebApp.BiometricManager.authenticate 생체인증 요청 시작`)
					const bioParam = {reason: "Telegram BiometricManager authenticate"}
					WebApp.BiometricManager.authenticate(bioParam, TgWebAppBioAuthenticateCallBack)
				}
			} else {

			}
		} catch(error) {
			console.error(`Error Start BioAuth`, error)
		}
	}

	// 텔레그램 생체인식 권한요청 callback()
	const TgWebAppBioRequestAccessCallBack = async (isSuccess: boolean) => {
		console.log(`//-------------------------------------------------`)
		console.log(`TgWebAppBioRequestAccessCallBack() !!!!!`)
		console.log(`텔레그램 BiometricManager 권한요청 결과 : ${isSuccess}`)
	};

	// 텔레그램 생체인식 인증요청 callback()
	const TgWebAppBioAuthenticateCallBack = async (isSuccess:boolean, bioToken:string) => {
		try {
			console.log(`//-------------------------------------------------`)
			console.log(`TgWebAppBioAuthenticateCallBack() !!!!!`)
			console.log(`텔레그램 BiometricManager 인증요청 결과 : ${isSuccess}`)

			console.log(`UnlockScreen 텔레그램 SDK API 생체인식 상태`)
			console.log(`WebApp.BiometricManager.isBiometricAvailable : ${WebApp.BiometricManager.isBiometricAvailable}`)
			console.log(`WebApp.BiometricManager.biometricType : ${WebApp.BiometricManager.biometricType}`)
			console.log(`WebApp.BiometricManager.isAccessRequested : ${WebApp.BiometricManager.isAccessRequested}`)
			console.log(`WebApp.BiometricManager.isAccessGranted : ${WebApp.BiometricManager.isAccessGranted}`)
			console.log(`WebApp.BiometricManager.isBiometricTokenSaved : ${WebApp.BiometricManager.isBiometricTokenSaved}`)
			
			//핀코드 비교 확인
			let strPin = ''
			let pinMatch = false
			if(null !== bioToken && 6 <= bioToken.length) {
				strPin = bioToken.trim()
				pinMatch = await checkPincodeMatch(strPin)
			} else {
				pinMatch = false
			}
			
			if (true === pinMatch) {
				onSuccess(strPin)
			} else {
				setPin([])
			}
		} catch(error) {
			console.log(`Error Tg Bio Auth`, error)
		}
	}


	const handleKeyPress = (key: string) => {
		if (key === 'backspace') {
			setPin(prevPin => prevPin.slice(0, -1))
		} else {
			if (pin.length < PIN_LENGTH) {
				setPin(prevPin => [...prevPin, key])
			}
		}
	}

	const handleConfirm = async () => {
		try {
			const strPin = pin.join('')
			const pinMatch = await checkPincodeMatch(strPin)
			if (true === pinMatch) {
				onSuccess(strPin)
			} else {
				setError(true)
				setTimeout(() => {
					setPin([])
					setError(false)
				}, 1000)
			}
		} catch (error) {
			console.error('Failed to retrieve the pin from storage', error)
		}
	}

	const handleFingerprintAuth = () => {
		setShowFingerprintAuth(true)
	}

	const handleFingerprintAuthFailure = () => { }

	const shuffleArray = (array: string[]) => {
		let currentIndex = array.length,
			randomIndex

		while (currentIndex !== 0) {
			randomIndex = Math.floor(Math.random() * currentIndex)
			currentIndex--
				;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
		}

		return array
	}

	const renderKey = (key: string, index: number) => (
		<div style={styles.keyWrapper} key={index}>
			<div
				onClick={() => { handleKeyPress(key) }}
				style={{
					// backgroundColor: theme.keyPadBackgroundColor,
					borderRadius: '50%',
				}}
			>
				<div className='pincodeBtn' style={styles.key}>
					<CustomText weight='medium' style={{ ...styles.keyText, color: theme.primaryText }}>
						{key}
					</CustomText>
				</div>
			</div>
		</div>
	)

	const styles = {
		container: {
			padding: "60px 5px 24px",
			position: "relative",
		} as React.CSSProperties,
		unlockContainer: {
			padding: "60px 5px 0px",
			position: "relative",
			minHeight: "calc(100vh - 70px)"
		} as React.CSSProperties,
		header: {
		} as React.CSSProperties,
		title1: {
			fontSize: 24,
			textAlign: 'center',
			color: theme.text_01,
		} as React.CSSProperties,
		title2: {
			fontSize: 24,
			textAlign: 'center',
			color: theme.text_01,
			marginBottom: 10
		} as React.CSSProperties,
		pinContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		} as React.CSSProperties,
		pinDot: {
			width: 16,
			height: 16,
			borderRadius: 8,
			backgroundColor: theme.pinCode_background_01,
			margin: 8
		} as React.CSSProperties,
		pinDotFilled: {
			backgroundColor: theme.pinCode_background_02
		} as React.CSSProperties,
		pinDotError: {
			backgroundColor: theme.pinCode_background_03,
			border: `1px solid ${theme.border_01}`,
		} as React.CSSProperties,
		errorText: {
			color: theme.error_text_01,
			fontSize: 14,
			height: "20px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center"
		} as React.CSSProperties,
		keypadContainer: {
			width: '100%',
		} as React.CSSProperties,
		keypadRow: {
			display: 'flex',
			justifyContent: 'space-around',
			width: '100%',
			marginBottom: 5,
		} as React.CSSProperties,
		keyWrapper: {
			cursor: "pointer"
		} as React.CSSProperties,
		key: {
			width: 70,
			height: 70,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			margin: 10
		} as React.CSSProperties,
		keyPlaceholder: {
			width: 70,
			height: 70,
			margin: 10
		} as React.CSSProperties,
		keyText: {
			fontSize: 24,
			color: theme.text_01,
			textAlign: 'center'
		} as React.CSSProperties,
	}

	return (
		<div className='container' style={{ ...(unlock ? styles.unlockContainer : styles.container), backgroundColor: theme.primaryBackgroundColor }}>
			<div style={styles.header}>
				<CustomText weight="medium" style={styles.title1}>
					qWallet
				</CustomText>
				<CustomText weight="medium" style={styles.title2}>
					{t('pincode_screen_message_01')}
				</CustomText>
			</div>
			<div>
				<div style={styles.pinContainer}>
					{Array.from({ length: PIN_LENGTH }).map((_, index) => (
						<div
							key={index}
							style={{
								...styles.pinDot,
								...(pin.length > index ? styles.pinDotFilled : {}),
								...(error && pin.length > index ? styles.pinDotError : {}),
							}}
						/>
					))}
				</div>
				<CustomText style={{ ...styles.errorText, color: theme.error }}>
					{error && t('pincode_screen_message_02')}
				</CustomText>
			</div>

			<div style={styles.keypadContainer}>
				{Array.from({ length: 3 }).map((_, rowIndex) => (
					<div key={rowIndex} style={styles.keypadRow}>
						{shuffledKeys.slice(rowIndex * 3, rowIndex * 3 + 3).map((key, index) => renderKey(key, index))}
					</div>
				))}

				{/* 마지막 줄: 빈 공간, 0, 백스페이스 */}
				<div style={styles.keypadRow}>
					<div style={styles.keyPlaceholder} />
					{renderKey(shuffledKeys[9], 9)}
					<div style={styles.keyWrapper} key="backspace" onClick={() => handleKeyPress('backspace')}>
						<div style={styles.key}>
							<Delete size={24} color={theme.primaryText} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PinCodeScreen

import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../context/ThemeContext';

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const SecretPhraseWarning: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	const { t } = useTranslation();

	const handleConfirm = () => {
		navigate('/SecretPhraseConfirmation')
	}

	const styles = {
		header: {
			width: "100%",
		} as React.CSSProperties,
		body: {
			width: "100%",
		} as React.CSSProperties,
		title: {
			fontSize: 18,
			color: theme.text_05,
			textAlign: 'left',
			marginBottom: 5
		} as React.CSSProperties,
		subTitle: {
			fontSize: 18,
			color: theme.text_05,
			textAlign: 'left',
			marginBottom: 20
		} as React.CSSProperties,
		warningContainer: {
			width: '100%'
		} as React.CSSProperties,
		warningItem: {
			display: "flex",
			alignItems: 'flex-start',
			marginBottom: 40
		} as React.CSSProperties,
		iconContainer: {
			marginRight: 10
		} as React.CSSProperties,
		icon: {
			width: 36,
			height: 36,
			top: 3
		} as React.CSSProperties,
		warningTextContainer: {
			flex: 1
		} as React.CSSProperties,
		warningText1: {
			fontSize: 16,
			color: theme.text_05,
			marginBottom: 2
		} as React.CSSProperties,
		warningText2: {
			fontSize: 14,
			color: theme.text_03
		} as React.CSSProperties,
		footer: {
			width: '100%'
		} as React.CSSProperties,
		button: {
			backgroundColor: theme.button_02,
			padding: 15,
			borderRadius: 10,
			width: '100%',
			display: "flex",
			alignItems: 'center',
			height: 54,
			justifyContent: 'center',
		} as React.CSSProperties,
		buttonText: {
			color: theme.text_01,
			fontSize: 16
		} as React.CSSProperties
	}

	return (
		<div className='container' style={{ backgroundColor: theme.primaryBackgroundColor }}>
			<div style={styles.header}>
				<CustomText weight="medium" style={{ ...styles.title, color: theme.primaryText }}>
					{t('recovery_syntax_guide_message_01')}
				</CustomText>
				<CustomText weight="medium" style={{ ...styles.subTitle, color: theme.primaryText }}>
					{t('recovery_syntax_guide_message_02')}
				</CustomText>
			</div>

			<div style={styles.body}>
				<div style={styles.warningContainer}>
					<div style={styles.warningItem}>
						<div style={styles.iconContainer}>
							<img
								src="/img/dont-share.png" // 웹에서의 이미지 경로 설정 필요
								alt="Don't share"
								style={styles.icon}
							/>
						</div>
						<div style={styles.warningTextContainer}>
							<CustomText style={{ ...styles.warningText1, color: theme.primaryText }}>
								{t('recovery_syntax_guide_message_03')}
							</CustomText>
							<CustomText style={{ ...styles.warningText1, color: theme.primaryText }}>
								{t('recovery_syntax_guide_message_04')}
							</CustomText>
							<CustomText style={{ ...styles.warningText2, color: theme.secondaryText }}>
								{t('recovery_syntax_guide_message_05')}
							</CustomText>
						</div>
					</div>

					<div style={styles.warningItem}>
						<div style={styles.iconContainer}>
							<img
								src="/img/secure.png" // 웹에서의 이미지 경로 설정 필요
								alt="Secure"
								style={styles.icon}
							/>
						</div>
						<div style={styles.warningTextContainer}>
							<CustomText style={{ ...styles.warningText1, color: theme.primaryText }}>
								{t('recovery_syntax_guide_message_06')}
							</CustomText>
							<CustomText style={{ ...styles.warningText1, color: theme.primaryText }}>
								{t('recovery_syntax_guide_message_07')}
							</CustomText>
							<CustomText style={{ ...styles.warningText2, color: theme.secondaryText }}>
								{t('recovery_syntax_guide_message_08')}
							</CustomText>
							<CustomText style={{ ...styles.warningText2, color: theme.secondaryText }}>
								{t('recovery_syntax_guide_message_09')}
							</CustomText>
						</div>
					</div>
				</div>
			</div>

			<div style={styles.footer}>
				<CustomButton
					containerStyle={{ ...styles.button, backgroundColor: theme.primary }}
					textStyle={styles.buttonText}
					onPress={handleConfirm}
					text={t('recovery_syntax_guide_message_10')}
					withBackground={true}
					backgroundColor={theme.primaryTouchBackgroundColor}
				/>
			</div>
		</div>
	)
}

export default SecretPhraseWarning

import React from 'react';
// import globalStyles from './globalStyles';
import useGlobalStyles from './globalStyles';

interface MontserratTextProps extends Omit<React.HTMLAttributes<HTMLSpanElement>, 'style'> {
	style?: React.CSSProperties | React.CSSProperties[];
	weight?: 'regular' | 'bold' | 'medium' | 'semi' | 'italic';
}

const MontserratText: React.FC<MontserratTextProps> = ({ style, weight = 'regular', ...rest }) => {
	let customStyle: React.CSSProperties;
	const globalStyles = useGlobalStyles();
	
	switch (weight) {
		case 'bold':
			customStyle = globalStyles.montserratBold;
			break;
		case 'medium':
			customStyle = globalStyles.montserratMedium;
			break;
		case 'semi':
			customStyle = globalStyles.montserratSemiBold;
			break;
		case 'italic':
			customStyle = globalStyles.montserratBoldItalic;
			break;
		default:
			customStyle = globalStyles.montserratRegular;
			break;
	}

	return (
		<div style={{ ...customStyle, ...(Array.isArray(style) ? Object.assign({}, ...style) : style) }} {...rest} />
	)
};

export default MontserratText;

export const lightTheme = {
	// 기본 백그라운드
	primaryBackgroundColor: '#F8F8F8',
	// 서브 백그라운드
	secondaryBackgroundColor: '#FFFFFF',
	// 핀코드 백그라운드
	pinCodeBackgroundColor: '#121022',
	// 큐알 백그라운드
	qrScannerBackgroundColor: '#000',
	// 모달 백그라운드
	modalBackgroundColor: '#FFFFFF',
	// 지갑 삭제 텍스트 컬러
	deleteWalletTitleColor: '#DD4B70',
	// 지갑 스크린 거래내역 입,출금에 따른 텍스트 컬러
	inDirectionTextColor: '#0066FF',
	outDirectionTextColor: '#FF0000',
	// 바텀 네비게이터 백그라운드
	bottomNavigatorBackgronundColor: '#FFFFFF',
	// 바텀 네비게이터 탑 보더 컬러
	bottomNavigatorTopBorderColor: '#D9D9D9',
	// 기본 텍스트 컬러
	primaryText: '#141414',
	// 서브 텍스트 컬러
	secondaryText: '#999999',
	// 기본 앱 컬러
	primary: '#0682F0',
	secondary: '#999999',
	// 에러 컬러
	error: '#F94946',
	// 보더 컬러
	border: '#EEEEEE',
	// 헤더 텍스트 컬러
	headerTextColor: '#141414',
	// 헤더 백그라운드 컬러
	headerBackgroundColor: '#FFFFFF',
	// 버튼 비활성화 백그라운드
	disableButtonBackgroundColor: '#cccccc',
	// 핀닷 백그라운드 컬러
	pinDotBackgroundColor: '#DDDDDD',
	// 빈 카드 섹션 백그라운드
	emptyCardBackgroundColor: '#FFFFFF',
	// 빈 카드 섹션 보더 색상
	emptyCardBorderColor: '#EEEEEE',
	// 구분선 색상
	dividerColor: '#FFFFFF',
	// 화살표 아이콘 색상
	arrowIconColor: '#ccc',
	// 앱 버전 컬러
	appVersionColor: '#ccc',
	// 회원가입 체크박스 비활성화 백그라운드
	disableCheckBoxBackgroundColor: '#FFFFFF',
	// 회원가입 체크박스 비활성화 보더 컬러
	disableCheckBoxBorderColor: '#999999',
	// 회원가입 체크박스 활성화 백그라운드
	enableCheckBoxBackgroundColor: '#DEF75D',
	// 회원가입 체크박스 활성화 보더 컬러
	enableCheckBoxBorderColor: '#FFFFFF',
	// 출금 체크박스 비활성화 백그라운드
	disableWithdrawCheckBoxBackgroundColor: '#C6C6C6',
	// 출금 체크박스 비활성화 보더 컬러
	disableWithdrawCheckBoxBorderColor: '#C6C6C6',
	// 출금 체크박스 활성화 백그라운드
	enableWithdrawCheckBoxBackgroundColor: '#DEF75D',
	// 출금 체크박스 활성화 보더 컬러
	enableWithdrawCheckBoxBorderColor: '#FFFFFF',
	// 출금 체크박스 비활성화 체크 아이콘 컬러
	disableCheckIconColor: '#FFFFFF',
	// 출금 체크박스 활성화 체크 아이콘 컬러
	enableCheckIconColor: '#FFFFFF',
	// 앱 아이콘 컬러 (더보기 스크린)
	appIconColor: '#141414',
	// 토스트 메세지 에러 컬러
	toastErrorColor: '#F94946',
	// 토스트 메세지 성공 컬러
	toastSuccessColor: '#06D6A0',
	// QR 코드 색상
	qrCodeColor: '#141414',
	// 텍스트 필드 보더 컬러
	textFieldBorderColor: '#EEEEEE',
	// 비밀 복구 구문 워드 보더 컬러
	secretWordBorderColor: '#EEEEEE',
	// 발란스 스크린 에셋 리스트 사이 구분선 색상
	assetContainerDviderColor: '#EEEEEE',
	// 발란스 스크린 거래내역 버튼 백그라운드 컬러
	tradeHistoryButtonBackgroundColor: '#FFFFFF',
	// 발란스 스크린 거래내역 버튼 보더 컬러
	tradeHistoryButtonBorderColor: '#FFFFFF',
	// 다크모드 흰 모달 전용 텍스트 컬러
	darkModeModalTextColor: '#141414',
	// 스켈레톤 UI 백그라운드 컬러
	skeletonInterfaceBackgroundColor: '#EEEEEE',
	// 컬러모드에 따른 키패드 백그라운드 컬러
	keyPadBackgroundColor: 'rgba(0, 0, 0, 0.1)',

	// primary 터치 백그라운드
	primaryTouchBackgroundColor: '#7057FF',
	// 백그라운드 없는 터치 백그라운드
	withoutBackgroundTouchBackgroundColor: '#F5F5FF',
	// 지문 사용 안함 버튼 터치 백그라운드
	notUseTouchBackgroundColor: '#F5F5FF',
	// 자산 페이지 거래내역 터치 백그라운드
	tradeButtonTouchBackgroundColor: '#EEEEEE',
	// 더보기 서브 메뉴 터치 백그라운드
	seeMoreMenuTouchBackgroundColor: '#EEEEEE',
	// 검정 백그라운드 터치 백그라운드
	blackBackgroundTouchBackgroundColor: '#242424',

	// 스왑 밸류 텍스트 컬러
	swapValueTextColor: '#141414',

	// NFT 리스트 이름 텍스트 컬러
	itemTextColor: '#141414',
	// NFT 아이템이 없을 경우 노출 텍스트 컬러
	noItemsTextColor: '#141414',
	// NFT 상세 카드 백그라운드
	detailCardBackgroundColor: '#EEEEEE',
	// NFT 상세 카드 텍스트 컬러
	detailCardTextColor: '#141414',
	// NFT 상세 버튼 컬러
	detailButtonBackgroundColor: '#0682F0',
	// NFT 상세 버튼 텍스트 컬러
	detailButtonTextColor: '#FFFFFF',
	// NFT import placeholder 컬러
	nftImportPlaceholderColor: '#141414',

	// NFT 리스트 이미지 백그라운드 컬러
	nftImageBackgroundColor: '#EEEEEE',

	// 하단 커스텀 탭바 보더 컬러
	customTabBarBorderColor: '#EEEEEE',

	// 지갑 스크린 탭 텍스트 컬러
	activeTabText: '#000',
	nonActiveTabText: '#666666',

	// 더보기 그룹핑 백그라운드 컬러
	groupingCardBackgroundColor: '#EEEEEE',
	// 더보기 그룹핑 타이틀 컬러
	groupingTtileColor: '#141414',
	// 더보기 구분선 컬러
	groupingDividerColor: '#FFFFFF',

	// 지갑 스크린 트랜잭션 히스토리 카드 컨테이너 백그라운드
	transactionContainerBackgroundColor: '#EEEEEE',
	// 트랜잭션 히스토리 리스트 백그라운드 컬러
	transactionHistoryListBackgroundColor: '#FFFFFF',
	// 트랜잭션 히스토리 날짜 텍스트 컬러
	transactionHistoryDateTextColor: '#141414',
	// 트랜잭션 히스토리 스켈레톤 보더 컬러
	transactionHistorySkeletonBorderColor: '#E7E7E7',


	background_01: '#FFFFFF',
	background_02: '#1C1C1E',
	background_03: '#000',
	background_04: '#7A60FF',
	background_05: '#171717',
	background_06: '#F9F9F9',
	background_07: '#101212',

	card_background_01: '#1F2122',
	card_background_02: '#FFFFFF',
	card_background_03: '#171717',
	card_background_04: '#101212',
	card_background_05: '#4658F9',
	card_background_06: '#F9F9F9',

	check_background_01: '#666666',

	modal_background_01: 'rgba(0, 0, 0, 0.5)',
	modal_background_02: '#fff',
	modal_background_03: 'rgba(0, 0, 0, 1)',

	img_background_01: '#FFFFFF',

	divider_background_01: '#1F2122',
	divider_background_02: '#EEEEEE',
	divider_background_03: '#333333',

	pinCode_background_01: '#DDDDDD',
	pinCode_background_02: '#0682F0',
	pinCode_background_03: '#F94946',
	pinCode_background_04: '#DEF750',
	pinCode_background_05: '#333333',

	text_01: '#000',
	text_02: '#666666',
	text_03: '#999999',
	text_04: '#000',
	text_05: '#141414',
	text_06: '#5F6467',
	text_07: '#F94946',
	text_08: '#7A60FF',
	text_09: '#777',
	text_10: '#CCCCCC',
	text_11: '#FF000F',
	text_12: '#121022',
	text_13: '#797979',
	text_14: '#E4E4E4',
	text_15: '#333333',
	text_16: '#555555',
	text_17: '#DD4B70',
	text_18: '#0682F0',
	text_19: '#A8AFF0',

	error_text_01: '#F94946',

	button_01: '#2E3133',
	button_02: '#7A60FF',
	button_03: '#1F2122',
	button_04: '#fff',
	button_05: '#ccc',
	button_06: '#999999',
	button_07: '#0682F0',
	button_08: '#101212',

	border_01: '#F94946',
	border_02: '#ffffff',
	border_03: '#7A60FF',
	border_04: '#2E3133',
	border_05: '#999999',
	border_06: '#dcdcdc',
	border_07: '#0682F0',
	border_08: '#404345',
	border_09: '#3d3d3d',
	border_10: '#EEEEEE',
	border_11: '#c7c7c7',
}

export const darkTheme = {
	primaryBackgroundColor: '#000000',
	secondaryBackgroundColor: '#000000',
	pinCodeBackgroundColor: '#121022',
	qrScannerBackgroundColor: '#000',
	modalBackgroundColor: '#101212',
	deleteWalletTitleColor: '#DD4B70',
	inDirectionTextColor: '#0066FF',
	outDirectionTextColor: '#FF0000',
	bottomNavigatorBackgronundColor: '#000',
	bottomNavigatorTopBorderColor: '#232626',
	primaryText: '#FFFFFF',
	secondaryText: '#999999',
	primary: '#0682F0',
	secondary: '#999999',
	error: '#F94946',
	border: '#EEEEEE',
	headerTextColor: '#FFFFFF',
	headerBackgroundColor: '#000',
	disableButtonBackgroundColor: '#cccccc',
	pinDotBackgroundColor: '#FFFFFF',
	emptyCardBackgroundColor: '#101212',
	emptyCardBorderColor: '#333333',
	dividerColor: '#333333',
	arrowIconColor: '#ccc',
	appVersionColor: '#ccc',
	disableCheckBoxBackgroundColor: '#FFFFFF',
	disableCheckBoxBorderColor: '#999999',
	enableCheckBoxBackgroundColor: '#DEF75D',
	enableCheckBoxBorderColor: '#FFFFFF',
	disableWithdrawCheckBoxBackgroundColor: '#C6C6C6',
	disableWithdrawCheckBoxBorderColor: '#C6C6C6',
	enableWithdrawCheckBoxBackgroundColor: '#DEF75D',
	enableWithdrawCheckBoxBorderColor: '#FFFFFF',
	disableCheckIconColor: '#FFFFFF',
	enableCheckIconColor: '#FFFFFF',
	appIconColor: '#FFFFFF',
	toastErrorColor: '#F94946',
	toastSuccessColor: '#06D6A0',
	qrCodeColor: '#141414',
	textFieldBorderColor: '#EEEEEE',
	secretWordBorderColor: '#EEEEEE',
	assetContainerDviderColor: '#333333',
	tradeHistoryButtonBackgroundColor: '#101212',
	tradeHistoryButtonBorderColor: '#1F2121',
	darkModeModalTextColor: '#141414',
	skeletonInterfaceBackgroundColor: '#232626',
	keyPadBackgroundColor: 'rgba(255, 255, 255, 0.5)',
	primaryTouchBackgroundColor: '#0682F0',
	withoutBackgroundTouchBackgroundColor: '#242424',
	notUseTouchBackgroundColor: '#F5F5FF',
	tradeButtonTouchBackgroundColor: '#EEEEEE',
	seeMoreMenuTouchBackgroundColor: '#242424',
	blackBackgroundTouchBackgroundColor: '#242424',
	swapValueTextColor: '#FFFFFF',
	itemTextColor: '#FFFFFF',
	noItemsTextColor: '#FFFFFF',
	detailCardBackgroundColor: '#1F2122',
	detailCardTextColor: '#FFFFFF',
	detailButtonBackgroundColor: '#0682F0',
	detailButtonTextColor: '#FFFFFF',
	nftImportPlaceholderColor: '#FFFFFF',
	nftImageBackgroundColor: '#EEEEEE',
	customTabBarBorderColor: '#2E3133',
	activeTabText: '#FFF',
	nonActiveTabText: '#666666',
	groupingCardBackgroundColor: '#1F2122',
	groupingTtileColor: '#CCCCCC',
	groupingDividerColor: '#2E3133',
	transactionContainerBackgroundColor: '#101212',
	transactionHistoryListBackgroundColor: '#101212',
	transactionHistoryDateTextColor: '#CCCCCC',
	transactionHistorySkeletonBorderColor: '#2E3133',


	background_01: '#FFFFFF',
	background_02: '#1C1C1E',
	background_03: '#000',
	background_04: '#7A60FF',
	background_05: '#171717',
	background_06: '#F9F9F9',
	background_07: '#101212',

	card_background_01: '#1F2122',
	card_background_02: '#FFFFFF',
	card_background_03: '#171717',
	card_background_04: '#101212',
	card_background_05: '#4658F9',
	card_background_06: '#F9F9F9',

	check_background_01: '#666666',

	modal_background_01: 'rgba(0, 0, 0, 0.5)',
	modal_background_02: '#fff',
	modal_background_03: 'rgba(0, 0, 0, 1)',

	img_background_01: '#FFFFFF',

	divider_background_01: '#1F2122',
	divider_background_02: '#EEEEEE',
	divider_background_03: '#333333',

	pinCode_background_01: '#DDDDDD',
	pinCode_background_02: '#0682F0',
	pinCode_background_03: '#F94946',
	pinCode_background_04: '#DEF750',
	pinCode_background_05: '#333333',

	text_01: '#FFFFFF',
	text_02: '#666666',
	text_03: '#999999',
	text_04: '#000',
	text_05: '#141414',
	text_06: '#5F6467',
	text_07: '#F94946',
	text_08: '#7A60FF',
	text_09: '#777',
	text_10: '#CCCCCC',
	text_11: '#FF000F',
	text_12: '#121022',
	text_13: '#797979',
	text_14: '#E4E4E4',
	text_15: '#333333',
	text_16: '#555555',
	text_17: '#DD4B70',
	text_18: '#0682F0',
	text_19: '#A8AFF0',

	error_text_01: '#F94946',

	button_01: '#2E3133',
	button_02: '#7A60FF',
	button_03: '#1F2122',
	button_04: '#fff',
	button_05: '#ccc',
	button_06: '#999999',
	button_07: '#0682F0',
	button_08: '#101212',

	border_01: '#F94946',
	border_02: '#ffffff',
	border_03: '#7A60FF',
	border_04: '#2E3133',
	border_05: '#999999',
	border_06: '#dcdcdc',
	border_07: '#0682F0',
	border_08: '#404345',
	border_09: '#3d3d3d',
	border_10: '#EEEEEE',
	border_11: '#c7c7c7',
}

export type Theme = typeof lightTheme

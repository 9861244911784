import React, { useContext, useEffect, useState, useCallback } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import { Check } from 'lucide-react';

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
import Container from '../Common/Container';
import CourierText from '../../styles/CourierText';
import CustomListCardWithIcon from '../Componentization/Card/CustomListCardWithIcon';
import CustomListCard from '../Componentization/Card/CustomListCard';
import CustomSwtich from '../Componentization/Switch/CustomSwtich';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkHeaderText, checkPincode, RootState } from '../../store';

import useWallet from '../../hooks/useWallet';
import useEthereum from '../../hooks/useEthereum';
import useEthereumOther from '../../hooks/useEthereumOther';
import useSecureStorage from '../../hooks/useSecureStorage';
import useDefaultNetworkAndToken from '../../hooks/useDefaultNetworkAndToken';

import MainPageHeader from '../../nav/MainPageHeader';
import NavigationBar from '../../nav/NavigationBar';

import WebApp from '@twa-dev/sdk'

import i18next from 'i18next'

const SeeMoreScreen = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const { t } = useTranslation();

	const [appVersion, setAppVersion] = useState('')
	const [forceUpdate, setForceUpdate] = useState(0)

	useEffect(() => {
		dispatch(checkHeaderText({ "link": "SeeMoreScreen", "title": t('stack_header_message_05') }))
	}, [])

	// const [token, setToken] = useState(route.params?.token || {})

	// useEffect(() => {
	// 	const fetchAppVersion = async () => {
	// 		const version = await DeviceInfo.getVersion()
	// 		setAppVersion(version)
	// 	}
	// 	fetchAppVersion()
	// }, [])

	// useEffect(() => {
	// 	const handleLanguageChange = () => {
	// 		setForceUpdate(prev => prev + 1)
	// 	}

	// 	i18next.on('languageChanged', handleLanguageChange)

	// 	return () => {
	// 		i18next.off('languageChanged', handleLanguageChange)
	// 	}
	// }, [])

	const items =
		// useMemo(
		// 	() => 
		[
			{
				icon: 'lock-closed-outline',
				text: i18next.t('see_more_screen_message_02'),
				screen: 'SecuritySettings',
				hasBadge: false,
				isToggle: false
			},
			// { icon: 'headset-outline', text: i18next.t('see_more_screen_message_03'), screen: 'ContactUs', hasBadge: true },
			// {
			// 	icon: 'moon-outline',
			// 	text: i18next.t('see_more_screen_message_09'),
			// 	screen: 'DarkMode',
			// 	hasBadge: false,
			// 	isToggle: true
			// },
			{
				icon: 'language-outline',
				text: i18next.t('see_more_screen_message_04'),
				screen: 'LanguageSettingsScreen',
				hasBadge: false,
				isToggle: false
			},
			{
				icon: 'document-text-outline',
				text: i18next.t('see_more_screen_message_05'),
				screen: 'Terms',
				hasBadge: true,
				isToggle: false
			},
			{
				icon: 'document-outline',
				text: i18next.t('see_more_screen_message_06'),
				screen: 'PrivacyPolicy',
				hasBadge: true,
				isToggle: false
			},
			{
				icon: 'information-circle-outline',
				text: i18next.t('see_more_screen_message_07'),
				screen: 'VersionInfo',
				hasBadge: false,
				isToggle: false,
				version: process.env.REACT_APP_VERSION
			},
			{
				icon: 'token-outline',
				text: i18next.t('see_more_screen_message_13'),
				screen: 'TokenList',
				hasBadge: false,
				isToggle: false
			},
			{
				icon: 'token-outline',
				text: i18next.t('see_more_screen_message_14'),
				screen: 'TokenCreate',
				hasBadge: false,
				isToggle: false
			},
		]
	// [appVersion, forceUpdate]
	// )

	const handleItemPress = useCallback(
		(screen: string, hasBadge: boolean) => {
			if (screen === 'Terms' && hasBadge) {
				let url = process.env.REACT_APP_TERMS_PAGE_URL || process.env.REACT_APP_PREPARE_HOMEPAGE_URL
				console.log(`Terms : ${url}`);
				try {
					window.open(url, '_blank');
				} catch (error) {
					console.log('Failed to open Terms URL: ', error)
				}
			} else if (screen === 'PrivacyPolicy' && hasBadge) {
				let url = process.env.REACT_APP_PRIVACY_PAGE_URL || process.env.REACT_APP_PREPARE_HOMEPAGE_URL
				console.log(`PrivacyPolicy : ${url}`);
				try {
					window.open(url, '_blank');
				} catch (error) {
					console.error('Failed to open PrivacyPolicy URL: ', error)
				}
			} else if (screen !== 'Terms' && screen !== 'PrivacyPolicy' && hasBadge) {
				let url = process.env.REACT_APP_PREPARE_HOMEPAGE_URL
				try {
					window.open(url, '_blank');
				} catch (error) {
					console.error('Failed to open Prepare URL: ', error)
				}
			} else if (screen === 'VersionInfo') {
				handleShare()
			} else {
				// @ts-ignore
				navigate(`/${screen}`)
			}
		},
		[navigate]
	)

	// const handleShare = useCallback(() => {
	// 	const message = `${i18next.t('see_more_screen_message_10')}\n\nAndroid: ${ANDROID_PLAY_STORE_URL}\n\niOS: ${APPLE_APP_STORE_URL}`
	// 	Share.share({
	// 		message: message,
	// 		title: i18next.t('see_more_screen_message_11')
	// 	})
	// }, [])

	const handleShare = useCallback(() => {
		const envShareUrl = process.env.REACT_APP_TELEGRAM_MINIAPP_SHARE_URL;

		if(null != envShareUrl && '' !== envShareUrl) {
			const shareText = encodeURIComponent(i18next.t('see_more_screen_message_10'));
			const shareUrl = encodeURIComponent(envShareUrl);
			const url = `https://t.me/share/url?text=${shareText}&url=${shareUrl}`;
			console.log(`handleShare WebApp.openTelegramLink : ${url}`)
	
			try {
				WebApp.openTelegramLink(url);
			} catch (error) {
				console.log(`Error openTelegramLink`, error)
			}
		}
	}, [])

	// const handleToggleTheme = useCallback(() => {
	// 	LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
	// 	toggleTheme()
	// }, [toggleTheme])

	const styles = {
		container: {
			padding: "60px 5px 12px",
		} as React.CSSProperties,
		sectionTitle: {
			fontSize: 14,
			color: theme.text_15,
			width: "100%",
			padding: "10px",
			boxSizing: "border-box"
		} as React.CSSProperties,
		item: {
			display: "flex",
			alignItems: 'center',
			justifyContent: "space-between",
			padding: "19px 24px",
			borderBottom: `1px solid ${theme.border_04}`,
			cursor: "pointer"
		} as React.CSSProperties,
		icon: {
			width: 24,
			height: 24,
			marginRight: 8
		} as React.CSSProperties,
		itemText: {
			fontSize: 18,
			color: theme.text_15,
		} as React.CSSProperties,
		versionNumber: {
			fontSize: 16,
			color: theme.text_03
		} as React.CSSProperties,
		arrowIcon: {
			width: 13,
			height: 21,
			tintColor: theme.text_03
		} as React.CSSProperties,
		groupContainer: {
			borderRadius: 10,
			marginVertical: 8,
			overflow: 'hidden',
			width: "100%",
			padding: "0 15px",
			boxSizing: "border-box",
		} as React.CSSProperties,
		topRounded: {
			borderTopLeftRadius: 10,
			borderTopRightRadius: 10
		} as React.CSSProperties,
		bottomRounded: {
			borderBottomLeftRadius: 10,
			borderBottomRightRadius: 10
		} as React.CSSProperties,
	}
	
	return (
		<div className='container' style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
			<div style={{ width: "100%" }}>
				<CustomText style={{ ...styles.sectionTitle, color: theme.groupingTtileColor }}>
					{t('see_more_screen_message_01')}
				</CustomText>
				<div style={{ ...styles.groupContainer, backgroundColor: theme.groupingCardBackgroundColor }}>
					{items.slice(0, 2).map((item, index) => (
						<div
							key={index}
							style={{
								...styles.item,
								borderColor: theme.groupingDividerColor,
								backgroundColor: theme.groupingCardBackgroundColor,
								...(index === 0 ? styles.topRounded : {}),
								...(index === 2 ? styles.bottomRounded : {}),
							}}
							onClick={() => navigate(`/${item.screen}`)
								// !item.isToggle && handleItemPress(item.screen, item.hasBadge)
							}
						// onClick={() => !item.isToggle && handleItemPress(item.screen, item.hasBadge)}
						>
							<>
								<CustomText style={{ ...styles.itemText, color: theme.primaryText }}>{item.text}</CustomText>
								{item.isToggle ? (
									<>123</>
									// <CustomSwtich isOn={theme === darkTheme} onToggle={handleToggleTheme} />
								) : (
									<img src="/img/icon_small_arrow_right.png" alt="arrow" style={styles.arrowIcon} />
								)}
							</>
						</div>
					))}
				</div>
				<CustomText style={{ ...styles.sectionTitle, color: theme.groupingTtileColor }}>
					{t('see_more_screen_message_08')}
				</CustomText>
				<div style={{ ...styles.groupContainer, backgroundColor: theme.groupingCardBackgroundColor }}>
					{items.slice(2, 5).map((item, index) => (
						<div
							key={index}
							style={{
								...styles.item,
								borderColor: theme.groupingDividerColor,
								backgroundColor: theme.groupingCardBackgroundColor,
								...(index === 0 ? styles.topRounded : {}),
								...(index === items.length - 1 ? styles.bottomRounded : {}),
							}}
							onClick={() => handleItemPress(item.screen, item.hasBadge)}
						>
							<CustomText style={{ ...styles.itemText, color: theme.primaryText }}>{item.text}</CustomText>
							{item.screen === 'VersionInfo' ? (
								<CustomText style={{ ...styles.versionNumber, color: theme.appVersionColor }}>
									{item.version}
								</CustomText>
							) : (
								<img src="/img/icon_small_arrow_right.png" alt="arrow" style={styles.arrowIcon} />
							)}
						</div>
					))}
				</div>

				<CustomText style={{ ...styles.sectionTitle, color: theme.groupingTtileColor }}>
					{t('see_more_screen_message_15')}
				</CustomText>
				<div style={{ ...styles.groupContainer, backgroundColor: theme.groupingCardBackgroundColor }}>
					{items.slice(5).map((item, index) => (
						<div
							key={index}
							style={{
								...styles.item,
								borderColor: theme.groupingDividerColor,
								backgroundColor: theme.groupingCardBackgroundColor,
								...(index === 0 ? styles.topRounded : {}),
								...(index === items.length - 1 ? styles.bottomRounded : {}),
							}}
							onClick={() => handleItemPress(item.screen, item.hasBadge)}
						>
							<CustomText style={{ ...styles.itemText, color: theme.primaryText }}>{item.text}</CustomText>
							{item.screen === 'VersionInfo' ? (
								<CustomText style={{ ...styles.versionNumber, color: theme.appVersionColor }}>
									{item.version}
								</CustomText>
							) : (
								<img src="/img/icon_small_arrow_right.png" alt="arrow" style={styles.arrowIcon} />
							)}
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default SeeMoreScreen

import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeContext } from '../../context/ThemeContext';
import { Check } from 'lucide-react';
import { ethers } from 'ethers'

import DatePicker from 'react-datepicker'; // react-datepicker 사용
import 'react-datepicker/dist/react-datepicker.css'; // DatePicker 스타일 추가
// import { Spin, Modal, Button } from 'antd';
// import { FadeLoader } from "react-spinners";

// import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
// import Container from '../Common/Container';
// import CourierText from '../../styles/CourierText';
// import CustomListCardWithIcon from '../Componentization/Card/CustomListCardWithIcon';
// import CustomListCard from '../Componentization/Card/CustomListCard';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkUnlock, RootState } from '../../store';
import { getCustomChainTransaction } from '../../hooks/useFetchBalance';

// import { ParamType } from 'ethers/lib/utils'
// import CustomTextInput from '../Componentization/CustomTextInput';
import PinCodeScreen from '../Authentication/PinCodeScreen';
// import Spinner from '../Componentization/Modal/Spinner';

import WebApp from '@twa-dev/sdk'

const ONE_SECOND_IN_MS = 1000

type RouteParams = {
	token: { name: string; symbol: string; balance: number; address?: string }
	scannedAddress?: string
}

const UnlockScreen: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let location = useLocation();
	let navigate = useNavigate();
	const { t } = useTranslation();
	let dispatch = useDispatch();

	const [showPinCodeAuth, setShowPinCodeAuth] = useState(true)
	const [fingerprintEnabled, setFingerprintEnabled] = useState(false)

	useEffect(() => {
		// 백버튼 초기화
		const initializeTgBack = async () => {
			const fullPath = location.pathname + location.hash
			console.log(`텔레그램 백버튼 초기화 : ${fullPath}`)

			const platform = WebApp.platform
			if (fullPath === '/UnlockScreen') {
				// UnlockScreen 들어오면 텔레그램 백 버튼 숨김 (닫기 버튼으로 변경됨)
				if('android' === platform || 'android_x' === platform || 'ios' === WebApp.platform || 'tdesktop' === WebApp.platform) {
					WebApp.BackButton.hide();
				}
			} else {
				// 텔레그램 미니앱의 백 버튼 표시
				if('android' === platform || 'android_x' === platform || 'ios' === WebApp.platform || 'tdesktop' === WebApp.platform) {
					WebApp.BackButton.show();
				}
			}
		}
		
		// 언락화면 표시 초기화
		const initUnlock = async () => {
			const useFingerprintEnabled = await localStorage.getItem('fingerprintEnabled')
			
			if('true' === useFingerprintEnabled) {
				setFingerprintEnabled(true)
			}

			setShowPinCodeAuth(true)
		}

		initializeTgBack()
		initUnlock()
	}, [location])
	
	const handlePinCodeintAuthSuccess = (pin: string) => {
		console.log(`UnlockScreen handlePinCodeintAuthSuccess`)
		setShowPinCodeAuth(false)
		dispatch(checkUnlock(true))
		setTimeout(() => {
			// 홈화면으로 이동
			navigate(`/BalanceScreen`)
		}, 500)
	}

	const styles = {
		container: {
			backgroundColor: theme.primaryBackgroundColor,
			padding: "0px 0px 0",
		} as React.CSSProperties,
		modalBackground: {
			display: "flex",
			justifyContent: 'center',
			alignItems: 'center',
			width: "100%",
			height: "100%",
			padding: "0px 0px 0px 0px",
		} as React.CSSProperties,
		forgotPassword: {
			display: "flex",
			justifyContent: 'center',
			alignItems: 'center',
			width: "100%",
			height: "70px",
		} as React.CSSProperties,
		title1: {
			fontSize: 24,
			textAlign: 'center',
			color: '#fff'
		} as React.CSSProperties,
	}

	return (
		<div className='container' style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
			<div className='modalbackground' style={styles.modalBackground}>
				{/* {fingerprintEnabled ? (
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' ,color: 'white', fontWeight:"bold" }}>
						'FingerPrint UI'
					</div>
				) : (
					<PinCodeScreen onSuccess={handlePinCodeintAuthSuccess} unlock={true} />
				)} */}
				<PinCodeScreen onSuccess={handlePinCodeintAuthSuccess} unlock={true} />
			</div>
			<div className='forgotPassword' style={styles.forgotPassword}>
				<div
					style={{ color: 'gray', fontWeight:"bold" }}
					onClick={() => navigate(`/ResetWalletScreen`)}
				>
					{t('unlock_screen_message_01')}
				</div>
			</div>
		</div>
	)
}

export default UnlockScreen

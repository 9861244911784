import React, { useContext } from 'react';
import { FadeLoader } from "react-spinners";
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../../context/ThemeContext';

const Spinner: React.FC = () => {
	const { t } = useTranslation();
	const { theme } = useContext(ThemeContext)

	const styles = {
		modalBackground: {
			display: "flex",
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: theme.modal_background_01,
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			zIndex: 101,
		} as React.CSSProperties,
		modalBox: {
			background: theme.modal_background_02,
			display: 'flex',
			alignItems: "center",
			justifyContent: "center",
			flexDirection: "column",
			padding: "30px",
			borderRadius: "10px",
			position: "relative"
		} as React.CSSProperties,
		processingText: {
			marginTop: 10,
			fontSize: 16
		} as React.CSSProperties,
	}

	return (
		<div style={styles.modalBackground}>
			<div style={styles.modalBox}>
				<div style={{ height: "60px" }}>
					<FadeLoader color={"gray"} style={{ position: "absolute", left: "61px", top: "50px" }} />
				</div>
				<div style={styles.processingText}>
					{t('template_withdraw_screen_message_25')}
				</div>
			</div>
		</div>
	)
}


export default Spinner
import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import { Copy } from 'lucide-react';

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
import Container from '../Common/Container';
import useSecureStorage from '../../hooks/useSecureStorage';
import MontserratText from '../../styles/MontserratText';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkPincode, RootState } from '../../store';

import i18next from 'i18next'

const SecretPhraseCheck: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	const { t } = useTranslation();

	const { retrieveEncryptedData, getWalletAddress } = useSecureStorage()

	const [selectedWords, setSelectedWords] = useState<any>({})
	const [error, setError] = useState(false)
	const [mnemonic, setMnemonic] = useState<string[]>([])
	const [checkIndices, setCheckIndices] = useState<number[]>([])
	const [options, setOptions] = useState<{ [key: number]: string[] }>({})

	const pincode = useSelector((state: RootState) => state.pincode.value);

	useEffect(() => {
		fetchMnemonic()
	}, [retrieveEncryptedData])

	const fetchMnemonic = async () => {
		const walletAddress = await getWalletAddress()
		if (walletAddress) {
			try {
				const data = await retrieveEncryptedData(walletAddress, pincode)
				if (data && data.mnemonic) {
					const mnemonicArray = data.mnemonic.split(' ')
					setMnemonic(mnemonicArray)
					const indices = getRandomIndices(mnemonicArray.length, 3)
					setCheckIndices(indices)
					setSelectedWords(indices.reduce((acc: any, index: any) => ({ ...acc, [index]: '' }), {}))
					setOptions(generateOptions(mnemonicArray, indices))
				}
			} catch (error) {
				console.error('Failed to retrieve mnemonic:', error)
			}
		}
	}

	const getRandomIndices = (length: number, count: number) => {
		const indices: number[] = []
		while (indices.length < count) {
			const index = Math.floor(Math.random() * length)
			if (!indices.includes(index)) {
				indices.push(index)
			}
		}
		return indices
	}

	const generateOptions = (mnemonicArray: string[], indices: number[]) => {
		const options: { [key: number]: string[] } = {}
		indices.forEach(index => {
			const wordOptions = new Set<string>()
			wordOptions.add(mnemonicArray[index])
			while (wordOptions.size < 3) {
				const randomWord = mnemonicArray[Math.floor(Math.random() * mnemonicArray.length)]
				wordOptions.add(randomWord)
			}
			options[index] = Array.from(wordOptions).sort()
		})
		return options
	}

	const handleOptionPress = (index: number, word: string) => {
		setSelectedWords((prev: any) => ({ ...prev, [index]: word }))
	}

	const handleNextPress = () => {
		const isCorrect = checkIndices.every(index => mnemonic[index] === selectedWords[index])

		if (isCorrect) {
			setError(false)
			navigate('/WalletCreationLoading')
		} else {
			setError(true)
		}
	}

	const isAllSelected = checkIndices.every(index => selectedWords[index] !== '')

	const styles = {
		header: {
			marginBottom: "30px"
		} as React.CSSProperties,
		body: {
			width: "100%"
		} as React.CSSProperties,
		title: {
			fontSize: 24,
			color: theme.text_05,
			textAlign: 'left',
			marginBottom: 10
		} as React.CSSProperties,
		subTitle: {
			fontSize: 14,
			color: theme.text_05,
			textAlign: 'left',
			marginBottom: 5
		} as React.CSSProperties,
		questionContainer: {
			marginBottom: 20
		} as React.CSSProperties,
		question: {
			fontSize: 16,
			color: theme.text_05,
			marginBottom: 10
		} as React.CSSProperties,
		optionsWrapper: {
			display: 'flex',
			border: `1px solid ${theme.border_06}`,
			borderRadius: 31,
			alignItems: 'center',
			position: 'relative',
			height: 48
		} as React.CSSProperties,
		optionButton: {
			width: "100%",
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '100%',
			paddingHorizontal: 10,
			backgroundColor: "inherit",
			border: "none"
		} as React.CSSProperties,
		firstOptionButton: {
			borderTopLeftRadius: 31,
			borderBottomLeftRadius: 31
		} as React.CSSProperties,
		lastOptionButton: {
			borderTopRightRadius: 31,
			borderBottomRightRadius: 31
		} as React.CSSProperties,
		selectedOptionButton: {
			backgroundColor: theme.button_04,
			border: `2px solid ${theme.border_07}`,
			borderRadius: 31,
			marginHorizontal: -2
		} as React.CSSProperties,
		optionText: {
			color: theme.text_13,
			fontSize: 18,
			textAlign: 'center',
			flexShrink: 1,
			maxWidth: '100%'
		} as React.CSSProperties,
		divider: {
			position: 'absolute',
			width: 1,
			height: '35%',
			backgroundColor: theme.divider_background_02
		} as React.CSSProperties,
		errorText: {
			color: theme.text_07,
			fontSize: 16,
			textAlign: 'left',
			marginTop: 10,
			marginBottom: 10
		} as React.CSSProperties,
		footer: {
			width: "100%",
			justifyContent: 'flex-end',
		} as React.CSSProperties,
		button: {
			width: "100%",
			padding: 15,
			borderRadius: 10,
			alignItems: 'center',
			height: 54,
			justifyContent: 'center'
		} as React.CSSProperties,
		buttonText: {
			color: theme.text_01,
			fontSize: 16
		} as React.CSSProperties,
	}

	return (
		<div className='container' style={{ backgroundColor: theme.primaryBackgroundColor }}>
			<div style={{ width: "100%" }}>
				<div style={styles.header}>
					<CustomText weight="medium" style={{ ...styles.title, color: theme.primaryText }}>
						{t('check_recovery_syntax_message_01')}
					</CustomText>
					<CustomText style={{ ...styles.subTitle, color: theme.primaryText }}>
						{t('check_recovery_syntax_message_02')}
					</CustomText>
					<CustomText style={{ ...styles.subTitle, color: theme.primaryText }}>
						{t('check_recovery_syntax_message_03')}
					</CustomText>
				</div>

				<div style={styles.body}>
					{checkIndices.map((index, idx) => (
						<div key={idx} style={styles.questionContainer}>
							<CustomText weight="medium" style={{ ...styles.question, color: theme.primaryText }}>
								{`${index + 1} ${t('check_recovery_syntax_message_04')}`}
							</CustomText>
							<div style={{ ...styles.optionsWrapper, borderColor: theme.secretWordBorderColor }}>
								{options[index]?.map((option, optionIdx) => (
									<React.Fragment key={optionIdx}>
										<button
											style={{
												...styles.optionButton,
												...(selectedWords[index] === option
													? { ...styles.selectedOptionButton }
													: {}),
												...(optionIdx === 0 ? styles.firstOptionButton : {}),
												...(optionIdx === options[index].length - 1 ? styles.lastOptionButton : {}),
											}}
											onClick={() => handleOptionPress(index, option)}
										>
											<MontserratText
												weight="medium"
												style={{ ...styles.optionText, ...(selectedWords[index] === option ? { color: theme.primary } : {}) }}
											>
												{option}
											</MontserratText>
										</button>
										{optionIdx < options[index].length - 1 && (
											<div
												style={{
													...styles.divider,
													left: `${((optionIdx + 1) * 100) / options[index].length}%`,
													opacity:
														selectedWords[index] &&
															(selectedWords[index] === options[index][optionIdx] ||
																selectedWords[index] === options[index][optionIdx + 1])
															? 0
															: 1
												}}
											/>
										)}
									</React.Fragment>
								))}
							</div>
						</div>
					))}
				</div>
			</div>

			<div style={styles.footer}>
				{error && (
					<CustomText weight="medium" style={{ ...styles.errorText, color: theme.error }}>
						{t('check_recovery_syntax_message_05')}
					</CustomText>
				)}
				<CustomButton
					containerStyle={{ ...styles.button, backgroundColor: theme.primary }}
					textStyle={styles.buttonText}
					onPress={handleNextPress}
					text={t('check_recovery_syntax_message_06')}
					disabled={!isAllSelected}
					disabledStyle={{ backgroundColor: theme.disableButtonBackgroundColor }}
					withBackground={true}
					backgroundColor={theme.primaryTouchBackgroundColor}
				/>
			</div>
		</div>
	)
}

export default SecretPhraseCheck

import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import { Copy } from 'lucide-react';

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
import Container from '../Common/Container';
import MontserratText from '../../styles/MontserratText';
import useWallet from '../../hooks/useWallet';
import useSecureStorage from '../../hooks/useSecureStorage';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkPincode, RootState } from '../../store';

import i18next from 'i18next'

const SecretPhraseConfirmation: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	const { t } = useTranslation();

	const { makeWallet } = useWallet()
	const { storeEncryptedData } = useSecureStorage()

	const [mnemonic, setMnemonic] = useState<string[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const [isComplate, setIsComplate] = useState<boolean>(false)

	const pincode = useSelector((state: RootState) => state.pincode.value);

	useEffect(() => {
		initializeWallet()
	}, [])

	const initializeWallet = async () => {
		try {
			const { privateKey, publicKey, address, mnemonic } = makeWallet()

			if (null == privateKey || null == publicKey || null == address || null == mnemonic) {
				setIsComplate(false)
				alert('Error Make mnemonic. please restart');
				return
			}

			// 암호화 저장
			const result = await storeEncryptedData(address, privateKey, publicKey, mnemonic, pincode);

			if (false == result) {
				console.error('Error save wallet data')
				setIsComplate(false)
				alert('Error Make mnemonic. please restart');
				return
			}

			setMnemonic(mnemonic.split(' '))
			setIsComplate(true)
		} catch (error) {
			console.error('Error initializing wallet:', error)
		} finally {
			setLoading(false)
		}
	}

	const handleNext = () => {
		if (true == isComplate) {
			navigate('/SecretPhraseCheck')
		} else {
			alert('Error Make mnemonic. please restart');
		}
	}

	const handleCopy = (): void => {
		const code = mnemonic.join(' ')
		if (code) {
			navigator.clipboard.writeText(code)
				.then(() => {
					alert(i18next.t('verify_recovery_syntax_message_10'));
				})
				.catch(err => {
					console.error('Fail Copy mnemonic:', err);
				});
		}
	};

	const styles = {
		scrollContainer: {
			width: "100%",
		} as React.CSSProperties,
		header: {
			marginBottom: "30px"
		} as React.CSSProperties,
		body: {
			marginBottom: "40px"
		} as React.CSSProperties,
		title: {
			fontSize: 24,
			color: theme.text_05,
			textAlign: 'left',
			marginBottom: 10
		} as React.CSSProperties,
		subTitle: {
			fontSize: 14,
			color: theme.text_05,
			textAlign: 'left',
			marginBottom: 5
		} as React.CSSProperties,
		phraseContainer: {
			display: "grid",
			gridTemplateColumns: "repeat(2, 1fr)",
			gap: "10px"
		} as React.CSSProperties,
		phraseItem: {
			marginBottom: 8,
			display: "flex",
			alignItems: 'center',
			border: `1px solid ${theme.border_06}`,
			padding: 8,
			borderRadius: 31
		} as React.CSSProperties,
		phraseIndexContainer: {
			padding: 5,
			paddingTop: 1,
			paddingBottom: 1,
			marginRight: 5
		} as React.CSSProperties,
		phraseIndex: {
			fontSize: 18,
			color: theme.text_08,
			height: "20px",
			display: "flex",
			alignItems: "center"
		} as React.CSSProperties,
		phraseWord: {
			fontSize: 18,
			color: theme.text_05,
			paddingLeft: 4,
			height: "20px",
			display: "flex",
			alignItems: "center"
		} as React.CSSProperties,
		clipboardButton: {
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center',
			gap: "10px",
			backgroundColor: theme.button_04,
			borderRadius: 10,
			marginTop: 10,
			border: `1px solid ${theme.border_03}`,
			height: 48,
			cursor: "pointer"
		} as React.CSSProperties,
		clipboardButtonText: {
			color: theme.text_08,
			fontSize: 14
		} as React.CSSProperties,
		warning: {
			marginTop: 15,
			display: "flex",
			alignItems: 'center',
			justifyContent: 'flex-start'
		} as React.CSSProperties,
		warningText1: {
			fontSize: 16,
			color: theme.text_05,
		} as React.CSSProperties,
		warningText2: {
			fontSize: 14,
			color: theme.text_03,
			textAlign: 'left',
			marginTop: 5
		} as React.CSSProperties,
		warningText3: {
			fontSize: 14,
			color: theme.text_03,
			textAlign: 'left',
			marginTop: 1
		} as React.CSSProperties,
		footer: {
			display: "flex",
			justifyContent: 'flex-end',
		} as React.CSSProperties,
		button: {
			width: "100%",
			display: "flex",
			backgroundColor: theme.button_02,
			padding: 15,
			borderRadius: 10,
			alignItems: 'center',
			height: 54,
			justifyContent: 'center'
		} as React.CSSProperties,
		buttonText: {
			color: theme.text_01,
			fontSize: 16
		} as React.CSSProperties,
		icon: {
			width: 18,
			height: 18,
			marginRight: 5
		} as React.CSSProperties
	}

	return (
		<div className='container' style={{ backgroundColor: theme.primaryBackgroundColor }}>
			<div style={styles.scrollContainer}>
				<div style={styles.header}>
					<CustomText weight="medium" style={{ ...styles.title, color: theme.primaryText }}>
						{t('verify_recovery_syntax_message_01')}
					</CustomText>
					<CustomText style={{ ...styles.subTitle, color: theme.primaryText }}>
						{t('verify_recovery_syntax_message_02')}
					</CustomText>
					<CustomText style={{ ...styles.subTitle, color: theme.primaryText }}>
						{t('verify_recovery_syntax_message_03')}
					</CustomText>
					<CustomText style={{ ...styles.subTitle, color: theme.primaryText }}>
						{t('verify_recovery_syntax_message_04')}
					</CustomText>
				</div>

				<div style={styles.body}>
					<div>
						{loading ? (
							<div style={styles.phraseContainer}>
								{Array.from({ length: 12 }).map((_, index) => (
									<div key={index} style={{ ...styles.phraseItem, borderColor: theme.secretWordBorderColor }}>
										<div style={styles.phraseIndexContainer}>
											<MontserratText weight="medium" style={{ ...styles.phraseIndex, color: theme.primary }}>
												{index + 1}
											</MontserratText>
										</div>
										<MontserratText weight="medium" style={{ ...styles.phraseWord, color: theme.primaryText }}>
											????
										</MontserratText>
									</div>
								))}
							</div>
						) : (
							<div style={styles.phraseContainer}>
								{
									mnemonic.map((word, index) => (
										<div key={index} style={{ ...styles.phraseItem, borderColor: theme.secretWordBorderColor }}>
											<div style={styles.phraseIndexContainer}>
												<MontserratText weight="medium" style={{ ...styles.phraseIndex, color: theme.primary }}>
													{index + 1}
												</MontserratText>
											</div>
											<MontserratText weight="medium" style={{ ...styles.phraseWord, color: theme.primaryText }}>
												{word}
											</MontserratText>
										</div>
									))
								}
							</div>
						)}
					</div>

					<CopyToClipboard text={mnemonic.join(' ')} onCopy={handleCopy}>
						<div style={{ ...styles.clipboardButton, ...styles.clipboardButtonText, borderColor: theme.primary, color: theme.text_04, backgroundColor: theme.notUseTouchBackgroundColor }}>
							<Copy size={15} />
							<div>{t('verify_recovery_syntax_message_05')}</div>
						</div>
					</CopyToClipboard>

					<div style={styles.warning}>
						<img
							src="/img/mark.png" // 웹에서 사용할 이미지 경로로 변경
							alt="Warning"
							style={styles.icon}
						/>
						<CustomText weight="medium" style={{ ...styles.warningText1, color: theme.primaryText }}>
							{t('verify_recovery_syntax_message_06')}
						</CustomText>
					</div>

					<CustomText style={{ ...styles.warningText2, color: theme.secondaryText }}>
						{t('verify_recovery_syntax_message_07')}
					</CustomText>
					<CustomText style={{ ...styles.warningText3, color: theme.secondaryText }}>
						{t('verify_recovery_syntax_message_08')}
					</CustomText>
				</div>

				<div style={styles.footer}>
					<CustomButton
						containerStyle={{ ...styles.button, backgroundColor: theme.primary }}
						textStyle={styles.buttonText}
						onPress={handleNext}
						text={t('verify_recovery_syntax_message_09')}
						withBackground={true}
						backgroundColor={theme.primaryTouchBackgroundColor}
					/>
				</div>
			</div>
		</div>
	)
}

export default SecretPhraseConfirmation

import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeContext } from '../../context/ThemeContext';
import { CircleAlert } from 'lucide-react';
import { ethers } from 'ethers'

import DatePicker from 'react-datepicker'; // react-datepicker 사용
import 'react-datepicker/dist/react-datepicker.css'; // DatePicker 스타일 추가
import { Spin, Modal, Button } from 'antd';

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
import Container from '../Common/Container';
import CourierText from '../../styles/CourierText';
import CustomListCardWithIcon from '../Componentization/Card/CustomListCardWithIcon';
import CustomListCard from '../Componentization/Card/CustomListCard';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkHeaderText, checkPincode, RootState } from '../../store';
import { getCustomChainTransaction } from '../../hooks/useFetchBalance';

import useWallet from '../../hooks/useWallet';
import useEthereum from '../../hooks/useEthereum';
import useEthereumOther from '../../hooks/useEthereumOther';
import useSecureStorage from '../../hooks/useSecureStorage';
import MainPageHeader from '../../nav/MainPageHeader';
import NavigationBar from '../../nav/NavigationBar';
import moment from 'moment'
import i18next from 'i18next'

import { ParamType } from 'ethers/lib/utils'
import CustomTextInput from '../Componentization/CustomTextInput';
import MontserratText from '../../styles/MontserratText';

type TransactionData = {
	transactionHash: string
	amount: string
	isSwapped: boolean
}

const SwapCompleteScreen: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const { t } = useTranslation();
	const { getWalletAddress } = useSecureStorage()

	const location = useLocation();
	const [transactionData, setTransactionData] = useState(location.state === null ? null : location.state.transactionData);
	const [transactionHash, setTransactionHash] = useState('')
	const [amount, setAmount] = useState('')
	const [isSwapped, setIsSwapped] = useState('')
	const [approveAmount, setApproveAmount] = useState('')

	useEffect(() => {
		dispatch(checkHeaderText({ "link": "SwapCompleteScreen", "title": "" }))
	}, [])

	useEffect(() => {
		if (location.state) {
			const txData = location.state.transactionData
			console.log(`스왑 완료 페이지 전달 데이터`, location.state.transactionData)
			setTransactionData(txData);
			setTransactionHash(txData.transactionHash)
			setAmount(txData.amount)
		}
	}, [location.state]);

	const handleComplete = () => {
		navigate('/BalanceScreen')
	}

	const handleOpenExplorer = () => {
		const url = `${process.env.REACT_APP_NETWORK_SCAN_URL}/tx/${transactionHash}`
		window.open(url, '_blank');
	}

	const handleCopyHash = () => {
		if (transactionHash) {
			navigator.clipboard.writeText(transactionHash)
				.then(() => {
					alert(i18next.t('template_swap_complete_screen_message_01'));
				})
				.catch(err => {
					console.error('클립보드 복사 실패:', err);
				});
		}
	}

	const formatNumberWithCommas = (number: number): string => {
		const fixedNumber = number.toFixed(6)
		const [integerPart, decimalPart] = fixedNumber.split('.')
		const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		return `${formattedIntegerPart}.${decimalPart}`
	}

	const styles = {
		container: {
			backgroundColor: theme.background_03,
			padding: "60px 24px 24px",
		} as React.CSSProperties,
		header: {
			display: "flex",
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: "column",
			margin: "40px 0",
		} as React.CSSProperties,
		title: {
			fontSize: 24,
			color: theme.text_05,
			textAlign: 'center',
			marginTop: 20
		} as React.CSSProperties,
		image: {
			width: 75,
			height: 68
		} as React.CSSProperties,
		infoContainer: {
			width: '100%',
			borderRadius: 10,
		} as React.CSSProperties,
		infoRow1: {
			display: "flex",
			justifyContent: 'space-between',
			marginBottom: 10,
		} as React.CSSProperties,
		label: {
			fontSize: 16,
			color: theme.text_09
		} as React.CSSProperties,
		valueContainer: {
			flex: 1
		} as React.CSSProperties,
		value: {
			fontSize: 16,
			color: theme.text_05,
			textAlign: 'right'
		} as React.CSSProperties,
		footer: {
			width: '100%',
			display: "flex",
			alignItems: 'center',
			justifyContent: 'flex-end'
		} as React.CSSProperties,
		explorerButton: {
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center',
			marginBottom: 20,
			border: `1px solid ${theme.border_03}`,
			borderRadius: 10,
			padding: "10px 15px",
			width: '100%',
			marginTop: 20,
			background: "inherit"
		} as React.CSSProperties,
		explorerText: {
			color: theme.text_08,
			fontSize: 16
		} as React.CSSProperties,
		completeButton: {
			backgroundColor: theme.button_02,
			padding: "15px 0",
			borderRadius: 27,
			width: '100%',
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center'
		} as React.CSSProperties,
		completeButtonText: {
			color: theme.text_01,
			fontSize: 16
		} as React.CSSProperties,
	}

	return (
		<div className='container' style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
			<div style={{ width: "100%" }}>
				<div style={styles.header}>
					<img
						src="/img/Rectangle.png"
						alt="Rectangle"
						style={styles.image}
					/>
					<CustomText weight="medium" style={{ ...styles.title, color: theme.primaryText }}>
						{i18next.t('template_swap_complete_screen_message_02')}
					</CustomText>
				</div>

				<div style={styles.infoContainer}>
					<div style={styles.infoRow1}>
						<CustomText style={styles.label}>
							{i18next.t('template_swap_complete_screen_message_03')}
						</CustomText>
						<button onClick={handleCopyHash} style={styles.valueContainer}>
							<MontserratText
								weight="medium"
								style={{ ...styles.value, color: theme.primaryText }}
							>
								{`${transactionHash.slice(0, 10)}...${transactionHash.slice(-6)}`}
							</MontserratText>
						</button>
					</div>
					<div style={styles.infoRow1}>
						<CustomText style={styles.label}>
							{i18next.t('template_swap_complete_screen_message_04')}
						</CustomText>
						<MontserratText weight="bold" style={{ ...styles.value, color: theme.primaryText }}>
							{formatNumberWithCommas(Number(amount))}{' '}
						</MontserratText>
					</div>

					<CustomButton
						containerStyle={{ ...styles.explorerButton, borderColor: theme.primary, gap: 5 }}
						textStyle={{ ...styles.explorerText, color: theme.primary }}
						onPress={handleOpenExplorer}
						iconSize={20}
						text={i18next.t('template_swap_complete_screen_message_05')}
						iconName="open-outline"
						withBackground={true}
						backgroundColor={theme.withoutBackgroundTouchBackgroundColor}
					/>
				</div>
			</div>

			<div style={styles.footer}>
				<CustomButton
					containerStyle={{ ...styles.completeButton, backgroundColor: theme.primary }}
					textStyle={styles.completeButtonText}
					onPress={handleComplete}
					text={i18next.t('template_swap_complete_screen_message_06')}
					withBackground={true}
					backgroundColor={theme.primaryTouchBackgroundColor}
				/>
			</div>
		</div >
	)
}

export default SwapCompleteScreen

import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import { Check } from 'lucide-react';

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
import Container from '../Common/Container';
import CourierText from '../../styles/CourierText';
import CustomListCardWithIcon from '../Componentization/Card/CustomListCardWithIcon';
import CustomListCard from '../Componentization/Card/CustomListCard';
import CustomSwtich from '../Componentization/Switch/CustomSwtich';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkHeaderText, checkPincode, RootState } from '../../store';

import useWallet from '../../hooks/useWallet';
import useEthereum from '../../hooks/useEthereum';
import useEthereumOther from '../../hooks/useEthereumOther';
import useSecureStorage from '../../hooks/useSecureStorage';
import useDefaultNetworkAndToken from '../../hooks/useDefaultNetworkAndToken';

import MainPageHeader from '../../nav/MainPageHeader';
import NavigationBar from '../../nav/NavigationBar';

import i18next from 'i18next'
import CustomTextInput from '../Componentization/CustomTextInput';

const TokenCreate: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const { t } = useTranslation();

	const { networkInfo, searchToken, addNewToken } = useDefaultNetworkAndToken();

	const [contract, setContract] = useState('')
	const [isContractValid, setIsContractValid] = useState(false)

	const [tokenName, setTokenName] = useState('')
	const [tokenSymbol, setTokenSymbol] = useState('')
	const [isTokenValid, setIsTokenValid] = useState(true)

	const [decimals, setDecimals] = useState('')
	const [isDecimalsValid, setIsDecimalsValid] = useState(true)

	const [isPhoneStored, setIsPhoneStored] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const { getTokenInfo } = useEthereum()

	const validateContract = (contract: string) => {
		const regex = /^0x[a-fA-F0-9]{40}$/
		return regex.test(contract)
	}

	const regex1 = /^0x[a-fA-F0-9]{40}$/
	const regex2 = /^[A-Za-z]+$/
	const regex3 = /^\d+$/

	useEffect(() => {
		dispatch(checkHeaderText({ "link": "TokenCreate", "title": t('see_more_screen_message_14') }))
	}, [])

	const handleContractChange = async (contract: string) => {

		setContract(contract)

		if (true == validateContract(contract)) {
			setIsLoading(true)

			// RPC 에서 토큰 정보 불러오기
			const tokenInfo = await getTokenInfo(contract);

			// 불러온 토큰 이름, 소수점 정보 표시
			if (null !== tokenInfo) {
				setTokenName(tokenInfo?.name)
				setTokenSymbol(tokenInfo?.symbol)
				setDecimals(tokenInfo?.decimals)

				setIsContractValid(true)
			}
			else {
				// 토큰 정보를 찾을 수 없는 경우
				setTokenName('')
				setTokenSymbol('')
				setDecimals('')

				setIsContractValid(false)

				// Toast.show({
				// 	type: 'error',
				// 	text1: i18next.t('add_token_screen_message_08'),
				// 	position: 'bottom',
				// 	visibilityTime: 1000
				// })
				alert(i18next.t('add_token_screen_message_08'));
			}
			setIsLoading(false)
		} else {
			// Toast.show({
			// 	type: 'error',
			// 	text1: i18next.t('add_token_screen_message_09'),
			// 	position: 'bottom',
			// 	visibilityTime: 1000
			// })
			alert(i18next.t('add_token_screen_message_09'));
		}
	}

	const handleAddNewToken = async () => {
		setIsLoading(true)
		if (validateContract(contract) && true == isContractValid) {
			try {
				const newToken = {
					name: tokenSymbol,
					symbol: tokenSymbol,
					description: 'This is a user add token',
					type: 'token',
					contract: contract,
					decimal: decimals,
					icon: '@assets/img/icon_default.png',
					networkid: networkInfo[0]?.networkid,
					chainid: networkInfo[0]?.networkid,
					networkname: networkInfo[0]?.symbol,
					useswap: false,
				};

				// 저장된 토큰 정보가 없으면 추가로 저장
				const result = await addNewToken(newToken)
				if ('success' == result) {
					// Toast.show({
					// 	type: 'success',
					// 	text1: i18next.t('add_token_screen_message_04'),
					// 	position: 'bottom',
					// 	visibilityTime: 1000
					// })
					alert(i18next.t('add_token_screen_message_04'));

					setContract('')
					setTokenName('')
					setTokenSymbol('')
					setDecimals('')
				}
				else if ('overlap' == result) {
					// Toast.show({
					// 	type: 'error',
					// 	text1: i18next.t('add_token_screen_message_05'),
					// 	position: 'bottom',
					// 	visibilityTime: 1000
					// })
					alert(i18next.t('add_token_screen_message_05'));
				}
				else {
					// Toast.show({
					// 	type: 'error',
					// 	text1: i18next.t('add_token_screen_message_06'),
					// 	position: 'bottom',
					// 	visibilityTime: 1000
					// })
					alert(i18next.t('add_token_screen_message_06'));
				}

			} catch (error) {
				console.error('Error verifying phone:', error)
			}

			setIsLoading(false)

		} else {
			// Toast.show({
			// 	type: 'error',
			// 	text1: i18next.t('add_token_screen_message_08'),
			// 	position: 'bottom',
			// 	visibilityTime: 1000
			// })
			alert(i18next.t('add_token_screen_message_08'));
			setIsLoading(false)
		}
	}

	// const dismissKeyboard = () => {
	// 	Keyboard.dismiss()
	// }

	const styles = {
		container: {
			padding: "60px 24px 24px",
		} as React.CSSProperties,
		inner: {
			display: "flex",
			flexDirection: "column",
			alignItems: 'space-between',
			justifyContent: 'space-between',
			width: "100%",
			boxSizing: "border-box",
			position: "relative",
		} as React.CSSProperties,
		inputContainer: {
			width: '100%',
			marginBottom: 16,
			height: "50px"
		} as React.CSSProperties,
		inputContainer2: {
			width: '100%',
			marginBottom: 16,
			borderBottom: "1px solid gray",
			borderRadius: 0,
			color: theme.text_01,
			height: "50px"
		} as React.CSSProperties,
		input: {
			width: '100%',
			padding: 12,
			border: "1px solid gray",
			borderRadius: 10
		} as React.CSSProperties,
		invalidInput: {
			border: "1px solid red",
		} as React.CSSProperties,
		text: {
			fontSize: 16,
			marginBottom: 16,
			textAlign: 'left'
		} as React.CSSProperties,
		button: {
			padding: "12px 24px",
			borderRadius: 10,
			width: '100%',
			marginBottom: 0,
			justifyContent: 'center',
			boxSizing: "border-box"
		} as React.CSSProperties,
		buttonText: {
			color: theme.text_01,
			fontSize: 16,
			textAlign: 'center',
		} as React.CSSProperties,
		loader: {
			fontSize: '14px',
		},
	}
	
	return (
		<div className='container' style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
			<div onClick={() => { }
				// dismissKeyboard
			} style={styles.inner}>
				<div style={{ flex: 1, justifyContent: 'flex-start' }}>
					<div>
						<p style={{ ...styles.text, color: theme.primaryText }}>
							{t('add_token_screen_message_01')}
						</p>
						<CustomTextInput
							value={contract}
							onChangeText={handleContractChange}
							placeholder=""
							keyboardType="default"
							containerStyle={styles.inputContainer}
							inputStyle={{ ...styles.input, color: theme.primaryText }}
							invalidStyle={styles.invalidInput}
							isValid={isContractValid}
							editable={true}
						/>
					</div>
					<div>
						<p style={{ ...styles.text, color: theme.primaryText }}>
							{t('add_token_screen_message_02')}
						</p>
						<CustomTextInput
							value={tokenSymbol}
							onChangeText={() => { }}
							placeholder={t('add_token_screen_message_07')}
							placeholderTextColor={theme.text_16}
							containerStyle={styles.inputContainer2}
							invalidStyle={styles.invalidInput}
							editable={false}
						/>
					</div>
				</div>
			</div>
			<CustomButton
				containerStyle={{ ...styles.button, backgroundColor: theme.primary }}
				onPress={handleAddNewToken}
				text={
					isLoading ? (
						<span style={styles.loader}>Loading...</span> // 웹에서는 로딩 상태를 표시하는 텍스트
					) : t('see_more_screen_message_14')
				}
				textStyle={styles.buttonText}
				withBackground={true}
				backgroundColor={theme.primaryTouchBackgroundColor}
			/>
		</div>
	)
}

export default TokenCreate

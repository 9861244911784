
import React, { useEffect, useState } from 'react';
import './App.css';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { initI18n } from './locales/i18n';

import Header from './nav/Header';

import CreateWallet from './components/Create/CreateWallet';
import WalletProtection from './components/Create/WalletProtection';
import SecretPhraseGeneration from './components/Create/SecretPhraseGeneration';
import SecretPhraseWarning from './components/Create/SecretPhraseWarning';
import SecretPhraseConfirmation from './components/Create/SecretPhraseConfirmation';
import SecretPhraseCheck from './components/Create/SecretPhraseCheck';
import WalletRecovery from './components/Restore/WalletRecovery';
import WalletRecoveryProtection from './components/Restore/WalletRecoveryProtection';
import WalletRecoveryLoading from './components/Restore/WalletRecoveryLoading';
import WalletRecoveryComplete from './components/Restore/WalletRecoveryComplete';
import WalletCreationLoading from './components/Create/Complete/WalletCreationLoading';
import WalletCreationComplete from './components/Create/Complete/WalletCreationComplete';
import BalanceScreen from './components/Balance/BalanceScreen';
import useSecureStorage from './hooks/useSecureStorage';
import WalletScreen from './components/Wallet/WalletScreen';
import WalletScreenDetail from './components/Wallet/WalletScreenDetail';
import PaymentScreen from './components/Payment/PaymentScreen';
import NFTScreen from './components/NFT/NFTScreen';
import NFTDetailsScreen from './components/NFT/NFTDetailsScreen';
import NFTTransferCompleteScreen from './components/NFT/NFTTransferCompleteScreen';
import SeeMoreScreen from './components/More/SeeMoreScreen';
import SecuritySettings from './components/More/Security/SecuritySettings';
import ChangePassword from './components/More/Security/ChangePassword';
import AutoLockScreen from './components/More/Security/AutoLockScreen';
import SecuritySecretPhraseWarning from './components/More/Security/SecuritySecretPhraseWarning';
import SecuritySecretPhraseConfirmation from './components/More/Security/SecuritySecretPhraseConfirmation';
import LanguageSettingsScreen from './components/More/Language/LanguageSettingsScreen';
import TokenList from './components/More/TokenList';
import TokenCreate from './components/More/TokenCreate';
import WithdrawScreen from './components/Balance/WithdrawScreen';
import WithdrawCompleteScreen from './components/Balance/WithdrawCompleteScreen';
import ReceiveScreen from './components/Balance/ReceiveScreen';
import SwapScreen from './components/Swap/SwapScreen';
import SwapCompleteScreen from './components/Swap/SwapCompleteScreen';
import DonationScreen from './components/Donation/DonationScreen';
import DonationCompleteScreen from './components/Donation/DonationCompleteScreen';
import UnlockScreen from './components/Unlock/UnlockScreen';
import ResetWalletScreen from './components/Unlock/ResetWalletScreen';

import WebApp from '@twa-dev/sdk'
import { BackButton } from '@twa-dev/sdk/react';

import { useSelector } from 'react-redux';
import { RootState } from './store';

import { ThemeProvider } from './context/ThemeContext';

// 릴리즈모드 console.log 비활성
if (process.env.NODE_ENV !== 'development') {
	console.log = () => {};
  console.info = () => {};
	console.warn = () => {};
	console.error = () => {};
}

const App: React.FC = () => {
  let location = useLocation();
  let navigate = useNavigate();

  const [isI18nInitialized, setI18nInitialized] = useState<boolean>(false); // i18n 초기화 상태
  const [loca, setLoca] = useState<boolean>(true)
  const [isUnlocked, setIsUnlocked] = useState<boolean>(false);
  const [isRegister, setIsRegister] = useState<boolean>(false);

  const { getLogin } = useSecureStorage()

  let unlockState: any = useSelector((state: RootState) => state.unlock);

  useEffect(() => {
    if (location.pathname === '#') setLoca(false)
    else if (location.pathname === '/') setLoca(false)
    else if (location.pathname === '/CreateWallet') setLoca(false)
    else if (location.pathname === '/UnlockScreen') setLoca(false)
    else if (location.pathname === '/BalanceScreen') setLoca(false)
    else if (location.pathname === '/WalletScreen') setLoca(false)
    else if (location.pathname === '/PaymentScreen') setLoca(false)
    else if (location.pathname === '/NFTScreen') setLoca(false)
    else setLoca(true)
  }, [location])

  // 지갑 생성이 완료된 경우 BalanceScreen 으로 보냄
  useEffect(() => {
    const initializeI18n = async () => {
      await initI18n();
      setI18nInitialized(true); // 초기화가 완료되면 상태 업데이트
    };

    initializeI18n();
    // initializeTgApi();

    // checkLockStatus()
  }, [])

  useEffect(() => {
    const initializeTgApi = async () => {
      console.log(`App.tsx 텔레그램 플랫폼 : ${WebApp.platform}`)
      await initTgPlatform()
      // if(false === WebApp.BiometricManager.isInited) {
      //   console.log(`App.tsx 텔레그램 생체인증 초기화 시작`)
      //   try{
      //     WebApp.BiometricManager.init(TgWebAppBioInitApiCallBack)
      //   } catch(error) {
      //     console.log(`App.tsx useEffect > initializeTgApi > WebApp not support`, error)
      //     await checkUnlockStatus()
      //   }
      // } else {
      //   console.log(`App.tsx useEffect > initializeTgApi > checkUnlockStatus 확인`)
      //   await checkUnlockStatus()
      // }
    };

    // const checkUnlockStatus = async () => {
    //   const fullPath = location.pathname + location.hash

    //   console.log(`//------------------------------`)
    //   console.log(`App.tsx unlockState`, unlockState.value)
  
    //   if(true === unlockState.value) {
    //     setIsUnlocked(true);
    //   } else {
    //     setIsUnlocked(false);
    //   }
  
    //   checkUnlock(fullPath)
  
    //   if (fullPath === '/BalanceScreen' || 
    //       fullPath === '/CreateWallet' ||
    //       fullPath === '/UnlockScreen'
    //     ) {
    //     // BalanceScreen으로 들어오면 텔레그램 백 버튼 숨김 (닫기 버튼으로 변경됨)
    //     WebApp.BackButton.hide();
    //   } else {
    //     // 텔레그램 미니앱의 백 버튼 표시
    //     WebApp.BackButton.show();
    //   }      
    // };

    initializeTgApi()
    // checkUnlockStatus()
    
  }, [location]);

  // 텔레그램 WebApp 초기화
  const initTgPlatform = async () => {
    console.log(`App.tsx 텔레그램 플랫폼 : ${WebApp.platform}`)
    const platform = WebApp.platform
    
    if('android' === platform || 'android_x' === platform || 'ios' === WebApp.platform) {
      if(false === WebApp.BiometricManager.isInited) {
        WebApp.BiometricManager.init(TgWebAppBioInitApiCallBack)
      } else {
        await checkUnlockStatus()
      }
    } else {
        await checkUnlockStatus()
    }
  }

  // 텔레그램 생체인증 시작
	const TgWebAppBioInitApiCallBack = async () => {
    checkUnlockStatus()
    WebApp.ready()
  }

  const checkUnlockStatus = async () => {
    const fullPath = location.pathname + location.hash

    console.log(`//------------------------------`)
    console.log(`App.tsx unlockState`, unlockState.value)

    if(true === unlockState.value) {
      setIsUnlocked(true);
    } else {
      setIsUnlocked(false);
    }

    checkUnlock(fullPath)
    
    const platform = WebApp.platform

    if (fullPath === '/BalanceScreen' || 
        fullPath === '/CreateWallet' ||
        fullPath === '/UnlockScreen'
      ) {
      // BalanceScreen으로 들어오면 텔레그램 백 버튼 숨김 (닫기 버튼으로 변경됨)
      if('android' === platform || 'android_x' === platform || 'ios' === WebApp.platform || 'tdesktop' === WebApp.platform) {
        WebApp.BackButton.hide();
      } 
    } else {
      // 텔레그램 미니앱의 백 버튼 표시
      if('android' === platform || 'android_x' === platform || 'ios' === WebApp.platform || 'tdesktop' === WebApp.platform) {
        WebApp.BackButton.show();
      }
    }      
  }

  // const checkLockStatus = async () => {
  //   console.log(`checkLockStatus`)
    
  //   setIsUnlocked(true);
  //   checkLogin()
  // };

  // const checkLogin = async () => {
  //   const loginStatus = await getLogin()

  //   console.log(`checkLogin 지갑 등록 상태 : ${loginStatus}`)
  //   console.log(`checkLogin 잠금해제 상태 isUnlocked : ${isUnlocked}`)
  //   if (true === loginStatus) {
  //     // 지갑 등록 상태
  //     // 언락 상태 확인
  //     setIsRegister(loginStatus)
  //     if (false == isUnlocked) {
  //       // 잠금 상태이면 UnlockScreen 으로 이동
  //       console.log(`지갑 잠금해제 상태 1 : ${isUnlocked}`)
  //       navigate(`/`)
  //     } else {
  //       // 언락 상태이면 BalanceScreen 으로 이동
  //       console.log(`지갑 잠금해제 상태 2 : ${isUnlocked}`)
  //       navigate(`BalanceScreen`)
  //     }
  //   } else {
  //     // 지갑 등록 상태가 아니라면
  //     // 지갑 생성 페이지로 이동
  //     console.log(`지갑 등록 상태 3 : ${loginStatus}`)
  //     navigate(`CreateWallet`)
  //   }

  //   return loginStatus;
  // }

  // 언락상태 확인
  const checkUnlock = async (location: string) => {
    const loginStatus = await getLogin()
    setIsRegister(loginStatus)
    
    const unlockStatus = unlockState.value;

    console.log(`App.tsx checkUnlock location : ${location} | loginStatus : ${loginStatus} | isRegister : ${isRegister} | isUnlocked : ${unlockStatus}`)

    if (false === loginStatus) {
      // 지갑 등록이 되지 않은 상태
      if ('/CreateWallet' !== location &&
        '/WalletProtection' !== location &&
        '/SecretPhraseGeneration' !== location &&
        '/SecretPhraseWarning' !== location &&
        '/SecretPhraseConfirmation' !== location &&
        '/SecretPhraseCheck' !== location &&
        '/WalletRecovery' !== location &&
        '/WalletRecoveryProtection' !== location &&
        '/WalletRecoveryLoading' !== location &&
        '/WalletRecoveryComplete' !== location &&
        '/WalletCreationLoading' !== location &&
        '/WalletCreationComplete' !== location
      ) {
        navigate('/CreateWallet');
      }
    }
    else if (true === loginStatus && false === unlockStatus) {
      // 지갑 등록 되고 잠금 상태
      if ('/' !== location && '/UnlockScreen' !== location && '/ResetWalletScreen' !== location) {
        // UnlockScreen 이외 페이지로 이동 불가
        navigate('/UnlockScreen');
      }
    }
    else if (true == loginStatus && true === unlockStatus) {
      // 지갑 등록 되고 언락 상태
      if ('/BalanceScreen' !== location &&
        '/WalletScreen' !== location &&
        '/WalletScreenDetail' !== location &&
        '/PaymentScreen' !== location &&
        '/NFTScreen' !== location &&
        '/NFTDetailsScreen' !== location &&
        '/NFTTransferCompleteScreen' !== location &&
        '/SeeMoreScreen' !== location &&
        '/SecuritySettings' !== location &&
        '/ChangePassword' !== location &&
        '/AutoLockScreen' !== location &&
        '/SecuritySecretPhraseWarning' !== location &&
        '/SecuritySecretPhraseConfirmation' !== location &&
        '/LanguageSettingsScreen' !== location &&
        '/TokenList' !== location &&
        '/TokenCreate' !== location &&
        '/WithdrawScreen' !== location &&
        '/WithdrawCompleteScreen' !== location &&
        '/ReceiveScreen' !== location &&
        '/SwapScreen' !== location &&
        '/SwapCompleteScreen' !== location &&
        '/DonationScreen' !== location &&
        '/DonationCompleteScreen' !== location
      ) {
        navigate('/BalanceScreen');
      }
      else {
      }

    }
  }

  // 텔레그램 backbutton 처리
  const handleTgBackButton = async () => {
    const fullPath = location.pathname + location.hash
    if('/BalanceScreen' !== fullPath && '/CreateWallet' !== fullPath) {
        // 홈화면, 지갑생성 시작 화면 이 아닌 경우는 이전 페이지로 이동
        navigate(-1)
    } else {
      // WebApp.close()
    }
  };

  // i18n 초기화가 완료되기 전에는 로딩 화면을 표시
  if (!isI18nInitialized) {
    // return <div>Loading translations...</div>;
    console.log(`Loading translations`)
    return <div></div>;
  }


// 뷰포트 변경 이벤트 핸들러 등록
  WebApp.onEvent("viewportChanged", () => {
    console.log(`WebApp.isExpanded : ${WebApp.isExpanded}`)

    if (true == WebApp.isExpanded) {
      console.log("미니앱이 축소되었습니다.");
      // 미니앱이 축소된 상태에서 필요한 동작 수행
      // 잠금상태로 전환
      setIsUnlocked(false);
    } else {
      console.log("미니앱이 확장되었습니다.");
      // 미니앱이 확장된 상태에서 필요한 동작 수행
    }
  });

  return (
    <div className='App'>
      <ThemeProvider>
        <BackButton onClick={() => handleTgBackButton()} />
        {loca && (<Header />)}
        <Routes>
          <Route path="/" element={<UnlockScreen />} />
          <Route path="/UnlockScreen" element={<UnlockScreen />} />
          <Route path="/ResetWalletScreen" element={<ResetWalletScreen />} />
          <Route path="/CreateWallet" element={<CreateWallet />} />
          <Route path="/WalletProtection" element={<WalletProtection />} />
          <Route path="/SecretPhraseGeneration" element={<SecretPhraseGeneration />} />
          <Route path="/SecretPhraseWarning" element={<SecretPhraseWarning />} />
          <Route path="/SecretPhraseConfirmation" element={<SecretPhraseConfirmation />} />
          <Route path="/SecretPhraseCheck" element={<SecretPhraseCheck />} />
          <Route path="/WalletRecovery" element={<WalletRecovery />} />
          <Route path="/WalletRecoveryProtection" element={<WalletRecoveryProtection />} />
          <Route path="/WalletRecoveryLoading" element={<WalletRecoveryLoading />} />
          <Route path="/WalletRecoveryComplete" element={<WalletRecoveryComplete />} />
          <Route path="/WalletCreationLoading" element={<WalletCreationLoading />} />
          <Route path="/WalletCreationComplete" element={<WalletCreationComplete />} />
          <Route path="/BalanceScreen" element={<BalanceScreen />} />
          <Route path="/WalletScreen" element={<WalletScreen />} />
          <Route path="/WalletScreenDetail" element={<WalletScreenDetail />} />
          <Route path="/PaymentScreen" element={<PaymentScreen />} />
          <Route path="/NFTScreen" element={<NFTScreen />} />
          <Route path="/NFTDetailsScreen" element={<NFTDetailsScreen />} />
          <Route path="/NFTTransferCompleteScreen" element={<NFTTransferCompleteScreen />} />
          <Route path="/SeeMoreScreen" element={<SeeMoreScreen />} />
          <Route path="/SecuritySettings" element={<SecuritySettings />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />
          <Route path="/AutoLockScreen" element={<AutoLockScreen />} />
          <Route path="/SecuritySecretPhraseWarning" element={<SecuritySecretPhraseWarning />} />
          <Route path="/SecuritySecretPhraseConfirmation" element={<SecuritySecretPhraseConfirmation />} />
          <Route path="/LanguageSettingsScreen" element={<LanguageSettingsScreen />} />
          <Route path="/TokenList" element={<TokenList />} />
          <Route path="/TokenCreate" element={<TokenCreate />} />
          <Route path="/WithdrawScreen" element={<WithdrawScreen />} />
          <Route path="/WithdrawCompleteScreen" element={<WithdrawCompleteScreen />} />
          <Route path="/ReceiveScreen" element={<ReceiveScreen />} />
          <Route path="/SwapScreen" element={<SwapScreen />} />
          <Route path="/SwapCompleteScreen" element={<SwapCompleteScreen />} />
          <Route path="/DonationScreen" element={<DonationScreen />} />
          <Route path="/DonationCompleteScreen" element={<DonationCompleteScreen />} />
        </Routes>
      </ThemeProvider>
    </div>
  );
};

export default App;
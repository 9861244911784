import { configureStore, createSlice, PayloadAction } from '@reduxjs/toolkit';

// login 상태 타입 정의
interface LoginState {
    value: boolean;
}

// unlock 상태 타입 정의
interface UnlockState {
    value: boolean;
}

// MyInfo 상태 타입 정의 (예시로 string 배열로 가정)
type MyInfoState = string[];

// Darkmode 상태 타입 정의
interface DarkmodeState {
    value: boolean;
}

// Languages 상태 타입 정의
interface LanguagesState {
    value: string;
}

// Pincode 상태 타입 정의
interface PincodeState {
    value: string;
}

interface MnemonicState {
    value: string;
}

// HeaderText 상태 타입 정의
interface HeaderText {
    link: string;
    title: string;
}

// login 슬라이스
let login = createSlice({
    name: 'login',
    initialState: { value: false } as LoginState,
    reducers: {
        checkLogin(state, action: PayloadAction<boolean>) {
            state.value = action.payload;
        },
    },
});

// unlock 슬라이스
let unlock = createSlice({
    name: 'unlock',
    initialState: { value: false } as UnlockState,
    reducers: {
        checkUnlock(state, action: PayloadAction<boolean>) {
            state.value = action.payload;
        },
    },
});

// MyInfo 슬라이스
let MyInfo = createSlice({
    name: 'MyInfo',
    initialState: [] as MyInfoState,
    reducers: {
        checkMyInfo(state, action: PayloadAction<string[]>) {
            return action.payload;
        },
    },
});

// Darkmode 슬라이스
let Darkmode = createSlice({
    name: 'Darkmode',
    initialState: { value: false } as DarkmodeState,
    reducers: {
        changeDark(state, action: PayloadAction<boolean>) {
            localStorage.setItem('darkMode', action.payload ? 'light' : 'dark');
            state.value = action.payload;
        },
    },
});

// Languages 슬라이스
let Languages = createSlice({
    name: 'Languages',
    initialState: { value: 'en-US' } as LanguagesState,
    reducers: {
        changeLanguages(state, action: PayloadAction<string>) {
            localStorage.setItem('language', action.payload);
            state.value = action.payload;
        },
    },
});

// pincode 슬라이스
let pincode = createSlice({
    name: 'pincode',
    initialState: { value: '' } as PincodeState,
    reducers: {
        checkPincode(state, action: PayloadAction<string>) {
            state.value = action.payload;
        },
    },
});

// mnemonic 슬라이스
let mnemonic = createSlice({
    name: 'mnemonic',
    initialState: { value: '' } as MnemonicState,
    reducers: {
        checkMnemonic(state, action: PayloadAction<string>) { // PayloadAction을 string[]로 변경
            state.value = action.payload;
        },
    },
});

// headerText 슬라이스
let headerText = createSlice({
    name: 'headerText',
    initialState: { "link": "", "title": "" } as HeaderText,
    reducers: {
        checkHeaderText(state, action: PayloadAction<HeaderText>) {
            state.link = action.payload.link;
            state.title = action.payload.title;
        },
    },
});

// 액션 export
export let { checkLogin } = login.actions;
export let { checkUnlock } = unlock.actions;
export let { checkMyInfo } = MyInfo.actions;
export let { changeDark } = Darkmode.actions;
export let { changeLanguages } = Languages.actions;
export let { checkPincode } = pincode.actions;
export let { checkMnemonic } = mnemonic.actions;
export let { checkHeaderText } = headerText.actions;

// 스토어 설정 및 타입 정의
const store = configureStore({
    reducer: {
        login: login.reducer,
        unlock: unlock.reducer,
        MyInfo: MyInfo.reducer,
        Darkmode: Darkmode.reducer,
        Languages: Languages.reducer,
        pincode: pincode.reducer,
        mnemonic: mnemonic.reducer,
        headerText: headerText.reducer,
    },
});

// RootState와 AppDispatch 타입 정의
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

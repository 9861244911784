import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../context/ThemeContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Settings, LockKeyhole } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, checkUnlock } from '../store';
import CustomText from '../styles/CustomText';


const MainPageHeader: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	const { t } = useTranslation();
	let headerText = useSelector((state: RootState) => state.headerText);
	let dispatch = useDispatch();
	
	const handleLock = () => {
		console.log(`UnlockScreen handlePinCodeintAuthSuccess`)
		dispatch(checkUnlock(false))
		setTimeout(() => {
            // 잠금해제 페이지로 이동
            navigate(`/UnlockScreen`)
        }, 500)
	}

	const styles = {
		container: {
			width:"calc(100% - 48px)",
			height: "30px",
			padding: "12px 24px",
			display: "flex",
			alignItems: 'center',
			justifyContent: 'space-between',
			top: "0",
			left: "0",
			background: theme.background_03,
			position: "fixed",
			zIndex: 100
		} as React.CSSProperties,
		iconBox: {
			display: "flex",
			alignItems: 'center',
			justifyContent: 'flex-end',
			gap: 20
		} as React.CSSProperties,
		icon: {
			width: "22px",
			height: "22px",
			cursor: 'pointer'
		} as React.CSSProperties,
		text: {
			color: theme.text_01,
			fontSize: "20px",
			display: "flex",
			alignItems: 'center',
			justifyContent: 'center',
			// paddingBottom: "3px"
		} as React.CSSProperties,
	}
	
	return (
		<div style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
			<CustomText style={{ ...styles.text }}>{headerText.title}</CustomText>
			<div style={{ ...styles.iconBox }}>
				<div style={{ ...styles.icon }} onClick={() => handleLock()}>
					<LockKeyhole size={22} color={theme.text_01} />
				</div>
				<div style={{ ...styles.icon }} onClick={() => navigate(`/SeeMoreScreen`)}>
					<Settings size={22} color={theme.text_01} />
				</div>
			</div>
		</div>
	)
}

export default MainPageHeader

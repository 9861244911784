import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeContext } from '../../context/ThemeContext';
import { CircleAlert } from 'lucide-react';
import { ethers } from 'ethers'

import DatePicker from 'react-datepicker'; // react-datepicker 사용
import 'react-datepicker/dist/react-datepicker.css'; // DatePicker 스타일 추가
import { Spin, Modal, Button } from 'antd';

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
import Container from '../Common/Container';
import CourierText from '../../styles/CourierText';
import CustomListCardWithIcon from '../Componentization/Card/CustomListCardWithIcon';
import CustomListCard from '../Componentization/Card/CustomListCard';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkHeaderText, checkPincode, RootState } from '../../store';

import { getCustomChainTransaction } from '../../hooks/useFetchBalance';

import useWallet from '../../hooks/useWallet';
import useEthereum from '../../hooks/useEthereum';
import useEthereumOther from '../../hooks/useEthereumOther';
import useSecureStorage from '../../hooks/useSecureStorage';
import useTokenSwap from '../../hooks/useTokenSwap';
import MainPageHeader from '../../nav/MainPageHeader';
import NavigationBar from '../../nav/NavigationBar';
import i18next from 'i18next'
import moment from 'moment'

import { ParamType } from 'ethers/lib/utils'
import CustomTextInput from '../Componentization/CustomTextInput';
import PinCodeScreen from '../Authentication/PinCodeScreen';
import Spinner from '../Componentization/Modal/Spinner';

// const DEFAULT_SWAP_CONTRACT_ADDRESS = '0xbe23565E20f261e447ee49cC549daB299bA712e2' // DIVC - BUFF 스왑 컨트랙트
const TOKEN_ADDRESS = ''

interface Asset {
	id: number
	name: string
	symbol: string
	balance: string
	icon: any
}

const emptyToken: Asset = {
	id: 0,
	name: '',
	symbol: '',
	balance: '',
	icon: '/img/icon_buff.png'
}

const SwapScreen: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const { t } = useTranslation();

	const { getWalletAddress } = useSecureStorage()
	const [address, setAddress] = useState<string | null>(null)
	const [isCrossChain, setIsCrossChain] = useState(false)
	const [isSwapped, setIsSwapped] = useState(false)
	const [amount, setAmount] = useState('')
	const [isApproving, setIsApproving] = useState(false)
	const [approveAmount, setApproveAmount] = useState('')
	const [privateKey, setPrivateKey] = useState<string | null>(null)
	const [modalVisible, setModalVisible] = useState(false)
	const [modalContent, setModalContent] = useState<'approve' | 'swap'>('approve')
	const [isSwapDisable, setisSwapDisable] = useState(false)
	const [swapContractAddress, setswapContractAddress] = useState<string>('')
	const [showFingerprintAuth, setShowFingerprintAuth] = useState(false)
	const [showPinCodeAuth, setShowPinCodeAuth] = useState(false)
	// const swapContractAddress = token.swapContractAddress || DEFAULT_SWAP_CONTRACT_ADDRESS

	let signer: ethers.Signer | null = null

	const location = useLocation();
	const [token, setToken] = useState(location.state === null ? null : location.state.token);

	useEffect(() => {
		dispatch(checkHeaderText({ "link": "SwapScreen", "title": t('stack_header_message_13') }))
	}, [])

	useEffect(() => {
		setToken(emptyToken)
		if (location.state) {
			const newToken = location.state.token
			console.log(`스왑 페이지 newToken`, newToken)
			if (newToken) {
				setAmount('')
				setApproveAmount('')
				setToken(newToken)

				console.log(`스왑 페이지 설정 토큰`, newToken)

				let contractAddress = ''
				if ('QCT1' === newToken.name) {
					contractAddress = process.env.REACT_APP_QCT1_SWAP_CONTRACT_ADDRESS!
					console.log(`Set QCO - QCT1 Swap Contract`, contractAddress);
				} else if ('QCT2' === newToken.name) {
					contractAddress = process.env.REACT_APP_QCT2_SWAP_CONTRACT_ADDRESS!
					console.log(`Set QCO - QCT2 Swap Contract`, contractAddress);
				}

				setswapContractAddress(contractAddress);

				if (null != contractAddress && 0 >= contractAddress.length) {
					setisSwapDisable(true)
				} else {
					setisSwapDisable(false)
				}
			}
		}
	}, [location.state]);

	useEffect(() => {
		const fetchAddress = async () => {
			const walletAddress = await getWalletAddress()
			setAddress(walletAddress)
		}
		fetchAddress()
	}, [])

	const tokenAddress = token.address || TOKEN_ADDRESS

	// const imageMap = {
	// 	'@assets/img/icon_buff.png': require('@assets/img/icon_buff.png'),
	// 	'@assets/img/icon_qco.png': require('@assets/img/icon_qco.png'),
	// 	'@assets/img/icon_tether_usdt.png': require('@assets/img/icon_tether_usdt.png')
	// };

	const {
		swapCoinToToken,
		swapTokenToCoin,
		approveTokens,
		isApprovalNeeded,
		loading,
		transactionHash,
		error,
		coinToTokenRate,
		tokenToCoinRate,
		setIsApprovalNeeded,
		isLoading,
		setIsLoading,
		checkAllowance,
		fetchContractData
	} = useTokenSwap(swapContractAddress, tokenAddress)

	const { retrieveEncryptedData } = useSecureStorage()

	const verificationBeforeSwap = async () => {
		setShowPinCodeAuth(true)
	}

	const handleSigner = async (pin: string) => {
		try {
			const walletAddress: any = address
			const data = await retrieveEncryptedData(walletAddress, pin)
			const pvKey: any = data.privateKey

			if (null == pvKey) {
				alert(i18next.t('template_swap_screen_message_03'));
				setModalVisible(false)
				return false
			}

			const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL)
			const wallet = new ethers.Wallet(pvKey, provider)
			signer = wallet
			// setSigner(wallet)
			return true
		} catch (error) {
			console.log('Error connecting wallet:', error)
			alert(i18next.t('template_swap_screen_message_03'));
			setModalVisible(false)
		}
		return false
	}

	const handleSwap = async () => {
		setModalVisible(true)
		if (!amount || isNaN(parseFloat(amount))) {
			alert(i18next.t('template_swap_screen_message_01'))
			setModalVisible(false)
			return
		}

		if (!signer) {
			alert(i18next.t('template_swap_screen_message_03'))
			setModalVisible(false)
			return
		}

		const owner = await signer.getAddress()
		const contractData = await fetchContractData()
		console.log(`스왑 컨트랙트 정보`, contractData)
		if (!contractData) {
			alert(i18next.t('template_swap_screen_message_05'))
			setModalVisible(false)
			return
		}

		const { ethBalance, tokenBalance, coinToTokenRate, tokenToCoinRate } = contractData
		const amountInWei = ethers.utils.parseUnits(amount.replace(/,/g, ''), 18)
		const isAllowed = await checkAllowance(amount, owner)

		// tokken approve 가 되어 있지 않으면 approve 진행
		if (!isAllowed) {
			await handleApprove()
			return
		}

		try {
			setIsLoading(true)
			setModalContent('swap')

			let receipt = null
			if (!isSwapped) {
				const requiredTokenAmount = amountInWei.mul(coinToTokenRate)
				if (tokenBalance.lt(requiredTokenAmount)) {
					// Toast.show({
					// 	type: 'error',
					// 	position: 'bottom',
					// 	text1: i18next.t('template_swap_screen_message_06'),
					// 	text2: i18next.t('template_swap_screen_message_07'),
					// 	visibilityTime: 1000
					// })
					alert(i18next.t('template_swap_screen_message_06'))
					setIsLoading(false)
					setModalVisible(false)
					return
				}
				receipt = await swapCoinToToken(amount.replace(/,/g, ''), signer)
			} else {
				const requiredEthAmount = amountInWei.div(tokenToCoinRate)
				if (ethBalance.lt(requiredEthAmount)) {
					// Toast.show({
					// 	type: 'error',
					// 	position: 'bottom',
					// 	text1: i18next.t('template_swap_screen_message_08'),
					// 	text2: i18next.t('template_swap_screen_message_09'),
					// 	visibilityTime: 1000
					// })
					alert(i18next.t('template_swap_screen_message_08'))
					setIsLoading(false)
					setModalVisible(false)
					return
				}
				receipt = await swapTokenToCoin(amount.replace(/,/g, ''), signer)
			}

			if (receipt) {
				setIsLoading(false)
				setModalVisible(false)

				navigate('/SwapCompleteScreen', {
					state: {
						transactionData: {
							transactionHash: receipt.transactionHash,
							amount: amount,
							isSwapped: isSwapped
						}
					}
				})

				// @ts-ignore
				// navigation.navigate('SwapCompleteScreen', {
				// 	transactionData: {
				// 		transactionHash: receipt.transactionHash,
				// 		amount,
				// 		isSwapped
				// 	}
				// })
			}
		} catch (error) {
			console.error('Error during swap:', error)
			setIsLoading(false)
			setModalVisible(false)
		}
	}

	const handleApprove = async () => {
		if (!signer) return
		setIsLoading(true)
		setIsApproving(true)
		setModalContent('approve')
		setModalVisible(true)

		try {
			const response = await approveTokens(approveAmount.replace(/,/g, ''), signer)
			if (response) {
				setIsApproving(false)
				setIsApprovalNeeded(false)
				setModalContent('swap')
				handleSwap()
			} else {
				setIsLoading(false)
				setModalVisible(false)
			}
		} catch (error) {
			console.error('Error during token approval:', error)
			setIsLoading(false)
			setModalVisible(false)
		}
	}

	const handleMax = async () => {
		if (signer) {
			let balance
			let balanceInTokens
			if (!isSwapped) {
				balance = await signer.getBalance()
				const formatBalance = ethers.utils.formatUnits(balance, 18)
				balanceInTokens = parseFloat(formatBalance).toFixed(4)
			} else {
				balance = token.balance
				balanceInTokens = parseFloat(balance).toFixed(4)
			}
			setApproveAmount(formatNumberWithCommas(balanceInTokens))
			setAmount(balanceInTokens)
		}
	}

	const handlePinCodeintAuthSuccess = async (pin: string) => {
		setShowPinCodeAuth(false)
		if (true == await handleSigner(pin)) {
			await handleSwap()
		}
	}

	const formatNumberWithCommas = (number: string) => {
		if (!number) return ''
		const parts = number.split('.')
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		return parts.join('.')
	}

	const styles = {
		container: {
			backgroundColor: theme.background_03,
			padding: "60px 5px 0px",
		} as React.CSSProperties,
		containerBox: {
			borderTop: `1px solid ${theme.border_04}`,
			borderLeft: `1px solid ${theme.border_04}`,
			borderRight: `1px solid ${theme.border_04}`,
			borderTopLeftRadius: 30,
			borderTopRightRadius: 30,
			padding: "24px"
		} as React.CSSProperties,
		header: {
			width: "100%",
			boxSizing: "border-box",
			display: 'flex',
			justifyContent: 'flex-start',
			marginBottom: 5,
			padding: "0 15px",
			gap: 20,
		} as React.CSSProperties,
		tab: {
			width: "auto"
		} as React.CSSProperties,
		tabText: {
			fontSize: 16,
			color: theme.text_03
		} as React.CSSProperties,
		swapContainer: {
			backgroundColor: theme.card_background_06
		} as React.CSSProperties,
		sendlabel: {
			fontSize: 12,
			color: theme.text_03,
			marginBottom: 19
		} as React.CSSProperties,
		receiveLabel: {
			fontSize: 12,
			color: theme.text_19,
			marginBottom: 10
		} as React.CSSProperties,
		coinSectionWrapper: {
			position: 'relative'
		} as React.CSSProperties,
		coinSection: {} as React.CSSProperties,
		sendCoinBox: {
			backgroundColor: theme.card_background_01,
			padding: "15px 18px",
			borderRadius: 10,
		} as React.CSSProperties,
		receiveCoinBox: {
			backgroundColor: theme.card_background_05,
			padding: "15px 18px",
			borderRadius: 10,
		} as React.CSSProperties,
		coinContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center'
		} as React.CSSProperties,
		coinInfoContainer: {
			width: "100%",
			display: 'flex',
			alignItems: 'center',
			marginBottom: 20
		} as React.CSSProperties,
		coinImageContainer: {
			width: 38,
			height: 38,
			borderRadius: 19,
			backgroundColor: theme.card_background_02,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		} as React.CSSProperties,
		coinIcon: {
			width: 38,
			height: 38
		} as React.CSSProperties,
		coinInfo: {
			marginLeft: 10,
			flex: 1
		} as React.CSSProperties,
		coinName: {
			fontSize: 15,
			color: theme.text_01
		} as React.CSSProperties,
		coinSubText: {
			fontSize: 14,
			color: theme.text_03
		} as React.CSSProperties,
		coinAmount: {
			width: "100%",
			fontSize: 18,
			color: theme.text_01,
			textAlign: 'left',
			background: "inherit",
			borderRight: "none",
			borderLeft: "none",
			borderTop: "none",
			outline: "none",
			borderBottom: `1px solid ${theme.border_08}`,

		} as React.CSSProperties,
		swapIconContainer: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: "translate(-30px, -15px)",
			zIndex: 1
		} as React.CSSProperties,
		swapIcon: {
			width: 40,
			height: 40
		} as React.CSSProperties,
		note: {
			fontSize: 12,
			color: theme.text_03,
			marginLeft: 2
		} as React.CSSProperties,
		noteContainer: {
			padding: "20px 0",
			gap: 8
		} as React.CSSProperties,
		noteRow: {
			display: 'flex',
			alignItems: 'center'
		} as React.CSSProperties,
		emptyContainer: {
			flexGrow: 1,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		} as React.CSSProperties,
		emptyText: {
			fontSize: 16
		} as React.CSSProperties,
		button: {
			width: "100%",
			backgroundColor: theme.button_07,
			borderRadius: 10,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: 50
		} as React.CSSProperties,
		disableButton: {
			backgroundColor: theme.button_06,
			borderRadius: 10,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: 50
		} as React.CSSProperties,
		buttonText: {
			color: theme.text_01,
			fontSize: 15
		} as React.CSSProperties,
		modalContainer: {
			flex: 1,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: theme.modal_background_01
		} as React.CSSProperties,
		modalContent: {
			backgroundColor: theme.modal_background_01,
			padding: 15,
			borderRadius: 10,
			display: 'flex',
			alignItems: 'center',
			width: '80%'
		} as React.CSSProperties,
		modalTitle: {
			fontSize: 18,
			marginBottom: 10
		} as React.CSSProperties,
		modalText: {
			fontSize: 14,
			color: theme.text_02,
			marginBottom: 20,
			textAlign: 'center'
		} as React.CSSProperties,
		modalBackground: {
			display: "flex",
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: theme.modal_background_01,
			position: "absolute",
			width: "100%",
			height: "100%",
			top: 0,
			left: 0,
			zIndex: 10
		} as React.CSSProperties,
		processingText: {
			marginTop: 10,
			fontSize: 16
		} as React.CSSProperties,
		activityIndicatorWrapper: {
			position: "absolute",
		} as React.CSSProperties,
	}

	return (
		<div className='container' style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
			<div style={{ width: "100%" }}>
				<div style={styles.header}>
					<button
						onClick={() => setIsCrossChain(false)}
						style={{
							...styles.tab,
							borderColor: !isCrossChain ? theme.primary : 'transparent',
						}}
					>
						<span style={{ ...styles.tabText, color: !isCrossChain ? theme.primary : theme.text_01, fontWeight: !isCrossChain ? 'bold' : 'normal' }}>
							{i18next.t('template_swap_screen_message_10')}
						</span>
					</button>

					<button
						onClick={() => setIsCrossChain(true)}
						style={{
							...styles.tab,
							borderColor: isCrossChain ? theme.primary : 'transparent',
						}}
					>
						<span style={{ ...styles.tabText, color: isCrossChain ? theme.primary : theme.text_01, fontWeight: isCrossChain ? 'bold' : 'normal' }}>
							{i18next.t('template_swap_screen_message_11')}
						</span>
					</button>
				</div>
				<div style={{ ...styles.containerBox, backgroundColor: theme.primaryBackgroundColor }}>
					{isCrossChain ? (
						<div style={styles.emptyContainer}>
							<CustomText style={{ ...styles.emptyText, color: theme.primaryText }}>
								{i18next.t('template_swap_screen_message_12')}
							</CustomText>
						</div>
					) : (
						<div style={{ ...styles.swapContainer, backgroundColor: theme.primaryBackgroundColor }}>
							<div style={styles.coinSectionWrapper}>
								<div style={{ ...styles.coinSection, marginBottom: 30 }}>
									<div style={styles.sendCoinBox}>
										<CustomText style={styles.sendlabel}>
											{i18next.t('template_swap_screen_message_13')}
										</CustomText>
										<div style={styles.coinContainer}>
											{!isSwapped ? (
												<div style={styles.coinInfoContainer}>
													<div style={styles.coinImageContainer}>
														<img
															src={'/img/icon_qco.png'}
															alt="coin"
															style={styles.coinIcon}
														/>
													</div>
													<div style={styles.coinInfo}>
														<div style={{ display: 'flex', justifyContent: 'space-between' }}>
															<CustomText style={styles.coinName}>QCO</CustomText>
															<div onClick={handleMax} style=
																{{ color: theme.primary, textDecorationLine: 'underline', textDecorationColor: theme.primary, fontSize: "13px" }}
															>
																{i18next.t('template_swap_screen_message_14')}
															</div>
														</div>
														<CustomText style={styles.coinSubText}>qChain</CustomText>
													</div>
												</div>
											) : (
												<div style={styles.coinInfoContainer}>
													<div style={styles.coinImageContainer}>
														<img src={token.icon} alt="coin" style={styles.coinIcon} />
													</div>
													<div style={styles.coinInfo}>
														<div style={{ display: 'flex', justifyContent: 'space-between' }}>
															<CustomText style={styles.coinName}>{token.symbol}</CustomText>
															<button onClick={handleMax} style=
																{{ color: theme.primary, textDecorationLine: 'underline', textDecorationColor: theme.primary }}
															>
																{i18next.t('template_swap_screen_message_14')}
															</button>
														</div>
														<CustomText style={styles.coinSubText}>qChain</CustomText>
													</div>
												</div>
											)}
										</div>
										<input
											onChange={(e) => {
												const numericValue = e.target.value.replace(/[^0-9.]/g, '');
												const parts = numericValue.split('.');
												if (parts.length > 2) {
													setAmount(numericValue);
												} else if (parts.length === 2 && parts[1].length > 6) {
													const decimalPart = parts[1].slice(0, 6);
													setAmount(`${parts[0]}.${decimalPart}`);
												} else {
													setAmount(numericValue);
												}
												setApproveAmount(numericValue);
											}}
											//   value={formatNumberWithCommas(approveAmount)}
											style={styles.coinAmount}
											placeholder={i18next.t('template_swap_screen_message_15')}
											type="text"
											maxLength={20}
										/>
									</div>
								</div>
								<div style={styles.swapIconContainer}>
									<button onClick={() => setIsSwapped(!isSwapped)}>
										<img src={'/img/icon_change_swap.png'} alt="swap icon" style={styles.swapIcon} />
									</button>
								</div>
								<div style={styles.coinSection}>
									<div style={{ ...styles.receiveCoinBox, backgroundColor: theme.card_background_05 }}>
										<CustomText style={styles.receiveLabel}>
											{i18next.t('template_swap_screen_message_16')}
										</CustomText>
										<div style={styles.coinContainer}>
											{!isSwapped ? (
												<div style={styles.coinInfoContainer}>
													<div style={styles.coinImageContainer}>
														<img
															src={token.icon || '/img/icon_bsc.png'}
															alt="coin"
															style={styles.coinIcon}
														/>
													</div>
													<div style={styles.coinInfo}>
														<CustomText style={styles.coinName}>{token.symbol}</CustomText>
														{/* <CustomText style={styles.coinSubText}>qChain</CustomText> */}
													</div>
												</div>
											) : (
												<div style={styles.coinInfoContainer}>
													<div style={styles.coinImageContainer}>
														<img src={'/img/icon_qco.png'} alt="coin" style={styles.coinIcon} />
													</div>
													<div style={styles.coinInfo}>
														<CustomText style={styles.coinName}>QCO</CustomText>
														<CustomText style={styles.coinSubText}>qChain</CustomText>
													</div>
												</div>
											)}
										</div>
										<input
											value={approveAmount === '' ? '0' : formatNumberWithCommas(approveAmount)}
											style={{ ...styles.coinAmount, borderBottom: "0px" }}
											readOnly
										/>
									</div>
								</div>
							</div>
							<div style={styles.noteContainer}>
								<div style={styles.noteRow}>
									<CircleAlert name="alert-circle-outline" size={16} color={theme.text_03} />
									<CustomText style={styles.note}>
										{i18next.t('template_swap_screen_message_17')}{' '}
										{!isCrossChain
											? i18next.t('template_swap_screen_message_10')
											: i18next.t('template_swap_screen_message_11')}{' '}
										{i18next.t('template_swap_screen_message_18')}
									</CustomText>
								</div>
								<div style={styles.noteRow}>
									<CircleAlert name="alert-circle-outline" size={16} color={theme.text_03} />
									<CustomText style={styles.note}>{i18next.t('template_swap_screen_message_19')}</CustomText>
								</div>
							</div>
							<button
								onClick={verificationBeforeSwap}
								style={{ ...styles.button, ...(isSwapDisable && styles.disableButton) }}
								disabled={isSwapDisable}
							>
								{!isSwapDisable ? (
									<CustomText style={styles.buttonText}>{i18next.t('template_swap_screen_message_20')}</CustomText>
								) : (
									<CustomText style={styles.buttonText}>{i18next.t('template_swap_screen_message_12')}</CustomText>
								)}
							</button>
						</div>
					)}
				</div>
				{modalVisible && (
					<div style={styles.modalBackground}>
						<Spinner />
					</div>
				)}

				{/* 토큰 approve 확인 모달 */}
				{/* {modalVisible && (
				<Modal transparent={true} animationType='fade' visible={modalVisible}>
					<View style={styles.modalContainer}>
						<View style={styles.modalContent}>
							{modalContent === 'approve' ? (
								<>
									<CustomText style={[styles.modalTitle, { color: theme.darkModeModalTextColor }]}>
										{i18next.t('template_swap_screen_message_21')}
									</CustomText>
									<CustomText style={[styles.modalText, { color: theme.darkModeModalTextColor }]}>
										{i18next.t('template_swap_screen_message_22')}
									</CustomText>
									<ActivityIndicator size='large' color={theme.primary} />
								</>
							) : (
								<>
									<CustomText style={[styles.modalTitle, { color: theme.darkModeModalTextColor }]}>
										{i18next.t('template_swap_screen_message_23')}
									</CustomText>
									<CustomText style={[styles.modalText, { color: theme.darkModeModalTextColor }]}>
										{i18next.t('template_swap_screen_message_24')}
									</CustomText>
									<ActivityIndicator size='large' color={theme.primary} />
								</>
							)}
						</View>
					</View>
				</Modal>
			)} */}

				{/* {modalVisible && (
					<div style={styles.modalBackground}>
						<Modal
							open={modalVisible}
							footer={null}
							closable={false}
							centered
							styles={{
								body: styles.activityIndicatorWrapper,
							}}
						>
							<Spin size="large" />
							<p style={styles.processingText}>
								{i18next.t('template_withdraw_screen_message_25')}
							</p>
						</Modal>
					</div>
				)} */}

				{/* 핀코드 입력 모달 */}
				{showPinCodeAuth && (
					<div style={styles.modalBackground}>
						<PinCodeScreen onSuccess={handlePinCodeintAuthSuccess} unlock={false} />
					</div>
				)}

			</div>
		</div>
	)
}

export default SwapScreen

import React from 'react';
// import globalStyles from './globalStyles';
import useGlobalStyles from './globalStyles';

interface CourierTextProps extends Omit<React.HTMLAttributes<HTMLSpanElement>, 'style'> {
	style?: React.CSSProperties | React.CSSProperties[]; // React에서는 CSS 객체를 사용
	weight?: 'regular' | 'bold' | 'medium' | 'semi';

}

const CourierText: React.FC<CourierTextProps> = ({ style, weight = 'regular', ...rest }) => {
	let customStyle: React.CSSProperties;
	const globalStyles = useGlobalStyles();

	switch (weight) {
		case 'bold':
			customStyle = globalStyles.courierBold
			break
		default:
			customStyle = globalStyles.courierRegular
			break
	}

	// React에서는 스타일을 객체 병합으로 처리
	return (
		<div style={{ ...customStyle, ...(Array.isArray(style) ? Object.assign({}, ...style) : style) }} {...rest} />
	)
};


export default CourierText

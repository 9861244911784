import React, { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';

interface CustomTextInputProps {
	value: string;
	onChangeText: (text: string) => void;
	placeholder?: string;
	placeholderTextColor?: string;
	keyboardType?: 'default' | 'numeric' | 'email-address' | 'phone-pad' | 'number-pad';
	containerStyle?: React.CSSProperties;
	inputStyle?: React.CSSProperties;
	invalidStyle?: React.CSSProperties;
	isValid?: boolean;
	maxLength?: number;
	useMultiLine?: boolean;
	editable?: boolean;
}

const CustomTextInput: React.FC<CustomTextInputProps> = ({
	value,
	onChangeText,
	placeholder,
	placeholderTextColor,
	keyboardType = 'default',
	containerStyle,
	inputStyle,
	invalidStyle,
	isValid = true,
	maxLength,
	useMultiLine = false,
	editable = true,
}) => {
	const { theme } = useContext(ThemeContext)

	return (
		<textarea
			style={{
				...styles.input,
				borderColor: theme.textFieldBorderColor,
				...containerStyle,
				...(!isValid && value.length > 0 ? invalidStyle : {}),
				...inputStyle,
			}}
			value={value}
			onChange={(e) => onChangeText(e.target.value)}
			placeholder={placeholder}
			maxLength={maxLength}
			disabled={!editable}
			aria-invalid={!isValid}
		/>
	)
}

const styles = {
	input: {
		border: "none",
		outline: "none",
		padding: '10px',
		// borderRadius: '10px',
		width: '100%',
		boxSizing: 'border-box',
		background: "inherit",
		display: 'flex',
		alignItems: "flex-start",
		justifyContent: "flex-start",
		resize: 'none', // 크기 조절 막기
		lineHeight: '1.5',
	} as React.CSSProperties,
};

export default CustomTextInput

import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../context/ThemeContext';

import CustomButton from '../Componentization/CustomButton';
import CustomText from '../../styles/CustomText';
import useSecureStorage from '../../hooks/useSecureStorage';

import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import i18next from 'i18next'

const WalletRecoveryComplete: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	const { t } = useTranslation();
	const { setLogin, getLogin } = useSecureStorage()
	
	const handleStartService = async () => {
		await setLogin()
		await setTimeout(() => {
			navigate(`/UnlockScreen`)
		}, 500)
		
	}

	const styles = {
		content: {
			display: "flex",
			flexDirection: "column",
			justifyContent: 'center',
			alignItems: 'center',
			marginBottom: 30,
			marginTop: "150px"
		} as React.CSSProperties,
		image: {
			width: 75,
			height: 68,
			marginBottom: 20
		} as React.CSSProperties,
		title: {
			fontSize: 24,
			color: theme.text_05,
			textAlign: 'center'
		} as React.CSSProperties,
		footer: {
			width: '100%'
		} as React.CSSProperties,
		button: {
			backgroundColor: theme.button_02,
			borderRadius: 10,
			alignItems: 'center',
			width: '100%',
			height: 54,
			justifyContent: 'center'
		} as React.CSSProperties,
		buttonText: {
			color: theme.text_01,
			fontSize: 16
		} as React.CSSProperties,
	}
	
	return (
		<div className='container' style={{ backgroundColor: theme.primaryBackgroundColor }}>
			<div style={styles.content}>
				<img
					src="/img/Rectangle.png" // 실제 이미지 경로로 수정 필요
					alt="Recovery Complete"
					style={styles.image}
				/>
				<CustomText style={{ ...styles.title, color: theme.primaryText }}>
					{t('wallet_recovery_complete_message_04')}
				</CustomText>
				<CustomText style={{ ...styles.title, color: theme.primaryText }}>
					{t('wallet_recovery_complete_message_05')}
				</CustomText>
			</div>

			<div style={styles.footer}>
				<CustomButton
					containerStyle={{ ...styles.button, backgroundColor: theme.primary }}
					textStyle={styles.buttonText}
					onPress={handleStartService}
					text={t('wallet_recovery_complete_message_06')}
					withBackground={true}
					backgroundColor={theme.primaryTouchBackgroundColor}
				/>
			</div>
		</div>
	)
}

export default WalletRecoveryComplete

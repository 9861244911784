import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../../context/ThemeContext';
import { Check } from 'lucide-react';

import CustomButton from '../../Componentization/CustomButton';
import CustomText from '../../../styles/CustomText';
import Container from '../../Common/Container';
import CourierText from '../../../styles/CourierText';
import CustomListCardWithIcon from '../../Componentization/Card/CustomListCardWithIcon';
import CustomListCard from '../../Componentization/Card/CustomListCard';
import CustomSwtich from '../../Componentization/Switch/CustomSwtich';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkHeaderText, checkPincode, RootState } from '../../../store';

import useWallet from '../../../hooks/useWallet';
import useEthereum from '../../../hooks/useEthereum';
import useEthereumOther from '../../../hooks/useEthereumOther';
import useSecureStorage from '../../../hooks/useSecureStorage';
import useDefaultNetworkAndToken from '../../../hooks/useDefaultNetworkAndToken';

import MainPageHeader from '../../../nav/MainPageHeader';
import NavigationBar from '../../../nav/NavigationBar';

import i18next from 'i18next'
import PinCodeScreen from '../../Authentication/PinCodeScreen';

const SecuritySecretPhraseWarning: React.FC = () => {
	const { theme } = useContext(ThemeContext)
	let navigate = useNavigate();
	let dispatch = useDispatch();
	const { t } = useTranslation();

	const [showAuthModal, setShowAuthModal] = useState(false)

	const handleConfirmPress = () => {
		setShowAuthModal(true)
	}

	const handleAuthSuccess = (pin: string) => {
		setShowAuthModal(false)
		console.log(`비밀복구문구 확인 성공 pincode : ${pin}`)

		// 성공한 pincode 파라미터로 전달
		navigate('/SecuritySecretPhraseConfirmation', {
			state: { pincode: pin }
		})
	}

	useEffect(() => {
		dispatch(checkHeaderText({ "link": "SecuritySecretPhraseWarning", "title": t('stack_header_message_08') }))
	}, [])

	const styles = {
		container: {
			padding: "60px 5px 24px",
		} as React.CSSProperties,
		header: {
			width: "100%",
			boxSizing: "border-box",
			padding: "0 24px"
		} as React.CSSProperties,
		body: {
			width: "100%",
		} as React.CSSProperties,
		title: {
			fontSize: 17,
			color: theme.text_05,
			textAlign: 'left',
			marginBottom: 5
		} as React.CSSProperties,
		subTitle: {
			fontSize: 17,
			color: theme.text_05,
			textAlign: 'left',
			marginBottom: 20
		} as React.CSSProperties,
		warningContainer: {
			width: '100%',
			paddingRight: "20px",
			boxSizing: "border-box"
		} as React.CSSProperties,
		warningItem: {
			display: "flex",
			alignItems: 'flex-start',
			marginBottom: 40
		} as React.CSSProperties,
		iconContainer: {
			marginRight: 10
		} as React.CSSProperties,
		icon: {
			width: 36,
			height: 36,
			top: 3
		} as React.CSSProperties,
		warningTextContainer: {
		} as React.CSSProperties,
		warningText1: {
			fontSize: 16,
			color: theme.text_05,
			marginBottom: 2
		} as React.CSSProperties,
		warningText2: {
			fontSize: 14,
			color: theme.text_03
		} as React.CSSProperties,
		footer: {
			display: "flex",
			justifyContent: 'flex-end',
			width: '100%',
			paddingRight: "20px",
			paddingLeft: "20px",
			boxSizing: "border-box"
		} as React.CSSProperties,
		button: {
			backgroundColor: theme.button_02,
			padding: 15,
			borderRadius: 10,
			width: '100%',
			display: "flex",
			alignItems: 'center',
			height: 54,
			justifyContent: 'center',
		} as React.CSSProperties,
		buttonText: {
			color: theme.text_01,
			fontSize: 16
		} as React.CSSProperties,
		modalBackground: {
			display: "flex",
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: theme.modal_background_01,
			position: "absolute",
			width: "100%",
			height: "100%",
			top: 0,
			left: 0
		} as React.CSSProperties,
	}

	return (
		<div className='container' style={{ ...styles.container, backgroundColor: theme.primaryBackgroundColor }}>
			<div style={styles.header}>
				<CustomText weight="medium" style={{ ...styles.title, color: theme.primaryText }}>
					{t('recovery_syntax_guide_message_01')}
				</CustomText>
				<CustomText weight="medium" style={{ ...styles.subTitle, color: theme.primaryText }}>
					{t('recovery_syntax_guide_message_02')}
				</CustomText>
			</div>
			<div style={styles.body}>
				<div style={styles.warningContainer}>
					<div style={styles.warningItem}>
						<div style={styles.iconContainer}>
							<img
								src="/img/dont-share.png"
								alt="Do not share"
								style={styles.icon}
							/>
						</div>
						<div style={styles.warningTextContainer}>
							<CustomText style={{ ...styles.warningText1, color: theme.primaryText }}>
								{t('recovery_syntax_guide_message_03')}
							</CustomText>
							<CustomText style={{ ...styles.warningText1, color: theme.primaryText }}>
								{t('recovery_syntax_guide_message_04')}
							</CustomText>
							<CustomText style={{ ...styles.warningText2, color: theme.secondaryText }}>
								{t('recovery_syntax_guide_message_05')}
							</CustomText>
						</div>
					</div>
					<div style={styles.warningItem}>
						<div style={styles.iconContainer}>
							<img
								src="/img/secure.png"
								alt="Keep it secure"
								style={styles.icon}
							/>
						</div>
						<div style={styles.warningTextContainer}>
							<CustomText style={{ ...styles.warningText1, color: theme.primaryText }}>
								{t('recovery_syntax_guide_message_06')}
							</CustomText>
							<CustomText style={{ ...styles.warningText1, color: theme.primaryText }}>
								{t('recovery_syntax_guide_message_07')}
							</CustomText>
							<CustomText style={{ ...styles.warningText2, color: theme.secondaryText }}>
								{t('recovery_syntax_guide_message_08')}
							</CustomText>
							<CustomText style={{ ...styles.warningText2, color: theme.secondaryText }}>
								{t('recovery_syntax_guide_message_09')}
							</CustomText>
						</div>
					</div>
				</div>
			</div>
			<div style={styles.footer}>
				{/* 확인 버튼 */}
				<CustomButton
					containerStyle={{ ...styles.button, backgroundColor: theme.primary }}
					textStyle={styles.buttonText}
					onPress={handleConfirmPress}
					text={t('recovery_syntax_guide_message_11')}
					withBackground={true}
					backgroundColor={theme.primaryTouchBackgroundColor}
				/>
			</div>

			{
				showAuthModal && (
					<div style={styles.modalBackground}>
						<PinCodeScreen onSuccess={handleAuthSuccess} unlock={false} />
					</div>
				)
			}

		</div>
	)
}

export default SecuritySecretPhraseWarning
